import request from '@/utils/request'

export function getRegion(params) {
  return request({
    url: 'dataManagement/userData/global/region',
    method: 'get',
    params,
  })
}

export function getIncrease(data) {
  return request({
    url: 'dataManagement/userData/global/increase',
    method: 'post',
    data,
  })
}
export function getActive(params) {
  return request({
    url: 'dataManagement/userData/global/active',
    method: 'get',
    params,
  })
}
