import request from '@/utils/request'

export function getDeviceIotInfoList(params) {
  return request({
    url: '/logManagement/getDeviceIotInfoList',
    method: 'get',
    params,
  })
}

export function doAddOrEdit(data) {
  return request({
    url: '/userManagement/AddOrEditAccount',
    method: 'post',
    data,
  })
}

export function doEdit(data) {
  return request({
    url: '/userManagement/editAccount',
    method: 'post',
    data,
  })
}

export function deleteFeedBack(data) {
  return request({
    url: '/userManagement/deleteFeedBack',
    method: 'post',
    data,
  })
}
