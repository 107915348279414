<template>
  <el-col>
    <el-card shadow="hover">
      <template #header>
        <span style="font-size: 17px">{{ title }}</span>
        <el-tooltip placement="top">
          <div slot="content">
            累计销售额里云存储、AI识别和4G卡
            <br />
            这三种服务的占比，分为人民币和美元显示。
          </div>
          <vab-icon icon="information-line" />
        </el-tooltip>
      </template>
      <vab-chart
        v-loading="loading"
        :init-options="initOptions"
        :option="option"
        style="height: 220px"
        theme="vab-echarts-theme"
      />
    </el-card>
  </el-col>
</template>

<script>
  import VabChart from '@/extra/VabChart'

  export default {
    name: 'VabChartBar',
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: true,
        queryForm: {},
        initOptions: {
          renderer: 'svg',
        },
        option: {
          color: ['#60b2fb', '#ffe153', '#6485f6'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            bottom: -5,
            type: 'scroll',
          },
          grid: {
            top: '0%',
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}',
            },
          },
          yAxis: {
            type: 'category',
            inverse: true,
            data: ['人民币', '美元'],
          },
          series: [
            {
              name: '云存储',
              type: 'bar',
              stack: 'total',
              emphasis: {
                focus: 'series',
              },
              data: [],
            },
            {
              name: 'AI识别',
              type: 'bar',
              stack: 'total',
              emphasis: {
                focus: 'series',
              },
              data: [],
            },
            {
              name: '4G卡',
              type: 'bar',
              stack: 'total',
              emphasis: {
                focus: 'series',
              },
              data: [],
            },
          ],
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.$root.eventVueOv.$on(
          'data-vodOrderAllTotal',
          (vodOrderAllTotal) => {
            this.option.series[0].data.push(vodOrderAllTotal.cnyTotal)
            this.option.series[0].data.push(vodOrderAllTotal.usdTotal)
            this.$root.eventVueOv.$on(
              'data-aiOrderAllTotal',
              (aiOrderAllTotal) => {
                this.option.series[1].data.push(aiOrderAllTotal.cnyTotal)
                this.option.series[1].data.push(aiOrderAllTotal.usdTotal)
                this.$root.eventVueOv.$on(
                  'data-unicomOrderAllTotal',
                  (unicomOrderAllTotal) => {
                    this.option.series[2].data.push(
                      unicomOrderAllTotal.cnyTotal
                    )
                    this.option.series[2].data.push(
                      unicomOrderAllTotal.usdTotal
                    )
                    this.loading = false
                  }
                )
              }
            )
          }
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-card {
      height: 300px !important;
    }
  }
  .el-card ::v-deep .el-card__header {
    padding: 10px;
  }
</style>
