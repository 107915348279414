<template>
  <div class="loss-early-warning-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userName"
              clearable
              placeholder="用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="设备uid"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.grade"
              clearable
              placeholder="预警级别"
            >
              <el-option key="一号预警" label="一号预警" value="一号预警" />
              <el-option key="二号预警" label="二号预警" value="二号预警" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-menu" :loading="buttonLoading" type="success" @click="downLoadExecl">
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="用户名"
        prop="username"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="用户ID"
        prop="userID_id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备UID"
        prop="uid"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备自云存开通起多少天没有上传数据"
        prop="day"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="numberFormat"
        label="设备数量"
        prop="device_number"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="deviceFormat"
        label="用户是否上传过数据"
        prop="other_device"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusTypeFormat"
        label="是否有有效期内的付费套餐"
        prop="use_status"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="预警级别"
        prop="grade"
        show-overflow-tooltip
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAlert, download } from '@/api/lossEarlyWarning'

  export default {
    name: 'LossWarning',
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      numberFormat(row) {
        if (row.device_number === 1) {
          return 'N/A'
        } else {
          return row.device_number
        }
      },
      statusTypeFormat(row) {
        if (row.use_status === 1) {
          return '有'
        } else if (row.use_status === 2) {
          return '无'
        }
      },
      deviceFormat(row) {
        if (row.other_device === 1) {
          return '有'
        } else if (row.other_device === 0) {
          return '无'
        }
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { result_list, total },
        } = await getAlert(this.queryForm)
        this.list = result_list
        this.total = total
        this.listLoading = false
      },
      downLoadExecl() {
        download().then((re) => {
          console.log(re)
          let blob = new Blob([re], {
            type: 'application/vnd.ms-excel;charset=utf-8',
          })
          let downloadUrl = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          //anchor.style.display = "none";
          a.href = downloadUrl
          // 这里的filename 带有后缀，能决定文件的类型
          a.setAttribute('download', '流失预警.xlsx')
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
          .catch((re) => {
            console.log(re)
          })
      }
    },
  }
</script>
