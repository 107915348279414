<template>
  <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
    <el-card v-show="!allFold" :height="height" shadow="hover">
      <el-button type="primary" @click="handleOpen">
        <vab-icon
          class="vab-dropdown"
          :class="{ 'vab-dropdown-active': fold }"
          icon="arrow-up-s-line"
        />
        类型统计
      </el-button>
      <el-tooltip placement="top">
        <div slot="content">
          选定时间内新增设备或活跃设备的类型统计
          <br />
          注意：未知设备类型是由于当时并未统计特定设备类型
        </div>
        <vab-icon icon="information-line" style="color: #409eff" />
      </el-tooltip>
      <el-tabs v-show="!fold" v-model="activeName" :height="height">
        <el-row :gutter="20">
          <el-col :lg="8" :md="12" :sm="24" :xl="6" :xs="24">
            <chart-piea />
          </el-col>
          <el-col :lg="16" :md="12" :sm="24" :xl="18" :xs="24">
            <chart-tablea />
          </el-col>
        </el-row>
      </el-tabs>
    </el-card>
  </el-col>
</template>

<script>
  import chartPiea from './chartPiea'
  import chartTablea from './chartTablea'

  export default {
    name: 'Echarts',
    components: {
      chartTablea,
      chartPiea,
    },
    data() {
      return {
        fold: false,
        allFold: false,
        height: this.$baseTableHeight(3) - 30,
        activeName: 'first',
      }
    },
    created() {
      this.getData()
    },
    methods: {
      handleOpen() {
        this.fold = !this.fold
        this.handleHeight()
      },
      // handleFold() {
      //   this.fold = true
      //   this.handleHeight()
      // },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      getData() {
        this.$root.eventVueDevice.$on('data-X', (data) => {
          if (data == 'newUser') {
            this.allFold = false
          } else {
            this.allFold = true
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tabs {
    &-card {
      height: 334px;
    }
  }
</style>
