<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="数量" prop="cdknum">
        <el-input v-model.trim="form.cdknum" />
      </el-form-item>
      <el-form-item label="国内外" prop="mold">
        <el-select v-model="form.mold" placeholder="请选择国内外">
          <el-option key="国内" label="国内" value="0" />
          <el-option key="国外" label="国外" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="套餐" prop="rank">
        <el-select v-model="form.rank" placeholder="请选择套餐">
          <el-option
            v-for="item in mealList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { doEdit, getVodStoreMeal } from '@/api/cdk'

  export default {
    name: 'UserInformation',
    data() {
      return {
        queryForm: { isSelect: 'true' },
        moldList: [],
        roleList: [],
        mealList: [],
        form: {
          role: '',
          isEdit: '',
        },
        rules: {
          cdknum: [{ required: true, trigger: 'blur', message: '请输入数量' }],
          mold: [{ required: true, trigger: 'blur', message: '请选择国内外' }],
          rank: [{ required: true, trigger: 'blur', message: '请选择套餐' }],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {
      this.getMeal()
    },
    methods: {
      showEdit() {
        this.title = '生成'
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await doEdit(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      async getMeal() {
        const {
          data,
        } = await getVodStoreMeal()
        this.mealList = data
        console.log(mealList)
      },
    },
  }
</script>
