<script src="//unpkg.com/vue/dist/vue.js"></script>
<script src="//unpkg.com/element-ui@2.15.5/lib/index.js"></script>
<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="15">
        <el-form :inline="false" :model="queryForm" @submit.native.prevent>
          <el-tag>最大标签数</el-tag>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.maxLabels"
              clearable
              placeholder="请输入最大标签数"
            />
          </el-form-item>
          <el-tag>最小辨识度</el-tag>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.minConfidence"
              clearable
              placeholder="请输入最小辨识度"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-s-promotion"
              type="primary"
              @click="submitForm"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
        <!-- 上传文件doc: https://element.eleme.cn/#/zh-CN/component/upload -->
        <el-upload
          ref="upload"
          action=""
          :auto-upload="false"
          class="upload-demo"
          :file-list="fileList"
          :headers="headers"
          :http-request="upLoadFile"
          list-type="picture"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
        >
          <el-button size="small" type="primary">选择图片</el-button>
          <div slot="tip" class="el-upload__tip">
            上传单张图片, 必须是 .jpeg 或 .png 格式, 不得大于5MB
          </div>
        </el-upload>

        <div style="margin: 50px 0"></div>
        <el-input
          id="uploadRes"
          v-model="textarea"
          :autosize="{ minRows: 15, maxRows: 100 }"
          clearable
          placeholder="响应数据"
          type="textarea"
        />
      </vab-query-form-left-panel>
    </vab-query-form>
  </div>
</template>

<script>
  const axios = require('axios')
  import network from '@/config/index.js' // 引用 baseURL
  export default {
    name: 'AItest',
    data() {
      return {
        queryForm: {
          maxLabels: 5,
          minConfidence: 55,
        },
        textarea: '',
        fileList: [],
        responseData: {},
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    },
    methods: {
      upLoadFile(file) {
        this.formData.append('file', file.file)
      },
      submitForm({ commit }) {
        // 构建传给后端的数据
        this.formData = new FormData()
        this.formData.append('maxLabels', this.queryForm.maxLabels)
        this.formData.append('minConfidence', this.queryForm.minConfidence)
        this.$refs.upload.submit() // 会触发 http-request,即 upLoadFile 方法
        let url = network.baseURL + '/testServe/AItest'
        console.log('url: ' + url)
        let headers = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        axios
          .post(url, this.formData, headers)
          .then((res) => {
            console.log(res)
            res = JSON.stringify(res) // 响应转为字符串
            this.textarea = res // 响应写入输入框
          })
          .catch((res) => {
            console.log(res)
          })
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        this.textarea = '' // 清空输入框
      },
      handlePreview(file) {
        console.log(file)
      },
    },
  }
</script>
