<template>
  <div
    class="order-operation-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-right-panel :span="50">
        <el-form
          :inline="true"
          :model="queryForm"
          @submit.native.prevent="fetchData"
        >
          <el-form-item>
            <el-date-picker
              v-model="time"
              align="center"
              end-placeholder="结束月份"
              range-separator="-"
              start-placeholder="开始月份"
              type="monthrange"
              value-format="timestamp"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="fetchData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="clickFullScreen">
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-download"
              type="primary"
              @click="hendleDownload"
            >
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '开始使用时间'">
            {{ row[item.prop] | timeFilter }}
          </span>
          <span v-else-if="item.label === '使用月份'">
            {{ row[item.prop] | monthFileter }}
          </span>
          <span v-else-if="item.label === '结束时间'">
            {{ row[item.prop] | timeFilter }}
          </span>
          <span v-else-if="item.label === '当月结算时间'">
            {{ row[item.prop] | timeFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <Download ref="download" />
  </div>
</template>

<script>
  import { getOperatingCosts } from '@/api/deviceOrder'
  // const axios = require('axios')
  import { formatDate } from '@/utils/formatDate'
  import Download from './components/downloadExcel'

  export default {
    name: 'OrderOperation',
    components: { Download },
    filters: {
      timeFilter(val) {
        if (val === 0) {
          return 'N/A'
        }
        let date = val * 1000
        return formatDate(new Date(date), 'yyyy-MM-dd hh:mm:ss')
      },
      monthFileter(val) {
        if (val === 0) {
          return 'N/A'
        }
        let date = val * 1000
        return formatDate(new Date(date), 'yyyy-MM')
      },
    },
    data() {
      return {
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          page: 1,
          line: 10,
        },
        time: [],
        columns: [
          {
            label: '订单号',
            prop: 'order_id',
            width: '180',
          },
          {
            label: 'UID',
            prop: 'uid',
            width: '210',
          },
          {
            label: '国内外',
            prop: 'region',
          },
          {
            label: '地区',
            prop: 'country_name',
          },
          {
            label: '类别',
            prop: 'expire',
          },
          {
            label: '套餐类型',
            prop: 'order_type',
          },
          {
            label: '订单金额',
            prop: 'price',
          },
          {
            label: '订单费用',
            prop: 'fee',
          },
          {
            label: '订单实收',
            prop: 'real_income',
          },
          {
            label: '收入分摊/天',
            prop: 'day_average_price',
            width: '100',
          },
          {
            label: '收入分摊/月',
            prop: 'month_average_price',
            width: '100',
          },
          {
            label: '购买量/月',
            prop: 'purchase_quantity',
            width: '100',
          },
          {
            label: '使用月份',
            prop: 'time',
            width: '100',
          },
          {
            label: '开始使用时间',
            prop: 'start_time',
            width: '180',
          },
          {
            label: '结束时间',
            prop: 'end_time',
            width: '180',
          },
          {
            label: '当月结算时间',
            prop: 'created_time',
            width: '180',
          },
          {
            label: '当月结算天数',
            prop: 'settlement_days',
            width: '110',
          },
          {
            label: '当月结算收入',
            prop: 'monthly_income',
            width: '110',
          },
          {
            label: '剩余使用时间（天）',
            prop: 'remaining_usage_time',
            width: '150',
          },
          {
            label: '实际存储量',
            prop: 'actual_storage',
            width: '110',
          },
          {
            label: '存储桶成本',
            prop: 'storage_cost',
            width: '110',
          },
          {
            label: '实际调用api次数',
            prop: 'actual_api',
            width: '130',
          },
          {
            label: 'api调用成本',
            prop: 'api_cost',
            width: '110',
          },
          {
            label: '实际流量',
            prop: 'actual_flow',
          },
          {
            label: '流量成本',
            prop: 'flow_cost',
          },
          {
            label: '利润',
            prop: 'profit',
          },
          {
            label: '利润率',
            prop: 'profit_margin',
          },
        ],
      }
    },
    created() {
      const currentDate = new Date() // 当前日期
      const currentYear = currentDate.getFullYear() // 当前年份
      const currentMonth = currentDate.getMonth() // 当前月份

      // 获取上个月的时间戳
      const lastMonthTimestamp = new Date(currentYear, currentMonth).getTime()
      this.time[0] = lastMonthTimestamp

      // 获取当月的时间戳
      const currentMonthTimestamp = new Date(
        currentYear,
        currentMonth + 1
      ).getTime()
      this.time[1] = currentMonthTimestamp

      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      handleSizeChange(val) {
        this.queryForm.line = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      hendleDownload() {
        this.$refs['download'].showComponent()
      },
      queryData() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        if (this.time) {
          this.queryForm.startTime = this.time[0] / 1000
          this.queryForm.endTime = this.time[1] / 1000
        }
        const {
          result: { res, count },
        } = await getOperatingCosts(this.queryForm)
        this.list = res
        this.total = count
        this.listLoading = false
        // const url =
        //   'http://129.153.85.188:8800/dataManagement/costsData/getOperatingCosts'
        // const token =
        //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiIxNTE1NjQyNjIzMzc5Mzk1MTM4MDAxMzgwMDEiLCJsYW5nIjoiZW4iLCJ1c2VyIjoiMTM4MDAxMzgwMDEiLCJtX2NvZGUiOiIxMjM0MTMyNDMyMTQiLCJleHAiOjE3MjE3ODI0NzJ9.zv0lEanSFgIoXOcdOkZBlU8g4kKcPe9CQqkVaztFiww'
        // const headers = {
        //   Authorization: `Bearer ${token}`,
        // }
        // const params = this.queryForm
        // axios.get(url, { params, headers }).then(
        //   function (res) {
        //     this.list = res.data.result.res
        //     this.total = res.data.result.count
        //     this.listLoading = false
        //   }.bind(this)
        // )
      },
    },
  }
</script>
