<template>
  <el-dialog title="核对结果" :visible.sync="dialogVisible" width="1000px">
    <el-table border :data="list">
      <el-table-column
        align="center"
        label="uid"
        prop="uid"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备名称"
        prop="deviceName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="ip"
        prop="ip"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="用户名"
        prop="uName"
        show-overflow-tooltip
      />
    </el-table>
    <template #footer>
      <el-button @click="dialogVisible = false">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'CheckDialog',
    data() {
      return {
        list: [],
        dialogVisible: false,
      }
    },
    methods: {
      showComponent(val) {
        this.dialogVisible = true
        this.list = val
      },
    },
  }
</script>
