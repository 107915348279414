<template>
  <el-card class="unicomStat-container" shadow="hover">
    <vab-query-form>
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.query_type"
              placeholder="流量类型"
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.iccid"
              clearable
              placeholder="iccid"
              style="width: 200px; margin-left: 8px"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.user_id"
              clearable
              placeholder="用户id"
              style="width: 200px; margin-left: 8px"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.year"
              clearable
              placeholder="选择年份"
              size="small"
              style="width: 100px; margin-left: 8px"
            >
              <el-option
                v-for="item in yearsList"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.month"
              clearable
              placeholder="选择月份"
              size="small"
              style="width: 110px; margin-left: 8px"
            >
              <el-option
                v-for="item in monthList"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="iccid"
        prop="iccid"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="用户id"
        prop="user_id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="流量"
        prop="flow_total_usage"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="110"
      >
        <template #default="{ row }">
          <el-button
            v-show="detailButtonShow"
            type="primary"
            @click="handleDetail(row)"
          >
            明细
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.line"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <detail ref="detail" @fetch-data="fetchData" />
  </el-card>
</template>

<script>
  import { getFlowInfo } from '@/api/unicomCostData'
  import Detail from './details'

  export default {
    name: 'UnicomStat',
    components: { Detail },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        detailButtonShow: 0,
        totalFlow: 0,
        queryForm: {
          query_type: 1,
          page: 1,
          line: 10,
        },
        typeList: [
          {
            value: 1,
            label: '查询测试总流量',
          },
          {
            value: 2,
            label: '月度流量',
          },
        ],
        yearsList: [],
        monthList: [],
      }
    },
    created() {
      this.fetchData()
      this.initYears()
      this.initMonth()
    },
    methods: {
      handleDetail(row) {
        this.$refs['detail'].showDetail(row)
      },
      handleSizeChange(val) {
        this.queryForm.line = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.page = 1
        this.fetchData()
      },
      initYears() {
        let myDate = new Date()
        let year = myDate.getFullYear() //获取当前年
        this.initSelectYear(year)
      },
      initSelectYear(year) {
        this.yearsList = []
        for (let i = 0; i < 30; i++) {
          this.yearsList.push({ value: year - i, label: year - i + ' 年' })
        }
      },
      initMonth() {
        for (let i = 0; i < 12; i++) {
          this.monthList.push({ value: i + 1, label: i + 1 + ' 月' })
        }
      },
      postData() {
        this.$store.commit('dataStat/changeUnicomYear', this.queryForm.year)
        this.$store.commit('dataStat/changeUnicomMonth', this.queryForm.month)
      },
      getSummaries(param) {
        const sums = []
        sums[0] = ''
        sums[3] = '流量合计：' + this.totalFlow
        return sums
      },
      async fetchData() {
        this.listLoading = true
        const {
          result: { iccidInfo, count, totalFlow },
        } = await getFlowInfo(this.queryForm)
        this.list = iccidInfo
        this.total = count
        this.totalFlow = totalFlow
        this.listLoading = false
        this.postData()
        if (this.queryForm.query_type === 2) {
          this.detailButtonShow = 1
        } else {
          this.detailButtonShow = 0
        }
      },
    },
  }
</script>
