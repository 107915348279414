<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form">
      <el-form-item label="软件名称" prop="pc_name">
        <el-input v-model.trim="form.pc_name" clearable />
      </el-form-item>
      <el-form-item label="项目类型" prop="bundle_version">
        <el-select
          v-model="form.bundle_version"
          clearable
          placeholder="请选择项目类型"
        >
          <el-option
            v-for="item in bundleVersionList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="版本号" prop="pc_version">
        <el-input v-model.trim="form.pc_version" clearable />
      </el-form-item>
      <el-form-item label="测试版" prop="pc_test">
        <el-select
          v-model="form.pc_test"
          clearable
          placeholder="请选择是否为测试版"
        >
          <el-option
            v-for="item in pcTestList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="语言类型" prop="lang">
        <el-input v-model.trim="form.lang" clearable />
      </el-form-item>
      <el-form-item label="文件类型" prop="file_type">
        <el-input v-model.trim="form.file_type" clearable />
      </el-form-item>
      <el-form-item label="整合包id" prop="package">
        <el-input v-model.trim="form.package" clearable />
      </el-form-item>
      <el-form-item label="更新内容" prop="explain">
        <el-input
          v-model.trim="form.explain"
          :autosize="{ minRows: 2, maxRows: 4 }"
          clearable
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="强制更新" prop="is_update">
        <el-select v-model="form.is_update" placeholder="请选择是否强制更新">
          <el-option
            v-for="item in isUpdateList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="开启版本" prop="is_open">
        <el-select v-model="form.is_open" placeholder="请选择是否开启版本">
          <el-option
            v-for="item in isOpenList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="内容信息" prop="content">
        <el-input
          v-model.trim="form.content"
          :autosize="{ minRows: 2, maxRows: 4 }"
          clearable
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="权限" prop="authority">
        <el-input v-model.trim="form.authority" clearable />
      </el-form-item>
      <el-form-item label="文件路径" prop="download_link">
        <el-input v-model.trim="form.download_link" />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { editPcVersion } from '@/api/pcVersion'

  export default {
    name: 'NewVersionInformation',
    data() {
      return {
        queryForm: { isSelect: 'true' },
        bundleVersionList: [
          {
            label: 'Zosi',
            value: 0,
          },
          {
            label: 'COCOON HD',
            value: 149,
          },
          {
            label: 'Loocam',
            value: 150,
          },
          {
            label: '中性',
            value: 151,
          },
          {
            label: 'Ansjer',
            value: 153,
          },
        ],
        pcTestList: [
          { label: '否', value: 0 },
          { label: '是', value: 1 },
        ],
        isUpdateList: [
          {
            label: '否',
            value: 0,
          },
          {
            label: '是',
            value: 1,
          },
        ],
        isOpenList: [
          {
            label: '否',
            value: 0,
          },
          {
            label: '是',
            value: 1,
          },
        ],

        form: {},
        title: '',
        dialogFormVisible: false,
      }
    },
    methods: {
      showEdit(row) {
        this.title = '编辑'
        this.form = Object.assign({}, row)
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await editPcVersion(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>
