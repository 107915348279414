<template>
  <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
    <el-card shadow="hover">
      <el-button type="primary" @click="handleOpen">
        <vab-icon
          class="vab-dropdown"
          :class="{ 'vab-dropdown-active': fold }"
          icon="arrow-up-s-line"
        />
        地区统计
      </el-button>
      <el-tooltip placement="top">
        <div slot="content">
          显示不同设备不同套餐的地区总数统计
          <br />
          注意：未知国家是由于当时并未统计特定国家
        </div>
        <vab-icon icon="information-line" style="color: #409eff" />
      </el-tooltip>
      <el-tabs v-show="!fold" v-model="activeName" :height="height">
        <el-row :gutter="20">
          <el-col :lg="8" :md="12" :sm="24" :xl="6" :xs="24">
            <chart-pie />
          </el-col>
          <el-col :lg="16" :md="12" :sm="24" :xl="18" :xs="24">
            <chart-table />
          </el-col>
        </el-row>
      </el-tabs>
    </el-card>
  </el-col>
</template>

<script>
  import chartPie from './chartPie'
  import chartTable from './chartTable'

  export default {
    name: 'Echarts',
    components: {
      chartTable,
      chartPie,
    },
    data() {
      return {
        fold: false,
        height: this.$baseTableHeight(3) - 30,
        activeName: 'first',
      }
    },
    methods: {
      handleOpen() {
        this.fold = !this.fold
        this.handleHeight()
      },
      // handleFold() {
      //   this.fold = true
      //   this.handleHeight()
      // },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
    },
  }
</script>

<style lang="scss" scoped>
  .echarts-container {
    padding: 0 !important;
    background: $base-color-background !important;
    ::v-deep {
      .echarts {
        width: 100%;
      }
    }
  }
</style>
