<template>
  <div class="tabs">
    <el-card class="tabs-cards" shadow="hover">
      <el-row class="row-bg" justify="space-between" type="flex">
        <el-button type="text" @click="handleFold">
          <span v-if="fold">展开明细数据</span>
          <span v-else>收起明细数据</span>
          <vab-icon
            class="vab-dropdown"
            :class="{ 'vab-dropdown-active': fold }"
            icon="arrow-up-s-line"
          />
        </el-button>
        <el-button
          v-if="$perms('serve_cloudUser_export')"
          icon="el-icon-menu"
          :loading="daochu == true"
          type="success"
          @click="handleDownload"
        >
          导出
        </el-button>
      </el-row>
      <el-tabs v-show="!fold" v-model="activeName" :height="height">
        <el-table :data="queryData" height="280">
          <el-table-column
            :formatter="timeFormat"
            label="日期"
            prop="startTime"
          />
          <el-table-column label="新增用户（占比）" prop="rate" />
        </el-table>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        fold: false,
        // height: this.$baseTableHeight(3) - 30,
        activeName: 'first',
        queryData: [],
      }
    },
    created() {
      this.getData()
    },
    methods: {
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      // handleDownload() {
      //   this.daochu = true
      //   down(this.queryForm)
      // },
      getData() {
        this.$root.eventVue.$on('data-E', (data) => {
          console.log(data)
          this.queryData = data
        })
      },
      timeFormat(row) {
        if (row.startTime === 0) {
          return 0
        } else {
          var date = new Date(row.startTime * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-card {
      height: 383px !important;
    }
  }
</style>
