<template>
  <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
    <div class="tabs">
      <el-card class="tabs-cards" shadow="hover">
        <el-row class="row-bg" justify="space-between" type="flex">
          <el-col>
            <el-button type="primary" @click="handleFold">
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': !fold }"
                icon="arrow-up-s-line"
              />
              设备数据明细表
            </el-button>
            <el-tooltip placement="top">
              <div slot="content">提供所有应用在核心指标上的明细数据</div>
              <vab-icon icon="information-line" style="color: #409eff" />
            </el-tooltip>
          </el-col>
          <download-excel
            :before-finish="finishDownload"
            :before-generate="startDownload"
            class="export-excel-wrapper"
            :data="excelData"
            :fields="json_fields"
            :header="title"
            name="设备数据明细表.xls"
          >
            <el-button
              icon="el-icon-menu"
              :loading="buttonLoading"
              type="success"
            >
              导出
            </el-button>
          </download-excel>
        </el-row>
        <el-tabs>
          <el-table v-loading="loading" :data="queryData" :height="height">
            <el-table-column
              :formatter="timeFormat"
              label="日期"
              prop="startTime"
            />
            <el-table-column label="新增设备（占比）" prop="count,rate">
              <template slot-scope="scope">
                {{ scope.row.count }} ({{ scope.row.rate }}%)
              </template>
            </el-table-column>
            <el-table-column label="活跃设备（占比）" prop="aCount,acRate">
              <template slot-scope="scope">
                {{ scope.row.aCount }} ({{ scope.row.acRate }}%)
              </template>
            </el-table-column>
          </el-table>
        </el-tabs>
      </el-card>
    </div>
  </el-col>
</template>

<script>
  import { getIncrease, getActive } from '@/api/deviceData'

  export default {
    components: {},
    data() {
      return {
        loading: true,
        buttonLoading: false,
        title: '用户数据',
        json_fields: {
          日期: {
            field: 'startTime',
            callback: (value) => {
              return this.timesFormat(value)
            },
          },
          新增设备占比: 'count',
          活跃设备占比: 'aCount',
        },
        fold: false,
        height: 260,
        activeName: 'first',
        excelData: [],
        queryData: [],
        timeForm: {
          startTime: '',
          endTime: '',
          timeUnit: 'day',
          orderType: 0,
        },
        firstData: [],
        secondData: [],
      }
    },
    created() {
      this.getNowDate()
      this.getDate()
      this.getUnit()
      this.getActiveData()
      this.getIncreaseData()
    },
    methods: {
      async getIncreaseData() {
        const {
          result: { device },
        } = await getIncrease(this.timeForm)
        let getData = []
        for (let i in device) {
          let obj = new Object()
          obj.startTime = device[i].startTime
          obj.count = device[i].count
          obj.rate = device[i].rate
          getData[i] = obj
        }
        this.firstData = getData
        this.mixData()
      },
      async getActiveData() {
        const {
          result: { device },
        } = await getActive(this.timeForm)
        let getData = []
        for (let i in device) {
          let obj = new Object()
          obj.aCount = device[i].count
          obj.acRate = device[i].rate
          getData[i] = obj
        }
        this.secondData = getData
      },
      mixData() {
        let getData = this.firstData
        let getDataNo = this.secondData
        let newData = []
        for (let i in getData) {
          let obj = new Object()
          obj.startTime = getData[i].startTime
          obj.count = getData[i].count
          obj.rate = getData[i].rate
          obj.aCount = getDataNo[i].aCount
          obj.acRate = getDataNo[i].acRate
          newData[i] = obj
        }
        this.queryData = newData
        this.loading = false
        this.getExcelData()
      },
      handleFold() {
        this.fold = !this.fold
        if (this.fold) {
          this.height = 520
        } else {
          this.height = 260
        }
      },
      getDate() {
        this.$root.eventVueDevice.$on('data-A', (data) => {
          let getData = data
          this.timeForm.startTime = getData[0] / 1000
          this.timeForm.endTime = getData[1] / 1000
          this.getActiveData()
          this.getIncreaseData()
        })
      },
      getUnit() {
        this.$root.eventVueDevice.$on('data-B', (data) => {
          this.timeForm.timeUnit = data
          this.getActiveData()
          this.getIncreaseData()
        })
      },
      getNowDate() {
        let myDate = new Date()
        let year = myDate.getFullYear() //获取当前年
        let mon = myDate.getMonth() + 1 //获取当前月
        if (mon < 10) {
          mon = '0' + mon
        }
        let date = myDate.getDate() //获取当前日
        if (date < 10) {
          date = '0' + date
        }
        let now =
          year + '-' + mon + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00'
        let nowAny = ''
        nowAny = Date.parse(now)
        this.timeForm.endTime = nowAny / 1000
        this.timeForm.startTime = this.timeForm.endTime - 1 * 60 * 60 * 24 * 7
      },
      getExcelData() {
        let excelData = this.queryData
        let newData = []
        for (let i in excelData) {
          let obj = new Object()
          obj.startTime = excelData[i].startTime
          obj.count = excelData[i].count + '(' + excelData[i].rate + '%' + ')'
          obj.aCount =
            excelData[i].aCount + '(' + excelData[i].acRate + '%' + ')'
          newData[i] = obj
        }
        this.excelData = newData
      },
      startDownload() {
        this.buttonLoading = true
      },
      finishDownload() {
        this.buttonLoading = false
      },
      timeFormat(row) {
        if (row.startTime === 0) {
          return 0
        } else {
          let date = new Date(row.startTime * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
      timesFormat(val) {
        if (val === 0) {
          return 0
        } else {
          let date = new Date(val * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-table {
      overflow: auto;
      &::before {
        height: 0px !important;
      }
    }
  }
</style>
