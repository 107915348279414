<template>
  <div class="echarts-container">
    <el-row :gutter="20">
      <data-picker />
      <chart-line title="新增趋势" />
      <chart-collection />
      <chart-collectiona />
      <chart-pieb />
      <chart-piec />
      <chart-pied />
      <chart-piee />
      <chart-tableb />
    </el-row>
  </div>
</template>

<script>
  import dataPicker from './components/dataPicker'
  import chartLine from './components/chartLine'
  import chartCollection from './components/chartCollection'
  import chartCollectiona from './components/chartCollectiona'
  import chartTableb from './components/chartTableb'
  import chartPieb from './components/chartPieb'
  import chartPiec from './components/chartPiec'
  import chartPied from './components/chartPied'
  import chartPiee from './components/chartPiee'

  export default {
    name: 'Echarts',
    components: {
      dataPicker,
      chartLine,
      chartCollection,
      chartCollectiona,
      chartTableb,
      chartPieb,
      chartPiec,
      chartPied,
      chartPiee,
    },
    data() {
      return {}
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .echarts-container {
    padding: 0 !important;
    background: $base-color-background !important;
    ::v-deep {
      .echarts {
        width: 100%;
        height: 280px;
      }
    }
  }
</style>
