<template>
  <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
    <div class="dataPicker">
      <el-card class="" shadow="hover">
        <el-date-picker
          v-model="queryForm.timeRange"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          type="daterange"
          value-format="timestamp"
          @change="postData"
        />
      </el-card>
    </div>
  </el-col>
</template>

<script>
  import Vue from 'vue'
  window.Event = new Vue()
  export default {
    name: '',
    components: {},
    data() {
      return {
        msg: 'msg',
        pickerOptions: {
          shortcuts: [
            {
              text: '过去7天',
              onClick(picker) {
                let myDate = new Date()
                let year = myDate.getFullYear() //获取当前年
                let mon = myDate.getMonth() + 1 //获取当前月
                if (mon < 10) {
                  mon = '0' + mon
                }
                let date = myDate.getDate() //获取当前日
                if (date < 10) {
                  date = '0' + date
                }
                let now =
                  year +
                  '-' +
                  mon +
                  '-' +
                  date +
                  ' ' +
                  '00' +
                  ':' +
                  '00' +
                  ':' +
                  '00'
                let nowAny = ''
                nowAny = Date.parse(now)
                const end = nowAny
                const start = nowAny - 1000 * 60 * 60 * 24 * 7
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '过去30天',
              onClick(picker) {
                let myDate = new Date()
                let year = myDate.getFullYear() //获取当前年
                let mon = myDate.getMonth() + 1 //获取当前月
                if (mon < 10) {
                  mon = '0' + mon
                }
                let date = myDate.getDate() //获取当前日
                if (date < 10) {
                  date = '0' + date
                }
                let now =
                  year +
                  '-' +
                  mon +
                  '-' +
                  date +
                  ' ' +
                  '00' +
                  ':' +
                  '00' +
                  ':' +
                  '00'
                let nowAny = ''
                nowAny = Date.parse(now)
                const end = nowAny
                const start = nowAny - 1000 * 60 * 60 * 24 * 30
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '过去60天',
              onClick(picker) {
                let myDate = new Date()
                let year = myDate.getFullYear() //获取当前年
                let mon = myDate.getMonth() + 1 //获取当前月
                if (mon < 10) {
                  mon = '0' + mon
                }
                let date = myDate.getDate() //获取当前日
                if (date < 10) {
                  date = '0' + date
                }
                let now =
                  year +
                  '-' +
                  mon +
                  '-' +
                  date +
                  ' ' +
                  '00' +
                  ':' +
                  '00' +
                  ':' +
                  '00'
                let nowAny = ''
                nowAny = Date.parse(now)
                const end = nowAny
                const start = nowAny - 1000 * 60 * 60 * 24 * 60
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        value: '',
        queryForm: {
          timeRange: [],
        },
      }
    },
    created() {
      this.getNowDate()
      this.postData()
    },
    methods: {
      postData() {
        this.$root.eventVueDevice.$emit('data-A', this.queryForm.timeRange)
      },
      getNowDate() {
        let myDate = new Date()
        let year = myDate.getFullYear() //获取当前年
        let mon = myDate.getMonth() + 1 //获取当前月
        if (mon < 10) {
          mon = '0' + mon
        }
        let date = myDate.getDate() //获取当前日
        if (date < 10) {
          date = '0' + date
        }
        let now =
          year + '-' + mon + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00'
        let nowAny = ''
        nowAny = Date.parse(now)
        this.queryForm.timeRange[1] = nowAny
        this.queryForm.timeRange[0] = nowAny - 1000 * 60 * 60 * 24 * 7
      },
    },
  }
</script>
