/* eslint-disable no-const-assign */
<template>
  <div
    class="user-management-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.username"
              clearable
              placeholder="用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="回复状态"
            >
              <el-option key="0" label="未回复" value="0" />
              <el-option key="1" label="已回复" value="1" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-dialog title="回复消息" :visible.sync="dialogFormVisible">
      <el-form :model="sendForm">
        <el-form-item label="用户名:" :label-width="formLabelWidth">
          <span>{{ sendForm.username }}</span>
        </el-form-item>
        <el-form-item label="回复内容:" :label-width="formLabelWidth">
          <el-input
            v-model="sendForm.msg"
            autocomplete="off"
            :autosize="{ minRows: 6, maxRows: 20 }"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="replyMsg">确 定</el-button>
      </div>
    </el-dialog>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '状态'">
            {{ row[item.prop] | statusFilter }}
          </span>
          <span v-else-if="item.label === '添加时间'">
            {{ row[item.prop] | dateFilter }}
          </span>
          <span v-else-if="item.label === '反馈类型'">
            {{ row[item.prop] | typeFilter }}
          </span>
          <div v-else-if="item.label === '图片'">
            <el-image
              :preview-src-list="row[item.prop]"
              :src="row[item.prop][0]"
              style="width: 100px; height: 100px"
            />
          </div>
          <div v-else-if="item.label === '反馈内容'">
            <el-input v-model="row[item.prop]" :rows="2" type="textarea" />
          </div>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="160"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('feedback_reply')"
            type="primary"
            @click="handleReply(row)"
          >
            回复
          </el-button>
          <el-button
            v-if="$perms('feedback_delete')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getFeedbackList, deleteFeedBack } from '@/api/userFeedback'
  import VabDraggable from 'vuedraggable'
  const axios = require('axios')
  import network from '@/config/index.js' // 引用 baseURL

  export default {
    name: 'DeviceOrder',
    components: {
      VabDraggable,
    },
    filters: {
      statusFilter(value) {
        if (value === 0) {
          return '未回复'
        } else if (value === 1) {
          return '已回复'
        }
      },
      dateFilter(value) {
        var date = new Date(value * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        return (
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':' +
          date.getSeconds()
        )
      },
      typeFilter(value) {
        if (value === 0) {
          return '产品建议'
        } else if (value === 1) {
          return '功能异常'
        } else if (value === 2) {
          return '安全问题'
        }
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        formLabelWidth: '120px',
        sendForm: { username: '', feedBackID: '', msg: '', uid: '' },
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        checkList: [
          '用户名',
          '图片',
          '反馈内容',
          '添加时间',
          '状态',
          '反馈类型',
          'APP类型',
          'APP版本',
          '手机型号',
          '系统版本',
          'uid',
          'APP ID',
          '评分',
        ],
        columns: [
          {
            label: '用户名',
            width: '150',
            prop: 'userID__username',
            sortable: true,
          },
          {
            label: '图片',
            width: '150',
            prop: 'img_url',
            sortable: true,
          },
          {
            label: '反馈内容',
            width: '200',
            prop: 'content',
            sortable: true,
          },
          {
            label: '添加时间',
            width: '160',
            prop: 'addTime',
            sortable: true,
          },
          {
            label: '状态',
            width: 'auto',
            prop: 'status',
            sortable: true,
          },
          {
            label: '反馈类型',
            width: 'auto',
            prop: 'type',
            sortable: true,
          },
          {
            label: 'APP类型',
            width: 'auto',
            prop: 'app',
            sortable: true,
          },
          {
            label: 'APP版本',
            width: 'auto',
            prop: 'app_version',
            sortable: true,
          },
          {
            label: '手机型号',
            width: 'auto',
            prop: 'phone_model',
            sortable: true,
          },
          {
            label: '系统版本',
            width: 'auto',
            prop: 'os_version',
            sortable: true,
          },
          {
            label: 'uid',
            width: 'auto',
            prop: 'uid',
            sortable: true,
          },
          {
            label: 'APP ID',
            width: 'auto',
            prop: 'appBundleId',
            sortable: true,
          },
          {
            label: '评分',
            width: 'auto',
            prop: 'score',
            sortable: true,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          username: '',
        },
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleReply(row) {
        if (row.id) {
          console.log(row.id)
          console.log(row.userID__username)
          this.sendForm.feedBackID = row.id
          this.sendForm.username = row.userID__username
          this.sendForm.uid = row.uid
          console.log(this.dialogFormVisible)
          this.dialogFormVisible = true
        }
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteFeedBack({ feedBackID: row.id })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getFeedbackList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      replyMsg() {
        this.$confirm('是否回复消息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let url = network.baseURL + '/userManagement/replyFeedBack'
            // 构建传给后端的数据
            this.formData = new FormData()
            this.formData.append('feedBackID', this.sendForm.feedBackID)
            this.formData.append('msg', this.sendForm.msg)
            this.formData.append('uid', this.sendForm.uid)
            axios.post(url, this.formData).then(
              function (res) {
                console.log('replyFeedBack 响应数据:', res.data)
                console.log(res.data.code)
                console.log(res.data.msg)
                if (res.data.code === 0) {
                  this.$message({
                    type: 'success',
                    message: '发送成功!',
                  })
                } else {
                  this.$message({
                    type: 'error',
                    message: '发送失败! ' + res.data.msg,
                  })
                }
              }.bind(this)
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消发送',
            })
          })
      },
    },
  }
</script>
