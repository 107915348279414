<template>
  <div
    class="user-management-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-update-form>
      <vab-update-form-left-panel :span="100">
        <el-form
          ref="form"
          :inline="true"
          :model="queryForm"
          :rules="rules"
          @submit.native.prevent
        >
          <el-form-item>
            <el-upload
              ref="upload"
              action=""
              :auto-upload="false"
              class="upload-file"
              :http-request="upLoadFile"
            >
              <el-button slot="trigger" size="small" type="primary">
                选取文件
              </el-button>
              <el-form-item>
                <el-select
                  v-model="queryForm.mci"
                  clearable
                  placeholder="设备类型"
                  style="width: 100px"
                >
                  <el-option
                    v-for="(item, index) in deviceTypeList"
                    :key="index"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="queryForm.lang"
                  clearable
                  placeholder="语言"
                  style="width: 100px"
                >
                  <el-option
                    v-for="(item, index) in langList"
                    :key="index"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model.trim="queryForm.ESN"
                  clearable
                  placeholder="设备规格名称"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model.trim="queryForm.max_ver"
                  clearable
                  placeholder="最大版本"
                  style="width: 100px"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model.trim="queryForm.channel"
                  clearable
                  placeholder="通道数"
                  style="width: 100px"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model.trim="queryForm.resolutionRatio"
                  clearable
                  placeholder="分辨率"
                  style="width: 100px"
                />
              </el-form-item>
              <el-form-item prop="Description">
                <el-input
                  v-model.trim="queryForm.Description"
                  clearable
                  placeholder="文件描述"
                />
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="queryForm.status"
                  clearable
                  placeholder="是否启用"
                  style="width: 100px"
                >
                  <el-option
                    v-for="(item, index) in statusList"
                    :key="index"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="queryForm.isPopup"
                  clearable
                  placeholder="是否提醒"
                  style="width: 100px"
                >
                  <el-option
                    v-for="(item, index) in popList"
                    :key="index"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-button
                v-if="$perms('version_versioninfo_upload')"
                size="small"
                style="margin-left: 10px"
                type="success"
                @click="submitUpload"
              >
                上传到服务器
              </el-button>
            </el-upload>
            <el-progress v-if="progressFlag" :percentage="loadProgress" />
          </el-form-item>
        </el-form>
      </vab-update-form-left-panel>
    </vab-update-form>
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-tabs
              v-model="queryForm.mci"
              type="card"
              @tab-click="handleTypeClick"
            >
              <el-tab-pane
                v-for="(item, index) in deviceTypeList"
                :key="index"
                :label="item"
              />
            </el-tabs>
          </el-form-item>
          <el-divider direction="vertical" />
          <el-form-item>
            <el-tabs
              v-model="queryForm.lang"
              type="card"
              @tab-click="handleLangClick"
            >
              <el-tab-pane
                v-for="(item, index) in langList"
                :key="index"
                :label="item"
              />
            </el-tabs>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.version"
              clearable
              placeholder="设备版本"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '是否启用'">
            {{ row[item.prop] | statusFilter }}
          </span>
          <span v-else-if="item.label === '是否提醒'">
            {{ row[item.prop] | popFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="150"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('version_versioninfo_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$perms('version_versioninfo_del')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    getEquipmentVersionList,
    deleteEquipmentVersion,
  } from '@/api/newVersionInformation'
  import VabDraggable from 'vuedraggable'
  const axios = require('axios')
  import network from '@/config/index.js' // 引用 baseURL
  import Edit from './components/NewVersionInformation'
  axios.defaults.retry = 3 //请求次数
  axios.defaults.retryDelay = 1000 //请求的间隙
  axios.interceptors.response.use(
    undefined,
    function axiosRetryInterceptor(err) {
      let config = err.config
      // If config does not exist or the retry option is not set, reject
      if (!config || !config.retry) return Promise.reject(err)

      // Set the variable for keeping track of the retry count
      config.__retryCount = config.__retryCount || 0

      // Check if we've maxed out the total number of retries
      if (config.__retryCount >= config.retry) {
        // Reject with the error
        return Promise.reject(err)
      }

      // Increase the retry count
      config.__retryCount += 1

      // Create new promise to handle exponential backoff
      let backoff = new Promise(function (resolve) {
        setTimeout(function () {
          resolve()
        }, config.retryDelay || 1)
      })

      // Return the promise in which recalls axios to retry the request
      return backoff.then(function () {
        return axios(config)
      })
    }
  )
  export default {
    name: 'NewVersionInformation',
    components: {
      Edit,
      VabDraggable,
    },
    filters: {
      statusFilter(value) {
        if (value === true) {
          return '是'
        } else {
          return '否'
        }
      },
      popFilter(value) {
        if (value === 1) {
          return '是'
        } else {
          return '否'
        }
      },
    },
    data() {
      return {
        loadProgress: '', // 动态显示进度条
        progressFlag: false, // 关闭进度条
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        deviceTypeList: ['IPC', 'DVR', 'NVR', 'XVR', 'OTHER'],
        langList: ['en', 'zh-Hans'],
        statusList: ['是', '否'],
        popList: ['是', '否'],
        checkList: [
          '设备规格名称',
          '设备规格代码',
          '设备版本',
          '软件版本',
          '最大版本',
          '语言',
          '是否启用',
          '是否提醒',
          '主芯片',
          '通道数',
          '分辨率',
          '设备机型',
          '公司代码',
          '文件大小',
          '文件路径',
          '描述',
          '添加时间',
          '更新时间',
        ],
        columns: [
          {
            label: '设备规格名称',
            width: '130',
            prop: 'ESN',
            sortable: true,
            disableCheck: false,
          },
          {
            label: '设备规格代码',
            width: '130',
            prop: 'code',
            sortable: true,
          },
          {
            label: '设备版本',
            width: '160',
            prop: 'version',
            sortable: true,
          },
          {
            label: '软件版本',
            width: '80',
            prop: 'softwareVersion',
            sortable: true,
          },
          {
            label: '最大版本',
            width: 'auto',
            prop: 'max_ver',
            sortable: true,
          },
          {
            label: '语言',
            width: 'auto',
            prop: 'lang',
            sortable: true,
          },
          {
            label: '是否启用',
            width: 'auto',
            prop: 'status',
            sortable: true,
          },
          {
            label: '是否提醒',
            width: 'auto',
            prop: 'is_popup',
            sortable: true,
          },
          {
            label: '主芯片',
            width: 'auto',
            prop: 'chipModelList2Code',
            sortable: true,
          },
          {
            label: '通道数',
            width: 'auto',
            prop: 'channel',
            sortable: true,
          },
          {
            label: '分辨率',
            width: 'auto',
            prop: 'resolutionRatio',
            sortable: true,
          },
          {
            label: '设备机型',
            width: 'auto',
            prop: 'type',
            sortable: true,
          },
          {
            label: '公司代码',
            width: 'auto',
            prop: 'companyCode',
            sortable: true,
          },
          {
            label: '文件大小',
            width: 'auto',
            prop: 'fileSize',
            sortable: true,
          },
          {
            label: '文件路径',
            width: 'auto',
            prop: 'filePath',
            sortable: true,
          },
          {
            label: '描述',
            width: 'auto',
            prop: 'Description',
            sortable: true,
          },
          {
            label: '添加时间',
            width: '160',
            prop: 'data_joined',
            sortable: true,
          },
          {
            label: '更新时间',
            width: '160',
            prop: 'update_time',
            sortable: true,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          mci: 'IPC',
          lang: 'en',
        },
        rules: {
          Description: [
            { required: true, trigger: 'blur', message: '请输入文件描述' },
          ],
        },
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.eid) {
          this.$refs['edit'].showEdit(row)
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getEquipmentVersionList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      handleTypeClick(tab, event) {
        console.log(tab, event)
        this.queryForm.mci = tab.label
        this.fetchData()
      },
      handleLangClick(tab, event) {
        console.log(tab, event)
        this.queryForm.lang = tab.label
        this.fetchData()
      },
      handleDelete(row) {
        if (row.eid) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteEquipmentVersion({ eid: row.eid })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      upLoadFile(file) {
        this.formData.append('file', file.file)
      },
      submitUpload() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.$confirm('是否上传文件?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                // 构建传给后端的数据
                this.formData = new FormData()
                this.formData.append('mci', this.queryForm.mci)
                this.formData.append('lang', this.queryForm.lang)
                this.formData.append('ESN', this.queryForm.ESN)
                this.formData.append('max_ver', this.queryForm.max_ver)
                this.formData.append('channel', this.queryForm.channel)
                this.formData.append(
                  'resolutionRatio',
                  this.queryForm.resolutionRatio
                )
                this.formData.append('Description', this.queryForm.Description)
                let status = this.queryForm.status === '是' ? 1 : 0
                this.formData.append('status', status)
                let isPopup = this.queryForm.isPopup === '是' ? 1 : 0
                this.formData.append('isPopup', isPopup)
                this.$refs.upload.submit() // 会触发 http-request,即 upLoadFile 方法
                let url = network.baseURL + '/versionManagement/upLoadFile'
                axios({
                  url: url,
                  method: 'post',
                  data: this.formData,
                  onUploadProgress: (progress) => {
                    this.loadProgress = Math.round(
                      (progress.loaded / progress.total).toFixed(2) * 100
                    ) // 进度条百分比
                    this.progressFlag = true
                  },
                })
                  .then(
                    function (res) {
                      if (res.data.code === 0) {
                        this.$message({
                          type: 'success',
                          message: '文件上传成功!',
                        })
                        this.progressFlag = false
                        this.fetchData()
                      } else {
                        this.$message({
                          type: 'error',
                          message: '文件上传失败! ' + res.data.msg,
                        })
                        this.progressFlag = false
                      }
                    }.bind(this)
                  )
                  .catch((res) => {
                    this.$message({
                      type: 'error',
                      message: '文件上传失败! ' + res,
                    })
                    this.progressFlag = false
                  })
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '取消上传',
                })
              })
          }
        })
      },
    },
  }
</script>
