<template>
  <div class="get-domain-name-log-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.serialNumber"
              clearable
              placeholder="序列号"
              style="width: 250px"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        v-for="item in columns"
        :key="item.prop"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getDomainLog } from '@/api/getDomainLog'

  export default {
    name: 'GetDomainNameLog',
    data() {
      return {
        list: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        columns: [
          {
            label: '序列号',
            width: 'auto',
            prop: 'serial_number',
          },
          {
            label: 'ip',
            width: 'auto',
            prop: 'ip',
          },
          {
            label: '地区id',
            width: 'auto',
            prop: 'region_id',
          },
          {
            label: '国家编码',
            width: 'auto',
            prop: 'country_code',
          },
          {
            label: '域名',
            width: 'auto',
            prop: 'api',
          },
          {
            label: '域名地区',
            width: 'auto',
            prop: 'api_region',
          },
          {
            label: '添加时间',
            width: 'auto',
            prop: 'add_time',
          },
          {
            label: '更新时间',
            width: 'auto',
            prop: 'update_time',
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getDomainLog(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
    },
  }
</script>
