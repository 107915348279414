<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="序列号" prop="serialNumber">
        <el-input
          v-model.trim="form.serialNumber"
          clearable
          placeholder="请输入序列号"
        />
      </el-form-item>
      <el-form-item label="套餐" prop="packageId">
        <el-select v-model="form.packageId" placeholder="请选择套餐">
          <el-option
            v-for="item in comboList"
            :key="item.id"
            :label="item.combo_name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save()">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { getFlowPackages, getComboType } from '@/api/cardUserInformation'
  export default {
    name: 'CardUserInformation',
    data() {
      return {
        comboList: [],
        form: {},
        rules: {
          serialNo: [
            { required: true, trigger: 'blur', message: '请输入序列号' },
          ],
          comboId: [{ required: true, trigger: 'blur', message: '请选择套餐' }],
        },
        title: '流量充值',
        dialogFormVisible: false,
        queryForm: {},
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      showFlow() {
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { reason } = await getFlowPackages(this.form)
            this.$baseMessage(reason, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      async fetchData() {
        const { result } = await getComboType(this.queryForm)
        this.comboList = result
      },
    },
  }
</script>
