import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/userManagement/getCustomizedPushList',
    method: 'get',
    params,
  })
}

export function getCountryList(params) {
  return request({
    url: '/userManagement/getCountryList',
    method: 'get',
    params,
  })
}

export function getDeviceTypeList(params) {
  return request({
    url: '、userManagement/getDeviceTypeList',
    method: 'get',
    params,
  })
}

export function addOrEditPush(data) {
  return request({
    url: '/userManagement/addOrEditCustomizedPush',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function delCustomizedPush(data) {
  return request({
    url: '/userManagement/delCustomizedPush',
    method: 'post',
    data,
  })
}
