var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "100px", model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "软件名称", prop: "pc_name" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.pc_name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "pc_name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.pc_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目类型", prop: "bundle_version" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择项目类型" },
                  model: {
                    value: _vm.form.bundle_version,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "bundle_version", $$v)
                    },
                    expression: "form.bundle_version"
                  }
                },
                _vm._l(_vm.bundleVersionList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "版本号", prop: "pc_version" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.pc_version,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "pc_version",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.pc_version"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "测试版", prop: "pc_test" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择是否为测试版" },
                  model: {
                    value: _vm.form.pc_test,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "pc_test", $$v)
                    },
                    expression: "form.pc_test"
                  }
                },
                _vm._l(_vm.pcTestList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "语言类型", prop: "lang" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.lang,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "lang",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.lang"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文件类型", prop: "file_type" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.file_type,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "file_type",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.file_type"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "整合包id", prop: "package" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.package,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "package",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.package"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新内容", prop: "explain" } },
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 2, maxRows: 4 },
                  clearable: "",
                  type: "textarea"
                },
                model: {
                  value: _vm.form.explain,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "explain",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.explain"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "强制更新", prop: "is_update" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择是否强制更新" },
                  model: {
                    value: _vm.form.is_update,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "is_update", $$v)
                    },
                    expression: "form.is_update"
                  }
                },
                _vm._l(_vm.isUpdateList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开启版本", prop: "is_open" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择是否开启版本" },
                  model: {
                    value: _vm.form.is_open,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "is_open", $$v)
                    },
                    expression: "form.is_open"
                  }
                },
                _vm._l(_vm.isOpenList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "内容信息", prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 2, maxRows: 4 },
                  clearable: "",
                  type: "textarea"
                },
                model: {
                  value: _vm.form.content,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "content",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.content"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "权限", prop: "authority" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.authority,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "authority",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.authority"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文件路径", prop: "download_link" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.download_link,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "download_link",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.download_link"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }