<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <el-form
      ref="form"
      label-position="left"
      label-width="80px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" />
      </el-form-item>
      <el-form-item label="详情" prop="msg">
        <el-input v-model="form.msg" type="textarea" />
      </el-form-item>
      <el-form-item label="链接" prop="link">
        <el-input v-model="form.link" />
      </el-form-item>
      <el-form-item label="预览图" prop="icon">
        <el-upload
          ref="upload"
          action="#"
          :auto-upload="false"
          class="upload-file"
          :http-request="upLoadFile"
          list-type="picture-card"
          :on-change="onChange"
          :on-remove="onRemove"
          :show-file-list="false"
        >
          <img v-if="form.icon_link" :src="form.icon_link" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="国家" prop="country">
        <el-select
          v-model="form.country"
          clearable
          filterable
          multiple
          placeholder="请选择国家"
        >
          <el-option
            v-for="item in countryList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="设备型号" prop="device_type">
        <Select
          :mul-select-loading="deviceTypeListLoading"
          :mul-selecteds="form.device_type"
          :select-options="deviceTypeList"
          @updateMultipleSelect="updateMultipleSelect"
        />
      </el-form-item>
      <el-form-item class="re_period" label="注册年限" prop="register_period">
        <el-row>
          <span>
            {{ form.register_period[0] }} -
            {{
              form.register_period[1] === 6
                ? '5年以上'
                : form.register_period[1] + '年'
            }}
          </span>
        </el-row>
        <el-row>
          <el-slider
            v-model="form.register_period"
            :marks="marks"
            :max="6"
            range
            show-stops
            :show-tooltip="false"
            style="width: 400px"
          />
        </el-row>
      </el-form-item>
      <el-form-item label="推送时间" prop="time">
        <el-col :span="8">
          <el-select
            v-model="form.time_zone"
            placeholder="时区"
            style="width: 145px"
          >
            <el-option
              v-for="item in timeZones"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col :span="11">
          <el-date-picker
            v-model="form.push_time"
            placeholder="请选择时间"
            type="datetime"
            value-format="timestamp"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="推送APP" prop="push_app">
        <el-radio v-model="form.push_app" label="周视">周视</el-radio>
        <el-radio v-model="form.push_app" label="ZosiSmart">ZosiSmart</el-radio>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">保 存</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { addOrEditPush } from '@/api/customPush'
  import Select from './select'

  export default {
    name: 'AddEditComponet',
    components: { Select },
    props: {
      countryList: {
        type: [Object, Array],
        default: () => {},
      },
      deviceTypeList: {
        type: [Object, Array],
        default: () => {},
      },
      deviceTypeListLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      const validateRegisterPeriod = (rule, value, callback) => {
        if (value[0] === 0 && value[1] === 0) {
          callback(new Error('请选择注册年限'))
        } else {
          callback()
        }
      }
      const validateTime = (rule, value, callback) => {
        if (!this.form.time_zone) {
          callback(new Error('请选择时区'))
        } else if (!this.form.push_time) {
          callback(new Error('请选择时间'))
        } else {
          callback()
        }
      }
      return {
        title: '',
        dialogFormVisible: false,
        form: {
          register_period: [0, 0],
          icon_link: '',
        },
        marks: {
          0: '0',
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '5年以上',
        },
        imgUrl: '',
        rules: {
          title: [{ required: true, trigger: 'blur', message: '请选择标题' }],
          msg: [{ required: true, trigger: 'blur', message: '请选择详情' }],
          link: [{ required: true, trigger: 'blur', message: '请选择链接' }],
          country: [{ required: true, trigger: 'blur', message: '请选择国家' }],
          device_type: [
            { required: true, trigger: 'blur', message: '请选择设备型号' },
          ],
          register_period: [
            {
              required: true,
              trigger: 'blur',
              validator: validateRegisterPeriod,
            },
          ],
          time: [{ required: true, trigger: 'blur', validator: validateTime }],
          push_app: [
            { required: true, trigger: 'blur', message: '请选择推送App' },
          ],
        },
        timeZones: [
          {
            value: 'UTC +1.0',
            label: 'UTC +1.0',
          },
          {
            value: 'UTC +2.0',
            label: 'UTC +2.0',
          },
          {
            value: 'UTC +3.0',
            label: 'UTC +3.0',
          },
          {
            value: 'UTC +4.0',
            label: 'UTC +4.0',
          },
          {
            value: 'UTC +5.0',
            label: 'UTC +5.0',
          },
          {
            value: 'UTC +6.0',
            label: 'UTC +6.0',
          },
          {
            value: 'UTC +7.0',
            label: 'UTC +7.0',
          },
          {
            value: 'UTC +8.0',
            label: 'UTC +8.0',
          },
          {
            value: 'UTC +9.0',
            label: 'UTC +9.0',
          },
          {
            value: 'UTC +10.0',
            label: 'UTC +10.0',
          },
          {
            value: 'UTC +11.0',
            label: 'UTC +11.0',
          },
          {
            value: 'UTC +12.0',
            label: 'UTC +12.0',
          },
          {
            value: 'UTC -1.0',
            label: 'UTC -1.0',
          },
          {
            value: 'UTC -2.0',
            label: 'UTC -2.0',
          },
          {
            value: 'UTC -3.0',
            label: 'UTC -3.0',
          },
          {
            value: 'UTC -4.0',
            label: 'UTC -4.0',
          },
          {
            value: 'UTC -5.0',
            label: 'UTC -5.0',
          },
          {
            value: 'UTC -6.0',
            label: 'UTC -6.0',
          },
          {
            value: 'UTC -7.0',
            label: 'UTC -7.0',
          },
          {
            value: 'UTC -8.0',
            label: 'UTC -8.0',
          },
          {
            value: 'UTC -9.0',
            label: 'UTC -9.0',
          },
          {
            value: 'UTC -10.0',
            label: 'UTC -10.0',
          },
          {
            value: 'UTC -11.0',
            label: 'UTC -11.0',
          },
          {
            value: 'UTC -12.0',
            label: 'UTC -12.0',
          },
        ],
        umi: false,
      }
    },
    methods: {
      showComponent(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
          //设备型号
          this.form.device_type = this.form.device_type.split(',')
          // 注册年限 字符串提取数字
          const str = this.form.register_period
          const matches = str.match(/\d+/g)
          this.form.register_period = matches.map(Number)
          if (this.form.register_period[1] === undefined) {
            this.form.register_period[1] = 6
          }
          // 推送时间 时间戳处理
          this.form.push_time = Date.parse(this.form.push_time)
          // 国家
          this.form.country = this.form.country.split(',')
          this.form.isEdit = true
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.formData = new FormData()
            this.formData.append('title', this.form.title)
            this.formData.append('msg', this.form.msg)
            this.formData.append('link', this.form.link)
            this.formData.append('country', this.form.country)
            // 设备型号
            let deviceType = this.form.device_type
            deviceType = JSON.stringify(deviceType)
              .replace('[', '')
              .replace(']', '')
              .replace(/"/g, '')
            this.formData.append('deviceType', deviceType)
            // 注册年限 数组转换为字符串
            let registerPeriod = this.form.register_period
            if (registerPeriod[1] === 6) {
              registerPeriod[1] = ''
            }
            registerPeriod = registerPeriod[0] + '-' + registerPeriod[1]
            this.formData.append('registerPeriod', registerPeriod)
            this.formData.append('timeZone', this.form.time_zone)
            this.formData.append(
              'pushTime',
              this.timeFormat(this.form.push_time)
            )
            this.formData.append('pushApp', this.form.push_app)
            if (this.form.isEdit) {
              this.formData.append('isEdit', this.form.isEdit)
              this.formData.append(
                'customizedPushId',
                this.form.customized_push_id
              )
            }
            this.$refs.upload.submit()
            const { msg } = await addOrEditPush(this.formData)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      upLoadFile(file) {
        this.formData.append('icon', file.file)
      },
      onChange(file) {
        this.form.icon_link = URL.createObjectURL(file.raw)
      },
      onRemove() {
        this.form.fileName = null
      },
      timeFormat(val) {
        if (val === 0) {
          return 'N/A'
        } else {
          let date = new Date(val)
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds()
          )
        }
      },
      updateMultipleSelect(val) {
        this.form.device_type = val
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-slider__marks-text {
    word-break: keep-all;
  }
  ::v-deep .el-slider__button-wrapper .el-tooltip {
    background-color: #1890ff;
  }
  ::v-deep .el-upload--picture-card {
    width: 50px;
    height: 50px;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #c0ccda;
  }
  .re_period {
    ::v-deep .el-form-item__content {
      padding-bottom: 25px;
    }
  }
</style>
