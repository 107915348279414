<template>
  <div class="customer-device-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="50">
        <el-form
          :inline="true"
          :model="queryForm"
          @submit.native.prevent="fetchData"
        >
          <el-form-item>
            <el-input
              v-model.trim="queryForm.serialNumber"
              clearable
              placeholder="请输入序列号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="请输入UID"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.orderNumber"
              clearable
              placeholder="请输入生产编号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.email"
              clearable
              placeholder="请输入邮箱"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="fetchData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-download"
              :loading="downloadLoading"
              type="primary"
              @click="download"
            >
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <div class="header-num">
      <span style="padding-left: 30px; padding-right: 39px">
        <span class="number">设备已添加数量:</span>
        {{ total }}
      </span>
      <span>
        <span class="number">生产编号设备总量:</span>
        {{ orderDeviceQuantity }}
      </span>
    </div>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        v-for="item in tableColumns"
        :key="item.prop"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.prop === 'addTime'">
            {{ row[item.prop] | timeFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.line"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getCustomerDeviceList } from '@/api/device'
  import { formatDate } from '@/utils/formatDate'

  export default {
    name: 'ActivityManage',
    filters: {
      timeFilter(val) {
        if (!val || val === 0) {
          return 'N/A'
        }
        let date = val * 1000
        return formatDate(new Date(date), 'yyyy-MM-dd hh:mm:ss')
      },
    },
    data() {
      return {
        tableColumns: [
          {
            label: '序列号',
            prop: 'serialNumber',
            width: '150',
          },
          {
            label: 'UID',
            prop: 'uid',
          },
          {
            label: '设备类型',
            prop: 'deviceType',
          },
          {
            label: '完整编码',
            prop: 'fullCode',
          },
          {
            label: '生产编号',
            prop: 'orderNumber',
          },
          {
            label: '用户名',
            prop: 'name',
          },
          {
            label: '邮箱',
            prop: 'email',
          },
          {
            label: '创建时间',
            prop: 'addTime',
            width: '170',
          },
        ],
        list: [],
        listLoading: false,
        downloadLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        orderDeviceQuantity: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total, orderDeviceQuantity },
        } = await getCustomerDeviceList(this.queryForm)
        this.list = list
        this.orderDeviceQuantity = orderDeviceQuantity
        this.total = total
        this.listLoading = false
      },
      formatJson(filterVal, jsonData) {
        const formatValue = (v) => ({
          ...v,
          addTime: this.$options.filters.timeFilter(
            v.addTime,
            'yyyy-MM-dd hh:mm:ss'
          ),
        })
        const formattedData = jsonData.map(formatValue)
        return formattedData.map((v) => filterVal.map((j) => v[j]))
      },
      async download() {
        this.downloadLoading = true
        let listData = []
        this.fileName = '定制客户'
        let form = { ...this.queryForm }
        delete form.pageNo
        delete form.pageSize
        const {
          data: { list },
        } = await getCustomerDeviceList(form)
        listData = list
        if (listData) {
          import('@/utils/excel').then((excel) => {
            const tHeader = this.tableColumns.map((column) => column.label)
            const filterVal = this.tableColumns.map((column) => column.prop)
            const list = listData
            const data = this.formatJson(filterVal, list)
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: this.fileName,
            })
            this.downloadLoading = false
          })
        } else {
          this.downloadLoading = false
          this.$baseMessage('数据为空', 'warning', 'vab-hey-message-warning')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .header-num {
    display: flex;
    place-items: center;
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    border-bottom: none;
    height: 30px;
  }
  .number {
    color: $base-color-blue;
  }
</style>
