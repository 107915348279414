<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="400px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="套餐" prop="packageId">
        <el-select v-model="form.packageId" placeholder="请选择套餐">
          <el-option
            v-for="item in comboIdList"
            :key="item.id"
            :label="item.combo_name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="使用状态" prop="status">
        <el-select v-model="form.status" placeholder="请选择状态">
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { packageUpdate } from '@/api/serialTrafficPackage'
  export default {
    name: 'EditComponent',
    props: {
      comboIdList: {
        type: [Object, Array],
        default: () => {},
      },
    },
    data() {
      return {
        statusList: [
          {
            value: 1,
            label: '未激活',
          },
          {
            value: 2,
            label: '已激活',
          },
        ],
        rules: {
          packageId: [
            { required: true, trigger: 'blur', message: '请选择套餐' },
          ],
          statusList: [
            { required: true, trigger: 'blur', message: '请选择使用状态' },
          ],
        },
        title: '编辑',
        dialogFormVisible: false,
        form: {},
      }
    },
    methods: {
      showEdit(row) {
        this.form = Object.assign({}, row)
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let obj = new Object()
            const { msg } = await packageUpdate(obj)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>
