<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" width="1450px">
    <vab-query-form>
      <vab-query-form-left-panel>
        <el-form :inline="true">
          <el-form-item>
            <el-input
              v-model.trim="queryForm.user_name"
              clearable
              placeholder="请输入用户姓名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.phone"
              clearable
              placeholder="请输入电话号码"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="fetchData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        v-for="item in tableColumns"
        :key="item.prop"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.toopltip"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '性别'">
            {{ row[item.prop] | typeFilter(sexList) }}
          </span>
          <span v-else-if="item.label === '是否输出过新品体验报告'">
            {{ row[item.prop] | typeFilter(reportList) }}
          </span>
          <span v-else-if="item.label === '是否入选'">
            <el-switch
              v-model="row[item.prop]"
              :active-value="1"
              :inactive-value="0"
              @change="handleOpenAndClose(row)"
            />
            {{ row[item.prop] | typeFilter(selectedList) }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.line"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </el-dialog>
</template>

<script>
  import { getActivityUser, editActivityUser } from '@/api/newProductExperience'

  export default {
    name: 'DetailsComponet',
    filters: {
      typeFilter(value, list) {
        const option = list.find((item) => item.value === value)
        if (option) {
          return option.label
        }
        return value
      },
    },
    data() {
      return {
        tableColumns: [
          {
            label: '用户姓名',
            prop: 'user_name',
            toopltip: true,
          },
          {
            label: '电话号码',
            prop: 'phone',
            toopltip: true,
          },
          {
            label: '地址',
            prop: 'address',
            toopltip: true,
          },
          {
            label: '性别',
            prop: 'sex',
          },
          {
            label: '年龄',
            prop: 'age',
          },
          {
            label: '使用环境',
            prop: 'usage_environment',
            toopltip: true,
          },
          {
            label: '是否输出过新品体验报告',
            prop: 'is_reports',
          },
          {
            label: '是否入选',
            prop: 'is_selected',
          },
        ],
        showStatusList: [
          {
            value: 0,
            label: '不展示',
          },
          {
            value: 1,
            label: '展示',
          },
        ],
        selectedList: [
          {
            value: 0,
            label: '未入选',
          },
          {
            value: 1,
            label: '入选',
          },
        ],
        reportList: [
          {
            value: 0,
            label: '未输出',
          },
          {
            value: 1,
            label: '输出过',
          },
        ],
        sexList: [
          {
            value: 0,
            label: '男',
          },
          {
            value: 1,
            label: '女',
          },
        ],
        list: [],
        title: '报名详情',
        dialogFormVisible: false,
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          page: 1,
          line: 10,
        },
      }
    },
    methods: {
      handleSizeChange(val) {
        this.queryForm.page_size = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      async showComponent(val) {
        this.dialogFormVisible = true
        this.queryForm.activity_id = val
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, count },
        } = await getActivityUser(this.queryForm)
        this.list = list
        this.total = count
        this.listLoading = false
      },
      async handleOpenAndClose(row) {
        const { msg } = await editActivityUser({
          activity_id: row.activity_id,
          user_id: row.id,
          is_selected: row.is_selected,
        })
        this.$baseMessage(msg, 'success', 'vab-hey-message-success')
        await this.fetchData()
      },
    },
  }
</script>
