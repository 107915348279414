<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item v-if="showID" label="ID" prop="id">
        <el-input v-model.trim="form.id" disabled />
      </el-form-item>
      <el-form-item label="appID" prop="app_id__appBundleId">
        <el-input
          v-if="title == '编辑'"
          v-model.trim="form.app_id__appBundleId"
          disabled
        />
        <el-select
          v-if="title == '添加'"
          v-model="form.app_id__appBundleId"
          clearable
          placeholder="请选择appID"
        >
          <el-option
            v-for="item in appBundleIdList"
            :key="item.id"
            :label="item.appBundleId"
            :value="item.appBundleId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="系统版本" prop="version">
        <el-input v-model.trim="form.version" clearable />
      </el-form-item>
      <el-form-item label="更新时间" prop="version_time">
        <el-input v-model.trim="form.version_time" clearable />
      </el-form-item>
      <el-form-item v-if="title == '添加'" label="中文内容" prop="cnContent">
        <el-input
          v-model="form.cnContent"
          autocomplete="off"
          :autosize="{ minRows: 6, maxRows: 20 }"
          type="textarea"
        />
      </el-form-item>
      <el-form-item v-if="title == '添加'" label="英文内容" prop="enContent">
        <el-input
          v-model="form.enContent"
          autocomplete="off"
          :autosize="{ minRows: 6, maxRows: 20 }"
          type="textarea"
        />
      </el-form-item>
      <el-form-item v-if="title == '编辑'" label="更新内容" prop="content">
        <el-input
          v-model="form.content"
          autocomplete="off"
          :autosize="{ minRows: 6, maxRows: 20 }"
          type="textarea"
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { getAppBundleIdList, addOrEditAppRecord } from '@/api/appRecord'

  export default {
    name: 'AppRecord',
    props: {
      queryForm: {
        type: Object,
        default: () => ({
          pageNo: 1,
          pageSize: 10,
          appType: 'IOS',
          queryVersion: '',
          queryAppBundleId: '',
        }),
      },
    },
    data() {
      return {
        showID: false,
        queryAppType: this.queryForm,
        appBundleIdList: [],
        form: {
          role: '',
          isEdit: '',
        },
        rules: {
          app_id__appBundleId: [
            { required: true, trigger: 'blur', message: '请选择appID' },
          ],
          version: [
            { required: true, trigger: 'blur', message: '请输入系统版本' },
          ],
          version_time: [
            { required: true, trigger: 'blur', message: '请输入更新时间' },
          ],
          cnContent: [
            { required: true, trigger: 'blur', message: '请输入中文内容' },
          ],
          enContent: [
            { required: true, trigger: 'blur', message: '请输入英文内容' },
          ],
          content: [
            { required: true, trigger: 'blur', message: '请输入更新内容' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
          this.form.isEdit = true
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await addOrEditAppRecord(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      async fetchData(queryForm) {
        const {
          data: { appBundleIdList },
        } = await getAppBundleIdList(queryForm)
        this.appBundleIdList = appBundleIdList
      },
    },
  }
</script>
