import request from '@/utils/request'

export function getAlert(params) {
  return request({
    url: 'serveManagement/deviceAttritionAlert',
    method: 'get',
    params,
  })
}

export function download(params) {
  return request({
    url: 'serveManagement/cloudDataExport',
    method: 'get',
    params,
    responseType: 'blob'
  })
}
