<template>
  <el-card class="order-card1" shadow="hover">
    <template #header>
      <span style="font-weight: bold; font-size: 18px">{{ title }}</span>
      <el-tooltip placement="top">
        <div slot="content">
          累计销售额中云存储、AI识别和4G卡
          <br />
          这三种服务的占比
        </div>
        <vab-icon icon="information-line" />
      </el-tooltip>
    </template>
    <vab-chart
      v-loading="loading"
      :init-options="initOptions"
      lazy
      :option="option"
      theme="vab-echarts-theme"
    />
  </el-card>
</template>

<script>
  import VabChart from '@/extra/VabChart'

  export default {
    name: 'VabChartPie',
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        vodOrderAllTotal: 0,
        aiOrderAllTotal: 0,
        unicomOrderAllTotal: 0,
        loading: true,
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)',
          },
          legend: {
            bottom: 'bottom',
            data: [],
            type: 'scroll',
          },
          series: [
            {
              name: '',
              type: 'pie',
              radius: ['30%', '70%'],
              center: ['50%', '40%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '14',
                },
              },
              labelLine: {
                show: false,
              },
              data: [{}, {}, {}],
            },
          ],
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      fetchData() {
        this.$root.eventVueOv.$on(
          'data-vodOrderAllTotal',
          (vodOrderAllTotal) => {
            this.vodOrderAllTotal = vodOrderAllTotal
            this.$root.eventVueOv.$on(
              'data-aiOrderAllTotal',
              (aiOrderAllTotal) => {
                this.aiOrderAllTotal = aiOrderAllTotal
                this.$root.eventVueOv.$on(
                  'data-unicomOrderAllTotal',
                  (unicomOrderAllTotal) => {
                    this.unicomOrderAllTotal = unicomOrderAllTotal
                    let total =
                      vodOrderAllTotal + aiOrderAllTotal + unicomOrderAllTotal
                    if (vodOrderAllTotal != 0) {
                      this.option.series[0].data[0].value = vodOrderAllTotal
                      let rate = parseFloat(vodOrderAllTotal / total).toFixed(2)
                      this.option.series[0].data[0].rate = rate * 100
                      this.option.series[0].data[0].name = '云存储'
                    }
                    if (aiOrderAllTotal != 0) {
                      this.option.series[0].data[1].value = aiOrderAllTotal
                      let rate = parseFloat(aiOrderAllTotal / total).toFixed(2)
                      this.option.series[0].data[1].rate = rate * 100
                      this.option.series[0].data[1].name = 'AI识别'
                    }
                    if (unicomOrderAllTotal != 0) {
                      this.option.series[0].data[2].value = unicomOrderAllTotal
                      let rate = parseFloat(
                        unicomOrderAllTotal / total
                      ).toFixed(2)
                      this.option.series[0].data[2].rate = rate * 100
                      this.option.series[0].data[2].name = '4G卡'
                    }
                    this.option.legend.data = this.option.series[0].data
                    this.loading = false
                  }
                )
              }
            )
          }
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  .order {
    &-card1 {
      height: 300px;
    }
  }
</style>
