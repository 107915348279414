<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="APP名称" prop="appName">
        <el-input v-model.trim="form.appName" clearable />
      </el-form-item>
      <el-form-item label="APP ID" prop="appBundleId">
        <el-input v-model.trim="form.appBundleId" clearable />
      </el-form-item>
      <el-form-item label="项目版本" prop="bundleVersion">
        <el-input v-model.trim="form.bundleVersion" clearable />
      </el-form-item>
      <el-form-item label="系统版本" prop="newAppversion">
        <el-input v-model.trim="form.newAppversion" clearable />
      </el-form-item>
      <el-form-item label="最小系统版本" prop="minAppversion">
        <el-input v-model.trim="form.minAppversion" clearable />
      </el-form-item>
      <el-form-item label="更新内容" prop="content">
        <el-input
          v-model.trim="form.content"
          clearable
          :rows="2"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="APP类型" prop="app_type">
        <el-select
          v-model="form.app_type"
          clearable
          placeholder="请选择APP类型"
        >
          <el-option
            v-for="item in appTypeList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="下载路径" prop="downloadLink">
        <el-input
          v-model.trim="form.downloadLink"
          clearable
          :rows="2"
          type="textarea"
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { addOrEditAppInfo } from '@/api/appVersion'

  export default {
    name: 'APPversion',
    data() {
      return {
        appTypeList: [
          {
            label: 'IOS',
            value: 1,
          },
          {
            label: '安卓',
            value: 2,
          },
          {
            label: 'AVSS',
            value: 3,
          },
        ],
        form: {},
        rules: {
          appName: [
            { required: true, trigger: 'blur', message: '请输入APP名称' },
          ],
          appBundleId: [
            { required: true, trigger: 'blur', message: '请输入APP ID' },
          ],
          bundleVersion: [
            { required: true, trigger: 'blur', message: '请输入项目版本' },
          ],
          newAppversion: [
            { required: true, trigger: 'blur', message: '请输入系统版本' },
          ],
          app_type: [
            { required: true, trigger: 'blur', message: '请选择APP类型' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    methods: {
      showEdit(row) {
        if (!row.id) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
          this.form.id = row.id
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await addOrEditAppInfo(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>
