import request from '@/utils/request'

export function getAiStoreMealList(params) {
  return request({
    url: '/aiServe/getAiStoreMealList',
    method: 'get',
    params,
  })
}

export function addOrEditAiStoreMeal(data) {
  return request({
    url: '/aiServe/addOrEditAiStoreMeal',
    method: 'post',
    data,
  })
}

export function deleteAiStoreMeal(data) {
  return request({
    url: '/aiServe/deleteAiStoreMeal',
    method: 'post',
    data,
  })
}
