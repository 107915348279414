<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form
      v-show="title === '添加'"
      ref="addForm"
      label-width="100px"
      :model="form"
      :rules="addRules"
    >
      <el-form-item label="套餐" prop="packageId">
        <el-select v-model="form.packageId" placeholder="请选择套餐">
          <el-option
            v-for="item in comboIdList"
            :key="item.id"
            :label="item.combo_name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="数量" prop="quantity">
        <el-input v-model.trim="form.quantity" />
      </el-form-item>
    </el-form>
    <el-form
      v-if="title === '编辑'"
      ref="form"
      label-width="100px"
      :model="form"
      :rules="editRules"
    >
      <el-form-item label="状态" prop="status">
        <el-select v-model="form.status" placeholder="请选择状态">
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否下载" prop="isDown">
        <el-select v-model="form.isDown" placeholder="请选择状态">
          <el-option
            v-for="item in isDownList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="过期时间" prop="expireTime">
        <el-date-picker
          v-model="form.expireTime"
          placeholder="选择日期时间"
          type="datetime"
          value-format="timestamp"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { addCode, updateCode, getFlowComboList } from '@/api/redemptionCode'

  export default {
    neme: 'RedemptionCode',
    data() {
      return {
        comboIdList: [],
        statusList: [
          { value: false, label: '未使用' },
          { value: true, label: '已使用' },
        ],
        isDownList: [
          { value: false, label: '未下载' },
          { value: true, label: '已下载' },
        ],
        addRules: {
          packageId: [
            { required: true, trigger: 'blur', message: '请选择套餐id' },
          ],
          quantity: [
            { required: true, trigger: 'blur', message: '请输入数量' },
          ],
        },
        editRules: {
          status: [{ required: true, trigger: 'blur', message: '请选择状态' }],
        },
        title: '',
        dialogFormVisible: false,
        form: {},
      }
    },
    created() {
      this.fetchComboIdList()
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
          this.form.expireTime = this.form.expireTime * 1000
          if (this.form.expireTime === 0) {
            this.form.expireTime = null
          }
        }
        this.dialogFormVisible = true
      },
      close() {
        if (this.title === '添加') {
          this.$refs['addForm'].resetFields()
        } else if (this.title === '编辑') {
          this.$refs['form'].resetFields()
        }
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        if (this.title === '添加') {
          this.$refs['addForm'].validate(async (valid) => {
            if (valid) {
              let obj = new Object()
              obj.packageId = this.form.packageId
              obj.quantity = this.form.quantity
              const { error_code, reason } = await addCode(obj)
              if (error_code) {
                this.$baseMessage(reason, 'warning')
              } else {
                this.$baseMessage(reason, 'success', 'vab-hey-message-success')
              }
              this.$emit('fetch-data')
              this.close()
            }
          })
        } else if (this.title === '编辑') {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              let obj = new Object()
              obj.id = this.form.id
              if (this.form.isDown === true) {
                obj.isDown = 1
              } else if (this.form.isDown === false) {
                obj.isDown = 0
              }
              if (this.form.status === true) {
                obj.status = 1
              } else if (this.form.status === false) {
                obj.status = 0
              }
              obj.expireTime = this.form.expireTime / 1000
              const { reason } = await updateCode(obj)
              this.$baseMessage(reason, 'success', 'vab-hey-message-success')
              this.$emit('fetch-data')
              this.close()
            }
          })
        }
      },
      async fetchComboIdList() {
        const { result } = await getFlowComboList()
        this.comboIdList = result
      },
    },
  }
</script>
