<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-button
          v-if="$perms('serve_cdk_insert')"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          激活码生成
        </el-button>
        <el-button
          v-if="$perms('serve_cdk_down_cn')"
          icon="el-icon-download"
          type="success"
          @click="handleDownCN"
        >
          下载国内未使用激活码
        </el-button>
        <el-button
          v-if="$perms('serve_cdk_down_en')"
          icon="el-icon-download"
          type="success"
          @click="handleDownEN"
        >
          下载国外未使用激活码
        </el-button>
        <!-- <el-button
          v-if="$perms('setting_userinfo_batchdel')"
          icon="el-icon-delete"
          type="danger"
          @click="handleDelete"
        >
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="16">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.cdk"
              clearable
              placeholder="激活码"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.is_activate"
              clearable
              placeholder="是否已激活"
            >
              <el-option key="是" label="是" value="1" />
              <el-option key="否" label="否" value="0" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryForm.mold" clearable placeholder="地区">
              <el-option key="国内" label="国内" value="0" />
              <el-option key="国外" label="国外" value="1" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.order"
              clearable
              placeholder="订单ID"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="激活码"
        prop="cdk"
        show-overflow-tooltip
        width="300"
      />
      <el-table-column
        align="center"
        :formatter="moldFormat"
        label="地区"
        prop="rank__bucket__mold"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="套餐名"
        prop="rank__title"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="activateFormat"
        label="是否已激活"
        prop="is_activate"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="downFormat"
        label="是否已下载"
        prop="is_down"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="订单ID"
        prop="order"
        show-overflow-tooltip
      />

      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="85"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('user_userinfo_del')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { doDelete, getCdkList, down } from '@/api/cdk'
  import Edit from './components/Cdk'

  export default {
    name: 'Cdk',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          username: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit() {
        this.$refs['edit'].showEdit()
      },
      handleDownCN() {
        down({ region: 'cn' })
      },
      handleDownEN() {
        down({ region: 'en' })
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await doDelete({ id: row.id })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getCdkList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      moldFormat(row) {
        if (row.rank__bucket__mold === 0) {
          return '国内'
        } else {
          return '国外'
        }
      },
      activateFormat(row) {
        if (row.is_activate === 0) {
          return '否'
        } else {
          return '是'
        }
      },
      downFormat(row) {
        if (row.is_down === 0) {
          return '否'
        } else {
          return '是'
        }
      },
      freeFormat(row) {
        if (row.is_free === 0) {
          return '否'
        } else {
          return '是'
        }
      },
    },
  }
</script>
