var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        top: "3vh",
        visible: _vm.dialogFormVisible,
        width: "650px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保 存")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "form",
          attrs: {
            "label-position": "left",
            "label-width": "80px",
            model: _vm.form,
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动名称", prop: "activity_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入活动名称" },
                model: {
                  value: _vm.form.activity_name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "activity_name", $$v)
                  },
                  expression: "form.activity_name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-cell" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "活动期数", prop: "issue" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入活动期数" },
                    model: {
                      value: _vm.form.issue,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "issue", $$v)
                      },
                      expression: "form.issue"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "产品名", prop: "product_name" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入产品名" },
                    model: {
                      value: _vm.form.product_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "product_name", $$v)
                      },
                      expression: "form.product_name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-cell" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "物品数量", prop: "product_number" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入物品数量" },
                    model: {
                      value: _vm.form.product_number,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "product_number", $$v)
                      },
                      expression: "form.product_number"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "物品原价", prop: "original_price" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入物品原价" },
                    model: {
                      value: _vm.form.original_price,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "original_price", $$v)
                      },
                      expression: "form.original_price"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-cell" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "轮播图", prop: "carousel_image_url" }
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadCarouselImage",
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "#",
                        "auto-upload": false,
                        "http-request": _vm.upLoadCarouselImage,
                        "list-type": "picture-card",
                        "on-change": _vm.onCarouselImageChange,
                        "show-file-list": false
                      }
                    },
                    [
                      _vm.form.carousel_image_url
                        ? _c("el-image", {
                            staticClass: "avatar",
                            attrs: {
                              fit: "contain",
                              src: _vm.form.carousel_image_url
                            }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "详情图", prop: "details_image_url" }
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadDetailsImage",
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "#",
                        "auto-upload": false,
                        "http-request": _vm.upLoadDetailsImage,
                        "list-type": "picture-card",
                        "on-change": _vm.onDetailsImageChange,
                        "show-file-list": false
                      }
                    },
                    [
                      _vm.form.details_image_url
                        ? _c("el-image", {
                            staticClass: "avatar",
                            attrs: {
                              fit: "contain",
                              src: _vm.form.details_image_url
                            }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "activity-process",
              attrs: { label: "活动流程", prop: "activity_process" }
            },
            [
              _c(
                "el-timeline",
                _vm._l(_vm.form.activity_process, function(activity, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: index,
                      attrs: { icon: "ri-number-" + (index + 1), size: "large" }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "name" }, [
                            _vm._v("流程名称")
                          ]),
                          _c("el-input", {
                            attrs: { placeholder: "请输入流程名称" },
                            model: {
                              value: activity.node_content,
                              callback: function($$v) {
                                _vm.$set(activity, "node_content", $$v)
                              },
                              expression: "activity.node_content"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("日期")]),
                          _c("el-date-picker", {
                            attrs: {
                              "default-time": ["00:00:00", "23:59:59"],
                              "end-placeholder": "结束日期",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              type: "daterange"
                            },
                            model: {
                              value: activity.start_time,
                              callback: function($$v) {
                                _vm.$set(activity, "start_time", $$v)
                              },
                              expression: "activity.start_time"
                            }
                          }),
                          _c("div", { staticClass: "icon" }, [
                            index === _vm.form.activity_process.length - 1 &&
                            _vm.form.activity_process.length > 1
                              ? _c("i", {
                                  staticClass: "el-icon-remove-outline",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteTimeline(index)
                                    }
                                  }
                                })
                              : _vm._e(),
                            index === _vm.form.activity_process.length - 1 &&
                            _vm.form.activity_process.length < 5
                              ? _c("i", {
                                  staticClass: "el-icon-circle-plus-outline",
                                  on: {
                                    click: function($event) {
                                      return _vm.addTimeline(index)
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "48%" },
              attrs: { label: "是否显示", prop: "is_show" }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "展示",
                  "active-value": 1,
                  "inactive-text": "不展示",
                  "inactive-value": 0
                },
                model: {
                  value: _vm.form.is_show,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "is_show", $$v)
                  },
                  expression: "form.is_show"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }