import request from '@/utils/request'

export function getAiStoreMealList(params) {
  return request({
    url: '/dataManagement/userData/active_user',
    method: 'get',
    params,
  })
}

export function getType(params) {
  return request({
    url: '/userManagement/getDeviceTypeList',
    method: 'get',
    params,
  })
}
