<template>
  <el-card shadow="hover">
    <template #header>
      <span style="font-weight: bold;font-size: 18px">{{ title }}</span>
      <el-tooltip placement="top">
        <div slot="content">新增用户地区百分比只显示10%及以上的，其余的为其他。<br/>注意：未知国家是由于当时并未统计特定国家</div>
        <vab-icon
          icon="information-line"
        />
      </el-tooltip>
    </template>
    <vab-chart
      v-loading="loading"
      :init-options="initOptions"
      lazy
      :option="option"
      theme="vab-echarts-theme"
    />
  </el-card>
</template>

<script>
  import VabChart from '@/extra/VabChart'

  export default {
    name: 'VabChartPie',
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: true,
        initOptions: {
          renderer: 'svg',
        },
        option: {
          grid: {
            top: 20,
            right: 20,
            bottom: 40,
            left: 40,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          legend: {
            bottom: 'bottom',
            data: [],
          },
          series: [
            {
              name: '新增趋势',
              type: 'pie',
              radius: ['40%', '80%'],
              center: ['50%', '45%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: false,
                position: 'center',
                type: 'scroll',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '14',
                },
              },
              labelLine: {
                show: false,
              },
              data: [],
            },
          ],
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.$root.eventVueOv.$on('data-I', (message) => {
          let getData = []
          let r = 0
          for (let i in message) {
            let obj = new Object()
            if (message[i].rate != 0) {
              obj.name = message[i].countryName
              obj.value = message[i].count
              obj.rate = message[i].rate
              getData[r] = obj
              r++
            } else {
              continue
            }
          }
          this.option.series[0].data = getData
          this.option.legend.data = getData
          this.loading = false
        })
      },
    },
  }
</script>
