<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="handleEdit">
          添加
        </el-button>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-if="false"
        align="center"
        label="id"
        prop="id"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="套餐名称"
        prop="comboName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statuTypeFormat"
        label="状态"
        prop="status"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="comboTypeFormat"
        label="套餐类型"
        prop="comboType"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="流量总量值"
        prop="flowTotal"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="有效期天数"
        prop="expirationDays"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="expirationTypeFormat"
        label="有效期类型"
        prop="expirationType"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="payTypeFormat"
        label="付款类型"
        prop="payTypes"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="价格"
        prop="price"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="原价格"
        prop="virtualPrice"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="描述信息"
        prop="remark"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="TimeFormat"
        label="更新时间"
        prop="updatedTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="crTimeFormat"
        label="创建时间"
        prop="createdTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="showFormat"
        label="是否显示"
        prop="isShow"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="150"
      >
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">编辑</el-button>
          <el-button type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getComboPage, deleteStoreMeal } from '@/api/card'
  import Edit from './components/cardPackagemanagement'

  export default {
    name: 'CompanyInfo',
    components: { Edit },
    data() {
      return {
        radio: '1',
        dialogFormVisible: false,
        formLabelWidth: '120px',
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { reason } = await deleteStoreMeal({
              id: row.id,
            })
            this.$baseMessage(reason, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          result: { list, total },
        } = await getComboPage(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      payTypeFormat(row) {
        let payType = ''
        for (let payTypeID of row.payTypes) {
          if (payTypeID === 1) {
            payType += 'PayPal,'
          } else if (payTypeID === 2) {
            payType += '支付宝,'
          } else if (payTypeID === 3) {
            payType += '微信支付,'
          } else if (payTypeID === 10) {
            payType += '免费体验,'
          } else if (payTypeID === 11) {
            payType += '激活码,'
          }
        }
        return payType.slice(0, -1) // 切掉最后的','
      },
      expirationTypeFormat(row) {
        if (row.expirationType === 0) {
          return '天'
        } else if (row.expirationType === 1) {
          return '月'
        } else if (row.expirationType === 2) {
          return '年'
        }
      },
      TimeFormat(row) {
        if (row.updatedTime === 0) {
          return 0
        } else {
          var date = new Date(row.updatedTime * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds()
          )
        }
      },
      crTimeFormat(row) {
        if (row.createdTime === 0) {
          return 0
        } else {
          var date = new Date(row.createdTime * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds()
          )
        }
      },
      showFormat(row) {
        if (row.isShow === 0) {
          return '否'
        } else if (row.isShow === 1) {
          return '是'
        }
      },
      statuTypeFormat(row) {
        if (row.status === 0) {
          return '开启'
        } else if (row.status === 1) {
          return '停用'
        }
      },
      comboTypeFormat(row) {
        if (row.comboType === 0) {
          return '商用'
        } else if (row.comboType === 1) {
          return '初始化赠送'
        } else if (row.comboType === 2) {
          return '赠送套餐'
        }
      },
    },
  }
</script>
