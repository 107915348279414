<template>
  <div
    class="cloudUser-table-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.username"
              clearable
              placeholder="用户账号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.phone"
              clearable
              placeholder="用户手机"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userEmail"
              clearable
              placeholder="用户邮箱"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="uid"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="云存状态"
            >
              <el-option key="开启" label="开启" value="0" />
              <el-option key="关闭" label="关闭" value="1" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.use_status"
              clearable
              placeholder="使用状态"
            >
              <el-option key="使用中" label="使用中" value="1" />
              <el-option key="已过期" label="已过期" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.has_unused"
              clearable
              placeholder="有未使用套餐"
            >
              <el-option key="否" label="否" value="0" />
              <el-option key="是" label="是" value="1" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.payType"
              clearable
              placeholder="支付方式"
            >
              <el-option key="PayPal" label="PayPal" value="1" />
              <el-option key="支付宝" label="支付宝" value="2" />
              <el-option key="微信支付" label="微信支付" value="3" />
              <el-option key="免费体验" label="免费体验" value="10" />
              <el-option key="激活码" label="激活码" value="11" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <div class="block">
              <span>添加时间:</span>
              <el-date-picker
                v-model="queryForm.addTimeRange"
                align="right"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="timestamp"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <div class="block">
              <span>到期时间:</span>
              <el-date-picker
                v-model="queryForm.endTimeRange"
                align="right"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="timestamp"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <div class="block">
              <span>播放时间:</span>
              <el-date-picker
                v-model="queryForm.logTimeRange"
                align="right"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="timestamp"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>

      <vab-query-form-left-panel :span="12">
        <el-button
          v-if="$perms('serve_cloudUser_export')"
          icon="el-icon-menu"
          :loading="daochu == true"
          type="success"
          @click="handleDownload"
        >
          导出
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '支付方式'">
            {{ row[item.prop] | payTypeFilter }}
          </span>
          <span v-else-if="item.label === '云存状态'">
            {{ row[item.prop] | statusFilter }}
          </span>
          <span v-else-if="item.label === '使用状态'">
            {{ row[item.prop] | useStatusFilter }}
          </span>
          <span v-else-if="item.label === '是否有未使用套餐'">
            {{ row[item.prop] | unusedFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="85"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('serve_devicePackage_initexp')"
            type="text"
            @click="handleInitexp(row)"
          >
            查看详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getCloudUserList, down } from '@/api/cloudUser'
  import VabDraggable from 'vuedraggable'

  export default {
    name: 'Playtj',
    components: {
      VabDraggable,
    },
    filters: {
      payTypeFilter(value) {
        if (value === 1) {
          return 'PayPal'
        } else if (value === 2) {
          return '支付宝'
        } else if (value === 3) {
          return '微信支付'
        } else if (value === 10) {
          return '免费体验'
        } else if (value === 11) {
          return '激活码'
        }
      },
      statusFilter(value) {
        if (value === 0) {
          return '开启'
        } else if (value === 1) {
          return '关闭'
        }
      },
      useStatusFilter(value) {
        if (value === 1) {
          return '使用中'
        } else if (value === 2) {
          return '已过期'
        }
      },
      unusedFilter(value) {
        if (value === 0) {
          return '否'
        } else if (value === 1) {
          return '是'
        }
      },
    },
    data() {
      return {
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        daochu: false,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          username: '',
        },
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        checkList: [
          '用户账号',
          '用户手机号',
          '用户邮箱',
          '注册时间',
          '设备UID',
          '设备通道',
          '云存状态',
          '添加时间',
          '到期时间',
          '使用状态',
          '是否有未使用套餐',
          '套餐描述',
          '支付方式',
          '价格',
          '播放次数',
          '产品编码',
          '版本',
        ],
        columns: [
          {
            label: '用户账号',
            width: '220',
            prop: 'username',
            sortable: true,
          },
          {
            label: '用户手机号',
            width: '150',
            prop: 'phone',
            sortable: true,
          },
          {
            label: '用户邮箱',
            width: '200',
            prop: 'userEmail',
            sortable: true,
          },
          {
            label: '注册时间',
            width: '200',
            prop: 'data_joined',
            sortable: true,
          },
          {
            label: '设备UID',
            width: '220',
            prop: 'uid',
            sortable: true,
          },
          {
            label: '设备通道',
            width: 'auto',
            prop: 'channel',
          },
          {
            label: '云存状态',
            width: '120',
            prop: 'status',
            sortable: true,
          },
          {
            label: '添加时间',
            width: '200',
            prop: 'addTime',
            sortable: true,
          },
          {
            label: '到期时间',
            width: '200',
            prop: 'endTime',
            sortable: true,
          },
          {
            label: '使用状态',
            width: '120',
            prop: 'use_status',
            sortable: true,
          },
          {
            label: '是否有未使用套餐',
            width: '160',
            prop: 'has_unused',
            sortable: true,
          },
          {
            label: '套餐描述',
            width: '200',
            prop: 'desc',
            sortable: true,
          },
          {
            label: '支付方式',
            width: '120',
            prop: 'payType',
            sortable: true,
          },
          {
            label: '价格',
            width: '100',
            prop: 'price',
            sortable: true,
          },
          {
            label: '播放次数',
            width: '120',
            prop: 'playcount',
            sortable: true,
          },
          {
            label: '产品编码',
            width: '120',
            prop: 'ucode',
            sortable: true,
          },
          {
            label: '版本',
            width: '120',
            prop: 'version',
            sortable: true,
          },
        ],
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleDownload() {
        this.daochu = true
        down({})
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getCloudUserList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      palFormat(row) {
        const payType = row.payType
        if (payType == 1) {
          return 'PayPal'
        } else if (payType == 2) {
          return '支付宝'
        } else if (payType == 3) {
          return '微信支付'
        } else if (payType == 10) {
          return '免费体验'
        } else if (payType == 11) {
          return '激活码'
        }
      },
      statusFormat(row) {
        const status = row.status
        if (status == 0) {
          return '开启'
        } else if (status == 1) {
          return '关闭'
        } else {
          return status
        }
      },
      useFormat(row) {
        const use_status = row.use_status
        if (use_status == 1) {
          return '使用中'
        } else if (use_status == 2) {
          return '已过期'
        } else {
          return use_status
        }
      },
      sunusedFormat(row) {
        const has_unused = row.has_unused
        if (has_unused == 0) {
          return '否'
        } else if (has_unused == 1) {
          return '是'
        } else {
          return has_unused
        }
      },
    },
  }
</script>
