<template>
  <div class="serial-traffic-package-container">
    <el-row :gutter="20">
      <el-col style="width: auto">
        <el-card shadow="hover">
          <el-row class="option">
            <el-upload
              ref="upload"
              accept=".txt"
              action="string"
              :auto-upload="false"
              class="upload"
              :file-list="fileList"
              :http-request="upLoadFile"
              :limit="1"
              :on-exceed="handleExceed"
            >
              <el-button
                class="upload_btn"
                slot="trigger"
                style="margin: 0 10px 0 0 !important"
                type="primary"
              >
                选择序列号文件
              </el-button>
              <el-select
                v-model="form.packageId"
                clearable
                placeholder="请选择套餐"
              >
                <el-option
                  v-for="item in comboIdList"
                  :key="item.id"
                  :label="item.combo_name"
                  :value="item.id"
                />
              </el-select>
              <el-button
                v-if="$perms('iot_serialTrafficPackage_upload')"
                icon="el-icon-plus"
                style="margin: 0px 0 0 10px !important"
                type="primary"
                @click="handleUpload"
              >
                上传
              </el-button>
            </el-upload>
          </el-row>
          <el-row v-if="amount || amount === 0" class="amount">
            <span>已上传{{ amount }}条</span>
          </el-row>
          <el-input
            v-model="textarea"
            :rows="10"
            style="width: 400px"
            type="textarea"
          />
        </el-card>
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <el-card shadow="hover">
          <vab-query-form>
            <vab-query-form-left-panel :span="12">
              <el-form
                class="queryForm"
                :inline="true"
                :model="queryForm"
                @submit.native.prevent
              >
                <el-form-item>
                  <el-input
                    v-model.trim="queryForm.serialNumber"
                    clearable
                    placeholder="请输入设备唯一序列号"
                  />
                </el-form-item>
                <el-form-item>
                  <el-select
                    v-model="queryForm.packageId"
                    clearable
                    placeholder="请选择套餐"
                  >
                    <el-option
                      v-for="item in comboIdList"
                      :key="item.id"
                      :label="item.combo_name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button
                    icon="el-icon-search"
                    type="primary"
                    @click="fetchData"
                  >
                    查询
                  </el-button>
                </el-form-item>
              </el-form>
            </vab-query-form-left-panel>
            <vab-query-form-right-panel :span="12">
              <el-form class="file_deal">
                <el-form-item>
                  <el-upload
                    ref="uploadPlus"
                    accept=".txt"
                    action="string"
                    :auto-upload="false"
                    class="upload"
                    :file-list="fileListPlus"
                    :http-request="upLoadFilePlus"
                    :limit="1"
                    :on-exceed="handleExceedPlus"
                  >
                    <el-button
                      icon="el-icon-plus"
                      slot="trigger"
                      type="primary"
                    >
                      选择文件
                    </el-button>
                    <el-button
                      icon="el-icon-document-checked"
                      type="primary"
                      @click="checkAndDownload(1)"
                    >
                      核对
                    </el-button>
                    <el-button
                      icon="el-icon-menu"
                      type="primary"
                      @click="checkAndDownload(2)"
                    >
                      导出
                    </el-button>
                  </el-upload>
                </el-form-item>
              </el-form>
            </vab-query-form-right-panel>
          </vab-query-form>
          <el-table
            v-loading="listLoading"
            border
            :data="list"
            @selection-change="setSelectRows"
          >
            <el-table-column
              align="center"
              label="设备唯一序列号"
              prop="serialNumber"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              label="套餐id"
              prop="packageId"
              show-overflow-tooltip
              width="100"
            />
            <el-table-column
              align="center"
              label="套餐名称"
              prop="packageName"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              :formatter="statusFormat"
              label="状态"
              prop="status"
              show-overflow-tooltip
              width="100"
            />
            <el-table-column
              align="center"
              :formatter="timeFormat"
              label="创建时间"
              prop="createdTime"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              label="创建人"
              prop="createdBy"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              :formatter="timeFormat"
              label="修改时间"
              prop="updatedTime"
              show-overflow-tooltip
            />
            <el-table-column
              align="center"
              label="修改人"
              prop="updatedBy"
              show-overflow-tooltip
            />
            <el-table-column align="center" label="操作" width="100">
              <template #default="{ row }">
                <el-button disabled type="primary" @click="handleEdit(row)">
                  编辑
                </el-button>
              </template>
            </el-table-column>
            <template #empty>
              <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              />
            </template>
          </el-table>
          <el-pagination
            background
            :current-page="queryForm.pageNo"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
          <Edit
            ref="edit"
            :combo-id-list="comboIdList"
            @fetch-data="fetchData"
          />
        </el-card>
      </el-col>
    </el-row>
    <Detail ref="detail" />
  </div>
</template>

<script>
  import {
    getFlowComboList,
    uploadSerialNumberFile,
    getPackagePage,
    checkFiles,
    exportFiles,
  } from '@/api/serialTrafficPackage'
  import Edit from './components/serialTrafficPackage.vue'
  import Detail from './components/checkDialog.vue'

  export default {
    name: 'SerialTrafficPackage',
    components: { Edit, Detail },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        form: {},
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
        comboIdList: [],
        textarea: '',
        fileList: [],
        fileListPlus: [],
        amount: '',
      }
    },
    created() {
      this.fetchComboIdList()
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      statusFormat(row) {
        const status = row.status
        if (status === 1) {
          return '未激活'
        } else if (status === 2) {
          return '已激活'
        } else {
          return status
        }
      },
      timeFormat(row, column) {
        const data = row[column.property]
        if (data === 0) {
          return 'N/A'
        } else {
          const date = new Date(data * 1000)
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds()
          )
        }
      },
      async handleUpload() {
        this.formData = new FormData()
        this.$refs.upload.submit()
        this.formData.append('packageId', JSON.stringify(this.form.packageId))
        if (!this.formData.get('file')) {
          this.$baseMessage('请选择序列号文件', 'warning')
        } else {
          if (this.form.packageId) {
            const { result, reason, error_code } = await uploadSerialNumberFile(
              this.formData
            )
            if (error_code === 0) {
              let errMsg = '异常信息：\n'
              for (let val of result.errData) {
                val = JSON.stringify(val).replace('{', '').replace('}', '')
                val = val + '\n'
                errMsg = errMsg + val
              }
              this.textarea = errMsg
              this.amount = result.total
            } else if (error_code === 10074) {
              this.$baseMessage(reason, 'warning', 'vab-hey-message-warning')
            } else {
              this.$baseMessage(reason, 'error', 'vab-hey-message-error')
            }
          } else {
            this.$baseMessage(
              '请选择套餐',
              'warning',
              'vab-hey-message-warning'
            )
          }
        }
      },
      upLoadFile(file) {
        this.formData.append('file', file.file)
      },
      upLoadFilePlus(file) {
        this.formData.append('serialFile', file.file)
      },
      handleExceed(files) {
        this.$refs.upload.clearFiles()
        this.$nextTick(() => {
          this.$refs.upload.handleStart(files[0])
        })
      },
      handleExceedPlus(files) {
        this.$refs.uploadPlus.clearFiles()
        this.$nextTick(() => {
          this.$refs.uploadPlus.handleStart(files[0])
        })
      },
      async checkAndDownload(val) {
        this.formData = new FormData()
        this.$refs.uploadPlus.submit()
        this.formData.append('optType', val)
        if (!this.formData.get('serialFile')) {
          this.$baseMessage('请选择文件', 'warning')
        } else {
          if (val === 1) {
            const { result } = await checkFiles(this.formData)
            this.$refs['detail'].showComponent(JSON.stringify(result))
          } else {
            exportFiles(this.formData)
              .then((re) => {
                console.log(re)
                let blob = new Blob([re], {
                  type: 'application/vnd.ms-excel;charset=utf-8',
                })
                let downloadUrl = window.URL.createObjectURL(blob)
                let a = document.createElement('a')
                a.href = downloadUrl
                a.setAttribute('download', '套餐核对.xlsx')
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
              })
              .catch((re) => {
                console.log(re)
              })
          }
        }
      },
      async fetchComboIdList() {
        const { result } = await getFlowComboList()
        this.comboIdList = result
      },
      async fetchData() {
        this.listLoading = true
        const {
          result: { list, total },
        } = await getPackagePage(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
    },
  }
</script>
<style>
  .el-upload-list {
    width: 410px;
  }
</style>
<style lang="scss" scoped>
  .option {
    .upload {
      min-width: 420px;
      height: 100px;
    }
    .el-button {
      padding-right: 10px;
      &:last-child {
        padding-right: 0;
      }
    }
  }
  .amount {
    justify-content: right;
    display: flex;
    width: 400px;
    color: #c0c4cc;
  }
  .queryForm {
    padding-bottom: 10px;
    float: left;
  }
  .file_deal {
    .upload {
      text-align: end;
      max-height: 35px;
      ::v-deep {
        .el-upload {
          padding-right: 10px;
        }
        .el-upload-list {
          text-align: left;
          transform: translate(-130px, -36px);
          width: 125px;
        }
      }
    }
  }
</style>
