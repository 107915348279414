var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 12 } },
            [
              _vm.$perms("user_userinfo_insert")
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleEdit }
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "存储桶名称" },
                          on: { change: _vm.selectChange },
                          model: {
                            value: _vm.selectValue,
                            callback: function($$v) {
                              _vm.selectValue = $$v
                            },
                            expression: "selectValue"
                          }
                        },
                        _vm._l(_vm.selectList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showBucketInput,
                            expression: "showBucketInput"
                          }
                        ],
                        attrs: {
                          clearable: "",
                          placeholder: "请输入存储桶名称"
                        },
                        model: {
                          value: _vm.queryForm.bucket,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "bucket",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.bucket"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showMoldSelect,
                              expression: "showMoldSelect"
                            }
                          ],
                          on: { change: _vm.moldChange },
                          model: {
                            value: _vm.moldValue,
                            callback: function($$v) {
                              _vm.moldValue = $$v
                            },
                            expression: "moldValue"
                          }
                        },
                        _vm._l(_vm.moldList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showFreeSelect,
                              expression: "showFreeSelect"
                            }
                          ],
                          on: { change: _vm.freeChange },
                          model: {
                            value: _vm.freeValue,
                            callback: function($$v) {
                              _vm.freeValue = $$v
                            },
                            expression: "freeValue"
                          }
                        },
                        _vm._l(_vm.freeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.queryData }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              type: "selection"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var $index = ref.$index
                  return [_vm._v(" " + _vm._s($index + 1) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "存储桶名称",
              prop: "bucket",
              "show-overflow-tooltip": "",
              width: "200"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "描述",
              prop: "content",
              "show-overflow-tooltip": "",
              width: "180"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.moldFormat,
              label: "国内外",
              prop: "mold",
              "show-overflow-tooltip": "",
              width: "70"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "国家",
              prop: "area",
              "show-overflow-tooltip": "",
              width: "50"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "地域",
              prop: "region",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "存储桶节点",
              prop: "endpoint",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.freeFormat,
              label: "是否免费",
              prop: "is_free",
              "show-overflow-tooltip": "",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "存储天数",
              prop: "storeDay",
              "show-overflow-tooltip": "",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.Regionformat,
              label: "大洲编号",
              prop: "region_id",
              "show-overflow-tooltip": "",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "添加时间",
              prop: "addTime",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "更新时间",
              prop: "updTime",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm.$perms("user_userinfo_edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(row)
                              }
                            }
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _vm._e(),
                    _vm.$perms("user_userinfo_del")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(row)
                              }
                            }
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }