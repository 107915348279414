var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "upload-info-container",
      class: { "vab-fullscreen": _vm.isFullscreen }
    },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "序列号" },
                        model: {
                          value: _vm.queryForm.serial_number,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "serial_number",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.serial_number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "uid" },
                        model: {
                          value: _vm.queryForm.uid,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "uid",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.uid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.queryData }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "reference",
                              fn: function() {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        margin: "0 0 10px 0 !important"
                                      },
                                      attrs: {
                                        icon: "el-icon-setting",
                                        type: "primary"
                                      }
                                    },
                                    [_vm._v(" 显示列设置 ")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList"
                              }
                            },
                            [
                              _c(
                                "vab-draggable",
                                _vm._b(
                                  { attrs: { list: _vm.columns } },
                                  "vab-draggable",
                                  _vm.dragOptions,
                                  false
                                ),
                                _vm._l(_vm.columns, function(item, index) {
                                  return _c(
                                    "div",
                                    { key: item + index },
                                    [
                                      _c("vab-icon", {
                                        attrs: { icon: "drag-drop-line" }
                                      }),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled:
                                              item.disableCheck === true,
                                            label: item.label
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(item.label) + " ")]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0 10px 10px 0 !important" },
                          attrs: { type: "primary" },
                          on: { click: _vm.clickFullScreen }
                        },
                        [
                          _c("vab-icon", {
                            attrs: {
                              icon: _vm.isFullscreen
                                ? "fullscreen-exit-fill"
                                : "fullscreen-fill"
                            }
                          }),
                          _vm._v(" 表格全屏 ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "tableSort",
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows }
        },
        [
          _vm._l(_vm.finallyColumns, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                align: "center",
                label: item.label,
                prop: item.prop,
                "show-overflow-tooltip": "",
                sortable: item.sortable,
                width: item.width
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        item.label === "上传状态"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("statusFilter")(row[item.prop])
                                  ) +
                                  " "
                              )
                            ])
                          : _c("span", [_vm._v(_vm._s(row[item.prop]))])
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "show-overflow-tooltip": "",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm.$perms("log_devicelog_download")
                      ? _c(
                          "a",
                          {
                            staticClass: "download",
                            attrs: { href: row.download_url }
                          },
                          [_vm._v(" 下载日志文件 ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }