<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="400px"
    @close="close"
  >
    <el-form ref="form" :model="form">
      <el-form-item>
        <el-upload
          ref="upload"
          accept=".txt"
          action="string"
          :auto-upload="false"
          class="upload"
          :http-request="upLoadFile"
          :limit="1"
          :on-exceed="handleExceed"
        >
          <el-button
            class="upload_button"
            slot="trigger"
            size="small"
            type="primary"
          >
            选择txt文件
          </el-button>
          <el-select
            v-model="form.type"
            class="upload_select"
            placeholder="请选择是否解绑出厂无限流量"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">重 置</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { iccidBatchReset } from '@/api/cardUserInformation'
  export default {
    name: 'BatchReset',
    data() {
      return {
        title: '批量重置',
        dialogFormVisible: false,
        form: {
          type: 0,
        },
        typeList: [
          {
            value: 0,
            label: '不解绑',
          },
          {
            value: 1,
            label: '解绑',
          },
        ],
      }
    },
    methods: {
      showComponent() {
        this.dialogFormVisible = true
      },
      close() {
        this.$refs.upload.clearFiles()
        this.dialogFormVisible = false
      },
      save() {
        this.formData = new FormData()
        this.$refs.upload.submit()
        this.formData.append('type', this.form.type)
        let title = '是否进行批量重置？'
        if (this.form.type === 1) {
          title = '是否进行批量重置并且解绑吗？'
        }
        if (!this.formData.get('file')) {
          this.$baseMessage('请选择文件', 'warning')
        } else {
          this.$confirm(title, '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            const { reason, error_code } = await iccidBatchReset(this.formData)
            if (error_code === 0) {
              this.$baseMessage(
                '批量重置成功',
                'success',
                'vab-hey-message-success'
              )
            } else if (error_code === 10074) {
              this.$baseMessage(reason, 'warning', 'vab-hey-message-warning')
            } else {
              this.$baseMessage(reason, 'error', 'vab-hey-message-error')
            }
            this.close()
          })
        }
      },
      upLoadFile(file) {
        this.formData.append('file', file.file)
      },
      handleExceed(files) {
        this.$refs.upload.clearFiles()
        this.$nextTick(() => {
          this.$refs.upload.handleStart(files[0])
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .upload {
    height: 50px;
  }
  .upload_select {
    float: right;
  }
  ::v-deep {
    .el-upload-list__item {
      transition: none !important;
      -webkit-transition: nonne !important;
    }
    .el-upload-list__item-name {
      transition: none !important;
      -webkit-transition: nonne !important;
    }
  }
</style>
