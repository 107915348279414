<template>
  <div class="active-user-container">
    <el-card shadow="hover">
      <template #header>
        <div class="header">
          <span style="font-weight: bold; font-size: 18px">活跃用户</span>
          <time-picker @time="timeChange" />
        </div>
      </template>
      <el-row justify="space-between" type="flex">
        <el-col class="query" :lg="8" :md="12" :sm="12" :xl="8" :xs="12">
          <el-select
            v-model="queryForm.device_type"
            clearable
            placeholder="设备类型"
          >
            <el-option
              v-for="item in deviceList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <el-input
            v-model.trim="queryForm.ucode"
            clearable
            placeholder="ucode"
            style="margin-left: 10px"
          />
          <el-button
            icon="el-icon-search"
            style="margin-left: 10px"
            type="primary"
            @click="fetchData"
          >
            查询
          </el-button>
        </el-col>
      </el-row>
      <div class="echartClass">
        <Bar :data="deviceTypeData" />
        <Bar :data="ucodeData" />
      </div>
    </el-card>
  </div>
</template>

<script>
  import timePicker from './components/timePicker'
  import Bar from './components/chartBar'
  import { getAiStoreMealList, getType } from '@/api/activeUserStat'

  export default {
    components: {
      timePicker,
      Bar,
    },
    data() {
      return {
        deviceList: [],
        deviceTypeData: [],
        ucodeData: [],
        queryForm: {},
      }
    },
    created() {
      this.fetchType()
    },
    methods: {
      // 时间切换
      timeChange(data) {
        this.queryForm.startTime = data[0] / 1000
        this.queryForm.endTime = data[1] / 1000
        this.fetchData()
      },
      // 获取设备类型
      async fetchType() {
        const {
          data: { list },
        } = await getType()
        this.deviceList = list
      },
      // 请求活跃用户数据
      async fetchData() {
        const { result } = await getAiStoreMealList(this.queryForm)
        this.deviceTypeData = result.device_type
        this.ucodeData = result.ucode
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-select {
      width: 350px;
    }
  }
  .echarts {
    width: 50%;
    height: 570px;
  }
  .echartClass {
    display: flex;
  }
  .header {
    display: flex;
    justify-content: space-between;
    place-items: center;
  }
  .query {
    display: flex;
  }
</style>
