import request from '@/utils/request'

export function AItest(data) {
  return request({
    url: '/testServe/AItest',
    method: 'post',
    data,
  })
}

export function doAddOrEdit(data) {
  return request({
    url: '/userManagement/AddOrEditAccount',
    method: 'post',
    data,
  })
}

export function doEdit(data) {
  return request({
    url: '/userManagement/editAccount',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/userManagement/doDelete',
    method: 'post',
    data,
  })
}
