import request from '@/utils/request'

export function getFlowInfo(params) {
  return request({
    url: 'dataManagement/unicomData/getFlowInfo',
    method: 'get',
    params,
  })
}

export function getMonthlyFlowInfo(params) {
  return request({
    url: 'dataManagement/unicomData/getMonthlyFlowInfo',
    method: 'get',
    params,
  })
}
