import request from '@/utils/request'

export function getComboPage(params) {
  return request({
    url: 'unicom/manage/get/deta/info',
    method: 'get',
    params,
  })
}

export function addOrEditCardMeal(data) {
  return request({
    url: '/unicom/manage/edit/combo',
    method: 'post',
    data,
  })
}

export function deleteStoreMeal(data) {
  return request({
    url: 'unicom/manage/dele/combo/info',
    method: 'post',
    data,
  })
}
