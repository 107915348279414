var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hello" },
    [
      _c("h1", [_vm._v("el-tree基本功能")]),
      _c("el-tree", {
        ref: "tree",
        attrs: {
          "check-strictly": "",
          data: _vm.treeData,
          "default-checked-keys": [1],
          "default-expand-all": "",
          "node-key": "id",
          props: _vm.defaultProps,
          "show-checkbox": ""
        },
        on: {
          "check-change": _vm.handleCheckChange,
          "node-click": _vm.handleNodeClick
        }
      }),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.getChecked }
            },
            [_vm._v(" 获取选中 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.setChecked }
            },
            [_vm._v(" 设置选中 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.selectAllNodes }
            },
            [_vm._v(" 全选 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.clearAllNodes }
            },
            [_vm._v(" 清空 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "warning" },
              on: { click: _vm.handleSelectAll }
            },
            [_vm._v(" 全选/清空 ")]
          ),
          _c(
            "el-select",
            {
              attrs: { size: "mini" },
              on: { change: _vm.selectChange },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            _vm._l(_vm.options, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }