import request from '@/utils/request'

export function getPcInfoList(params) {
  return request({
    url: '/versionManagement/getPcInfoList',
    method: 'get',
    params,
  })
}

export function editPcVersion(data) {
  return request({
    url: '/versionManagement/editPcVersion',
    method: 'post',
    data,
  })
}

export function deletePcInfo(data) {
  return request({
    url: '/versionManagement/deletePcInfo',
    method: 'post',
    data,
  })
}
