import request from '@/utils/request'

export function getAppVersionList(params) {
  return request({
    url: '/versionManagement/getAppVersionList',
    method: 'get',
    params,
  })
}

export function addOrEditAppInfo(data) {
  return request({
    url: '/versionManagement/addOrEditAppInfo',
    method: 'post',
    data,
  })
}

export function deleteAppVersion(data) {
  return request({
    url: '/versionManagement/deleteAppVersion',
    method: 'post',
    data,
  })
}

export function getAppSet(data) {
  return request({
    url: '/versionManagement/getAppSet',
    method: 'post',
    data,
  })
}

export function editAppSet(data) {
  return request({
    url: '/versionManagement/editAppSet',
    method: 'post',
    data,
  })
}
