<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form">
      <el-input
        v-model.trim="form.hint"
        clearable
        placeholder="请输入理由"
        :rows="5"
        type="textarea"
      />
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save()">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { checkPass } from '@/api/superPassword'

  export default {
    name: 'UserInformation',
    data() {
      return {
        form: {
          userID: '',
          uid: '',
          hint: '',
          status: 0,
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    methods: {
      showReason(row) {
        this.title = '未通过理由'
        this.dialogFormVisible = true
        this.form.userID = row.userID
        this.form.uid = row.uid
        this.form.hint = row.hint
        this.form.ID = row.id
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            // this.form.userID = row.userID
            // this.form.uid = row.uid
            const { reason } = await checkPass(this.form)
            this.$baseMessage(reason, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-textarea {
    width: 400px;
  }
</style>
