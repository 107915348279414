<template>
  <div class="target">
    <el-card class="target-card1" shadow="hover">
      <template #header>
        <span style="font-weight: bold; font-size: 18px">{{ title }}</span>
        <el-tooltip placement="top">
          <div slot="content">
            统计一周（按7天为单位）、一个月（按周为单位）、
            <br />
            三个月（按月为单位）和一年（按月为单位）的销售额趋势
          </div>
          <vab-icon icon="information-line" />
        </el-tooltip>
      </template>
      <el-row class="row-bg" justify="end" type="flex">
        <el-radio-group v-model="radio1" @change="buttonChange">
          <el-radio-button :label="1" plain type="primary">
            一周
          </el-radio-button>
          <el-radio-button :label="2" plain type="primary">
            一个月
          </el-radio-button>
          <el-radio-button :label="3" plain type="primary">
            三个月
          </el-radio-button>
          <el-radio-button :label="4" plain type="primary">
            一年
          </el-radio-button>
        </el-radio-group>
      </el-row>
      <vab-chart
        v-loading="loading"
        class="target-echart1"
        :init-options="initOptions"
        :option="option"
        theme="vab-echarts-theme"
      />
    </el-card>
  </div>
</template>
<script>
  import VabChart from '@/extra/VabChart'
  import { getSalesVolume } from '@/api/overView'

  export default {
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      const colorList = ['#9E87FF', '#73DDFF', '#fe9a8b', '#F56948', '#9E87FF']
      return {
        loading: true,
        radio1: 1,
        queryForm: {
          startTime: '',
          endTime: '',
          timeUnit: 'day',
        },
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'z-index:1',
          },
          legend: {
            type: 'scroll',
          },
          grid: {
            top: '4%',
            left: '2%',
            right: '5%',
            bottom: '2%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: [],
              axisLine: {
                lineStyle: {
                  color: '#DCE2E8',
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                interval: 0,
                color: '#556677',
                fontSize: 12,
                margin: 15,
              },
              axisPointer: {
                label: {
                  padding: [0, 0, 10, 0],
                  margin: 15,
                  fontSize: 12,
                  backgroundColor: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#fff',
                      },
                      {
                        offset: 0.86,
                        color: '#fff',
                      },
                      {
                        offset: 0.86,
                        color: '#33c0cd',
                      },
                      {
                        offset: 1,
                        color: '#33c0cd',
                      },
                    ],
                    global: false,
                  },
                },
              },
              boundaryGap: false,
            },
          ],
          yAxis: [
            {
              type: 'value',
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#DCE2E8',
                },
              },
              axisLabel: {
                color: '#556677',
              },
              splitLine: {
                show: false,
              },
              boundaryGap: [0, '30%'],
            },
          ],
          series: [
            {
              name: '人民币',
              type: 'line',
              data: [],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              lineStyle: {
                width: 5,
              },
              itemStyle: {
                color: colorList[3],
                borderColor: colorList[3],
              },
            },
            {
              name: '美元',
              type: 'line',
              data: [],
              symbolSize: 1,
              symbol: 'circle',
              smooth: true,
              yAxisIndex: 0,
              showSymbol: false,
              lineStyle: {
                width: 5,
              },
              itemStyle: {
                color: colorList[1],
                borderColor: colorList[1],
              },
            },
          ],
        },
      }
    },
    created() {
      this.oneWeek()
    },
    methods: {
      buttonChange(val) {
        if (val == 1) {
          this.oneWeek()
        } else if (val == 2) {
          this.oneMonth()
        } else if (val == 3) {
          this.threeMonths()
        } else {
          this.oneYear()
        }
      },
      oneWeek() {
        this.queryForm.timeUnit = 'day'
        this.queryForm.endTime = this.getNowDate()
        let endTime = this.queryForm.endTime
        this.queryForm.startTime = endTime - 1 * 60 * 60 * 24 * 7
        this.getData()
      },
      oneMonth() {
        this.queryForm.timeUnit = 'week'
        this.queryForm.endTime = this.getNowDate()
        let endTime = this.queryForm.endTime
        this.queryForm.startTime = endTime - 1 * 60 * 60 * 24 * 30
        this.getData()
      },
      threeMonths() {
        this.queryForm.timeUnit = 'month'
        this.queryForm.endTime = this.getNowDate()
        let endTime = this.queryForm.endTime
        this.queryForm.startTime = endTime - 1 * 60 * 60 * 24 * 90
        this.getData()
      },
      oneYear() {
        this.queryForm.timeUnit = 'month'
        this.queryForm.endTime = this.getNowDate()
        let endTime = this.queryForm.endTime
        this.queryForm.startTime = endTime - 1 * 60 * 60 * 24 * 360
        this.getData()
      },
      async getData() {
        const { result } = await getSalesVolume(this.queryForm)
        let endTime = []
        for (let i in result) {
          endTime.push(this.timeFormat(result[i].endTime))
        }
        let rmbTotal = []
        let usdTotal = []
        for (let r in result) {
          rmbTotal.push(result[r].cnyTotal)
          usdTotal.push(result[r].usdTotal)
        }
        this.option.xAxis[0].data = endTime
        this.option.series[0].data = rmbTotal
        this.option.series[1].data = usdTotal
        this.loading = false
      },
      getNowDate() {
        let myDate = new Date()
        let year = myDate.getFullYear() //获取当前年
        let mon = myDate.getMonth() + 1 //获取当前月
        if (mon < 10) {
          mon = '0' + mon
        }
        let date = myDate.getDate() //获取当前日
        if (date < 10) {
          date = '0' + date
        }
        let now =
          year + '-' + mon + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00'
        let nowAny = ''
        nowAny = Date.parse(now)
        return nowAny / 1000
      },
      timeFormat(time) {
        if (time === 0) {
          return 0
        } else if (this.queryForm.timeUnit == 'month') {
          let date = new Date(time * 1000)
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1)
          )
        } else {
          let date = new Date(time * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .target {
    margin-bottom: $base-margin;
  }

  ::v-deep {
    .target-echart1 {
      width: 100%;
      height: 205px;
    }
  }
</style>
