import request from '@/utils/request'

export function getAllData(params) {
  return request({
    url: 'dataManagement/homeData/global/allData',
    method: 'get',
    params,
  })
}

export function getSalesVolume(params) {
  return request({
    url: 'dataManagement/homeData/global/salesVolume',
    method: 'get',
    params,
  })
}
