<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button
          v-if="$perms('user_userinfo_insert')"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <!-- 选择查询内容 -->
          <el-form-item>
            <el-select
              v-model="selectValue"
              placeholder="存储桶名称"
              @change="selectChange"
            >
              <el-option
                v-for="item in selectList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-show="showBucketInput"
              v-model.trim="queryForm.bucket"
              clearable
              placeholder="请输入存储桶名称"
            />
          </el-form-item>
          <!-- 选择国内外查询 -->
          <el-form-item>
            <el-select
              v-show="showMoldSelect"
              v-model="moldValue"
              @change="moldChange"
            >
              <el-option
                v-for="item in moldList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 选择是否免费查询 -->
          <el-form-item>
            <el-select
              v-show="showFreeSelect"
              v-model="freeValue"
              @change="freeChange"
            >
              <el-option
                v-for="item in freeList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        label="存储桶ID"
        prop="bucketID"
        show-overflow-tooltip
      /> -->
      <el-table-column
        align="center"
        label="存储桶名称"
        prop="bucket"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="描述"
        prop="content"
        show-overflow-tooltip
        width="180"
      />
      <el-table-column
        align="center"
        :formatter="moldFormat"
        label="国内外"
        prop="mold"
        show-overflow-tooltip
        width="70"
      />
      <el-table-column
        align="center"
        label="国家"
        prop="area"
        show-overflow-tooltip
        width="50"
      />
      <el-table-column
        align="center"
        label="地域"
        prop="region"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="存储桶节点"
        prop="endpoint"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="freeFormat"
        label="是否免费"
        prop="is_free"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="存储天数"
        prop="storeDay"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        :formatter="Regionformat"
        label="大洲编号"
        prop="region_id"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="添加时间"
        prop="addTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="更新时间"
        prop="updTime"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="操作" width="150">
        <template #default="{ row }">
          <el-button
            v-if="$perms('user_userinfo_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$perms('user_userinfo_del')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { deleteVodBucket, getVodBucketList } from '@/api/cloudStorageBucket'
  import Edit from './components/CloudStorageBucket'

  export default {
    name: 'CloudStorageBucket',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        selectValue: '',
        selectList: [
          {
            value: '存储桶名称',
            label: '存储桶名称',
          },
          {
            value: '国内外',
            label: '国内外',
          },
          {
            value: '是否免费',
            label: '是否免费',
          },
        ],
        moldValue: '国内',
        moldList: [
          {
            value: '国内',
            label: '国内',
          },
          {
            value: '国外',
            label: '国外',
          },
        ],
        freeValue: '否',
        freeList: [
          {
            value: '否',
            label: '否',
          },
          {
            value: '是',
            label: '是',
          },
        ],
        showBucketInput: true,
        showMoldSelect: false,
        showFreeSelect: false,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.bucketID) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.bucketID) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteVodBucket({ bucketID: row.bucketID })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await deleteVodBucket({ ids })
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getVodBucketList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      moldFormat(row) {
        if (row.mold === 0) {
          return '国内'
        } else {
          return '国外'
        }
      },
      freeFormat(row) {
        if (row.is_free === 0) {
          return '否'
        } else {
          return '是'
        }
      },
      Regionformat(row) {
        if (row.region_id === 1) {
          return '1(中国)'
        } else if (row.region_id === 2) {
          return '2(亚洲)'
        } else if (row.region_id === 3) {
          return '3(美洲)'
        } else if (row.region_id === 4) {
          return '4(欧洲)'
        } else {
          return row.region_id
        }
      },
      selectChange(selectValue) {
        if (selectValue == '存储桶名称') {
          // 切换显示
          this.showBucketInput = true
          this.showMoldSelect = false
          this.showFreeSelect = false
          // 清空无关的查询数据
          this.queryForm.mold = ''
          this.queryForm.is_free = ''
        } else if (selectValue == '国内外') {
          this.showBucketInput = false
          this.showMoldSelect = true
          this.showFreeSelect = false
          this.queryForm.bucket = ''
          this.queryForm.mold = 0
          this.queryForm.is_free = ''
        } else if (selectValue == '是否免费') {
          this.showBucketInput = false
          this.showMoldSelect = false
          this.showFreeSelect = true
          this.queryForm.bucket = ''
          this.queryForm.mold = ''
          this.queryForm.is_free = 0
        }
      },
      moldChange(moldValue) {
        if (moldValue == '国内') {
          this.queryForm.mold = 0
        } else {
          this.queryForm.mold = 1
        }
      },
      freeChange(freeValue) {
        if (freeValue == '否') {
          this.queryForm.is_free = 0
        } else {
          this.queryForm.is_free = 1
        }
      },
    },
  }
</script>
