var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vab-chart", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: _vm.loading,
        expression: "loading"
      }
    ],
    attrs: {
      "init-options": _vm.initOptions,
      lazy: "",
      option: _vm.option,
      theme: "vab-echarts-theme"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }