<template>
  <el-dialog title="选择时间" :visible.sync="dialogVisible" width="400px">
    <el-date-picker
      v-model="time"
      align="center"
      end-placeholder="结束月份"
      range-separator="-"
      start-placeholder="开始月份"
      type="monthrange"
      value-format="timestamp"
    />
    <template #footer>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        :disabled="!time"
        :loading="loading"
        type="primary"
        @click="download"
      >
        导 出
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { getOperatingCosts } from '@/api/deviceOrder'
  import { formatDate } from '@/utils/formatDate'
  // const axios = require('axios')

  export default {
    name: 'DownloadExcel',
    data() {
      return {
        dialogVisible: false,
        loading: false,
        time: null,
        form: {},
        fileName: '',
      }
    },
    methods: {
      showComponent() {
        this.dialogVisible = true
      },
      timeFormatter(val, format) {
        if (val) {
          const time = val * 1000
          return formatDate(new Date(time), format)
        } else {
          return ''
        }
      },
      formatJson(filterVal, jsonData) {
        const formatValue = (v) => ({
          ...v,
          time: this.timeFormatter(v.time, 'yyyy-MM'),
          start_time: this.timeFormatter(v.start_time, 'yyyy-MM-dd hh:mm:ss'),
          end_time: this.timeFormatter(v.end_time, 'yyyy-MM-dd hh:mm:ss'),
          created_time: this.timeFormatter(
            v.created_time,
            'yyyy-MM-dd hh:mm:ss'
          ),
          price: Number(v.price),
          fee: Number(v.price),
          real_income: Number(v.real_income),
          day_average_price: Number(v.day_average_price),
          month_average_price: Number(v.month_average_price),
          purchase_quantity: Number(v.purchase_quantity),
          monthly_income: Number(v.monthly_income),
          remaining_usage_time: Number(v.remaining_usage_time),
          actual_storage: Number(v.actual_storage),
          storage_cost: Number(v.storage_cost),
          actual_api: Number(v.actual_api),
          api_cost: Number(v.api_cost),
          actual_flow: Number(v.actual_flow),
          flow_cost: Number(v.flow_cost),
          profit: Number(v.profit),
          profit_margin: Number(v.profit_margin),
        })
        const formattedData = jsonData.map(formatValue)
        return formattedData.map((v) => filterVal.map((j) => v[j]))
      },
      async download() {
        this.loading = true
        let listData = []
        this.form.startTime = this.time[0] / 1000
        this.form.endTime = this.time[1] / 1000
        this.fileName = ''
        this.fileName =
          '订单详情' +
          '(' +
          this.timeFormatter(this.form.startTime, 'yyyy-MM') +
          '至' +
          this.timeFormatter(this.form.endTime, 'yyyy-MM') +
          ')'
        const {
          result: { res },
        } = await getOperatingCosts(this.form)
        // const url =
        //   'http://129.153.85.188:8800/dataManagement/costsData/getOperatingCosts'
        // const token =
        //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiIxNTE1NjQyNjIzMzc5Mzk1MTM4MDAxMzgwMDEiLCJsYW5nIjoiZW4iLCJ1c2VyIjoiMTM4MDAxMzgwMDEiLCJtX2NvZGUiOiIxMjM0MTMyNDMyMTQiLCJleHAiOjE3MjE3ODI0NzJ9.zv0lEanSFgIoXOcdOkZBlU8g4kKcPe9CQqkVaztFiww'
        // const headers = {
        //   Authorization: `Bearer ${token}`,
        // }
        // const params = this.form
        // await axios.get(url, { params, headers }).then(function (res) {
        //   listData = res.data.result.res
        // })
        listData = res
        if (listData) {
          import('@/utils/excel').then((excel) => {
            const tHeader = [
              '订单号',
              'UID',
              '国内外',
              '地区',
              '类别',
              '套餐类型',
              '订单金额',
              '订单费用',
              '订单实收',
              '收入分摊/天',
              '收入分摊/月',
              '购买量/月',
              '使用月份',
              '开始使用时间',
              '结束时间',
              '当月结算时间',
              '当月结算天数',
              '当月结算收入',
              '剩余使用时间（天）',
              '实际存储量',
              '存储桶成本',
              '实际调用api次数',
              'api调用成本',
              '实际流量',
              '流量成本',
              '利润',
              '利润率',
            ]
            const filterVal = [
              'order_id',
              'uid',
              'region',
              'country_name',
              'expire',
              'order_type',
              'price',
              'fee',
              'real_income',
              'day_average_price',
              'month_average_price',
              'purchase_quantity',
              'time',
              'start_time',
              'end_time',
              'created_time',
              'settlement_days',
              'monthly_income',
              'remaining_usage_time',
              'actual_storage',
              'storage_cost',
              'actual_api',
              'api_cost',
              'actual_flow',
              'flow_cost',
              'profit',
              'profit_margin',
            ]
            const list = listData
            const data = this.formatJson(filterVal, list)
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: this.fileName,
            })
            this.loading = false
          })
        } else {
          this.loading = false
          this.$baseMessage('数据为空', 'warning', 'vab-hey-message-warning')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      display: flex;
      justify-content: center;
    }
  }
</style>
