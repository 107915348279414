<template>
  <el-dialog
    :title="title"
    top="3vh"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="110px" :model="form" :rules="rules">
      <el-form-item label="存储桶名称" prop="bucket">
        <el-select v-model="form.bucket" placeholder="请选择存储桶">
          <el-option
            v-for="item in bucketList"
            :key="item.id"
            :label="item.bucket"
            :value="item.bucket"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="天数" prop="day">
        <el-input v-model.trim="form.day" placeholder="请输入天数" />
      </el-form-item>
      <el-form-item label="有效期(月)" prop="expire">
        <el-select v-model="form.expire" placeholder="请选择有效期">
          <el-option
            v-for="item in expireList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="类型" prop="commodity_type">
        <el-select v-model="form.commodity_type" placeholder="请选择类型">
          <el-option
            v-for="item in commodityTypeList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="支付类型" prop="pay_type">
        <el-select
          v-model="form.pay_type"
          multiple
          placeholder="请选择支付类型(可多选)"
        >
          <el-option
            v-for="item in payTypeList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="规格码" prop="commodity_code">
        <el-input
          v-model.trim="form.commodity_code"
          placeholder="请输入规格码"
        />
      </el-form-item>
      <el-form-item label="是否优惠" prop="is_discounts">
        <el-select v-model="form.is_discounts" placeholder="请选择是否优惠">
          <el-option
            v-for="item in isDiscountsList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="优惠价格" prop="discount_price">
        <el-input
          v-model.trim="form.discount_price"
          placeholder="请输入优惠价格"
        />
      </el-form-item>
      <el-form-item label="虚拟金额" prop="virtual_price">
        <el-input
          v-model.trim="form.virtual_price"
          placeholder="请输入虚拟金额"
        />
      </el-form-item>
      <el-form-item label="价格" prop="price">
        <el-input v-model.trim="form.price" placeholder="请输入价格" />
      </el-form-item>
      <el-form-item label="货币" prop="currency">
        <el-select
          v-model="form.currency"
          placeholder="请选择货币"
          @change="currencyChange"
        >
          <el-option
            v-for="item in currencyList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="货币符号" prop="symbol">
        <el-input v-model.trim="form.symbol" disabled />
      </el-form-item>
      <el-form-item label="是否隐藏" prop="is_show">
        <el-select v-model="form.is_show" placeholder="请选择是否隐藏">
          <el-option
            v-for="item in isShowList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否支持AI" prop="is_ai">
        <el-select
          v-model="form.is_ai"
          clearable
          placeholder="请选择是否支持AI"
        >
          <el-option
            v-for="item in isAiList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="像素等级" prop="pixel_level">
        <el-select
          v-model="form.pixel_level"
          clearable
          placeholder="请选择像素等级"
        >
          <el-option
            v-for="item in pixelLevelList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="苹果内购商品id" prop="product_id">
        <el-input
          v-model.trim="form.product_id"
          placeholder="请输入苹果内购商品id"
        />
      </el-form-item>
      <el-form-item label="内购订阅组" prop="subscription_group">
        <el-input
          v-model.trim="form.subscription_group"
          placeholder="请输入内购订阅组"
        />
      </el-form-item>
      <el-form-item label="内购订阅组id" prop="subscription_group_id">
        <el-input
          v-model.trim="form.subscription_group_id"
          placeholder="请输入内购订阅组id"
        />
      </el-form-item>
      <el-form-item label="订阅类型" prop="package_type">
        <el-select
          v-model="form.package_type"
          clearable
          placeholder="请选择订阅类型"
        >
          <el-option
            v-for="item in packageTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save()">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { addOrEditStoreMeal } from '@/api/packageInformation'
  import { getVodBucketList } from '@/api/cloudStorageBucket'

  export default {
    name: 'UserInformation',
    data() {
      return {
        queryBucketForm: { isSelect: 'true' },
        bucketList: [],
        expireList: [
          {
            value: 1,
            label: '1',
          },
          {
            value: 2,
            label: '2',
          },
          {
            value: 3,
            label: '3',
          },
          {
            value: 4,
            label: '4',
          },
          {
            value: 5,
            label: '5',
          },
          {
            value: 6,
            label: '6',
          },
          {
            value: 7,
            label: '7',
          },
          {
            value: 8,
            label: '8',
          },
          {
            value: 9,
            label: '9',
          },
          {
            value: 10,
            label: '10',
          },
          {
            value: 11,
            label: '11',
          },
          {
            value: 12,
            label: '12',
          },
        ],
        isFreeList: [
          {
            value: 0,
            label: '否',
          },
          {
            value: 1,
            label: '是',
          },
        ],
        regionIdList: [
          {
            value: 1,
            label: '1(中国)',
          },
          {
            value: 2,
            label: '2(亚洲)',
          },
          {
            value: 3,
            label: '3(美洲)',
          },
          {
            value: 4,
            label: '4(欧洲)',
          },
        ],
        commodityTypeList: [
          {
            value: 0,
            label: '事件型',
          },
          {
            value: 1,
            label: '连续型',
          },
        ],
        payTypeList: [
          {
            value: 1,
            label: 'PayPal',
          },
          {
            value: 2,
            label: '支付宝',
          },
          {
            value: 3,
            label: '微信支付',
          },
          {
            value: 4,
            label: '苹果支付',
          },
          {
            value: 5,
            label: '苹果内购',
          },
          {
            value: 10,
            label: '免费体验',
          },
          {
            value: 11,
            label: '激活码',
          },
        ],
        isDiscountsList: [
          {
            value: 0,
            label: '否',
          },
          {
            value: 1,
            label: '是',
          },
        ],
        currencyList: [
          {
            value: 'USD',
            label: 'USD',
          },
          {
            value: 'CNY',
            label: 'CNY',
          },
        ],
        symbolList: [
          {
            value: '$',
            label: '$',
          },
          {
            value: '¥',
            label: '¥',
          },
        ],
        isShowList: [
          {
            value: 0,
            label: '否',
          },
          {
            value: 1,
            label: '是',
          },
        ],
        isAiList: [
          {
            value: 0,
            label: '不支持',
          },
          {
            value: 1,
            label: '支持',
          },
        ],
        pixelLevelList: [
          {
            value: 0,
            label: '低于4k像素',
          },
          {
            value: 1,
            label: '大于等于4k像素',
          },
        ],
        packageTypeList: [
          {
            value: 0,
            label: '非订阅',
          },
          {
            value: 1,
            label: '订阅',
          },
        ],
        form: {
          role: '',
          isEdit: '',
        },
        rules: {
          bucket: [
            { required: true, trigger: 'blur', message: '请选择存储桶' },
          ],
          day: [{ required: true, trigger: 'blur', message: '请输入天数' }],
          expire: [
            { required: true, trigger: 'blur', message: '请选择有效期' },
          ],
          pay_type: [
            {
              required: true,
              trigger: 'blur',
              message: '请选择支付类型',
            },
          ],
          price: [{ required: true, trigger: 'blur', message: '请输入价格' }],
          currency: [
            { required: true, trigger: 'blur', message: '请选择货币' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {
      this.fetchVodBucketData()
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加套餐'
        } else {
          this.title = '编辑套餐'
          this.form = Object.assign({}, row)
          this.form.isEdit = true
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            console.log('pay_type:' + this.form.pay_type)
            console.log(typeof this.form.pay_type)
            if (null != this.form.pay_type && '' != this.form.pay_type) {
              // 将对象转为字符串
              this.form.pay_type = JSON.stringify(this.form.pay_type)
            }
            const { msg } = await addOrEditStoreMeal(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      async fetchVodBucketData() {
        const {
          data: { list },
        } = await getVodBucketList(this.queryBucketForm)
        this.bucketList = list
      },
      currencyChange(currency) {
        if (currency === 'USD') {
          this.form.symbol = '$'
        } else if (currency === 'CNY') {
          this.form.symbol = '¥'
        }
      },
    },
  }
</script>
