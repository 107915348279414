<template>
  <div class="super-password-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="15">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="请输入设备uid"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.status"
              clearable
              placeholder="请选择状态"
            >
              <el-option key="0" label="未通过" value="0" />
              <el-option key="1" label="通过" value="1" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="设备uid"
        prop="uid"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="用户名"
        prop="userID__username"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="订单ID"
        prop="orderID"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="语言"
        prop="lang"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="需求描述"
        prop="describe"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="购买渠道描述"
        prop="purchase_channel"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="状态"
        prop="status"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="温馨提示"
        prop="hint"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="购买时间"
        prop="buyTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="添加时间"
        prop="addTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="250"
      >
        <template #default="{ row }">
          <el-button type="primary" @click="confirmPass(row)">通过</el-button>
          <el-button type="success" @click="handleReason(row)">
            取消通过
          </el-button>
          <el-button type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <reason ref="reason" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getList, checkPass, deleteInformation } from '@/api/superPassword'
  import Reason from './components/superPassword'

  export default {
    name: 'SuperPassword',
    components: { Reason },
    data() {
      return {
        dialogFormVisible: false,
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleReason(row) {
        if (row.userID) {
          this.$refs['reason'].showReason(row)
        } else {
          this.$refs['reason'].showReason()
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      statusFormat(row) {
        if (row.status === 0) {
          return '未通过'
        } else if (row.status === 1) {
          return '通过'
        }
      },
      timeFormat(row, column) {
        let data = row[column.property]
        if (data === 0) {
          return 0
        } else {
          let date = new Date(data * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds()
          )
        }
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, count },
        } = await getList(this.queryForm)
        this.list = list
        this.total = count
        this.listLoading = false
      },
      confirmPass(row) {
        this.$baseConfirm('您确定通过吗？', null, async () => {
          const { reason } = await checkPass({
            userID: row.userID,
            uid: row.uid,
            status: 1,
            ID: row.id,
            lang: row.lang,
          })
          this.$baseMessage(reason, 'success', 'vab-hey-message-success')
          await this.fetchData()
        })
      },
      handleDelete(row) {
        this.$baseConfirm('您确定删除吗？', null, async () => {
          const { reason } = await deleteInformation({
            id: row.id,
          })
          this.$baseMessage(reason, 'success', 'vab-hey-message-success')
          await this.fetchData()
        })
      },
    },
  }
</script>
