<template>
  <div class="unicomStat-container">
    <el-row :gutter="10">
      <el-col>
        <roughTable />
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import roughTable from './components/roughTable'

  export default {
    name: 'WorkDataStat',
    components: {
      roughTable,
    },
  }
</script>
