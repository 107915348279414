import request from '@/utils/request'

export function doAddOrEdit(data) {
  return request({
    url: '/surveys/save',
    method: 'post',
    data,
  })
}

export function doEdit(data) {
  return request({
    url: '/surveys/edit',
    method: 'post',
    data,
  })
}

export function getList(params) {
  return request({
    url: '/surveys/list',
    method: 'get',
    params,
  })
}

export function doDelete(params) {
  return request({
    url: '/surveys/list',
    method: 'get',
    params,
  })
}

export function resetPassword(params) {
  return request({
    url: '/surveys/list',
    method: 'get',
    params,
  })
}

export function getTitleList(params) {
  return request({
    url: '/surveys/title/list',
    method: 'get',
    params,
  })
}

export function titleSave(data) {
  return request({
    url: '/surveys/title/save',
    method: 'post',
    data,
  })
}

export function getCloudVodSurveyPage(params) {
  return request({
    url: '/surveys/cloud/vod/answer/page',
    method: 'get',
    params,
  })
}

export function getSurveysUserLogPage(params) {
  return request({
    url: '/surveys/cloud/storage/log/page',
    method: 'get',
    params,
  })
}
