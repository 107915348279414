<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button
          v-if="$perms('user_userinfo_insert')"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <!-- 选择查询的套餐ID -->
          <el-form-item label="套餐ID" prop="storeMealID">
            <el-select
              v-model="queryForm.storeMealID"
              clearable
              placeholder="请选择查询的套餐ID"
            >
              <el-option
                v-for="item in storeMealList"
                :key="item.id"
                :label="item.id"
                :value="item.id"
              >
                <span style="float: left">{{ item.id }}</span>
                <span style="float: right; font-size: 12px; color: #8492a6">
                  {{ item.bucket__bucket }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="套餐ID"
        prop="storeMealID"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="套餐语言ID"
        prop="langID"
        show-overflow-tooltip
        width="100"
      />
      <el-table-column
        align="center"
        label="套餐语言"
        prop="lang"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="套餐标题"
        prop="title"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="套餐描述"
        prop="content"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="优惠描述"
        prop="discountContent"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="新套餐标题"
        prop="new_title"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="150"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('user_userinfo_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$perms('user_userinfo_del')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    deleteStoreMealLanguage,
    getStoreMealLanguage,
  } from '@/api/packageLanguage'
  import Edit from './components/PackageLanguage'
  import { getStoreMealList } from '@/api/packageInformation'

  export default {
    name: 'PackageLanguage',
    components: { Edit },
    data() {
      return {
        list: [],
        storeMealList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        selectValue: '',
        selectList: [
          {
            value: '套餐ID',
            label: '套餐ID',
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
      this.fetchStoreMealData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.storeMealID) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.storeMealID) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteStoreMealLanguage({
              storeMealID: row.storeMealID,
              langID: row.langID,
            })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await deleteStoreMealLanguage({ ids })
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getStoreMealLanguage(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      async fetchStoreMealData() {
        this.queryForm.isSelect = 'true'
        const {
          data: { list },
        } = await getStoreMealList(this.queryForm)
        this.storeMealList = list
      },
    },
  }
</script>
