<template>
  <div class="user-management-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>云存题目</span>
      </div>
      <div v-for="item in answers" :key="item" class="text item">
        {{ item.title }}
      </div>
    </el-card>
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <span>云存答案</span>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="15">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userName"
              clearable
              placeholder="请输入用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.countryName"
              clearable
              placeholder="请输入国家"
            />
          </el-form-item>
          <el-form-item>
            评分
            <el-input-number
              v-model="queryForm.num"
              :max="10"
              :min="0"
              placeholder="请输入评分"
              :step="1"
            />
            <el-select
              v-model="queryForm.optVal"
              clearable
              placeholder="请选择条件"
              style="width: 100px"
            >
              <el-option
                v-for="item in options"
                :key="item.optVal"
                :label="item.label"
                :value="item.optVal"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <div class="block">
              <span>提交时间:</span>
              <el-date-picker
                v-model="queryForm.timeRange"
                align="right"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="timestamp"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-if="false"
        align="center"
        label="id"
        prop="id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="用户名"
        prop="uName"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="昵称"
        prop="nickName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="国家"
        prop="country"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="评分"
        prop="answer1"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="题2"
        prop="answer2"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="题3"
        prop="answer3"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="题4"
        prop="answer4"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="题5"
        prop="answer5"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="题6"
        prop="answer6"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createdTime"
        show-overflow-tooltip
        sortable
      />

      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getTitleList, getCloudVodSurveyPage } from '@/api/surveys'

  export default {
    name: 'SurveysInformation',
    data() {
      return {
        radio: '1',
        dialogFormVisible: false,
        formLabelWidth: '120px',
        sendForm: { username: '', userID: '', msg: '' },
        list: [],
        answers: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          surveyId: 1,
          pageNo: 1,
          pageSize: 10,
          userName: '',
          nickName: '',
          countryName: '',
          num: 0,
          optVal: '',
        },
        options: [
          {
            optVal: '1',
            label: '大于',
          },
          {
            optVal: '2',
            label: '小于',
          },
          {
            optVal: '3',
            label: '等于',
          },
          {
            optVal: '4',
            label: '大于等于',
          },
          {
            optVal: '5',
            label: '小于等于',
          },
        ],
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        console.log(row)
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleTitleEdit(row) {
        console.log(row)
        if (row.id) {
          this.$refs['titles'].showEdit(row)
        } else {
          this.$refs['titles'].showEdit()
        }
      },
      handleSendMsg(row) {
        if (row.userID) {
          this.sendForm.username = row.username
          this.sendForm.userID = row.userID
          this.dialogFormVisible = true
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        if (
          this.queryForm.optVal !== null &&
          this.queryForm.optVal.length !== 0
        ) {
          this.queryForm.optVal = String(this.queryForm.optVal)
        } else {
          this.queryForm.optVal = null
        }
        this.listLoading = true
        const {
          data: { list, total },
        } = await getCloudVodSurveyPage(this.queryForm)
        this.list = list
        this.total = total

        const {
          data: { answers },
        } = await getTitleList(this.queryForm)
        this.answers = answers

        this.listLoading = false
      },
    },
  }
</script>
<style>
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: '';
  }

  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 800px;
  }
</style>
