var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_c("span", [_vm._v(_vm._s(_vm.title))])]
              },
              proxy: true
            }
          ])
        },
        [
          _c("span", { staticClass: "demonstration" }),
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { justify: "space-between", type: "flex" }
            },
            [
              _c(
                "el-tag",
                { staticClass: "card-header-tag", attrs: { type: "success" } },
                [_vm._v("新增用户")]
              ),
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.postDay } },
                    [_vm._v("天")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.postWeek } },
                    [_vm._v("周")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.postMonth }
                    },
                    [_vm._v("月")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.postQuarter }
                    },
                    [_vm._v("季度")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.postYear } },
                    [_vm._v("年")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("vab-chart", {
            staticClass: "trend-echart",
            attrs: {
              "init-options": _vm.initOptions,
              lazy: "",
              option: _vm.option,
              theme: "vab-echarts-theme"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }