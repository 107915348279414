<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-table
      ref="table"
      v-loading="loading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="日期"
        prop="date"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="流量"
        prop="flow"
        show-overflow-tooltip
      />
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.line"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <template #footer>
      <el-button @click="close">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { getMonthlyFlowInfo } from '@/api/unicomCostData'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Details',
    data() {
      return {
        list: [],
        title: '明细',
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        dialogFormVisible: false,
        loading: true,
        queryForm: {
          iccid: '',
          page: 1,
          line: 10,
        },
      }
    },
    computed: {
      ...mapGetters({
        year: 'dataStat/unicomYear',
      }),
      ...mapGetters({
        month: 'dataStat/unicomMonth',
      }),
    },
    watch: {
      year(newVal) {
        this.queryForm.year = newVal
      },
      month(newVal) {
        this.queryForm.month = newVal
      },
    },
    created() {},
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.line = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      showDetail(row) {
        let list = Object.assign({}, row)
        this.queryForm.iccid = list.iccid
        this.dialogFormVisible = true
        this.fetchData()
      },
      close() {
        this.dialogFormVisible = false
      },
      async fetchData() {
        this.loading = true
        const {
          result: { list, count },
        } = await getMonthlyFlowInfo(this.queryForm)
        let newArr = list.map((item) => ({
          date: Object.keys(item).toString(),
          flow: Object.values(item).toString(),
        }))
        this.list = newArr
        this.total = count
        this.loading = false
      },
    },
  }
</script>
