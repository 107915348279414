<template>
  <div class="custom-message-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="50">
        <el-form
          :inline="true"
          :model="queryForm"
          @submit.native.prevent="fetchData"
        >
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uids"
              clearable
              placeholder="请输入逗号分隔的uid"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userID"
              clearable
              placeholder="请输入用户ID"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="fetchData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        align="center"
        label="设备uid"
        prop="uid"
        show-overflow-tooltip
      />

      <el-table-column
        align="center"
        label="推送图片"
        prop="img"
        show-overflow-tooltip
        width="110px"
      >
        <template slot-scope="scope">
          <el-image :src="scope.row.img" />
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="已读状态"
        prop="status"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="answerStatusFormat"
        label="接听状态"
        prop="answerStatus"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="报警信息"
        prop="alarm"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="存储桶位置"
        prop="storageLocation"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备昵称"
        prop="devNickName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="通道"
        prop="channel"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="事件类型"
        prop="eventType"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="上报时间"
        prop="eventTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="添加时间"
        prop="addTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备类型"
        prop="uidType"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="AI类型图片边框位置信息"
        prop="borderCoords"
        show-overflow-tooltip
      >
        <template #default="scope">
          <pre>{{ JSON.stringify(scope.row.borderCoords) }}</pre>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="ai事件类型"
        prop="aiEventTypeList"
        show-overflow-tooltip
      />
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { queryInfoList } from '@/api/customMessage'
  import { formatDate } from '@/utils/formatDate'

  export default {
    name: 'CustomMessage',
    data() {
      return {
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        list: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
      }
    },

    methods: {
      handleCurrentChange(pageNumber) {
        this.queryForm.pageNo = pageNumber
        this.fetchData()
      },
      statusFormat(row) {
        if (row.status === false) {
          return '未读'
        } else if (row.status === true) {
          return '已读'
        }
      },
      answerStatusFormat(row) {
        if (row.answerStatus === false) {
          return '未接听'
        } else if (row.answerStatus === true) {
          return '已接听'
        }
      },
      timeFormat(row, column) {
        const data = row[column.property]
        return formatDate(new Date(data * 1000), 'yyyy-MM-dd hh:mm:ss')
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await queryInfoList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },

      handleSizeChange(pageSize) {
        this.queryForm.pageSize = pageSize
        this.fetchData()
      },
    },
  }
</script>
