import request from '@/utils/request'

export function getDeviceLogList(params) {
  return request({
    url: '/logManagement/getDeviceLogList',
    method: 'get',
    params,
  })
}

export function downloadDeviceLog(params) {
  return request({
    url: '/logManagement/downloadDeviceLog',
    method: 'get',
    params,
  })
}
