var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: _vm.dialogWidth
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showEmptySpan,
                    expression: "showEmptySpan"
                  }
                ]
              }),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showPrevButton,
                      expression: "showPrevButton"
                    }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.prev }
                },
                [_vm._v(" 上一步 ")]
              ),
              _vm.title === "添加"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showNextButton,
                          expression: "showNextButton"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.next }
                    },
                    [_vm._v(" 下一步 ")]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSaveButton,
                      expression: "showSaveButton"
                    }
                  ]
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("确 定")]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCloseButton,
                      expression: "showCloseButton"
                    }
                  ]
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.close } },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isSetting
        ? _c(
            "div",
            [
              _vm.title === "添加"
                ? _c(
                    "el-steps",
                    {
                      staticStyle: { "padding-bottom": "20px" },
                      attrs: {
                        active: _vm.active,
                        "align-center": "",
                        "finish-status": "success"
                      }
                    },
                    [
                      _c("el-step", { attrs: { title: "基础信息" } }),
                      _c("el-step", { attrs: { title: "设备名称" } }),
                      _c("el-step", { attrs: { title: "选择服务器" } })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active == 0,
                      expression: "active == 0"
                    }
                  ],
                  ref: "form",
                  attrs: {
                    "label-width": "88px",
                    model: _vm.form,
                    rules: _vm.rules
                  }
                },
                [
                  !_vm.form.isEdit
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "包名", prop: "app_bundle_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "allow-create": "",
                                filterable: "",
                                multiple: "",
                                placeholder: "请选择或输入包名"
                              },
                              model: {
                                value: _vm.form.app_bundle_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "app_bundle_id", $$v)
                                },
                                expression: "form.app_bundle_id"
                              }
                            },
                            _vm._l(_vm.appBundle, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备类型", prop: "model" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择设备类型" },
                          model: {
                            value: _vm.form.model,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "model", $$v)
                            },
                            expression: "form.model"
                          }
                        },
                        _vm._l(_vm.modelList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备型号", prop: "type" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.type,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "type",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.type"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.title === "编辑"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "语言", prop: "lang" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择语言" },
                              model: {
                                value: _vm.form.lang,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "lang", $$v)
                                },
                                expression: "form.lang"
                              }
                            },
                            _vm._l(_vm.langList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.title === "编辑"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "设备名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版本号", prop: "version_number" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.version_number,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "version_number",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.version_number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.sort,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "sort",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.sort"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "config", prop: "config" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.app_device_type__config,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "app_device_type__config",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.app_device_type__config"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "upload",
                          attrs: { label: "图标", prop: "fileName" }
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-file",
                              attrs: {
                                action: "",
                                "auto-upload": false,
                                "file-list": _vm.fileList,
                                "http-request": _vm.upLoadIcon,
                                "list-type": "picture-card",
                                "on-change": _vm.onChange,
                                "on-remove": _vm.onRemove,
                                "show-file-list": false
                              }
                            },
                            [
                              _vm.form.icon
                                ? _c("img", { attrs: { src: _vm.form.icon } })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon"
                                  })
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "upload",
                          attrs: { label: "图标V2", prop: "fileNameV2" }
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "uploadV2",
                              staticClass: "upload-file",
                              attrs: {
                                action: "",
                                "auto-upload": false,
                                "file-list": _vm.fileListV2,
                                "http-request": _vm.upLoadIconV2,
                                "list-type": "picture-card",
                                "on-change": _vm.onChangeV2,
                                "on-remove": _vm.onRemoveV2,
                                "show-file-list": false
                              }
                            },
                            [
                              _vm.form.iconV2
                                ? _c("img", { attrs: { src: _vm.form.iconV2 } })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon"
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.active === 1
                ? _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active === 1,
                          expression: "active === 1"
                        }
                      ],
                      ref: "form_vice",
                      attrs: {
                        "label-width": "150px",
                        model: _vm.form,
                        rules: _vm.rules_vice
                      }
                    },
                    _vm._l(_vm.langList, function(language, index) {
                      return _c(
                        "el-form-item",
                        {
                          key: index,
                          attrs: {
                            label: "设备名称(" + language.label + ")",
                            prop: "name_" + language.value
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.mulName[language.value],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.mulName,
                                  language.value,
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.mulName[language.value]"
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.active === 2
                ? _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active === 2,
                          expression: "active === 2"
                        }
                      ],
                      ref: "form_server",
                      staticClass: "server-select",
                      attrs: { model: _vm.form, rules: _vm.rules_server }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "region" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.form.region,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "region", $$v)
                                },
                                expression: "form.region"
                              }
                            },
                            _vm._l(_vm.serverList, function(item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.value,
                                  attrs: { label: item.value }
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 20, maxRows: 40 },
                  placeholder: "请输入内容",
                  type: "textarea"
                },
                model: {
                  value: _vm.textarea,
                  callback: function($$v) {
                    _vm.textarea = $$v
                  },
                  expression: "textarea"
                }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }