var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { "label-width": "200px", model: _vm.form } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "请输入UID" } },
        [
          _c("el-input", {
            on: { change: _vm.handUID },
            model: {
              value: _vm.form.uid,
              callback: function($$v) {
                _vm.$set(_vm.form, "uid", $$v)
              },
              expression: "form.uid"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "UID生成base64" } },
        [
          _c("el-input", {
            attrs: { disabled: "true" },
            model: {
              value: _vm.form.uid_code,
              callback: function($$v) {
                _vm.$set(_vm.form, "uid_code", $$v)
              },
              expression: "form.uid_code"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "当前时间戳" } },
        [
          _c("el-input", {
            attrs: { disabled: "true" },
            model: {
              value: _vm.form.time_stamp,
              callback: function($$v) {
                _vm.$set(_vm.form, "time_stamp", $$v)
              },
              expression: "form.time_stamp"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "时间戳生成base64" } },
        [
          _c("el-input", {
            attrs: { disabled: "true" },
            model: {
              value: _vm.form.token,
              callback: function($$v) {
                _vm.$set(_vm.form, "token", $$v)
              },
              expression: "form.token"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "设备版本" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.device_version,
              callback: function($$v) {
                _vm.$set(_vm.form, "device_version", $$v)
              },
              expression: "form.device_version"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "请输入语言" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.form.language,
                callback: function($$v) {
                  _vm.$set(_vm.form, "language", $$v)
                },
                expression: "form.language"
              }
            },
            _vm._l(_vm.options1, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("创建IOT证书")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }