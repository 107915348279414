<template>
  <el-dialog
    :append-to-body="true"
    title="编辑套餐有效期"
    :visible.sync="dialogVisible"
    width="400px"
  >
    <el-date-picker
      v-model="form.expireTime"
      align="center"
      placeholder="选择日期"
      type="date"
      value-format="timestamp"
    />
    <template #footer>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleConfirm">
        确 定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { updateFlowComboById } from '@/api/cardUserInformation'

  export default {
    name: 'EditExpireTime',
    data() {
      return {
        dialogVisible: false,
        loading: false,
        form: {
          id: null,
          expireTime: null,
        },
        fileName: '',
      }
    },
    methods: {
      showComponent(val) {
        this.dialogVisible = true
        this.form.expireTime = new Date(val.expireTime).getTime()
        this.form.id = val.id
      },
      async handleConfirm() {
        this.loading = true
        this.formData = new FormData()
        this.formData.append('expireTime', this.form.expireTime / 1000 + 86399)
        this.formData.append('id', this.form.id)
        const { reason } = await updateFlowComboById(this.formData)
        this.$baseMessage(reason, 'success', 'vab-hey-message-success')
        this.$emit('fetch-data')
        this.loading = false
        this.dialogVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      display: flex;
      justify-content: center;
    }
  }
</style>
