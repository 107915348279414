import request from '@/utils/request'

export function getAppRecordList(params) {
  return request({
    url: '/versionManagement/getAppRecordList',
    method: 'get',
    params,
  })
}

export function getAppBundleIdList(params) {
  return request({
    url: '/versionManagement/getAppBundleIdList',
    method: 'get',
    params,
  })
}

export function addOrEditAppRecord(data) {
  return request({
    url: '/versionManagement/addOrEditAppRecord',
    method: 'post',
    data,
  })
}

export function deleteAppRecord(data) {
  return request({
    url: '/versionManagement/deleteAppRecord',
    method: 'post',
    data,
  })
}
