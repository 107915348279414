<template>
  <el-col>
    <el-card class="order-card1" shadow="hover">
      <vab-chart
        v-loading="loading"
        :init-options="initOptions"
        lazy
        :option="option"
        theme="vab-echarts-theme"
      />
    </el-card>
  </el-col>
</template>

<script>
  import VabChart from '@/extra/VabChart'

  export default {
    name: 'VabChartPie',
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: true,
        initOptions: {
          renderer: 'svg',
        },
        option: {
          grid: {
            top: 20,
            right: 20,
            bottom: 40,
            left: 40,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)',
          },
          legend: {
            bottom: 'bottom',
            data: [],
            type: 'scroll',
          },
          series: [
            {
              name: '新增趋势',
              type: 'pie',
              radius: ['40%', '70%'],
              center: ['50%', '42%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '14',
                },
              },
              labelLine: {
                show: false,
              },
              data: [],
            },
          ],
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.$root.eventVueDevice.$on('data-E', (message) => {
          let getData = []
          for (var i in message) {
            let obj = new Object()
            obj.name = message[i].type
            obj.value = message[i].count
            obj.rate = message[i].rate
            getData[i] = obj
          }
          this.option.series[0].data = getData
          this.option.legend.data = getData
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .order {
    &-card1 {
      height: 334px;
    }
  }
</style>
