<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="520px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="编号" prop="no">
        <el-input
          v-model.trim="form.no"
          clearable
          :disabled="inpNo"
          width="15px"
        />
      </el-form-item>
      <el-form-item label="调查类型" prop="userType">
        <el-select v-model="form.userType" clearable placeholder="请选择类型">
          <el-option
            v-for="item in types"
            :key="item.value"
            :label="item.typeName"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否显示" prop="isShow">
        <el-switch
          v-model="form.isShow"
          active-color="#13ce66"
          inactive-color="#ff4949"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="form.dateTime"
          end-placeholder="结束日期"
          range-separator="至"
          start-placeholder="开始日期"
          type="datetimerange"
          @input="dateRangeChange()"
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { doEdit, doAddOrEdit } from '@/api/surveys'
  import { getList } from '@/api/roleManagement'

  export default {
    name: 'UserInformation',
    data() {
      return {
        queryForm: { isSelect: 'true' },
        roleList: [],
        types: [
          {
            typeName: '云存储用户',
            value: 1,
          },
          {
            typeName: 'AI用户',
            value: 2,
          },
          {
            typeName: '所有APP用户',
            value: 3,
          },
        ],
        inpNo: false,
        form: {
          userType: '',
          isShow: false,
          dateTime: [new Date(), new Date()],
        },
        rules: {
          no: [{ required: true, trigger: 'blur', message: '请输入编号' }],
        },
        title: '',
        dialogFormVisible: false,
        value2: '',
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.inpNo = false
        } else {
          this.title = '编辑'
          this.inpNo = true

          this.form = Object.assign({}, row)
          if (row.isShow === 1) {
            this.form.isShow = true
          }
          this.form.isEdit = true
          this.form.dateTime = [row.startTime, row.endTime]
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      dateRangeChange() {
        let _this = this
        _this.$nextTick(() => {
          _this.$forceUpdate()
        })
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.dateTime[0] =
              new Date(this.form.dateTime[0]).getTime() / 1000
            this.form.dateTime[1] =
              new Date(this.form.dateTime[1]).getTime() / 1000
            if (this.inpNo) {
              const { msg } = await doEdit(this.form)
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            } else {
              const { msg } = await doAddOrEdit(this.form)
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            }

            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      async fetchData() {
        const {
          data: { list },
        } = await getList(this.queryForm)
        this.roleList = list
      },
    },
  }
</script>
