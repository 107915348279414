<template>
  <div class="custom-customer-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="4">
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          新增
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        v-for="item in tableColumns"
        :key="item.prop"
        align="center"
        :label="item.label"
        :prop="item.prop"
      >
        <template #default="{ row }">
          <span v-if="item.prop === 'createdTime'">
            {{ row[item.prop] | timeFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.line"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <Add ref="add" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getList } from '@/api/customCustomer'
  import { formatDate } from '@/utils/formatDate'
  import Add from './components/addComponent'

  export default {
    name: 'ActivityManage',
    filters: {
      timeFilter(val) {
        if (val === 0) {
          return 'N/A'
        }
        let date = val * 1000
        return formatDate(new Date(date), 'yyyy-MM-dd hh:mm:ss')
      },
    },
    components: {
      Add,
    },
    data() {
      return {
        tableColumns: [
          {
            label: '生产编号',
            prop: 'orderNumber',
          },
          {
            label: '用户名',
            prop: 'name',
          },
          {
            label: '用户邮箱',
            prop: 'email',
          },
          {
            label: '国家',
            prop: 'countryName',
          },
          {
            label: '生产数量',
            prop: 'quantity',
          },
          {
            label: '创建时间',
            prop: 'createdTime',
          },
        ],
        list: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      handleAdd() {
        this.$refs['add'].showComponent()
      },
    },
  }
</script>
