<template>
  <div class="trend">
    <el-card class="trend-card" shadow="hover">
      <template #header>
        <span>
          <vab-icon icon="line-chart-fill" />
          AI服务开通数量
        </span>
      </template>

      <el-button icon="el-icon-arrow-left" type="primary" @click="lastYear">
        上一年
      </el-button>
      <el-button disabled type="success">{{ queryForm.year }}</el-button>
      <el-button type="primary" @click="nextYear">
        下一年
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>

      <vab-chart
        class="trend-echart"
        :init-options="initOptions"
        lazy
        :option="option"
        theme="vab-echarts-theme"
      />
    </el-card>
  </div>
</template>

<script>
  import VabChart from '@/extra/VabChart'
  import { getAiDataList } from '@/api/aiData'

  export default {
    components: {
      VabChart,
    },
    data() {
      return {
        queryForm: { year: 2022 },
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'z-index:1',
          },
          grid: {
            top: '4%',
            left: '2%',
            right: '2%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
              boundaryGap: false,
            },
          ],
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: [],
          list: [],
        },
      }
    },
    created() {
      this.fetchYear()
      this.fetchData()
    },
    methods: {
      async fetchData() {
        const {
          data: { list },
        } = await getAiDataList(this.queryForm)
        let cloudDataList = []
        let property = {
          symbol: 'circle',
          smooth: true,
          yAxisIndex: 0,
          showSymbol: false,
          lineStyle: {},
          areaStyle: {
            opacity: 0.8,
          },
        }
        list.forEach(function (item) {
          item = Object.assign(item, property) // 拼接数据和属性
          // console.log(item)
          cloudDataList.push(item)
        })
        console.log('cloudDataList: ' + cloudDataList)
        this.option.series = cloudDataList
      },
      fetchYear() {
        let nowDate = new Date()
        this.queryForm.year = nowDate.getFullYear()
        console.log('year:' + this.queryForm.year)
      },
      lastYear() {
        this.queryForm.year--
        this.fetchData()
      },
      nextYear() {
        this.queryForm.year++
        this.fetchData()
      },
    },
  }
</script>
