<template>
  <div class="daily-reconciliation-container">
    <vab-query-form>
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item>
          <el-input
            v-model.trim="queryForm.username"
            clearable
            placeholder="请输入用户名"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model.trim="queryForm.order_id"
            clearable
            placeholder="订单ID"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model.trim="queryForm.trade_no"
            clearable
            placeholder="请输入第三方订单号"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.pay_type"
            clearable
            placeholder="请选择支付方式"
          >
            <el-option
              v-for="item in payTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.status"
            clearable
            placeholder="请选择处理结果"
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="queryForm.time"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="datetimerange"
            value-format="timestamp"
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="fetchData">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="id"
        prop="id"
        show-overflow-tooltip
        width="100"
      />
      <el-table-column
        align="center"
        label="用户名"
        prop="username"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="商品名"
        prop="meal_name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="订单ID"
        prop="order_id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="第三方订单号"
        prop="trade_no"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="paypal协议id"
        prop="agreement_id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="payTypeFormat"
        label="支付方式"
        prop="pay_type"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="价格"
        prop="price"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="支付成功时间"
        prop="pay_time"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="处理结果"
        prop="status"
        show-overflow-tooltip
        width="100"
      />
      <el-table-column align="center" label="操作" width="150">
        <template #default="{ row }">
          <el-button
            :disabled="row.status === 1"
            type="primary"
            @click="handleOrder(row)"
          >
            处理订单
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getList, updateOrder } from '@/api/dailyReconciliation'
  export default {
    name: 'DailyReconciliation',
    data() {
      return {
        comboIdList: [],
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        selectValue: '',
        queryForm: {
          page_no: 1,
          page_size: 10,
        },
        payTypeList: [
          { value: 0, label: 'PayPal自动续费' },
          { value: 1, label: 'PayPal普通支付' },
          { value: 2, label: '支付宝' },
          { value: 3, label: '微信' },
        ],
        statusList: [
          { value: 0, label: '未处理' },
          { value: 1, label: '已处理' },
        ],
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.page_size = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page_no = val
        this.fetchData()
      },
      typeFormatter(row, column, list) {
        let data = row[column.property]
        list.forEach((item) => {
          if (data === item.value) {
            data = item.label
          }
        })
        return data
      },
      statusFormat(row, column) {
        return this.typeFormatter(row, column, this.statusList)
      },
      payTypeFormat(row, column) {
        return this.typeFormatter(row, column, this.payTypeList)
      },
      timeFormat(row, column) {
        const data = row[column.property]
        if (data === 0) {
          return 'N/A'
        } else {
          const date = new Date(data * 1000)
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds()
          )
        }
      },
      async fetchData() {
        this.listLoading = true
        let obj = new Object()
        obj = Object.assign({}, this.queryForm)
        if (obj.time) {
          this.$set(obj, 'start_time', this.queryForm.time[0] / 1000)
          this.$set(obj, 'end_time', this.queryForm.time[1] / 1000)
          this.$delete(obj, 'time')
        }
        const {
          data: { list, total },
        } = await getList(obj)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      async handleOrder(row) {
        const id = row.id
        const { msg } = await updateOrder({ id: id })
        if (msg === 'Success') {
          this.$baseMessage('处理成功', 'success', 'vab-hey-message-success')
        } else {
          this.$baseMessage('处理失败', 'error')
        }
        await this.fetchData()
      },
    },
  }
</script>
