<template>
  <vab-chart
    v-loading="loading"
    :init-options="initOptions"
    lazy
    :option="option"
    theme="vab-echarts-theme"
  />
</template>

<script>
  import VabChart from '@/extra/VabChart'

  export default {
    components: {
      VabChart,
    },
    props: {
      data: {
        type: [Object, Array],
        default: () => {},
      },
    },
    data() {
      return {
        loading: true,
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          dataZoom: [
            {
              type: 'inside',
            },
          ],
          grid: {
            top: '5%',
            left: '2%',
            right: '4%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: {
            name: '活跃用户',
            type: 'bar',
            itemStyle: {
              color: new VabChart.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: new VabChart.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' },
                ]),
              },
            },
            data: [],
          },
        },
      }
    },
    watch: {
      data(newVal) {
        this.fetchData(newVal)
      },
    },
    methods: {
      fetchData(val) {
        console.log(val)
        if (Object.keys(val).length != 0) {
          console.log(val)
          this.option = this.$options.data().option
          this.option.xAxis.data = Object.keys(val)
          this.option.series.data = Object.values(val)
        } else {
          this.option = {
            title: {
              text: '暂无数据',
              x: 'center',
              y: 'center',
              textStyle: {
                fontFamily: 'Manteka',
                fontSize: '12',
                fontWeight: 'normal',
              },
            },
          }
        }
        this.loading = false
      },
    },
  }
</script>
