import request from '@/utils/request'

export function getActivityList(params) {
  return request({
    url: '/activityManagement/getActivityList',
    method: 'get',
    params,
  })
}

export function addOrEditActivity(data) {
  return request({
    url: 'activityManagement/addOrEditActivity',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function getActivityUser(params) {
  return request({
    url: '/activityManagement/getActivityUser',
    method: 'get',
    params,
  })
}

export function editActivityUser(data) {
  return request({
    url: 'activityManagement/editActivityUser',
    method: 'post',
    data,
  })
}
