var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { attrs: { lg: 24, md: 24, sm: 24, xl: 24, xs: 24 } },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "18px"
                      }
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _c(
                    "el-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 提供各设备的付费订单、免费套餐订单、新增首次付费和复购订单趋势变化及明细数据 "
                          )
                        ]
                      ),
                      _c("vab-icon", { attrs: { icon: "information-line" } })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("span", { staticClass: "demonstration" }),
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { justify: "space-between", type: "flex" }
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.buttonChange1 },
                  model: {
                    value: _vm.radio1,
                    callback: function($$v) {
                      _vm.radio1 = $$v
                    },
                    expression: "radio1"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("付费订单")
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v("免费套餐订单")
                  ]),
                  _c("el-radio-button", { attrs: { label: 3 } }, [
                    _vm._v("新增首次付费")
                  ]),
                  _c("el-radio-button", { attrs: { label: 4 } }, [
                    _vm._v("复购订单")
                  ]),
                  _c(
                    "el-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 复购订单指的是消费者第二次及以上购买同一产品 "
                          )
                        ]
                      ),
                      _c("vab-icon", { attrs: { icon: "information-line" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.buttonChange2 },
                  model: {
                    value: _vm.radio2,
                    callback: function($$v) {
                      _vm.radio2 = $$v
                    },
                    expression: "radio2"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("云存储")
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v("AI识别")
                  ]),
                  _c("el-radio-button", { attrs: { label: 3 } }, [
                    _vm._v("4G卡")
                  ])
                ],
                1
              ),
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.buttonChange3 },
                  model: {
                    value: _vm.radio3,
                    callback: function($$v) {
                      _vm.radio3 = $$v
                    },
                    expression: "radio3"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("天")
                  ]),
                  _c(
                    "el-radio-button",
                    { attrs: { disabled: _vm.disabledWeek, label: 2 } },
                    [_vm._v(" 周 ")]
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { disabled: _vm.disabledMonth, label: 3 } },
                    [_vm._v(" 月 ")]
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { disabled: _vm.disabledQuarter, label: 4 } },
                    [_vm._v(" 季度 ")]
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { disabled: _vm.disabledYear, label: 5 } },
                    [_vm._v(" 年 ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("vab-chart", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "trend-echart",
            staticStyle: { height: "360px" },
            attrs: {
              "init-options": _vm.initOptions,
              lazy: "",
              option: _vm.option,
              theme: "vab-echarts-theme"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }