<template>
  <div class="user-management-container">
    <!--    <el-card class="box-card">-->
    <!--      <div slot="header" class="clearfix">-->
    <!--        <span>统计</span>-->
    <!--      </div>-->
    <!--    </el-card>-->
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <span>用户问卷调查记录</span>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="15">
        <!-- <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userName"
              clearable
              placeholder="请输入用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.nickName"
              clearable
              placeholder="请输入昵称"
            />
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form> -->
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-if="false"
        align="center"
        label="id"
        prop="id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="用户名"
        prop="uName"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="昵称"
        prop="nickName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="surveysType"
        label="类型"
        prop="type"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        :formatter="freeFormat"
        label="问卷"
        prop="isFilled"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        :formatter="upgradeFormat"
        label="是否更新APP"
        prop="isUpgradeFormat"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createdTime"
        show-overflow-tooltip
        sortable
      />

      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getSurveysUserLogPage } from '@/api/surveys'

  export default {
    name: 'SurveysInformation',
    data() {
      return {
        radio: '1',
        dialogFormVisible: false,
        formLabelWidth: '120px',
        sendForm: { username: '', userID: '', msg: '' },
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          type: 1,
          pageNo: 1,
          pageSize: 10,
          userName: '',
          nickName: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      surveysType(row) {
        console.log(row.type)
        if (row.type === 1) {
          return '云存储用户'
        }
        if (row.type === 2) {
          return 'Ai用户'
        }
        if (row.type === 3) {
          return '所有APP用户'
        }
      },
      freeFormat(row) {
        if (row.isFilled === 0) {
          return '未提交'
        } else {
          return '已提交'
        }
      },
      upgradeFormat(row) {
        if (row.isUpgrade === 0) {
          return '未更新'
        } else {
          return '已更新'
        }
      },
      handleSendMsg(row) {
        if (row.userID) {
          this.sendForm.username = row.username
          this.sendForm.userID = row.userID
          this.dialogFormVisible = true
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getSurveysUserLogPage(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
    },
  }
</script>
<style>
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: '';
  }

  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 800px;
  }
</style>
