<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="存储桶名称" prop="bucket">
        <el-input v-model.trim="form.bucket" />
      </el-form-item>
      <el-form-item label="描述" prop="content">
        <el-input v-model.trim="form.content" type="content" />
      </el-form-item>
      <el-form-item label="国内外" prop="mold">
        <el-select v-model="form.mold" placeholder="请选择国内外">
          <el-option
            v-for="item in moldList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="国家" prop="area">
        <el-input v-model.trim="form.area" />
      </el-form-item>
      <el-form-item label="地域" prop="region">
        <el-input v-model.trim="form.region" />
      </el-form-item>
      <el-form-item label="存储桶节点" prop="endpoint">
        <el-input v-model.trim="form.endpoint" />
      </el-form-item>
      <el-form-item label="是否免费" prop="is_free">
        <el-select v-model="form.is_free" placeholder="请选择是否免费">
          <el-option
            v-for="item in isFreeList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="存储天数" prop="storeDay">
        <el-input v-model.trim="form.storeDay" />
      </el-form-item>
      <el-form-item label="大洲编号" prop="region_id">
        <el-select v-model="form.region_id" placeholder="请选择大洲编号">
          <el-option
            v-for="item in regionIdList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { addOrEditVodBucket } from '@/api/cloudStorageBucket'

  export default {
    name: 'UserInformation',
    data() {
      return {
        queryForm: { isSelect: 'true' },
        moldList: [
          {
            value: 0,
            label: '国内',
          },
          {
            value: 1,
            label: '国外',
          },
        ],
        isFreeList: [
          {
            value: 0,
            label: '否',
          },
          {
            value: 1,
            label: '是',
          },
        ],
        regionIdList: [
          {
            value: 1,
            label: '1(中国)',
          },
          {
            value: 2,
            label: '2(亚洲)',
          },
          {
            value: 3,
            label: '3(美洲)',
          },
          {
            value: 4,
            label: '4(欧洲)',
          },
        ],
        form: {
          role: '',
          isEdit: '',
        },
        rules: {
          content: [{ required: true, trigger: 'blur', message: '请输入描述' }],
          mold: [{ required: true, trigger: 'blur', message: '请选择国内外' }],
          area: [{ required: true, trigger: 'blur', message: '请输入国家' }],
          region: [{ required: true, trigger: 'blur', message: '请输入地域' }],
          endpoint: [
            { required: true, trigger: 'blur', message: '请输入存储桶节点' },
          ],
          is_free: [
            { required: true, trigger: 'blur', message: '请选择是否免费' },
          ],
          storeDay: [
            { required: true, trigger: 'blur', message: '请输入存储天数' },
          ],
          region_id: [
            { required: true, trigger: 'blur', message: '请选择大洲编号' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
          this.form.isEdit = true
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await addOrEditVodBucket(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      moldChange(mold) {
        alert(mold)
      },
    },
  }
</script>
