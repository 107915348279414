<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="15" />
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-if="false"
        align="center"
        label="id"
        prop="id"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="企业名称"
        prop="name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="企业标志"
        prop="mark"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="企业ID"
        prop="secret"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="序列号数量"
        prop="quantity"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        :formatter="createdTime"
        label="创建时间"
        prop="add_time"
        show-overflow-tooltip
        sortable
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <titles ref="titles" />
  </div>
</template>

<script>
  import { companyPage } from '@/api/company'
  import { formatDate } from '@/utils/formatDate'

  export default {
    name: 'CompanyInfo',
    data() {
      return {
        radio: '1',
        dialogFormVisible: false,
        formLabelWidth: '120px',
        sendForm: { username: '', userID: '', msg: '' },
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleTitleEdit(row) {
        console.log(row)
        if (row.id) {
          this.$refs['titles'].showEdit(row)
        } else {
          this.$refs['titles'].showEdit()
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        this.queryForm.page = this.queryForm.pageNo
        this.queryForm.limit = this.queryForm.pageSize
        const {
          data: { list, total },
        } = await companyPage(this.queryForm)
        this.list = list
        this.total = total
        console.log(list)
        this.listLoading = false
      },
      createdTime(row) {
        let date = row.add_time * 1000
        return formatDate(new Date(date), 'yyyy年MM月dd日 hh:mm:ss')
      },
    },
  }
</script>
