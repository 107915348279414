<template>
  <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="12">
    <el-card v-show="!allFold" :height="height" shadow="hover">
      <el-button type="primary" @click="handleOpen">
        <vab-icon
          class="vab-dropdown"
          :class="{ 'vab-dropdown-active': fold }"
          icon="arrow-up-s-line"
        />
        各型号销售额占比
      </el-button>
      <el-tooltip placement="top">
        <div slot="content">不同型号的云存储销售额占比</div>
        <vab-icon icon="information-line" style="color: #409eff" />
      </el-tooltip>
      <el-tabs v-show="!fold" :height="height">
        <vab-chart
          v-loading="loading"
          :init-options="initOptions"
          lazy
          :option="option"
          theme="vab-echarts-theme"
        />
      </el-tabs>
    </el-card>
  </el-col>
</template>

<script>
  import VabChart from '@/extra/VabChart'

  export default {
    name: 'VabChartPie',
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: true,
        status: '',
        fold: false,
        allFold: false,
        height: this.$baseTableHeight(3) - 30,
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
            enterable: true, // 鼠标是否可进入提示框浮层中
            hideDelay: 200, // 浮层隐藏的延迟
            confine: true,
            backgroundColor: 'rgba(255,255,255, 1)',
            formatter: function (params) {
              let htmlStr =
                '<div style="height: auto;max-height: 240px;overflow-y: auto;"><p>' +
                params[0].axisValue +
                '</p>'
              for (let i = 0; i < params.length; i++) {
                htmlStr +=
                  '<p style="color: #666;">' +
                  params[i].marker +
                  params[i].seriesName +
                  ':' +
                  params[i].value +
                  '</p>'
              }
              htmlStr += '</div>'
              return htmlStr
            },
          },
          legend: {
            bottom: -5,
            type: 'scroll',
          },
          grid: {
            top: '0%',
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value}',
            },
          },
          yAxis: {
            type: 'category',
            inverse: true,
            data: ['人民币', '美元'],
          },
          series: [],
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      handleOpen() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      getData() {
        this.$root.eventVueService.$on('data-D', (data) => {
          let lenged = []
          data.map((item) => {
            lenged.push(item['typeName'])
          })
          let series = []
          let ob = []
          lenged.map((item) => {
            let obj = {
              name: item,
              type: 'bar',
              stack: 'total',
              emphasis: {
                focus: 'series',
              },
              data: [],
            }
            ob.push(obj)
            series = ob
          })
          for (let i in data) {
            series[i].data.push(data[i].cnyTotalMoney)
            series[i].data.push(data[i].usdTotalMoney)
          }
          this.option.series = series
          this.loading = false
        })
        this.$root.eventVueService.$on('data-X', (data) => {
          if (data == 'payOrder') {
            this.allFold = false
          } else {
            this.allFold = true
          }
        })
      },
    },
  }
</script>
