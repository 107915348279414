<template>
  <div
    class="user-management-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-left-panel :span="30">
        <el-form :inline="true" :model="upQueryData" @submit.native.prevent>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.appBundleName"
              placeholder="包名"
            >
              <el-option
                v-for="item in packageList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model.trim="queryForm.model" placeholder="设备类型">
              <el-option
                v-for="item in modelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.lang"
              clearable
              placeholder="语言"
            >
              <el-option
                v-for="item in languageList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.type"
              clearable
              placeholder="设备型号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="设备名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
      <vab-query-form-left-panel :span="30">
        <el-form :inline="true" :model="upQueryData" @submit.native.prevent>
          <el-form-item>
            <el-button
              v-if="$perms('device_appDeviceType_insert')"
              icon="el-icon-plus"
              type="primary"
              @click="handleEdit()"
            >
              添加
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="$perms('device_appDeviceType_insert')"
              :disabled="selectRows.length === 1 || selectRows.length === 0"
              icon="el-icon-edit"
              style="margin: 0 0 0 0 !important"
              type="primary"
              @click="handleBatchEdit()"
            >
              批量编辑
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" type="selection" />
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.toopltip"
        :sortable="item.sortable"
        :type="item.type"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label === '图标'">
            <el-image
              :preview-src-list="row[item.prop] | filterImg"
              :src="row[item.prop]"
              style="width: 80px; height: 80px"
            />
          </div>
          <div v-else-if="item.label === '图标V2'">
            <el-image
              :preview-src-list="row[item.prop] | filterImg"
              :src="row[item.prop]"
              style="width: 80px; height: 80px"
            />
          </div>
          <span v-else-if="item.label === '设备类型'">
            {{ row[item.prop] | daviceTypeFilter }}
          </span>
          <span v-else-if="item.label === '语言'">
            {{ langFormat(row[item.prop]) }}
          </span>
          <span v-else-if="item.label === 'config'">
            {{ JSON.stringify(row[item.prop]) }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="
          $perms('device_appdevicetype_edit') ||
          $perms('device_appdevicetype_delete')
        "
        align="center"
        label="操作"
        show-overflow-tooltip
        width="150"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('device_appdevicetype_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$perms('device_appdevicetype_delete')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" :app-bundle="packageList" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    getAppDeviceTypeList,
    deleteAppDeviceType,
    getAppNameList,
  } from '@/api/appDeviceType'
  import Edit from './components/AppDeviceType'
  import VabDraggable from 'vuedraggable'
  // const axios = require('axios')
  // import network from '@/config/index.js' // 引用 baseURL

  export default {
    name: 'DeviceType',
    components: {
      Edit,
      VabDraggable,
    },
    filters: {
      daviceTypeFilter(value) {
        if (value === 1) {
          return 'DVR'
        } else if (value === 2) {
          return 'IPC'
        }
      },
      filterImg(item) {
        if (typeof item === 'string') {
          return item.split('|')
        } else {
          return item
        }
      },
    },
    data() {
      return {
        packageList: [],
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        AppBundle: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: [], //列表中选中的数据
        reserveSelection: true,
        modelList: [
          {
            label: 'DVR',
            value: '1',
          },
          {
            label: 'IPC',
            value: '2',
          },
        ],
        languageList: [
          {
            label: '简体中文',
            value: 'cn',
          },
          {
            label: '繁体中文',
            value: 'cn_tw',
          },
          {
            label: '英文',
            value: 'en',
          },
          {
            label: '日语',
            value: 'jp',
          },
          {
            label: '俄语',
            value: 'ru',
          },
          {
            label: '葡萄牙语',
            value: 'pt',
          },
          {
            label: '波兰语',
            value: 'pl',
          },
          {
            label: '荷兰语',
            value: 'nl',
          },
          {
            label: '意大利语',
            value: 'it',
          },
          {
            label: '法语',
            value: 'fr',
          },
          {
            label: '西班牙语',
            value: 'es',
          },
          {
            label: '德语',
            value: 'de',
          },
        ],
        checkList: [
          'APP包名',
          '设备类型',
          '设备型号',
          '语言',
          '设备名称',
          '排序',
          'config',
          '图标V2',
          '图标',
        ],
        columns: [
          {
            label: 'APP包名',
            width: 'auto',
            prop: 'app_bundle_id',
            sortable: false,
          },
          {
            label: '设备类型',
            width: 'auto',
            prop: 'model',
            sortable: false,
          },
          {
            label: '设备型号',
            width: '210',
            prop: 'type',
            sortable: false,
          },
          {
            label: '语言',
            width: 'auto',
            prop: 'lang',
            sortable: false,
          },
          {
            label: '设备名称',
            width: 'auto',
            prop: 'name',
            sortable: false,
          },
          {
            label: '排序',
            width: 'auto',
            prop: 'sort',
            sortable: false,
          },
          {
            label: 'config',
            width: 'auto',
            prop: 'app_device_type__config',
            sortable: false,
          },
          {
            label: '图标V2',
            width: 'auto',
            prop: 'iconV2',
            sortable: false,
          },
          {
            label: '图标',
            width: 'auto',
            prop: 'icon',
            sortable: false,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 30,
          appBundleName: 'ZosiSmart',
          model: '1',
        },
        upQueryData: {
          app_bundle_id: '',
          model: '',
          type: '',
          name: '',
          sort: '',
        },
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        // return this.columns.filter((item) =>
        //   this.checkList.includes(item.label)
        // )
        return this.columns
      },
    },
    created() {
      this.fetchData()
      this.fetchAppData()
    },
    methods: {
      langFormat(val) {
        let data = ''
        let list = this.languageList
        list.forEach((item) => {
          if (val === item.value) {
            data = item.label
          }
        })
        return data
      },
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      handleSelectionChange(val) {
        this.selectRows = val
        return this.selectRows
      },
      getRowKeys(row) {
        return row.app_device_type__devicenamelanguage__id
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.$refs.tableSort.clearSelection()
        // this.queryForm.lang = this.upQueryData.lang
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getAppDeviceTypeList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      async fetchAppData() {
        this.listLoading = true
        const { data } = await getAppNameList()
        this.packageList = data
        this.listLoading = false
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleBatchEdit() {
        const rows = this.selectRows
        let selectTypeId = []
        let selectLanguageId = []
        rows.forEach((row) => {
          selectTypeId.push(row.app_device_type__id)
          selectLanguageId.push(row.app_device_type__devicenamelanguage__id)
        })
        this.$refs['edit'].showEditBatch(rows, selectTypeId, selectLanguageId)
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('确定要删除当前项吗', null, async () => {
            const { msg } = await deleteAppDeviceType({
              appBundleId: row.id,
              appDeviceTypeId: row.app_device_type__id,
            })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.deleteLastPageData()
            await this.fetchData()
          })
        }
      },
      //列表分页删除最后一页的最后一条数据，返回上一页
      async deleteLastPageData() {
        if (this.total % this.queryForm.pageSize === 1) {
          const lastPage =
            (this.total + this.queryForm.pageSize - 1) / this.queryForm.pageSize
          if (this.queryForm.pageNo === lastPage && lastPage !== 1) {
            this.queryForm.pageNo = this.queryForm.pageNo - 1
          }
        }
      },
    },
  }
</script>
