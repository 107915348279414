<template>
  <el-dialog
    :title="title"
    top="3vh"
    :visible.sync="dialogFormVisible"
    width="500px"
  >
    <div v-loading="loading">
      <el-upload
        ref="upload"
        action=""
        :auto-upload="false"
        class="upload-file"
        :http-request="upLoadFile"
        :limit="1"
        :on-change="onChange"
        :on-exceed="handleExceed"
        :on-remove="onRemove"
      >
        <el-button slot="trigger" size="small" type="success">
          选择excel文件
        </el-button>
      </el-upload>
      <div class="button">
        <el-button
          :disabled="buttonFlag"
          size="small"
          type="primary"
          @click="handlePaypalOrderReconcile"
        >
          paypal自动对账
        </el-button>
        <el-button
          :disabled="buttonFlag"
          size="small"
          type="primary"
          @click="handleWechatOrderReconcile"
        >
          微信自动对账
        </el-button>
        <el-button
          :disabled="buttonFlag"
          size="small"
          type="primary"
          @click="handleAlipayOrderReconcile"
        >
          支付宝自动对账
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import {
    paypalOrderReconcile,
    wechatOrderReconcile,
    alipayOrderReconcile,
  } from '@/api/deviceOrder'
  export default {
    name: 'ReconfiliationDialog',
    data() {
      return {
        file: [],
        title: '自动对账',
        dialogFormVisible: false,
        loading: false,
      }
    },
    computed: {
      buttonFlag() {
        if (this.file.length === 0) {
          return true
        } else {
          return false
        }
      },
    },
    methods: {
      showDialog() {
        this.dialogFormVisible = true
      },
      upLoadFile(file) {
        this.formData.append('file', file.file)
      },
      onChange(file) {
        this.file = URL.createObjectURL(file.raw)
      },
      handleExceed(files) {
        this.$refs.upload.clearFiles()
        this.$nextTick(() => {
          this.$refs.upload.handleStart(files[0])
        })
      },
      onRemove() {
        this.file = []
      },
      async handlePaypalOrderReconcile() {
        this.loading = true
        this.formData = new FormData()
        this.$refs.upload.submit()
        await paypalOrderReconcile(this.formData)
          .then((re) => {
            let blob = new Blob([re], {
              type: 'application/vnd.ms-excel;charset=utf-8',
            })
            let downloadUrl = window.URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.href = downloadUrl
            a.setAttribute('download', 'paypal订单核对.xlsx')
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          })
          .catch((re) => {
            console.log(re)
          })
        this.loading = false
      },
      async handleWechatOrderReconcile() {
        this.loading = true
        this.formData = new FormData()
        this.$refs.upload.submit()
        await wechatOrderReconcile(this.formData)
          .then((re) => {
            let blob = new Blob([re], {
              type: 'application/vnd.ms-excel;charset=utf-8',
            })
            let downloadUrl = window.URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.href = downloadUrl
            a.setAttribute('download', '微信订单核对.xlsx')
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          })
          .catch((re) => {
            console.log(re)
          })
        this.loading = false
      },
      async handleAlipayOrderReconcile() {
        this.loading = true
        this.formData = new FormData()
        this.$refs.upload.submit()
        await alipayOrderReconcile(this.formData)
          .then((re) => {
            let blob = new Blob([re], {
              type: 'application/vnd.ms-excel;charset=utf-8',
            })
            let downloadUrl = window.URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.href = downloadUrl
            a.setAttribute('download', '支付宝订单核对.xlsx')
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          })
          .catch((re) => {
            console.log(re)
          })
        this.loading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .upload-file {
    height: 100px;
  }
  .button {
    display: flex;
    justify-content: space-between;
  }
</style>
