<template>
  <div class="user-management-container">
    <el-dialog title="上传uid" :visible.sync="dialogFormVisible">
      <el-form :model="dialogForm">
        <el-form-item label="企业名称:" :label-width="formLabelWidth">
          <span>{{ dialogForm.companyName }}</span>
        </el-form-item>
        <el-form-item label="区域名称:" :label-width="formLabelWidth">
          <span>{{ dialogForm.regionName }}</span>
        </el-form-item>
        <el-form-item label="族群:" :label-width="formLabelWidth">
          <span>{{ dialogForm.name }}</span>
        </el-form-item>
        <el-form-item label="uid总数:" :label-width="formLabelWidth">
          <span>{{ dialogForm.uidCount }}</span>
        </el-form-item>
        <el-form-item label="p2p类型:">
          <el-select
            v-model="dialogForm.p2pType"
            clearable
            placeholder="请选择p2p类型"
            style="width: 150px"
            @change="p2pTypeChange"
          >
            <el-option
              v-for="item in p2pTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="showInput" label="*平台:">
          <el-select
            v-model="dialogForm.platform"
            clearable
            filterable
            allow-create
            default-first-option
            placeholder="请选择平台"
            style="width: 150px"
            @blur="nameblur()"
            ref="nameSel"
          >
            <el-option
              v-for="item in platformList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="showInput" label="*设备初始化字符:">
          <el-input
            v-model="dialogForm.initString"
            clearable
            placeholder="请输入设备初始化字符"
          />
        </el-form-item>
        <el-form-item v-if="showInput" label="*app初始化字符:">
          <el-input
            v-model="dialogForm.initStringApp"
            clearable
            placeholder="请输入app初始化字符"
          />
        </el-form-item>
        <el-form-item>
          <el-upload
            ref="upload"
            action=""
            :auto-upload="false"
            class="upload-file"
            :on-change="changeUpload"
          >
            <el-button
              v-if="$perms('device_devicereset_select')"
              slot="trigger"
              type="primary"
            >
              选取uid文件
            </el-button>
            <el-button
              v-if="$perms('device_devicereset_select')"
              style="margin-left: 10px"
              type="success"
              @click="submitUpload"
            >
              开始上传
            </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-if="false"
        align="center"
        label="id"
        prop="id"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="id"
        prop="id"
        show-overflow-tooltip
        width="55"
      />
      <el-table-column
        align="center"
        label="企业名称"
        prop="company__name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="区域名称"
        prop="region__name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="族群"
        prop="name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="uid总数"
        prop="uid_count"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="tutk已使用uid数量"
        prop="tutk_use_count"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="尚云已使用uid数量"
        prop="shangyun_use_count"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="tutk未使用uid数量"
        prop="tutk_unuse_count"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="尚云未使用uid数量"
        prop="shangyun_unuse_count"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="150"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('vpg_info_uploaduid')"
            type="success"
            @click="handleUpload(row)"
          >
            上传uid
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  const axios = require('axios')
  import network from '@/config/index.js'
  import { Loading } from 'element-ui'
  import { vpgPage } from '@/api/vpg'
  import { formatDate } from '@/utils/formatDate'

  export default {
    name: 'SurveysInformation',
    data() {
      return {
        radio: '1',
        dialogFormVisible: false,
        formLabelWidth: '120px',
        uidList: '',
        dialogForm: {
          id: 0,
          companyName: '',
          regionName: '',
          name: '',
          uidCount: 0,
          p2pType: 1,
          platform: '',
          initString: '',
          initStringApp: '',
        },
        list: [],
        p2pTypeList: [
          { name: '尚云', value: 1 },
          { name: 'tutk', value: 2 },
        ],
        platformList: [ 'ACN', 'AUS', 'AEU' ],
        showInput: true,
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          status: '',
          serialNumber: '',
          useStatus: '',
        },
        ACN: {
          initString: 'EFGHFDBFKOIKGJJOEOHEFPEDGLIOHCLFFFCCCNFIDIMBOOPKDJFDDKKEGLPJJHKHFBILKKGOKANNEHBKONPKOJDLKNPABFDPFEDIHBBHIIPGBADCFIAIODIHHJ',
          initStringApp: 'EFGHFDBFKOIKGJJOEOHEFPEDGLNMHLNKHKFFBNDCAIJNKNKGDAABDHPCGMLMIMLPAOMDKPDHPLNEBHCEIM',
        },
        AUS: {
          initString: 'EFGHFDBKLMIAGGIGFLHKEKBBGGLPFKKHFKDGBECEACMFLFPMDEFFDNLJCBLBIBOAAJJAPODFKHOGGBAMPNPBPPDMKDNCHCCJBCDOGFAHIOOIBLHJBDFPKHIDDCJDBKCC',
          initStringApp: 'EFGHFDBKLMIAGGIGFLHKEKFBGHMPGIMDGDENABCOBAIDLBLOCIACCKOLHELKJBKAAIMKLJDP',
        },
        AEU: {
          initString: 'EBGJFNBCKDJKGGJOEKHAFOEDGLIIGPKIFBCMCCFHAAJEKEPPDDEEDEKFHNONMBLDBLIMKBGGKINDFCAPPMOBPECGIHLGEMCJENDMGMAFINPBFFDFABBMPLJMGC',
          initStringApp: 'EBGCFGBLKEIAGAJKENHHFPEDGLNMHGMAHPFEBLDEANJLLDKHDHACCMPEGPLHIHLEALMMKADMOONOBICJIB',
        },
      }
    },
    watch: {
      'dialogForm.platform': {
        handler(newVal) {
          if (this.dialogForm.p2pType === 1) {
            if (newVal === 'ACN') {
              this.dialogForm.initString = this.ACN.initString
              this.dialogForm.initStringApp = this.ACN.initStringApp
            }
            else if (newVal === 'AUS') {
              this.dialogForm.initString = this.AUS.initString
              this.dialogForm.initStringApp = this.AUS.initStringApp
            }
            else if (newVal === 'AEU') {
              this.dialogForm.initString = this.AEU.initString
              this.dialogForm.initStringApp = this.AEU.initStringApp
            }
          }
        }
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      //可通过ref属性获取当前select选择器中的输入内容
      nameblur(){
        this.dialogForm.platform = this.$refs.nameSel.selectedLabel
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleTitleEdit(row) {
        console.log(row)
        if (row.id) {
          this.$refs['titles'].showEdit(row)
        } else {
          this.$refs['titles'].showEdit()
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        this.queryForm.page = this.queryForm.pageNo
        this.queryForm.limit = this.queryForm.pageSize
        const {
          data: { list, total },
        } = await vpgPage(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      createdTime(row) {
        let date = row.add_time * 1000
        return formatDate(new Date(date), 'yyyy年MM月dd日 hh:mm:ss')
      },
      p2pTypeChange(value) {
        console.log(value)
        if (value === 1) {
          this.showInput = true
        } else {
          this.showInput = false
        }
      },
      handleUpload(row) {
        console.log(row)
        if (row.id) {
          this.dialogForm.id = row.id
          this.dialogForm.companyName = row.company__name
          this.dialogForm.regionName = row.region__name
          this.dialogForm.name = row.name
          this.dialogForm.uidCount = row.uid_count
          this.dialogFormVisible = true
        }
      },
      changeUpload(file) {
        console.log(file)
        let reader = new FileReader()
        if (typeof FileReader === 'undefined') {
          this.$message({
            type: 'info',
            message: '您的浏览器不支持文件读取',
          })
          return
        }
        reader.readAsText(file.raw, 'utf-8')
        var _this = this
        reader.onload = function (e) {
          _this.uidList = e.target.result
          console.log('uidList:\n', _this.uidList)
        }
      },
      submitUpload() {
        this.$confirm('确认上传uid文件?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 构建传给后端的数据
            this.formData = new FormData()
            this.formData.append('uid_list', this.uidList)
            this.formData.append('vpg_id', this.dialogForm.id)
            this.formData.append('p2p_type', this.dialogForm.p2pType)
            this.formData.append('platform', this.dialogForm.platform)
            this.formData.append('init_string', this.dialogForm.initString)
            this.formData.append(
              'init_string_app',
              this.dialogForm.initStringApp
            )

            let url = network.baseURL + '/serial/uploadUid'
            console.log('url: ' + url)
            let loadingInstance = Loading.service({
              lock: true,
              text: '文件上传中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })
            axios
              .post(url, this.formData)
              .then(
                function (res) {
                  loadingInstance.close()
                  console.log(res)
                  if (res.data.result_code === 0) {
                    this.fetchData()
                    this.dialogFormVisible = false
                    this.$message({
                      type: 'success',
                      message: '上传成功!',
                    })
                  } else {
                    this.$message({
                      type: 'error',
                      message: '上传失败! ' + res.data.msg,
                    })
                  }
                }.bind(this)
              )
              .catch((res) => {
                console.log(res)
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消上传',
            })
          })
      },
    },
  }
</script>
