<template>
  <el-col>
    <el-card shadow="hover">
      <template #header>
        <span style="font-weight: bold;font-size: 18px">{{ title }}</span>
        <el-tooltip placement="top">
          <div slot="content">展示排行前20位的国家数据<br/>注意：未知国家是由于当时并未统计特定国家</div>
          <vab-icon
            icon="information-line"
          />
        </el-tooltip>
      </template>
      <vab-chart
        v-loading="loading"
        :init-options="initOptions"
        :option="option"
        style="height: 470px"
        theme="vab-echarts-theme"
      />
    </el-card>
  </el-col>
</template>

<script>
  import VabChart from '@/extra/VabChart'
  export default {
    name: 'VabChartBar',
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: true,
        queryForm: {},
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          dataZoom: [
            {
              startValue: '',
            },
            {
              type: 'inside',
            },
            {
              endValue: '',
            },
          ],
          grid: {
            top: '5%',
            left: '2%',
            right: '4%',
            bottom: '15%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: {
            type: 'bar',
            data: [],
            itemStyle: {
              color: new VabChart.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: new VabChart.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' },
                ]),
              },
            },
          },
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.$root.eventVueDevice.$on('data-countries', (countries) => {
          let countryName = []
          for (let i in countries) {
            countryName.push(countries[i].countryName)
          }
          let countryCount = []
          for (let r in countries) {
            countryCount.push(countries[r].count)
          }
          this.option.xAxis.data = countryName
          this.option.series.data = countryCount
          this.loading = false
        })
      },
      // async fetchData() {
      //   const {
      //     result: { countries },
      //   } = await getRegion(this.queryForm)
      //   let countryName = []
      //   for (let i in countries) {
      //     countryName.push(countries[i].countryName)
      //   }
      //   let countryCount = []
      //   for (let r in countries) {
      //     countryCount.push(countries[r].count)
      //   }
      //   this.option.xAxis.data = countryName
      //   this.option.series.data = countryCount
      //   this.loading = false
      // },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-card {
      height: 500px !important;
    }
  }
</style>
