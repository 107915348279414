<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.serialNumber"
              clearable
              placeholder="请输入序列号"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.companyName"
              clearable
              placeholder="关联企业"
              style="width: 100px"
            >
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="使用状态"
              style="width: 100px"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="15" />
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-if="false"
        align="center"
        label="id"
        prop="id"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="序列号"
        prop="serial_number"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="关联企业"
        prop="company__name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="getStatus"
        label="使用状态"
        prop="status"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="createdTime"
        label="添加时间"
        prop="add_time"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        :formatter="createdTime"
        label="更新时间"
        prop="update_time"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        v-if="$perms('serial_serialInfo_syncdata')"
        align="center"
        label="操作"
        width="150"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('serial_serialInfo_syncdata')"
            type="primary"
            @click="handleSyncData(row)"
          >
            同步数据
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <sync-data ref="sync" />
  </div>
</template>

<script>
  import { companyPage } from '@/api/company'
  import { serialPage } from '@/api/serial'
  import { formatDate } from '@/utils/formatDate'
  import syncData from './components/syncData'

  export default {
    name: 'SurveysInformation',
    components: { syncData },
    data() {
      return {
        radio: '1',
        dialogFormVisible: false,
        formLabelWidth: '120px',
        sendForm: { username: '', userID: '', msg: '' },
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        statusOptions: [
          { name: '未使用', value: 0 },
          { name: '已分配', value: 1 },
          { name: '绑定uid', value: 2 },
          { name: '已占用', value: 3 },
        ],
        companyList: [],
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          status: '',
          serialNumber: '',
          useStatus: '',
        },
      }
    },
    created() {
      this.fetchData()
      this.fetchCompanyData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleTitleEdit(row) {
        console.log(row)
        if (row.id) {
          this.$refs['titles'].showEdit(row)
        } else {
          this.$refs['titles'].showEdit()
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      handleSyncData(row) {
        this.$refs['sync'].showComponent(row)
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        this.queryForm.page = this.queryForm.pageNo
        this.queryForm.limit = this.queryForm.pageSize
        const {
          data: { list, total },
        } = await serialPage(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      async fetchCompanyData() {
        this.queryForm.isSelect = 'true'
        const {
          data: { list },
        } = await companyPage(this.queryForm)
        this.companyList = list
      },
      getStatus(row) {
        if (row.status === 0) {
          return '未使用'
        } else if (row.status === 1) {
          return '已分配'
        } else if (row.status === 2) {
          return '绑定uid'
        } else if (row.status === 3) {
          return '已占用'
        }
      },
      getUseStatus(row) {
        if (row.use_status === 0) {
          return '未分配'
        } else {
          return '已分配'
        }
      },
      createdTime(row) {
        let date = row.add_time * 1000
        return formatDate(new Date(date), 'yyyy年MM月dd日 hh:mm:ss')
      },
    },
  }
</script>
