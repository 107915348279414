<template>
  <div
    class="user-management-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-tabs
              v-model="queryForm.app_type"
              type="card"
              @tab-click="handleTypeClick"
            >
              <el-tab-pane
                v-for="(item, index) in appTypeList"
                :key="index"
                :label="item"
              />
            </el-tabs>
          </el-form-item>

          <el-form-item>
            <el-button
              v-if="$perms('version_appversion_insert')"
              icon="el-icon-plus"
              type="primary"
              @click="handleEdit"
            >
              添加
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.appName"
              clearable
              placeholder="APP 名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-dialog title="APP JSON 控制" :visible.sync="dialogFormVisible">
      <el-form :model="appSet">
        <el-form-item label="APP ID:" :label-width="formLabelWidth">
          <span>{{ appSet.appBundleId }}</span>
        </el-form-item>
        <el-form-item label="APP SET:" :label-width="formLabelWidth">
          <el-input
            v-model="appSet.content"
            autocomplete="off"
            :autosize="{ minRows: 6, maxRows: 20 }"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAppSet(appSet)">确 定</el-button>
      </div>
    </el-dialog>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === 'APP类型'">
            {{ row[item.prop] | appTypeFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="250"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('version_appversion_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$perms('version_appversion_editjson')"
            type="primary"
            @click="showAppSet(row)"
          >
            JSON控制
          </el-button>
          <el-button
            v-if="$perms('version_appversion_del')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    getAppVersionList,
    deleteAppVersion,
    getAppSet,
    editAppSet,
  } from '@/api/appVersion'
  import VabDraggable from 'vuedraggable'
  import Edit from './components/APPversion'

  export default {
    name: 'APPversion',
    components: {
      Edit,
      VabDraggable,
    },
    filters: {
      appTypeFilter(value) {
        if (value === 1) {
          return 'IOS'
        } else if (value === 2) {
          return '安卓'
        } else {
          return 'AVSS'
        }
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        formLabelWidth: '120px',
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        appTypeList: ['IOS', '安卓', 'AVSS'],
        statusList: ['是', '否'],
        checkList: [
          'APP名称',
          'APP ID',
          '项目版本',
          '系统版本',
          '最小系统版本',
          '更新内容',
          'APP类型',
          '下载路径',
          '添加时间',
          '更新时间',
        ],
        columns: [
          {
            label: 'APP名称',
            width: '150',
            prop: 'appName',
            sortable: true,
            disableCheck: false,
          },
          {
            label: 'APP ID',
            width: '200',
            prop: 'appBundleId',
            sortable: true,
          },
          {
            label: '项目版本',
            width: '130',
            prop: 'bundleVersion',
            sortable: true,
          },
          {
            label: '系统版本',
            width: 'auto',
            prop: 'newAppversion',
            sortable: true,
          },
          {
            label: '最小系统版本',
            width: 'auto',
            prop: 'minAppversion',
            sortable: true,
          },
          {
            label: '更新内容',
            width: 'auto',
            prop: 'content',
            sortable: true,
          },
          {
            label: 'APP类型',
            width: 'auto',
            prop: 'app_type',
            sortable: true,
          },
          {
            label: '下载路径',
            width: 'auto',
            prop: 'downloadLink',
            sortable: true,
          },
          {
            label: '添加时间',
            width: '160',
            prop: 'add_time',
            sortable: true,
          },
          {
            label: '更新时间',
            width: '160',
            prop: 'update_time',
            sortable: true,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          app_type: 'IOS',
          content: 'en',
        },
        appSet: {
          appBundleId: '',
          content: '',
        },
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getAppVersionList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      handleTypeClick(tab, event) {
        console.log(tab, event)
        this.queryForm.app_type = tab.label
        this.fetchData()
      },
      handleDelete(row) {
        if (row.appBundleId) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteAppVersion({
              appBundleId: row.appBundleId,
            })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      async showAppSet(row) {
        console.log('@@@' + row.id)
        if (row.id) {
          const { data } = await getAppSet({ appBundleId: row.appBundleId })
          console.log('响应:' + data.content)
          // 赋值弹出框显示内容
          this.appSet.appBundleId = row.appBundleId
          this.appSet.content = data.content
          this.dialogFormVisible = true
        }
      },
      async saveAppSet(appSet) {
        console.log('appSet:' + appSet.appBundleId + appSet.content)
        if (appSet.content) {
          const { msg } = await editAppSet({
            appBundleId: appSet.appBundleId,
            content: appSet.content,
          })
          this.dialogFormVisible = false
          this.$baseMessage(msg, 'success', 'vab-hey-message-success')
        }
      },
    },
  }
</script>
