var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "1450px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        }
      }
    },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入用户姓名" },
                        model: {
                          value: _vm.queryForm.user_name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "user_name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.user_name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入电话号码" },
                        model: {
                          value: _vm.queryForm.phone,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.fetchData }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: { border: "", data: _vm.list }
        },
        _vm._l(_vm.tableColumns, function(item) {
          return _c("el-table-column", {
            key: item.prop,
            attrs: {
              align: "center",
              label: item.label,
              prop: item.prop,
              "show-overflow-tooltip": item.toopltip,
              width: item.width
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      item.label === "性别"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("typeFilter")(
                                    row[item.prop],
                                    _vm.sexList
                                  )
                                ) +
                                " "
                            )
                          ])
                        : item.label === "是否输出过新品体验报告"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("typeFilter")(
                                    row[item.prop],
                                    _vm.reportList
                                  )
                                ) +
                                " "
                            )
                          ])
                        : item.label === "是否入选"
                        ? _c(
                            "span",
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.handleOpenAndClose(row)
                                  }
                                },
                                model: {
                                  value: row[item.prop],
                                  callback: function($$v) {
                                    _vm.$set(row, item.prop, $$v)
                                  },
                                  expression: "row[item.prop]"
                                }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("typeFilter")(
                                      row[item.prop],
                                      _vm.selectedList
                                    )
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(row[item.prop]))])
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.page,
          layout: _vm.layout,
          "page-size": _vm.queryForm.line,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }