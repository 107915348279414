import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/userManagement/getUserInfo',
    method: 'get',
    params,
  })
}

export function doAddOrEdit(data) {
  return request({
    url: '/userManagement/AddOrEditAccount',
    method: 'post',
    data,
  })
}

export function doEdit(data) {
  return request({
    url: '/userManagement/editAccount',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/userManagement/doDelete',
    method: 'post',
    data,
  })
}

export function resetPassword(data) {
  return request({
    url: '/userManagement/resetPassword',
    method: 'post',
    data,
  })
}
