import request from '@/utils/request'

export function getPayOrder(params) {
  return request({
    url: 'dataManagement/serviceData/global/payOrder',
    method: 'get',
    params,
  })
}

export function getFreeOrder(params) {
  return request({
    url: 'dataManagement/serviceData/global/freeOrder',
    method: 'get',
    params,
  })
}

export function getRepeatPayOrder(params) {
  return request({
    url: 'dataManagement/serviceData/global/repeatPayOrder',
    method: 'get',
    params,
  })
}

export function getFirstPayOrder(params) {
  return request({
    url: 'dataManagement/serviceData/global/firstPayOrder',
    method: 'get',
    params,
  })
}
