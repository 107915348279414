<template>
  <el-form ref="form" label-width="200px" :model="form">
    <el-form-item label="请输入UID">
      <el-input v-model="form.uid" @change="handUID" />
    </el-form-item>
    <el-form-item label="UID生成base64">
      <el-input v-model="form.uid_code" disabled="true" />
    </el-form-item>
    <el-form-item label="当前时间戳">
      <el-input v-model="form.time_stamp" disabled="true" />
    </el-form-item>
    <el-form-item label="时间戳生成base64">
      <el-input v-model="form.token" disabled="true" />
    </el-form-item>
    <el-form-item label="设备版本">
      <el-input v-model="form.device_version" />
    </el-form-item>

    <el-form-item label="请输入语言">
      <el-select v-model="form.language" placeholder="请选择">
        <el-option
          v-for="item in options1"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <!-- <el-input v-model="form.language" /> -->
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">创建IOT证书</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { getCVM } from '@/api/iotTest'
  let Base64 = require('js-base64').Base64
  export default {
    data() {
      return {
        form: {
          uid: '',
          uid_code: '',
          token: '',
          time_stamp: '',
          device_version: '',
          language: '',
        },
        options1: [
          { value: 'cn', label: '中文' },
          { value: 'en', label: '英文' },
        ],
      }
    },
    methods: {
      handUID(userPwd) {
        for (var i = 0; i < 3; i++) {
          if (i == 0) {
            userPwd = 123 + userPwd + 123
            userPwd = Base64.encode(userPwd)
          }
          if (i == 1) {
            userPwd = 12 + userPwd + 12
            userPwd = Base64.encode(userPwd)
          }
          if (i == 2) {
            userPwd = 1 + userPwd + 1
            userPwd = Base64.encode(userPwd)
          }
        }

        this.form.uid_code = userPwd
        const datime = Date.parse(new Date()).toString()
        this.form.time_stamp = datime.slice(0, -3)

        var token = this.form.time_stamp

        for (var k = 0; k < 3; k++) {
          if (k == 0) {
            token = 456 + token + 456
            token = Base64.encode(token)
          }
          if (k == 1) {
            token = 45 + token + 45
            token = Base64.encode(token)
          }
          if (k == 2) {
            token = 6 + token + 6
            token = Base64.encode(token)
          }
        }
        this.form.token = token
      },
      async onSubmit() {
        const msg = await getCVM(this.form)
        this.$confirm(JSON.stringify(msg), '证书内容', {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          center: true,
          type: 'warning',
        }).then(() => {
          this.$message({
            type: 'success',
            message: '创建证书成功!',
          })
        })
      },
    },
  }
</script>
