<template>
  <div>
    <el-row>
      <el-col>
        <el-card class="order-card1" shadow="hover">
          <el-row
            style="
              font-size: 20px;
              font-weight: 600;
              margin: 0 auto;
              text-align: center;
            "
          >
            <vab-icon
              icon="user-add-line"
              style="font-size: 30px; font-weight: 500"
            />
            累计销售额
            <el-tooltip placement="top">
              <div slot="content">截止至统计时间，平台所累计的销售额。</div>
              <vab-icon icon="information-line" />
            </el-tooltip>
          </el-row>
          <el-row style="text-align: center">
            <el-tooltip placement="top">
              <div slot="content">人民币</div>
              <h1>
                ¥
                <vab-count
                  :decimals="countConfig.decimals"
                  :duration="countConfig.duration"
                  :end-val="countConfig.endVal"
                  :prefix="countConfig.prefix"
                  :separator="countConfig.separator"
                  :start-val="countConfig.startVal"
                  :suffix="countConfig.suffix"
                />
              </h1>
            </el-tooltip>
          </el-row>
          <el-row style="text-align: center">
            <el-tooltip placement="top">
              <div slot="content">美元</div>
              <h1>
                $
                <vab-count
                  :decimals="countConfig2.decimals"
                  :duration="countConfig2.duration"
                  :end-val="countConfig2.endVal"
                  :prefix="countConfig2.prefix"
                  :separator="countConfig2.separator"
                  :start-val="countConfig2.startVal"
                  :suffix="countConfig2.suffix"
                />
              </h1>
            </el-tooltip>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import VabCount from '@/extra/VabCount'

  export default {
    components: { VabCount },
    data() {
      return {
        countConfig: {
          startVal: 0,
          endVal: 0,
          decimals: 2,
          prefix: '',
          suffix: '',
          separator: ',',
          duration: 2500,
        },
        countConfig2: {
          startVal: 0,
          endVal: 0,
          decimals: 2,
          prefix: '',
          suffix: '',
          separator: ',',
          duration: 2500,
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.$root.eventVueOv.$on('data-H', (message) => {
          this.countConfig.endVal = message.cnyTotal
          this.countConfig2.endVal = message.usdTotal
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .order {
    &-card1 {
      height: 300px;
      background: linear-gradient(to right, #60b2fb, #6485f6);

      &-content {
        text-align: center;
      }

      ::v-deep {
        .el-card {
          &__header,
          &__body {
            color: $base-color-white !important;
          }
        }
      }
    }
  }
</style>
