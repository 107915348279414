<template>
  <el-card class="tabs-cards" shadow="hover">
    <el-table v-loading="loading" border :data="queryData">
      <el-table-column label="设备uid" prop="uid" />
      <el-table-column label="上传时长" prop="uploadDuration" />
      <el-table-column label="上传次数" prop="uploadFrequency" />
      <el-table-column label="播放次数" prop="playFrequency" />
      <el-table-column label="播放时长" prop="playDuration" />
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </el-card>
</template>

<script>
  import { getVodData } from '@/api/workData'

  export default {
    name: 'Echarts',
    components: {},
    data() {
      return {
        loading: true,
        height: 520,
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        queryData: [],
        queryForm: {
          startTime: '',
          endTime: '',
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.getNowMon()
      this.getData()
      this.getDate()
    },
    methods: {
      async getData() {
        const { result } = await getVodData(this.queryForm)
        this.queryData = result.vodData
        this.total = result.uploadDeviceCount
        this.loading = false
      },
      getDate() {
        this.$root.eventVueWork.$on('data-A', (message) => {
          this.queryForm.startTime = message[0] / 1000
          this.queryForm.endTime = message[1] / 1000 + 1
          this.loading = true
          this.getData()
        })
      },
      getNowMon() {
        let myDate = new Date()
        let year = myDate.getFullYear() //获取当前年
        let mon = myDate.getMonth() + 1 //获取这个月
        if (mon < 10) {
          mon = '0' + mon
        }
        let now =
          year + '-' + mon + '-' + '01' + ' ' + '00' + ':' + '00' + ':' + '00'
        let nowAny = ''
        nowAny = Date.parse(now)
        this.queryForm.startTime = nowAny / 1000
        this.queryForm.endTime = nowAny / 1000 + 1
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.getData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.getData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .echarts-container {
    padding: 0 !important;
    background: $base-color-background !important;
    ::v-deep {
      .echarts {
        width: 100%;
        height: 200px;
      }
    }
  }
</style>
