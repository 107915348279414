<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="有效天数" prop="effective_day">
        <el-input
          v-model.trim="form.effective_day"
          placeholder="请输入有效天数"
        />
      </el-form-item>
      <el-form-item label="价格" prop="price">
        <el-input v-model.trim="form.price" placeholder="请输入价格" />
      </el-form-item>
      <el-form-item label="虚拟价格" prop="virtual_price">
        <el-input
          v-model.trim="form.virtual_price"
          placeholder="请输入虚拟价格"
        />
      </el-form-item>
      <el-form-item label="货币" prop="currency">
        <el-select
          v-model="form.currency"
          placeholder="请选择货币"
          @change="currencyChange"
        >
          <el-option
            v-for="item in currencyList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="货币符号" prop="symbol">
        <el-input v-model.trim="form.symbol" disabled />
      </el-form-item>
      <el-form-item label="支付类型" prop="pay_type">
        <el-select
          v-model="form.pay_type"
          multiple
          placeholder="请选择支付类型(可多选)"
        >
          <el-option
            v-for="item in payTypeList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否优惠" prop="is_discounts">
        <el-select v-model="form.is_discounts" placeholder="请选择是否优惠">
          <el-option
            v-for="item in isDiscountsList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="优惠价格" prop="discount_price">
        <el-input
          v-model.trim="form.discount_price"
          placeholder="请输入优惠价格"
        />
      </el-form-item>
      <el-form-item label="是否显示" prop="is_show">
        <el-select v-model="form.is_show" placeholder="请选择是否显示">
          <el-option
            v-for="item in isShowList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save()">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { addOrEditAiStoreMeal } from '@/api/aiPackageInformation'

  export default {
    name: 'UserInformation',
    data() {
      return {
        payTypeList: [
          {
            value: 1,
            label: 'PayPal',
          },
          {
            value: 2,
            label: '支付宝',
          },
          {
            value: 3,
            label: '微信支付',
          },
          {
            value: 10,
            label: '免费体验',
          },
          {
            value: 11,
            label: '激活码',
          },
        ],
        isDiscountsList: [
          {
            value: 0,
            label: '否',
          },
          {
            value: 1,
            label: '是',
          },
        ],
        currencyList: [
          {
            value: 'USD',
            label: 'USD',
          },
          {
            value: 'CNY',
            label: 'CNY',
          },
        ],
        symbolList: [
          {
            value: '$',
            label: '$',
          },
          {
            value: '¥',
            label: '¥',
          },
        ],
        isShowList: [
          {
            value: 0,
            label: '否',
          },
          {
            value: 1,
            label: '是',
          },
        ],
        form: {
          role: '',
          isEdit: '',
        },
        rules: {
          effective_day: [
            { required: true, trigger: 'blur', message: '请输入有效天数' },
          ],
          pay_type: [
            {
              required: true,
              trigger: 'blur',
              message: '请选择支付类型',
            },
          ],
          price: [{ required: true, trigger: 'blur', message: '请输入价格' }],
          currency: [
            { required: true, trigger: 'blur', message: '请选择货币' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加AI套餐'
        } else {
          this.title = '编辑AI套餐'
          this.form = Object.assign({}, row)
          this.form.isEdit = true
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            console.log('pay_type:' + this.form.pay_type)
            console.log(typeof this.form.pay_type)
            if (null != this.form.pay_type && '' != this.form.pay_type) {
              // 将对象转为字符串
              this.form.pay_type = JSON.stringify(this.form.pay_type)
            }
            const { msg } = await addOrEditAiStoreMeal(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      currencyChange(currency) {
        if (currency === 'USD') {
          this.form.symbol = '$'
        } else if (currency === 'CNY') {
          this.form.symbol = '¥'
        }
      },
    },
  }
</script>
