<template>
  <el-dialog
    center
    title="提示"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <el-row style="display: flex; text-align: center">
      <el-col>
        <el-button type="primary" @click="handleSync('test')">
          同步测试服数据
        </el-button>
      </el-col>
      <el-col>
        <el-button type="primary" @click="handleSync('cn')">
          同步国内数据
        </el-button>
      </el-col>
      <el-col>
        <el-button type="primary" @click="handleSync('us')">
          同步美洲数据
        </el-button>
      </el-col>
      <el-col>
        <el-button type="primary" @click="handleSync('eu')">
          同步欧洲数据
        </el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
  import { syncSerial } from '@/api/serial'
  export default {
    name: 'SyncData',
    data() {
      return {
        dialogFormVisible: false,
        form: {},
      }
    },
    methods: {
      showComponent(row) {
        this.form = Object.assign({}, row)
        this.dialogFormVisible = true
      },
      close() {
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      handleSync(val) {
        let tip = ''
        if (val === 'test') {
          tip = '确定要同步测试服数据吗'
        } else if (val === 'cn') {
          tip = '确定要同步国内数据吗'
        } else if (val === 'us') {
          tip = '确定要同步美洲数据吗'
        } else if (val === 'eu') {
          tip = '确定要同步欧洲数据吗'
        }
        this.$baseConfirm(tip, null, async () => {
          const { msg } = await syncSerial({
            serial: this.form.serial_number,
            syncRegion: val,
          })
          this.$baseMessage(msg, 'success', 'vab-hey-message-success')
          this.close()
        })
      },
    },
  }
</script>
