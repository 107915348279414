var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      attrs: { shadow: "hover" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "span",
                { staticStyle: { "font-weight": "bold", "font-size": "18px" } },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _c(
                "el-tooltip",
                { attrs: { placement: "top" } },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v("提供新增用户和活跃用户的趋势变化及明细数据")
                  ]),
                  _c("vab-icon", { attrs: { icon: "information-line" } })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("span", { staticClass: "demonstration" }),
      _c(
        "el-row",
        {
          staticClass: "row-bg",
          attrs: { justify: "space-between", type: "flex" }
        },
        [
          _c(
            "el-radio-group",
            {
              on: { change: _vm.buttonChange },
              model: {
                value: _vm.radio1,
                callback: function($$v) {
                  _vm.radio1 = $$v
                },
                expression: "radio1"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: 1 } }, [
                _vm._v("新增用户")
              ]),
              _c("el-radio-button", { attrs: { label: 2 } }, [
                _vm._v("活跃用户")
              ])
            ],
            1
          ),
          _vm._t("default")
        ],
        2
      ),
      _c("vab-chart", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "trend-echart",
        staticStyle: { height: "360px" },
        attrs: {
          "init-options": _vm.initOptions,
          lazy: "",
          option: _vm.option,
          theme: "vab-echarts-theme"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }