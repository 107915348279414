var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 8 } },
            [
              _vm.$perms("user_userinfo_insert")
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleEdit }
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("vab-query-form-right-panel", { attrs: { span: 15 } })
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          false
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "id",
                  prop: "id",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "编号",
              prop: "no",
              "show-overflow-tooltip": "",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.surveysType,
              label: "类型",
              prop: "userType",
              "show-overflow-tooltip": "",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.freeFormat,
              label: "显示",
              prop: "isShow",
              "show-overflow-tooltip": "",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "显示时间",
              prop: "startTime",
              "show-overflow-tooltip": "",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "结束时间",
              prop: "endTime",
              "show-overflow-tooltip": "",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "createdTime",
              "show-overflow-tooltip": "",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "show-overflow-tooltip": "",
              width: "350"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm.$perms("user_userinfo_edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(row)
                              }
                            }
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleTitleEdit(row)
                          }
                        }
                      },
                      [_vm._v(" 题目 ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
      _c("titles", { ref: "titles" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }