<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="3">
        <el-button
          v-if="$perms('user_userinfo_insert')"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
        <!-- <el-button
          v-if="$perms('setting_userinfo_batchdel')"
          icon="el-icon-delete"
          type="danger"
          @click="handleDelete"
        >
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="21">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userId"
              clearable
              placeholder="请输入用户ID"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.username"
              clearable
              placeholder="请输入用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.NickName"
              clearable
              placeholder="请输入昵称"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.phone"
              clearable
              placeholder="请输入手机"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userEmail"
              clearable
              placeholder="请输入邮箱"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.countryName"
              clearable
              placeholder="请输入国家"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-dialog title="发送系统消息" :visible.sync="dialogFormVisible">
      <el-form :model="sendForm">
        <el-form-item label="用户名:" :label-width="formLabelWidth">
          <span>{{ sendForm.username }}</span>
        </el-form-item>
        <el-form-item label="发送内容:" :label-width="formLabelWidth">
          <el-input
            v-model="sendForm.msg"
            autocomplete="off"
            :autosize="{ minRows: 6, maxRows: 20 }"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendSysMsg">确 定</el-button>
      </div>
    </el-dialog>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="userID"
        prop="userID"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="用户名"
        prop="username"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="昵称"
        prop="NickName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="角色"
        prop="role"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="手机"
        prop="phone"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="邮箱"
        prop="userEmail"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="国家"
        prop="countryName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="appID"
        prop="appBundleId"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="添加时间"
        prop="data_joined"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="最后登录"
        prop="last_login"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="350"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('user_userinfo_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$perms('user_userinfo_sendmsg')"
            type="primary"
            @click="handleSendMsg(row)"
          >
            发送系统消息
          </el-button>
          <el-button
            v-if="$perms('user_userinfo_del')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button
            v-if="$perms('user_userinfo_resetpsw')"
            type="danger"
            @click="resetPassword(row)"
          >
            重置密码
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { doDelete, getList, resetPassword } from '@/api/userInformation'
  import Edit from './components/UserInformation'
  const axios = require('axios')
  import network from '@/config/index.js' // 引用 baseURL

  export default {
    name: 'UserInformation',
    components: { Edit },
    data() {
      return {
        dialogFormVisible: false,
        formLabelWidth: '120px',
        sendForm: { username: '', userID: '', msg: '' },
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          username: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.userID) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleSendMsg(row) {
        if (row.userID) {
          this.sendForm.username = row.username
          this.sendForm.userID = row.userID
          this.dialogFormVisible = true
        }
      },
      sendSysMsg() {
        this.$confirm('是否发送消息给该用户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let url = network.baseURL + '/userManagement/sendSysMsgToUser'
            // 构建传给后端的数据
            this.formData = new FormData()
            this.formData.append('userID', this.sendForm.userID)
            this.formData.append('msg', this.sendForm.msg)
            axios.post(url, this.formData).then(
              function (res) {
                console.log('响应数据:', res.data)
                console.log(res.data.code)
                console.log(res.data.msg)
                if (res.data.code === 0) {
                  this.$message({
                    type: 'success',
                    message: '发送成功!',
                  })
                } else {
                  this.$message({
                    type: 'error',
                    message: '发送失败! ' + res.data.msg,
                  })
                }
              }.bind(this)
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消发送',
            })
          })
      },
      handleDelete(row) {
        if (row.userID) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await doDelete({ userID: row.userID })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await doDelete({ ids })
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
          }
        }
      },
      resetPassword(row) {
        if (row.userID) {
          this.$baseConfirm('你确定要重置该用户的密码吗', null, async () => {
            const { msg } = await resetPassword({ userID: row.userID })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
    },
  }
</script>
