<template>
  <div
    class="version-pcversion-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-right-panel :span="30">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.pcName"
              clearable
              placeholder="软件名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '项目类型'">
            {{ row[item.prop] | bundleVersionFilter }}
          </span>
          <span v-else-if="item.label === '测试版'">
            {{ row[item.prop] | pcTestFilter }}
          </span>
          <span v-else-if="item.label === '强制更新'">
            {{ row[item.prop] | isUpdateFilter }}
          </span>
          <span v-else-if="item.label === '是否开启版本'">
            {{ row[item.prop] | isOpenFilter }}
          </span>
          <span
            v-else-if="item.label === '添加时间' || item.label === '更新时间'"
          >
            {{ row[item.prop] | dateFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="150"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('version_pcversion_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$perms('version_pcversioninfo_del')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getPcInfoList, deletePcInfo } from '@/api/pcVersion'
  import VabDraggable from 'vuedraggable'
  const axios = require('axios')
  import network from '@/config/index.js' // 引用 baseURL
  import Edit from './components/PCversion'

  export default {
    name: 'PCversion',
    components: {
      Edit,
      VabDraggable,
    },
    filters: {
      bundleVersionFilter(value) {
        if (value === 0) {
          return 'Zosi'
        } else if (value === 149) {
          return 'COCOON HD'
        } else if (value === 150) {
          return 'Loocam'
        } else if (value === 151) {
          return '中性'
        } else if (value === 153) {
          return 'Ansjer'
        }
      },
      pcTestFilter(value) {
        if (value === 0) {
          return '否'
        } else {
          return '是'
        }
      },
      isUpdateFilter(value) {
        if (value === 0) {
          return '否'
        } else {
          return '是'
        }
      },
      isOpenFilter(value) {
        if (value === 0) {
          return '否'
        } else {
          return '是'
        }
      },
      dateFilter(value) {
        var date = new Date(value * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        return (
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':' +
          date.getSeconds()
        )
      },
    },
    data() {
      return {
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        deviceTypeList: ['IPC', 'DVR', 'NVR', 'XVR', 'OTHER'],
        langList: ['en', 'zh-Hans'],
        statusList: ['是', '否'],
        checkList: [
          'id',
          '软件名称',
          '项目类型',
          '版本号',
          '测试版',
          '语言类型',
          '文件类型',
          '整合包id',
          '更新内容',
          '强制更新',
          '是否开启版本',
          '内容信息',
          '权限',
          '文件路径',
          '添加时间',
          '更新时间',
        ],
        columns: [
          {
            label: 'id',
            width: 'auto',
            prop: 'id',
            sortable: true,
            disableCheck: false,
          },
          {
            label: '软件名称',
            width: 'auto',
            prop: 'pc_name',
            sortable: true,
          },
          {
            label: '项目类型',
            width: 'auto',
            prop: 'bundle_version',
            sortable: true,
          },
          {
            label: '版本号',
            width: 'auto',
            prop: 'pc_version',
            sortable: true,
          },
          {
            label: '测试版',
            width: 'auto',
            prop: 'pc_test',
            sortable: true,
          },
          {
            label: '语言类型',
            width: 'auto',
            prop: 'lang',
            sortable: true,
          },
          {
            label: '文件类型',
            width: 'auto',
            prop: 'file_type',
            sortable: true,
          },
          {
            label: '整合包id',
            width: 'auto',
            prop: 'package',
            sortable: true,
          },
          {
            label: '更新内容',
            width: 'auto',
            prop: 'explain',
            sortable: true,
          },
          {
            label: '强制更新',
            width: 'auto',
            prop: 'is_update',
            sortable: true,
          },
          {
            label: '是否开启版本',
            width: 'auto',
            prop: 'is_open',
            sortable: true,
          },
          {
            label: '内容信息',
            width: 'auto',
            prop: 'content',
            sortable: true,
          },
          {
            label: '权限',
            width: 'auto',
            prop: 'authority',
            sortable: true,
          },
          {
            label: '文件路径',
            width: 'auto',
            prop: 'download_link',
            sortable: true,
          },
          {
            label: '添加时间',
            width: '160',
            prop: 'add_time',
            sortable: true,
          },
          {
            label: '更新时间',
            width: '160',
            prop: 'update_time',
            sortable: true,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          mci: 'IPC',
          lang: 'en',
        },
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getPcInfoList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      handleTypeClick(tab, event) {
        console.log(tab, event)
        this.queryForm.mci = tab.label
        this.fetchData()
      },
      handleLangClick(tab, event) {
        console.log(tab, event)
        this.queryForm.lang = tab.label
        this.fetchData()
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deletePcInfo({ id: row.id })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      upLoadFile(file) {
        this.formData.append('file', file.file)
        this.formData.append(
          'fileSize',
          (file.file.size / 1024 / 1024).toFixed(2) + 'M'
        )
      },
      submitUpload() {
        this.$confirm('是否上传文件?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 构建传给后端的数据
            this.formData = new FormData()
            this.formData.append('mci', this.queryForm.mci)
            this.formData.append('lang', this.queryForm.lang)
            this.formData.append('ESN', this.queryForm.ESN)
            this.formData.append('max_ver', this.queryForm.max_ver)
            this.formData.append('channel', this.queryForm.channel)
            this.formData.append(
              'resolutionRatio',
              this.queryForm.resolutionRatio
            )
            this.formData.append('Description', this.queryForm.Description)
            let status = this.queryForm.status === '是' ? 1 : 0
            this.formData.append('status', status)
            this.$refs.upload.submit() // 会触发 http-request,即 upLoadFile 方法
            let url = network.baseURL + '/versionManagement/upLoadFile'
            console.log('url: ' + url)
            let headers = {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
            axios
              .post(url, this.formData, headers)
              .then(
                function (res) {
                  console.log(res)
                  if (res.data.code === 0) {
                    this.$message({
                      type: 'success',
                      message: '文件上传成功!',
                    })
                    this.fetchData()
                  } else {
                    this.$message({
                      type: 'error',
                      message: '文件上传失败! ' + res.data.msg,
                    })
                  }
                }.bind(this)
              )
              .catch((res) => {
                console.log(res)
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消上传',
            })
          })
      },
    },
  }
</script>
