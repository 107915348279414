<template>
  <div class="ai-package-language-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button
          v-if="$perms('ai_packagelang_insert')"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <!-- 选择查询的AI套餐ID -->
          <el-form-item label="AI套餐ID" prop="aiMealID">
            <el-select
              v-model="queryForm.aiMealID"
              clearable
              placeholder="请选择查询的AI套餐ID"
            >
              <el-option
                v-for="item in aiStoreMealList"
                :key="item.id"
                :label="item.id"
                :value="item.id"
              >
                <span style="float: left">{{ item.id }}</span>
                <span style="float: right; font-size: 12px; color: #8492a6">
                  {{ item.lang__title }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="AI套餐ID"
        prop="aiMealID"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="套餐语言ID"
        prop="langID"
        show-overflow-tooltip
        width="100"
      />
      <el-table-column
        align="center"
        label="套餐语言"
        prop="lang"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="套餐标题"
        prop="title"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="套餐描述"
        prop="content"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="优惠描述"
        prop="discountContent"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="150"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('ai_packagelang_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$perms('ai_packagelang_del')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    getAiMealLanguage,
    deleteAiMealLanguage,
  } from '@/api/aiPackageLanguage'
  import Edit from './components/AiPackageLanguage'
  import { getAiStoreMealList } from '@/api/aiPackageInformation'

  export default {
    name: 'AiPackageLanguage',
    components: { Edit },
    data() {
      return {
        list: [],
        aiStoreMealList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        selectValue: '',
        selectList: [
          {
            value: 'AI套餐ID',
            label: 'AI套餐ID',
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
      this.fetchStoreMealData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.aiMealID) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.aiMealID) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteAiMealLanguage({
              aiMealID: row.aiMealID,
              langID: row.langID,
            })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getAiMealLanguage(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      async fetchStoreMealData() {
        this.queryForm.isSelect = 'true'
        const {
          data: { list },
        } = await getAiStoreMealList(this.queryForm)
        this.aiStoreMealList = list
      },
    },
  }
</script>
