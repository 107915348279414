<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="套餐名称" prop="comboName">
        <el-input
          v-model.trim="form.comboName"
          clearable
          maxlength="30"
          placeholder="请输入套餐名称"
        />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="form.status" clearable placeholder="请选择状态">
          <el-option
            v-for="item in statusList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="套餐类型" prop="comboType">
        <el-select
          v-model="form.comboType"
          clearable
          placeholder="请选择套餐类型"
        >
          <el-option
            v-for="item in comboList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="流量总量值" prop="flowTotal">
        <el-input
          v-model.trim="form.flowTotal"
          clearable
          placeholder="请输入流量总量值，单位为MB"
        />
      </el-form-item>
      <el-form-item label="有效期天数" prop="expirationDays">
        <el-input
          v-model.trim="form.expirationDays"
          clearable
          placeholder="请输入有效期天数"
        />
      </el-form-item>
      <el-form-item label="有效期类型" prop="expirationType">
        <el-select
          v-model="form.expirationType"
          clearable
          placeholder="请选择有效期类型"
        >
          <el-option
            v-for="item in expirationTypeList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="付款类型" prop="payTypes">
        <el-select
          v-model="form.payTypes"
          clearable
          multiple
          placeholder="请选择付款类型(可多选)"
        >
          <el-option
            v-for="item in payTypeList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="价格" prop="price">
        <el-input
          v-model.trim="form.price"
          clearable
          placeholder="请输入价格"
          @change="checkValue()"
        />
      </el-form-item>
      <el-form-item label="原价格" prop="virtualPrice">
        <el-input
          v-model.trim="form.virtualPrice"
          clearable
          placeholder="请输入原价格"
          @change="checkVirtualPrice()"
        />
      </el-form-item>
      <el-form-item label="描述信息" prop="remark">
        <el-input
          v-model.trim="form.remark"
          clearable
          placeholder="请输入描述信息"
        />
      </el-form-item>
      <el-form-item label="是否显示" prop="isShow">
        <el-select v-model="form.isShow" clearable placeholder="请选择是否显示">
          <el-option
            v-for="item in isShowList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input
          v-model.trim="form.sort"
          clearable
          placeholder="请输入排序值"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save()">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { addOrEditCardMeal } from '@/api/card'

  export default {
    name: 'UserInformation',
    data() {
      return {
        statusList: [
          {
            value: 0,
            label: '开启',
          },
          {
            value: 1,
            label: '停用',
          },
        ],
        comboList: [
          {
            value: 0,
            label: '商用',
          },
          {
            value: 1,
            label: '初始化赠送',
          },
          {
            value: 2,
            label: '充值赠送',
          }
        ],
        expirationTypeList: [
          {
            value: 0,
            label: '天',
          },
          {
            value: 1,
            label: '月',
          },
          {
            value: 2,
            label: '年',
          },
        ],
        payTypeList: [
          {
            value: 1,
            label: 'PayPal',
          },
          {
            value: 2,
            label: '支付宝',
          },
          {
            value: 3,
            label: '微信支付',
          },
          {
            value: 10,
            label: '免费体验',
          },
          {
            value: 11,
            label: '激活码',
          },
        ],
        isShowList: [
          {
            value: 0,
            label: '不显示',
          },
          {
            value: 1,
            label: '显示',
          },
        ],

        form: {
          role: '',
          isEdit: '',
          comboID: '',
          Show: '',
          payType: '',
          price: '',
          virtualPrice: '',
        },
        rules: {
          status: [{ required: true, trigger: 'blur', message: '请选择状态' }],
          comboName: [
            { required: true, trigger: 'blur', message: '请输入套餐名称' },
          ],
          comboType: [
            { required: true, trigger: 'blur', message: '请输入套餐类型' },
          ],
          payTypes: [
            { required: true, trigger: 'blur', message: '请选择付款类型' },
          ],
          flowTotal: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入流量总量值',
            },
            {
              validator: (rule, value, callback) => {
                if (/^(?:[0-9]\d*)$/.test(value) == false) {
                  callback(new Error('请输入非负整数'))
                } else {
                  callback()
                }
              },
              trigger: 'change',
            },
          ],
          expirationDays: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入有限期天数',
            },
            {
              validator: (rule, value, callback) => {
                if (/^(?:[0-9]\d*)$/.test(value) == false) {
                  callback(new Error('请输入非负整数'))
                } else {
                  callback()
                }
              },
              trigger: 'change',
            },
          ],
          price: [{ required: true, trigger: 'blur', message: '请输入价格' }],
          virtualPrice: [{ required: true, trigger: 'blur', message: '请输入原价格' }],
          expirationType: [
            { required: true, trigger: 'blur', message: '请选择有限期类型' },
          ],
          isShow: [
            { required: true, trigger: 'blur', message: '请选择是否显示' },
          ],
          sort: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入排序值',
            },
            {
              validator: (rule, value, callback) => {
                if (/^(?:[0-9]\d*)$/.test(value) == false) {
                  callback(new Error('请输入非负整数'))
                } else {
                  callback()
                }
              },
              trigger: 'change',
            },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
          this.form.isEdit = true
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            console.log('payTypes:' + this.form.payTypes)
            console.log(typeof this.form.payTypes)
            if (null != this.form.payTypes && '' != this.form.payTypes) {
              // 将对象转为字符串
              this.form.payTypes = JSON.stringify(this.form.payTypes)
            }
            const { reason } = await addOrEditCardMeal(this.form)
            this.$baseMessage(reason, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      checkValue() {
        var value = '' + this.form.price.toString()
        value = value
          .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
          .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
          .replace(/^\./g, '') //保证第一个为数字而不是.
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
          .replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
        if (value.indexOf('.') < 0 && value != '') {
          // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          value = parseFloat(value)
        }
        this.form.price = value
      },
      checkVirtualPrice() {
        var value = '' + this.form.virtualPrice.toString()
        value = value
          .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
          .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
          .replace(/^\./g, '') //保证第一个为数字而不是.
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
          .replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
        if (value.indexOf('.') < 0 && value != '') {
          // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          value = parseFloat(value)
        }
        this.form.virtualPrice = value
      },
    },
  }
</script>
