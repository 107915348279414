import request from '@/utils/request'

export function getDeviceOrderList(params) {
  return request({
    url: '/serveManagement/getDeviceOrderList',
    method: 'get',
    params,
  })
}

export function doRefund(data) {
  return request({
    url: '/cloudstorage/doRefund',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/serveManagement/deleteDeviceOrder',
    method: 'post',
    data,
  })
}

export function doOutOfService(params) {
  return request({
    url: '/serveManagement/deactivationPackage',
    method: 'get',
    params,
  })
}

export function doEdit(params) {
  return request({
    url: '/serveManagement/editExpress',
    method: 'get',
    params,
  })
}

export function mealTransfer(data) {
  return request({
    url: '/serveManagement/mealTransfer',
    method: 'post',
    data,
  })
}

export function paypalOrderReconcile(data) {
  return request({
    url: 'https://www.dvema.com/serveManagement/paypalOrderReconcile',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    responseType: 'blob',
  })
}

export function wechatOrderReconcile(data) {
  return request({
    url: 'http://www.zositechc.cn/serveManagement/wechatOrderReconcile',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    responseType: 'blob',
  })
}

export function alipayOrderReconcile(data) {
  return request({
    url: 'http://www.zositechc.cn/serveManagement/alipayOrderReconcile',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    responseType: 'blob',
  })
}

export function getOperatingCosts(params) {
  return request({
    url: '/dataManagement/costsData/getOperatingCosts',
    method: 'get',
    params,
  })
}
