var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "echarts-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            [_c("time-picker", { on: { userTime: _vm.userTime } })],
            1
          ),
          _c(
            "el-col",
            [
              _c(
                "chart-line",
                {
                  attrs: { data: _vm.nowData, title: "新增趋势" },
                  on: { userType: _vm.userType }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.timeUnitChange },
                      model: {
                        value: _vm.radio,
                        callback: function($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio"
                      }
                    },
                    _vm._l(_vm.timeList, function(item) {
                      return _c("el-radio-button", {
                        key: item.label,
                        attrs: { disabled: item.disabled, label: item.label }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            [_c("chart-collection", { attrs: { data: _vm.nowData.region } })],
            1
          ),
          _c(
            "el-col",
            [
              _c("chart-table", {
                attrs: {
                  desc: "提供所有应用在核心指标上的明细数据",
                  "excel-data": _vm.excelData,
                  "table-columns": _vm.tableColumns2,
                  "table-data": _vm.newData,
                  title: "用户数据明细表"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            [
              _c("chart-Bar", {
                attrs: { data: _vm.regionData, title: "用户地区分布" },
                on: { regionType: _vm.regionType }
              })
            ],
            1
          ),
          _c(
            "el-col",
            [
              _c("chart-table", {
                attrs: {
                  "table-columns": _vm.tableColumns3,
                  "table-data": _vm.regionData,
                  title: "用户地区明细表"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }