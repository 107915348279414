<template>
  <div class="order">
    <el-row :gutter="20">
      <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <el-card class="order-card1" shadow="hover">
          <el-row>
            <vab-icon icon="shopping-bag-2-line" />
            新增用户
          </el-row>
          <el-row>
            <el-col :span="3">
              <p>昨日</p>
            </el-col>
            <el-col :span="3">
              <h1>
                <vab-count
                  :decimals="countConfig.decimals"
                  :duration="countConfig.duration"
                  :end-val="countConfig.endVal"
                  :prefix="countConfig.prefix"
                  :separator="countConfig.separator"
                  :start-val="countConfig.startVal"
                  :suffix="countConfig.suffix"
                />
              </h1>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <el-card class="order-card1" shadow="hover">
          <el-row>
            <vab-icon icon="shopping-bag-2-line" />
            活跃用户
          </el-row>
          <el-row>
            <el-col :span="3">
              <p>昨日</p>
            </el-col>
            <el-col :span="3">
              <h1>
                <vab-count
                  :decimals="countConfig.decimals"
                  :duration="countConfig.duration"
                  :end-val="countConfig.endVal"
                  :prefix="countConfig.prefix"
                  :separator="countConfig.separator"
                  :start-val="countConfig.startVal"
                  :suffix="countConfig.suffix"
                />
              </h1>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <el-card class="order-card1" shadow="hover">
          <el-row>
            <vab-icon icon="shopping-bag-2-line" />
            累计用户
          </el-row>
          <el-row>
            <el-col :span="3">
              <p>昨日</p>
            </el-col>
            <el-col :span="3">
              <h1>
                <vab-count
                  :decimals="countConfig.decimals"
                  :duration="countConfig.duration"
                  :end-val="countConfig.endVal"
                  :prefix="countConfig.prefix"
                  :separator="countConfig.separator"
                  :start-val="countConfig.startVal"
                  :suffix="countConfig.suffix"
                />
              </h1>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <el-card class="order-card1" shadow="hover">
          <el-row>
            <vab-icon icon="shopping-bag-2-line" />
            设备新增用户
          </el-row>
          <el-row>
            <el-col :span="3">
              <p>昨日</p>
            </el-col>
            <el-col :span="3">
              <h1>
                <vab-count
                  :decimals="countConfig.decimals"
                  :duration="countConfig.duration"
                  :end-val="countConfig.endVal"
                  :prefix="countConfig.prefix"
                  :separator="countConfig.separator"
                  :start-val="countConfig.startVal"
                  :suffix="countConfig.suffix"
                />
              </h1>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <el-card class="order-card1" shadow="hover">
          <el-row>
            <vab-icon icon="shopping-bag-2-line" />
            设备活跃用户
          </el-row>
          <el-row>
            <el-col :span="3">
              <p>昨日</p>
            </el-col>
            <el-col :span="3">
              <h1>
                <vab-count
                  :decimals="countConfig.decimals"
                  :duration="countConfig.duration"
                  :end-val="countConfig.endVal"
                  :prefix="countConfig.prefix"
                  :separator="countConfig.separator"
                  :start-val="countConfig.startVal"
                  :suffix="countConfig.suffix"
                />
              </h1>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="12" :sm="24" :xl="6" :xs="24">
        <el-card class="order-card1" shadow="hover">
          <el-row>
            <vab-icon icon="shopping-bag-2-line" />
            设备累计用户
          </el-row>
          <el-row>
            <el-col :span="3">
              <p>昨日</p>
            </el-col>
            <el-col :span="3">
              <h1>
                <vab-count
                  :decimals="countConfig.decimals"
                  :duration="countConfig.duration"
                  :end-val="countConfig.endVal"
                  :prefix="countConfig.prefix"
                  :separator="countConfig.separator"
                  :start-val="countConfig.startVal"
                  :suffix="countConfig.suffix"
                />
              </h1>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import VabCount from '@/extra/VabCount'
  import _ from 'lodash'

  export default {
    components: { VabCount },
    data() {
      return {
        countConfig: {
          startVal: 0,
          endVal: _.random(1000, 6000),
          decimals: 0,
          prefix: '',
          suffix: '',
          separator: ',',
          duration: 5000,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .order {
    &-card1 {
      height: 150px;
      background: linear-gradient(to right, #60b2fb, #6485f6);

      &-content {
        text-align: center;
      }

      ::v-deep {
        .el-card {
          &__header,
          &__body {
            color: $base-color-white !important;
          }
        }
      }
    }

    &-card2 {
      height: 490px;

      &-content {
        text-align: center;

        .order-chart {
          width: 100%;
          height: 345px;
        }
      }
    }

    margin-bottom: $base-margin;
  }
</style>
