<template>
  <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
    <el-card class="tabs-cards" shadow="hover">
      <el-row class="row-bg" justify="space-between" type="flex">
        <el-col>
          <el-button type="primary" @click="handleFold">
            <vab-icon
              class="vab-dropdown"
              :class="{ 'vab-dropdown-active': !fold }"
              icon="arrow-up-s-line"
            />
            服务类数据明细表
          </el-button>
          <el-tooltip placement="top">
            <div slot="content">提供所有应用在核心指标上的明细数据</div>
            <vab-icon icon="information-line" style="color: #409eff" />
          </el-tooltip>
        </el-col>
        <download-excel
          :before-finish="finishDownload"
          :before-generate="startDownload"
          class="export-excel-wrapper"
          :data="queryData"
          :fields="json_fields"
          :header="title"
          name="服务类数据明细表.xls"
        >
          <el-button
            icon="el-icon-menu"
            :loading="buttonLoading"
            type="success"
          >
            导出
          </el-button>
        </download-excel>
      </el-row>
      <el-tabs>
        <el-table v-loading="loading" :data="queryData" :height="height">
          <el-table-column
            :formatter="timeFormat"
            label="日期"
            prop="startTime"
          />
          <el-table-column label="付费订单" prop="payOrder" />
          <el-table-column label="免费套餐订单" prop="freeComboOrder" />
          <el-table-column label="新增首次付费" prop="newPayOrder" />
          <el-table-column label="复购订单" prop="repurchaseOrder" />
        </el-table>
      </el-tabs>
    </el-card>
  </el-col>
</template>

<script>
  import {
    getPayOrder,
    getFreeOrder,
    getRepeatPayOrder,
    getFirstPayOrder,
  } from '@/api/serviceData'

  export default {
    components: {},
    data() {
      return {
        loading: true,
        buttonLoading: false,
        title: '订单数据',
        json_fields: {
          日期: {
            field: 'startTime',
            callback: (value) => {
              return this.timesFormat(value)
            },
          },
          付费订单: 'payOrder',
          免费套餐订单: 'freeComboOrder',
          新增首次付费: 'newPayOrder',
          复购订单: 'repurchaseOrder',
        },
        fold: false,
        height: '260',
        activeName: 'first',
        queryData: [],
        timeForm: {
          startTime: '',
          endTime: '',
          timeUnit: 'day',
          storeMealType: 0,
        },
        firstData: [],
        secondData: [],
        thirdData: [],
        fourthData: [],
        queryForm: {
          tableData: [],
          fileName: '新增用户统计',
        },
      }
    },
    created() {
      this.getNowDate()
      this.getData()
      this.newPayOrderData()
      this.repurchaseOrderData()
      this.freeComboOrderData()
      this.payOrderData()
    },
    methods: {
      async payOrderData() {
        const {
          result: { orders },
        } = await getPayOrder(this.timeForm)
        let getData = []
        for (let i in orders) {
          let obj = new Object()
          obj.startTime = orders[i].startTime
          obj.payOrder = orders[i].count
          getData[i] = obj
        }
        this.firstData = getData
        this.mixData()
      },
      async freeComboOrderData() {
        const {
          result: { orders },
        } = await getFreeOrder(this.timeForm)
        let getData = []
        for (let i in orders) {
          let obj = new Object()
          obj.freeComboOrder = orders[i].count
          getData[i] = obj
        }
        this.secondData = getData
      },
      async newPayOrderData() {
        const {
          result: { orders },
        } = await getFirstPayOrder(this.timeForm)
        let getData = []
        for (let i in orders) {
          let obj = new Object()
          obj.newPayOrder = orders[i].count
          getData[i] = obj
        }
        this.thirdData = getData
      },
      async repurchaseOrderData() {
        const {
          result: { orders },
        } = await getRepeatPayOrder(this.timeForm)
        let getData = []
        for (var i in orders) {
          let obj = new Object()
          obj.repurchaseOrder = orders[i].count
          getData[i] = obj
        }
        this.fourthData = getData
      },
      mixData() {
        let getData = this.firstData
        let getDataSe = this.secondData
        let getDataTh = this.thirdData
        let getDataFo = this.fourthData
        let newData = []
        for (var i in getData) {
          let obj = new Object()
          obj.startTime = getData[i].startTime
          obj.payOrder = getData[i].payOrder
          obj.freeComboOrder = getDataSe[i].freeComboOrder
          obj.newPayOrder = getDataTh[i].newPayOrder
          obj.repurchaseOrder = getDataFo[i].repurchaseOrder
          newData[i] = obj
        }
        this.queryData = newData
        this.loading = false
      },
      handleFold() {
        this.fold = !this.fold
        if (this.fold) {
          this.height = 520
        } else {
          this.height = 260
        }
      },
      getData() {
        this.$root.eventVueService.$on('data-F', (message) => {
          this.timeForm.startTime = message.startTime
          this.timeForm.endTime = message.endTime
          this.timeForm.timeUnit = message.timeUnit
          this.timeForm.storeMealType = message.storeMealType
          this.newPayOrderData()
          this.repurchaseOrderData()
          this.freeComboOrderData()
          this.payOrderData()
        })
      },
      getNowDate() {
        let myDate = new Date()
        let year = myDate.getFullYear() //获取当前年
        let mon = myDate.getMonth() + 1 //获取当前月
        if (mon < 10) {
          mon = '0' + mon
        }
        let date = myDate.getDate() //获取当前日
        if (date < 10) {
          date = '0' + date
        }
        let now =
          year + '-' + mon + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00'
        let nowAny = ''
        nowAny = Date.parse(now)
        this.timeForm.endTime = nowAny / 1000
        this.timeForm.startTime = this.timeForm.endTime - 1 * 60 * 60 * 24 * 7
      },
      startDownload() {
        this.buttonLoading = true
      },
      finishDownload() {
        this.buttonLoading = false
      },
      timeFormat(row) {
        if (row.startTime === 0) {
          return 0
        } else {
          let date = new Date(row.startTime * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
      timesFormat(val) {
        if (val === 0) {
          return 0
        } else {
          let date = new Date(val * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-table {
      overflow: auto;
      &::before {
        height: 0px !important;
      }
    }
  }
</style>
