<template>
  <div class="tabs">
    <el-card class="tabs-card" shadow="hover">
      <el-tabs v-model="activeName">
        <el-table v-loading="loading" :data="tableData" height="280">
          <el-table-column label="设备名" prop="typeName" />
          <el-table-column label="设备量（百分比）" prop="count,typeRate">
            <template slot-scope="scope">
              {{ scope.row.count }} ({{ scope.row.typeRate }}%)
            </template>
          </el-table-column>
        </el-table>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        loading: true,
        fold: false,
        activeName: 'first',
        tableData: [],
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.$root.eventVueService.$on('data-D', (message) => {
          this.tableData = message
          this.loading = false
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tabs {
    &-card {
      height: 334px;
    }
  }
</style>
