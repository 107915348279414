var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "version-apprecord-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 8 } },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.handleTypeClick },
                  model: {
                    value: _vm.queryForm.app_type,
                    callback: function($$v) {
                      _vm.$set(_vm.queryForm, "app_type", $$v)
                    },
                    expression: "queryForm.app_type"
                  }
                },
                _vm._l(_vm.appTypeList, function(item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item }
                  })
                }),
                1
              ),
              _c("Edit", { ref: "edit" }),
              _c("span", { staticStyle: { "margin-left": "10px" } }),
              _vm.$perms("version_apprecord_insert")
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleEdit }
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: { border: "", data: _vm.app_record_list },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "appID",
              prop: "app_id__appBundleId",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "app名称",
              prop: "app_id__appName",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.appTypeFormat,
              label: "app类型",
              prop: "app_id__app_type",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "系统语言",
              prop: "newApp_version_list",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticStyle: { "margin-left": "10px" } }, [
                      _vm._v(
                        " " + _vm._s(scope.row.newApp_version_list[0][0]) + " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "系统版本",
              prop: "newApp_version_list",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "系统版本" },
                        on: {
                          change: function($event) {
                            return _vm.fetchData(
                              scope.row.app_id__appBundleId,
                              scope.row.version
                            )
                          }
                        },
                        model: {
                          value: scope.row.version,
                          callback: function($$v) {
                            _vm.$set(scope.row, "version", $$v)
                          },
                          expression: "scope.row.version"
                        }
                      },
                      _vm._l(scope.row.newApp_version_list, function(item) {
                        return _c("el-option", {
                          key: item[0] + item[1],
                          attrs: { value: item[0] + item[1] }
                        })
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "更新内容",
              prop: "content",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: {
                        autosize: { minRows: 2, maxRows: 4 },
                        type: "textarea"
                      },
                      model: {
                        value: scope.row.content,
                        callback: function($$v) {
                          _vm.$set(scope.row, "content", $$v)
                        },
                        expression: "scope.row.content"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "更新时间",
              prop: "version_time",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "show-overflow-tooltip": "",
              width: "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm.$perms("version_apprecord_edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(row)
                              }
                            }
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _vm._e(),
                    _vm.$perms("version_apprecord_del")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(row)
                              }
                            }
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }