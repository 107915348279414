var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "100px", model: _vm.form, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "有效天数", prop: "effective_day" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入有效天数" },
                model: {
                  value: _vm.form.effective_day,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "effective_day",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.effective_day"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "价格", prop: "price" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入价格" },
                model: {
                  value: _vm.form.price,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "price",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.price"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "虚拟价格", prop: "virtual_price" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入虚拟价格" },
                model: {
                  value: _vm.form.virtual_price,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "virtual_price",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.virtual_price"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货币", prop: "currency" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择货币" },
                  on: { change: _vm.currencyChange },
                  model: {
                    value: _vm.form.currency,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "currency", $$v)
                    },
                    expression: "form.currency"
                  }
                },
                _vm._l(_vm.currencyList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货币符号", prop: "symbol" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.symbol,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "symbol",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.symbol"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "支付类型", prop: "pay_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    placeholder: "请选择支付类型(可多选)"
                  },
                  model: {
                    value: _vm.form.pay_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "pay_type", $$v)
                    },
                    expression: "form.pay_type"
                  }
                },
                _vm._l(_vm.payTypeList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否优惠", prop: "is_discounts" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择是否优惠" },
                  model: {
                    value: _vm.form.is_discounts,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "is_discounts", $$v)
                    },
                    expression: "form.is_discounts"
                  }
                },
                _vm._l(_vm.isDiscountsList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "优惠价格", prop: "discount_price" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入优惠价格" },
                model: {
                  value: _vm.form.discount_price,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "discount_price",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.discount_price"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示", prop: "is_show" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择是否显示" },
                  model: {
                    value: _vm.form.is_show,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "is_show", $$v)
                    },
                    expression: "form.is_show"
                  }
                },
                _vm._l(_vm.isShowList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }