<template>
  <div
    class="user-management-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.UID"
              clearable
              placeholder="设备UID"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.serialNumber"
              clearable
              maxlength="9"
              placeholder="序列号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.version"
              clearable
              placeholder="设备版本"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.NickName"
              clearable
              placeholder="设备名称"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.Type"
              clearable
              placeholder="设备类型"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.username"
              clearable
              placeholder="用户名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '云存状态'">
            {{ row.fields[item.prop] | statusFilter }}
          </span>
          <span v-else-if="item.label === 'AI开启状态'">
            {{ row.fields[item.prop] | statusFilter }}
          </span>
          <span v-else>{{ row.fields[item.prop] }}</span>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
      <el-table-column align="center" fixed="right" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="primary" @click="openDetail(row)">详情</el-button>
          <el-button
            v-if="$perms('device_deviceinformation_delete')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-dropdown trigger="click">
            <el-button style="margin-left: 10px" type="primary">
              更多
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="padding: 0">
                <el-button
                  v-if="$perms('device_deviceinformation_resetprimaryuser')"
                  style="width: 100%; background: none; border: 0"
                  @click="resetPrimaryUser(row)"
                >
                  重置主用户
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item style="padding: 0">
                <el-button
                  v-if="$perms('device_deviceinformation_resetcloudvod')"
                  style="width: 100%; background: none; border: 0"
                  @click="resetVod(row)"
                >
                  重置云存
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item style="padding: 0">
                <el-button
                  v-if="$perms('device_deviceinformation_resetai')"
                  style="width: 100%; background: none; border: 0"
                  @click="resetAi(row)"
                >
                  重置AI
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item style="padding: 0">
                <el-button
                  v-if="$perms('device_deviceinformation_resetall')"
                  style="width: 100%; background: none; border: 0"
                  @click="resetAll(row)"
                >
                  全部重置
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <detail ref="detail" />
  </div>
</template>

<script>
  import {
    getDeviceInfoList,
    deleteDevice,
    resetPrimaryUser,
    resetVod,
    resetAi,
    resetAll,
    getUidPush,
  } from '@/api/device'
  import Detail from './components/detail'
  import VabDraggable from 'vuedraggable'

  export default {
    name: 'DeviceInformation',
    components: {
      Detail,
      VabDraggable,
    },
    filters: {
      statusFilter(value) {
        if (value === 0) {
          return '关闭'
        } else if (value === 1) {
          return '开启'
        }
      },
    },
    data() {
      return {
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        checkList: [
          '设备UID',
          '序列号',
          '设备名称',
          '设备密码',
          '用户名称',
          'userID',
          '主用户',
          '设备类型',
          '通道数',
          '支持Alexa',
          '支持AI',
          'AI开启状态',
          '支持云存',
          '云存状态',
          '移动侦测',
          '人形追踪',
          'ip',
          '设备版本',
          '添加时间',
          '更新时间',
        ],
        columns: [
          {
            label: '设备UID',
            width: '210',
            prop: 'UID',
            sortable: false,
          },
          {
            label: '序列号',
            width: 'auto',
            prop: 'serial_number',
            sortable: false,
          },
          {
            label: '设备名称',
            width: 'auto',
            prop: 'NickName',
            sortable: false,
            disableCheck: false,
          },
          {
            label: '设备密码',
            width: 'auto',
            prop: 'View_Password',
            sortable: false,
          },
          {
            label: '用户名称',
            width: 'auto',
            prop: 'username',
            sortable: false,
          },
          {
            label: 'userID',
            width: 'auto',
            prop: 'userID',
            sortable: false,
          },
          {
            label: '主用户',
            width: 'auto',
            prop: 'vodPrimaryMaster',
            sortable: false,
          },
          {
            label: '设备类型',
            width: 'auto',
            prop: 'Type',
            sortable: false,
          },
          {
            label: '通道数',
            width: 'auto',
            prop: 'ChannelIndex',
            sortable: false,
          },
          {
            label: '支持Alexa',
            width: '85',
            prop: 'isAlexa',
            sortable: false,
          },
          {
            label: '支持AI',
            width: '85',
            prop: 'isAI',
            sortable: false,
          },
          {
            label: 'AI开启状态',
            width: '95',
            prop: 'ai_status',
            sortable: false,
          },
          {
            label: '支持云存',
            width: '85',
            prop: 'cloudVod',
            sortable: false,
          },
          {
            label: '云存状态',
            width: '85',
            prop: 'status',
            sortable: false,
          },
          {
            label: '移动侦测',
            width: '85',
            prop: 'iSNotification',
            sortable: false,
          },
          {
            label: '人形追踪',
            width: '85',
            prop: 'isHuman',
            sortable: false,
          },
          {
            label: 'ip',
            width: 'auto',
            prop: 'ip',
            sortable: false,
          },
          {
            label: '设备版本',
            width: 'auto',
            prop: 'version',
            sortable: false,
          },
          {
            label: '添加时间',
            width: 'auto',
            prop: 'data_joined',
            sortable: false,
          },
          {
            label: '更新时间',
            width: 'auto',
            prop: 'update_time',
            sortable: false,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getDeviceInfoList(this.queryForm)
        this.list = list.datas
        this.total = total
        this.listLoading = false
      },
      async openDetail(row) {
        const queryForm = {}
        queryForm.UID = row.fields.UID
        const { data } = await getUidPush(queryForm)
        this.$refs['detail'].showDetails(data)
      },
      handleDelete(row) {
        if (row.pk) {
          this.$baseConfirm('确定要删除当前项吗', null, async () => {
            const { msg } = await deleteDevice({
              userID: row.fields.userID,
              deviceID: row.pk,
              uid: row.fields.UID,
            })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      resetPrimaryUser(row) {
        console.log('重置主用户uid: ' + row.fields.UID)
        if (row.fields.UID) {
          this.$baseConfirm('确定要重置主用户吗', null, async () => {
            const { msg } = await resetPrimaryUser({ uid: row.fields.UID })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
          })
        }
      },
      resetVod(row) {
        console.log('重置云存uid: ' + row.fields.UID)
        if (row.fields.UID) {
          this.$baseConfirm('确定要重置云存吗', null, async () => {
            const { msg } = await resetVod({ uid: row.fields.UID })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
          })
        }
      },
      resetAi(row) {
        console.log('重置AI uid: ' + row.fields.UID)
        if (row.fields.UID) {
          this.$baseConfirm('确定要重置AI吗', null, async () => {
            const { msg } = await resetAi({ uid: row.fields.UID })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
          })
        }
      },
      resetAll(row) {
        console.log('全部重置 uid: ' + row.fields.UID)
        if (row.fields.UID) {
          this.$baseConfirm('确定要全部重置吗', null, async () => {
            const { msg } = await resetAll({ uid: row.fields.UID })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
          })
        }
      },
    },
  }
</script>
