<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="设备规格名称" prop="ESN">
        <el-input v-model.trim="form.ESN" clearable />
      </el-form-item>
      <el-form-item label="设备版本" prop="version">
        <el-input v-model.trim="form.version" disabled />
      </el-form-item>
      <el-form-item label="最大版本" prop="max_ver">
        <el-input v-model.trim="form.max_ver" clearable />
      </el-form-item>
      <el-form-item label="是否启用" prop="status">
        <el-select v-model="form.status" placeholder="请选择是否启用">
          <el-option
            v-for="item in statusList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="通道数" prop="channel">
        <el-input v-model.trim="form.channel" clearable />
      </el-form-item>
      <el-form-item label="分辨率" prop="resolutionRatio">
        <el-input v-model.trim="form.resolutionRatio" clearable />
      </el-form-item>
      <el-form-item label="描述" prop="Description">
        <el-input v-model.trim="form.Description" clearable />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { editVersionInformation } from '@/api/newVersionInformation'

  export default {
    name: 'NewVersionInformation',
    data() {
      return {
        queryForm: { isSelect: 'true' },
        statusList: [
          {
            label: '是',
            value: true,
          },
          {
            label: '否',
            value: false,
          },
        ],
        form: {},
        rules: {
          max_ver: [
            { required: true, trigger: 'blur', message: '请输入最大版本' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    methods: {
      showEdit(row) {
        this.title = '编辑'
        this.form = Object.assign({}, row)
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await editVersionInformation(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>
