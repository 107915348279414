import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/unicom/manage/getExchangeCodePage',
    method: 'get',
    params,
  })
}

export function getFlowComboList(params) {
  return request({
    url: '/unicom/manage/getFlowComboList',
    method: 'get',
    params,
  })
}

export function updateCode(data) {
  return request({
    url: '/unicom/manage/updateExchangeCode',
    method: 'post',
    data,
  })
}

export function addCode(data) {
  return request({
    url: '/unicom/manage/batchGenerateCDK',
    method: 'post',
    data,
  })
}

export function downloadCDK(params) {
  return request({
    url: '/unicom/manage/downloadCDK',
    method: 'get',
    params,
    responseType: 'blob',
  })
}
