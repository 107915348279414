import request from '@/utils/request'

export function getEquipmentVersionList(params) {
  return request({
    url: '/versionManagement/getEquipmentVersionList',
    method: 'get',
    params,
  })
}

export function editVersionInformation(data) {
  return request({
    url: '/versionManagement/editVersionInformation',
    method: 'post',
    data,
  })
}

export function deleteEquipmentVersion(data) {
  return request({
    url: '/versionManagement/deleteEquipmentVersion',
    method: 'post',
    data,
  })
}
