import request from '@/utils/request'

export function getCdkList(params) {
  return request({
    url: '/serveManagement/getCdkList',
    method: 'get',
    params,
  })
}

export function doEdit(data) {
  return request({
    url: '/serveManagement/createCdk',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/serveManagement/deleteCdk',
    method: 'post',
    data,
  })
}

export function down(params) {
  return request({
    url: '/serveManagement/downloadCDK',
    method: 'get',
    params,
  }).then((res) => {
    const fileName = '激活码'
    const _res = res
    let blob = new Blob([_res])
    let downloadElement = document.createElement('a')
    let href = window.URL.createObjectURL(blob) //创建下载的链接
    downloadElement.href = href
    downloadElement.download = fileName //下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() //点击下载
    document.body.removeChild(downloadElement) //下载完成移除元素
    window.URL.revokeObjectURL(href) //释放掉blob对象
  })
}

export function getVodStoreMeal(params) {
  return request({
    url: '/serveManagement/getVodStoreMeal',
    method: 'get',
    params,
  })
}
