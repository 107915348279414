/* eslint-disable no-const-assign */
<template>
  <div>
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="uid"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="设备UID"
        prop="uid"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备通道"
        prop="channel"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="云存储桶名称"
        prop="bucket__bucket"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="dateFormat"
        label="到期时间"
        prop="endTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="云存储的天数"
        prop="bucket__storeDay"
        show-overflow-tooltip
      />

      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="160"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('serve_devicePackage_initexp')"
            type="warning"
            @click="handleInitexp(row)"
          >
            重置云存体验套餐
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { experiencereset, getDeviceOrderList } from '@/api/devicePackage'

  export default {
    name: 'DevicePackage',
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          username: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleInitexp(row) {
        if (row.id) {
          this.$baseConfirm('你确定要重置当前项吗', null, async () => {
            const { msg } = await experiencereset({ id: row.id })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getDeviceOrderList(this.queryForm)
        console.log(list)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      dateFormat(row) {
        var date = new Date(row.endTime * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        return (
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':' +
          date.getSeconds()
        )
      },
    },
  }
</script>
