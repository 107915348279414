<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="13" style="float: right">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-select
              v-model="queryForm.vpg_id"
              clearable
              placeholder="vpg id"
              style="width: 100px"
            >
              <el-option
                v-for="item in vpgList"
                :key="item.id"
                :label="item.id"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="请输入uid"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.serialNumber"
              clearable
              placeholder="请输入序列号"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="使用状态"
              style="width: 100px"
            >
              <el-option
                v-for="item in statusOps"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.p2pType"
              clearable
              placeholder="类型"
              style="width: 100px"
            >
              <el-option
                v-for="item in p2pTypeOps"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="15" />
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-if="false"
        align="center"
        label="id"
        prop="id"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="vpg id"
        prop="vpg_id"
        show-overflow-tooltip
        width="65"
      />
      <el-table-column
        align="center"
        label="uid"
        prop="uid"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        label="序列号"
        prop="serial"
        show-overflow-tooltip
        sortable
        width="90"
      />
      <el-table-column
        align="center"
        :formatter="getStatus"
        label="使用状态"
        prop="status"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        :formatter="getP2PType"
        label="p2p类型"
        prop="p2pType"
        show-overflow-tooltip
        width="90"
      />
      <el-table-column
        align="center"
        label="平台"
        prop="platform"
        show-overflow-tooltip
        width="90"
      />
      <el-table-column
        align="center"
        :formatter="getAreaType"
        label="区域"
        prop="area"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="完整uid"
        prop="fullUidCode"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备初始化字符"
        prop="init_string"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="app初始化字符"
        prop="init_string_app"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="createdTime"
        label="添加时间"
        prop="addTime"
        show-overflow-tooltip
        sortable
      />
      <el-table-column
        align="center"
        :formatter="updateTime"
        label="更新时间"
        prop="updateTime"
        show-overflow-tooltip
        sortable
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <titles ref="titles" />
  </div>
</template>

<script>
  import { uidPage } from '@/api/uid'
  import { vpgPage } from '@/api/vpg'
  import { formatDate } from '@/utils/formatDate'

  export default {
    name: 'SurveysInformation',
    data() {
      return {
        radio: '1',
        dialogFormVisible: false,
        formLabelWidth: '120px',
        sendForm: { username: '', userID: '', msg: '' },
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        statusOps: [
          { name: '未使用', value: 0 },
          { name: '已使用', value: 2 },
        ],
        p2pTypeOps: [
          { name: '尚云', value: 1 },
          { name: 'TUTK', value: 2 },
        ],
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          status: '',
          serialNumber: '',
          useStatus: '',
          uid: '',
          p2pType: '',
        },
      }
    },
    created() {
      this.fetchData()
      this.fetchVpgData()
    },
    methods: {
      handleTitleEdit(row) {
        console.log(row)
        if (row.id) {
          this.$refs['titles'].showEdit(row)
        } else {
          this.$refs['titles'].showEdit()
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        this.queryForm.page = this.queryForm.pageNo
        this.queryForm.limit = this.queryForm.pageSize
        const {
          data: { list, total },
        } = await uidPage(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      async fetchVpgData() {
        this.queryForm.isSelect = 'true'
        const {
          data: { list },
        } = await vpgPage(this.queryForm)
        this.vpgList = list
      },
      getStatus(row) {
        if (row.status === 0) {
          return '未使用'
        } else if (row.status === 2) {
          return '已使用'
        } else {
          return row.status
        }
      },
      getP2PType(row) {
        if (row.p2pType === 1) {
          return '尚云'
        } else {
          return 'tutk'
        }
      },
      getAreaType(row) {
        if (row.area === 0) {
          return '国内'
        } else {
          return '国外'
        }
      },
      createdTime(row) {
        let date = row.addTime * 1000
        return formatDate(new Date(date), 'yyyy-MM-dd hh:mm:ss')
      },
      updateTime(row) {
        let date = row.updateTime * 1000
        return formatDate(new Date(date), 'yyyy-MM-dd hh:mm:ss')
      },
    },
  }
</script>
