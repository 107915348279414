import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/userManagement/getDeviceSuperPassword',
    method: 'get',
    params,
  })
}

export function checkPass(params) {
  return request({
    url: '/userManagement/customerServiceManagement',
    method: 'get',
    params,
  })
}

export function deleteInformation(params) {
  return request({
    url: '/userManagement/deleteInformation',
    method: 'get',
    params,
  })
}
