var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { staticStyle: { float: "right" }, attrs: { span: 13 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100px" },
                          attrs: { clearable: "", placeholder: "vpg id" },
                          model: {
                            value: _vm.queryForm.vpg_id,
                            callback: function($$v) {
                              _vm.$set(_vm.queryForm, "vpg_id", $$v)
                            },
                            expression: "queryForm.vpg_id"
                          }
                        },
                        _vm._l(_vm.vpgList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.id, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入uid" },
                        model: {
                          value: _vm.queryForm.uid,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "uid",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.uid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入序列号" },
                        model: {
                          value: _vm.queryForm.serialNumber,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "serialNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.serialNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100px" },
                          attrs: { clearable: "", placeholder: "使用状态" },
                          model: {
                            value: _vm.queryForm.status,
                            callback: function($$v) {
                              _vm.$set(_vm.queryForm, "status", $$v)
                            },
                            expression: "queryForm.status"
                          }
                        },
                        _vm._l(_vm.statusOps, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100px" },
                          attrs: { clearable: "", placeholder: "类型" },
                          model: {
                            value: _vm.queryForm.p2pType,
                            callback: function($$v) {
                              _vm.$set(_vm.queryForm, "p2pType", $$v)
                            },
                            expression: "queryForm.p2pType"
                          }
                        },
                        _vm._l(_vm.p2pTypeOps, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.queryData }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("vab-query-form-right-panel", { attrs: { span: 15 } })
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          false
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "id",
                  prop: "id",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var $index = ref.$index
                  return [_vm._v(" " + _vm._s($index + 1) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "vpg id",
              prop: "vpg_id",
              "show-overflow-tooltip": "",
              width: "65"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "uid",
              prop: "uid",
              "show-overflow-tooltip": "",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序列号",
              prop: "serial",
              "show-overflow-tooltip": "",
              sortable: "",
              width: "90"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.getStatus,
              label: "使用状态",
              prop: "status",
              "show-overflow-tooltip": "",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.getP2PType,
              label: "p2p类型",
              prop: "p2pType",
              "show-overflow-tooltip": "",
              width: "90"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "平台",
              prop: "platform",
              "show-overflow-tooltip": "",
              width: "90"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.getAreaType,
              label: "区域",
              prop: "area",
              "show-overflow-tooltip": "",
              width: "80"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "完整uid",
              prop: "fullUidCode",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "设备初始化字符",
              prop: "init_string",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "app初始化字符",
              prop: "init_string_app",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.createdTime,
              label: "添加时间",
              prop: "addTime",
              "show-overflow-tooltip": "",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.updateTime,
              label: "更新时间",
              prop: "updateTime",
              "show-overflow-tooltip": "",
              sortable: ""
            }
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
      _c("titles", { ref: "titles" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }