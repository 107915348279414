import request from '@/utils/request'

export function getDeviceInfoList(params) {
  return request({
    url: '/deviceManagement/getDeviceInfoList',
    method: 'get',
    params,
  })
}

export function deleteDevice(params) {
  return request({
    url: '/deviceManagement/deleteDevice',
    method: 'get',
    params,
  })
}

export function resetPrimaryUser(params) {
  return request({
    url: '/deviceManagement/resetPrimaryUser',
    method: 'get',
    params,
  })
}

export function resetVod(params) {
  return request({
    url: '/deviceManagement/resetVod',
    method: 'get',
    params,
  })
}

export function resetAi(params) {
  return request({
    url: '/deviceManagement/resetAi',
    method: 'get',
    params,
  })
}

export function getDeviceTypeList(params) {
  return request({
    url: '/deviceManagement/getDeviceTypeList',
    method: 'get',
    params,
  })
}

export function deleteDeviceType(params) {
  return request({
    url: '/deviceManagement/deleteDeviceType',
    method: 'get',
    params,
  })
}

export function getUidSetList(params) {
  return request({
    url: '/deviceManagement/getUidSetList',
    method: 'get',
    params,
  })
}

export function resetAll(params) {
  return request({
    url: '/deviceManagement/resetAll',
    method: 'get',
    params,
  })
}

export function getUidPush(data) {
  return request({
    url: '/deviceManagement/getUidPush',
    method: 'post',
    data,
  })
}

export function checkDeviceInfo(data) {
  return request({
    url: '/deviceManagement/checkDeviceInfo',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function getCustomerDeviceList(params) {
  return request({
    url: '/deviceManagement/getCustomerDeviceList',
    method: 'get',
    params,
  })
}
