<template>
  <div class="dataPicker">
    <el-date-picker
      v-model="timeRange"
      align="center"
      end-placeholder="结束日期"
      range-separator="~"
      start-placeholder="开始日期"
      type="daterange"
      value-format="timestamp"
      @change="postData"
    />
  </div>
</template>

<script>
  export default {
    name: '',
    components: {},
    data() {
      return {
        timeRange: [],
      }
    },
    created() {
      this.getNowDate()
      this.postData()
    },
    methods: {
      postData() {
        this.$emit('time', this.timeRange)
      },
      getNowDate() {
        let myDate = new Date()
        let year = myDate.getFullYear() //获取当前年
        let mon = myDate.getMonth() + 1 //获取当前月
        if (mon < 10) {
          mon = '0' + mon
        }
        let date = myDate.getDate() //获取当前日
        if (date < 10) {
          date = '0' + date
        }
        let now =
          year + '-' + mon + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00'
        let nowAny = ''
        nowAny = Date.parse(now)
        this.timeRange[1] = nowAny
        this.timeRange[0] = nowAny - 1000 * 60 * 60 * 24 * 7
      },
    },
  }
</script>
