<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button
          v-if="$perms('iot_redemptioncode_insert')"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.code"
              clearable
              placeholder="兑换码"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.isDown"
              clearable
              placeholder="是否下载"
            >
              <el-option key="未下载" label="未下载" value="0" />
              <el-option key="已下载" label="已下载" value="1" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="fetchData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="$perms('iot_redemptioncode_download')"
              icon="el-icon-download"
              type="primary"
              @click="handleDownload"
            >
              下载
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="兑换码"
        prop="code"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="状态"
        prop="status"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="isDownFormat"
        label="是否下载"
        prop="isDown"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="typeFormat"
        label="套餐类型"
        prop="packageType"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="套餐名称"
        prop="comboName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="创建时间"
        prop="createdTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="更新时间"
        prop="updatedTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="过期时间"
        prop="expireTime"
        show-overflow-tooltip
      />
      <el-table-column
        v-if="$perms('iot_redemptioncode_edit')"
        align="center"
        label="操作"
        show-overflow-tooltip
        width="150"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('iot_redemptioncode_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { downloadCDK, getList } from '@/api/redemptionCode'
  import Edit from './components/redemptionCode'

  export default {
    name: 'RedemptionCode',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        selectValue: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      typeFormat(row) {
        if (row.packageType === 0) {
          return '联通'
        } else if (row.packageType === 1) {
          return '五兴'
        } else {
          return '其他'
        }
      },
      statusFormat(row) {
        if (row.status === false) {
          return '未使用'
        } else if (row.status === true) {
          return '已使用'
        }
      },
      isDownFormat(row) {
        if (row.isDown === false) {
          return '未下载'
        } else if (row.isDown === true) {
          return '已下载'
        }
      },
      timeFormat(row, column) {
        let data = row[column.property]
        if (data === 0) {
          return 'N/A'
        } else {
          let date = new Date(data * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds()
          )
        }
      },
      async fetchData() {
        this.listLoading = true
        const {
          result: { data, total },
        } = await getList(this.queryForm)
        this.list = data
        this.total = total
        this.listLoading = false
      },
      async handleDownload() {
        await downloadCDK()
          .then((re) => {
            if (re.type == 'text/html') {
              this.handlerResponseError(re)
            } else {
              const blob = new Blob([re], {
                type: 'application/vnd.ms-excel;charset=utf-8',
              })
              let downloadUrl = window.URL.createObjectURL(blob)
              let a = document.createElement('a')
              a.href = downloadUrl
              a.setAttribute('download', '兑换码.xlsx')
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              this.$baseMessage(
                '下载成功',
                'success',
                'vab-hey-message-success'
              )
              this.fetchData()
            }
          })
          .catch((re) => {
            console.log(re)
          })
      },
      handlerResponseError(data) {
        const _this = this
        const fileReader = new FileReader()
        fileReader.onload = function () {
          const jsonData = JSON.parse(fileReader.result)
          _this.$baseMessage(jsonData.reason, 'warning')
        }
        fileReader.readAsText(data)
      },
    },
  }
</script>
