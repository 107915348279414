<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="旧序列号">{{ form.serialNo }}</el-form-item>
      <el-form-item label="新序列号" prop="targetSerialNumber">
        <el-input
          v-model.trim="form.targetSerialNumber"
          clearable
          maxlength="9"
          placeholder="请输入新序列号"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save()">提 交</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { transferDevicePackage } from '@/api/deviceCardPackage'
  export default {
    name: 'PackageTransfer',
    data() {
      const validateTargetSerialNumber = (rule, value, callback) => {
        console.log(value)
        if (value === undefined || value === null) {
          callback(new Error('请输入新序列号'))
        } else if (value.length === 9) {
          callback()
        } else {
          callback(new Error('请输入九位序列号'))
        }
      }
      return {
        form: {},
        rules: {
          targetSerialNumber: [
            {
              required: true,
              trigger: 'blur',
              validator: validateTargetSerialNumber,
            },
          ],
        },
        title: '套餐转移',
        dialogFormVisible: false,
        queryForm: {},
      }
    },
    methods: {
      showComponent(row) {
        this.dialogFormVisible = true
        this.form = row
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let obj = new Object()
            obj.orderId = this.form.orderID
            obj.targetSerialNumber = this.form.targetSerialNumber
            const { reason } = await transferDevicePackage(obj)
            this.$baseMessage(reason, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>
