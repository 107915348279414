<template>
  <div class="activity-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="4">
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          新增
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        v-for="item in tableColumns"
        :key="item.prop"
        align="center"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.toopltip"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '轮播图'">
            <el-image
              fit="contain"
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop] + '?' + Date.now()"
            />
          </span>
          <span v-else-if="item.label === '详情图'">
            <el-image
              fit="contain"
              :preview-src-list="[row[item.prop]]"
              :src="row[item.prop] + '?' + Date.now()"
            />
          </span>
          <span v-else-if="item.label === '活动流程'">
            <el-steps :active="row[item.prop].length" align-center>
              <el-step
                v-for="(step, index) in row[item.prop]"
                :key="index"
                :description="computedDesc(step.start_time, step.end_time)"
                :title="step.node_content"
              />
            </el-steps>
          </span>
          <span v-else-if="item.label === '是否展示'">
            {{ row[item.prop] | typeFilter(showStatusList) }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="180">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">编辑</el-button>
          <el-button type="primary" @click="handleDetail(row)">
            报名详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.line"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <Details ref="details" />
  </div>
</template>

<script>
  import { getActivityList } from '@/api/newProductExperience'
  import { formatDate } from '@/utils/formatDate'
  import Edit from './components/addOrEditComponent'
  import Details from './components/detailsComponent'

  export default {
    name: 'ActivityManage',
    components: {
      Edit,
      Details,
    },
    filters: {
      typeFilter(value, list) {
        const option = list.find((item) => item.value === value)
        if (option) {
          return option.label
        }
        return value
      },
    },
    data() {
      return {
        tableColumns: [
          {
            label: '活动名称',
            prop: 'activity_name',
            toopltip: true,
          },
          {
            label: '活动期数',
            prop: 'issue',
            width: '100',
            toopltip: true,
          },
          {
            label: '产品名',
            prop: 'product_name',
            toopltip: true,
          },
          {
            label: '物品数量',
            prop: 'product_number',
            width: '100',
            toopltip: true,
          },
          {
            label: '物品原价',
            prop: 'original_price',
            width: '100',
            toopltip: true,
          },
          {
            label: '轮播图',
            prop: 'carousel_image_url',
            width: '100',
          },
          {
            label: '详情图',
            prop: 'details_image_url',
            width: '100',
          },
          {
            label: '活动流程',
            prop: 'activity_process',
            width: '500',
          },
          {
            label: '是否展示',
            prop: 'is_show',
            width: '100',
          },
        ],
        showStatusList: [
          {
            value: 0,
            label: '不展示',
          },
          {
            value: 1,
            label: '展示',
          },
        ],
        list: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          page: 1,
          line: 10,
        },
      }
    },
    computed: {
      computedDesc() {
        return (start, end) => {
          const startDate = new Date(start * 1000)
          const endDate = new Date(end * 1000)

          if (startDate.getDay() === endDate.getDay()) {
            return formatDate(startDate, 'MM-dd')
          } else {
            if (startDate.getMonth() === endDate.getMonth()) {
              return (
                formatDate(startDate, 'M月d') + '-' + formatDate(endDate, 'd日')
              )
            } else {
              return (
                formatDate(startDate, 'M月d日') +
                '-' +
                formatDate(endDate, 'M月d日')
              )
            }
          }
        }
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleSizeChange(val) {
        this.queryForm.line = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, count },
        } = await getActivityList(this.queryForm)
        this.list = list
        this.total = count
        this.listLoading = false
      },
      handleEdit(row) {
        this.$refs['edit'].showComponent(row)
      },
      handleAdd() {
        this.$refs['edit'].showComponent()
      },
      handleDetail(row) {
        this.$refs['details'].showComponent(row.id)
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-step__title {
    font-size: 14px;
    line-height: 28px;
  }
  ::v-deep .el-step__description {
    font-size: 10px;
    padding: 0 !important;
  }
</style>
