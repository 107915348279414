import request from '@/utils/request'

export function getStoreMealList(params) {
  return request({
    url: '/serveManagement/getStoreMealList',
    method: 'get',
    params,
  })
}

export function addOrEditStoreMeal(data) {
  return request({
    url: '/serveManagement/addOrEditStoreMeal',
    method: 'post',
    data,
  })
}

export function deleteStoreMeal(data) {
  return request({
    url: '/serveManagement/deleteStoreMeal',
    method: 'post',
    data,
  })
}
