<template>
  <div>
    <el-row :gutter="20">
      <el-col>
        <el-card class="order-card1" shadow="hover">
          <el-row style="font-size: 20px; font-weight: 600">
            <vab-icon
              icon="user-add-line"
              style="font-size: 30px; font-weight: 500"
            />
            平台新增设备数
            <el-tooltip placement="top">
              <div slot="content">一个新UID或者新序列号首次报到服务器，<br/>即视为增长设备，注意序列号的使用状态</div>
              <vab-icon
                icon="information-line"
              />
            </el-tooltip>
          </el-row>
          <el-row
            style="
              font-size: 17px;
              font-weight: 500;
              float: right;
              margin-right: 100px;
            "
          >
            <p>
              昨日
              <vab-count
                :decimals="countConfig.decimals"
                :duration="countConfig.duration"
                :end-val="countConfig.endVal"
                :prefix="countConfig.prefix"
                :separator="countConfig.separator"
                :start-val="countConfig.startVal"
                style="font-size: 30px; font-weight: 600"
                :suffix="countConfig.suffix"
              />
            </p>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import VabCount from '@/extra/VabCount'

  export default {
    components: { VabCount },
    data() {
      return {
        countConfig: {
          startVal: 0,
          endVal: 0,
          decimals: 0,
          prefix: '',
          suffix: '',
          separator: ',',
          duration: 2500,
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.$root.eventVueOv.$on('data-D', (message) => {
          this.countConfig.endVal = message
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .order {
    &-card1 {
      height: 150px;
      background: linear-gradient(to right, #60b2fb, #6485f6);

      &-content {
        text-align: center;
      }

      ::v-deep {
        .el-card {
          &__header,
          &__body {
            color: $base-color-white !important;
          }
        }
      }
    }

    &-card2 {
      height: 490px;

      &-content {
        text-align: center;

        .order-chart {
          width: 100%;
          height: 345px;
        }
      }
    }

    margin-bottom: $base-margin;
  }
</style>
