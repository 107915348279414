var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        top: "3vh",
        visible: _vm.dialogFormVisible,
        width: "500px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "110px", model: _vm.form, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "存储桶名称", prop: "bucket" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择存储桶" },
                  model: {
                    value: _vm.form.bucket,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "bucket", $$v)
                    },
                    expression: "form.bucket"
                  }
                },
                _vm._l(_vm.bucketList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.bucket, value: item.bucket }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "天数", prop: "day" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入天数" },
                model: {
                  value: _vm.form.day,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "day",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.day"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "有效期(月)", prop: "expire" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择有效期" },
                  model: {
                    value: _vm.form.expire,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "expire", $$v)
                    },
                    expression: "form.expire"
                  }
                },
                _vm._l(_vm.expireList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "类型", prop: "commodity_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择类型" },
                  model: {
                    value: _vm.form.commodity_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "commodity_type", $$v)
                    },
                    expression: "form.commodity_type"
                  }
                },
                _vm._l(_vm.commodityTypeList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "支付类型", prop: "pay_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    placeholder: "请选择支付类型(可多选)"
                  },
                  model: {
                    value: _vm.form.pay_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "pay_type", $$v)
                    },
                    expression: "form.pay_type"
                  }
                },
                _vm._l(_vm.payTypeList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "规格码", prop: "commodity_code" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入规格码" },
                model: {
                  value: _vm.form.commodity_code,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "commodity_code",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.commodity_code"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否优惠", prop: "is_discounts" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择是否优惠" },
                  model: {
                    value: _vm.form.is_discounts,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "is_discounts", $$v)
                    },
                    expression: "form.is_discounts"
                  }
                },
                _vm._l(_vm.isDiscountsList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "优惠价格", prop: "discount_price" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入优惠价格" },
                model: {
                  value: _vm.form.discount_price,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "discount_price",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.discount_price"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "虚拟金额", prop: "virtual_price" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入虚拟金额" },
                model: {
                  value: _vm.form.virtual_price,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "virtual_price",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.virtual_price"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "价格", prop: "price" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入价格" },
                model: {
                  value: _vm.form.price,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "price",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.price"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货币", prop: "currency" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择货币" },
                  on: { change: _vm.currencyChange },
                  model: {
                    value: _vm.form.currency,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "currency", $$v)
                    },
                    expression: "form.currency"
                  }
                },
                _vm._l(_vm.currencyList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货币符号", prop: "symbol" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.symbol,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "symbol",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.symbol"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否隐藏", prop: "is_show" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择是否隐藏" },
                  model: {
                    value: _vm.form.is_show,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "is_show", $$v)
                    },
                    expression: "form.is_show"
                  }
                },
                _vm._l(_vm.isShowList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否支持AI", prop: "is_ai" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择是否支持AI" },
                  model: {
                    value: _vm.form.is_ai,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "is_ai", $$v)
                    },
                    expression: "form.is_ai"
                  }
                },
                _vm._l(_vm.isAiList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "像素等级", prop: "pixel_level" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择像素等级" },
                  model: {
                    value: _vm.form.pixel_level,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "pixel_level", $$v)
                    },
                    expression: "form.pixel_level"
                  }
                },
                _vm._l(_vm.pixelLevelList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "苹果内购商品id", prop: "product_id" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入苹果内购商品id" },
                model: {
                  value: _vm.form.product_id,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "product_id",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.product_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "内购订阅组", prop: "subscription_group" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内购订阅组" },
                model: {
                  value: _vm.form.subscription_group,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "subscription_group",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.subscription_group"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "内购订阅组id", prop: "subscription_group_id" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内购订阅组id" },
                model: {
                  value: _vm.form.subscription_group_id,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "subscription_group_id",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.subscription_group_id"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订阅类型", prop: "package_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择订阅类型" },
                  model: {
                    value: _vm.form.package_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "package_type", $$v)
                    },
                    expression: "form.package_type"
                  }
                },
                _vm._l(_vm.packageTypeList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }