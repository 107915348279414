<template>
  <div class="hello">
    <h1>el-tree基本功能</h1>
    <el-tree
      ref="tree"
      check-strictly
      :data="treeData"
      :default-checked-keys="[1]"
      default-expand-all
      node-key="id"
      :props="defaultProps"
      show-checkbox
      @check-change="handleCheckChange"
      @node-click="handleNodeClick"
    />
    <div class="btn">
      <el-button size="mini" type="primary" @click="getChecked">
        获取选中
      </el-button>
      <el-button size="mini" type="primary" @click="setChecked">
        设置选中
      </el-button>
      <el-button size="mini" type="primary" @click="selectAllNodes">
        全选
      </el-button>
      <el-button size="mini" type="primary" @click="clearAllNodes">
        清空
      </el-button>
      <el-button size="mini" type="warning" @click="handleSelectAll">
        全选/清空
      </el-button>
      <el-select v-model="value" size="mini" @change="selectChange">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Hello',
    data() {
      return {
        defaultProps: {
          children: 'childList',
          label: 'label',
        },
        treeData: [
          {
            id: 1,
            label: '一级 1',
            childList: [
              {
                id: 4,
                label: '二级 1-1',
                childList: [
                  {
                    id: 9,
                    label: '三级 1-1-1',
                  },
                  {
                    id: 10,
                    label: '三级 1-1-2',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            label: '一级 2',
            childList: [
              {
                id: 5,
                label: '二级 2-1',
              },
              {
                id: 6,
                label: '二级 2-2',
              },
            ],
          },
          {
            id: 3,
            label: '一级 3',
            childList: [
              {
                id: 7,
                label: '二级 3-1',
              },
              {
                id: 8,
                label: '二级 3-2',
              },
            ],
          },
        ],
        options: [
          {
            value: '1',
            label: '展开所有',
          },
          {
            value: '2',
            label: '收合所有',
          },
        ],
        value: '展开所有',
        checkAll: false,
      }
    },
    methods: {
      handleNodeClick(data) {
        console.log(data)
        this.$refs.tree.getCheckedKeys()
      },
      handleCheckChange(data, checked, indeterminate) {
        console.log(data, checked, indeterminate)
      },
      //获取选中
      getChecked() {
        // 1.通过node获取
        console.log('通过node获取', this.$refs.tree.getCheckedNodes())
        // 2.通过key获取
        console.log('通过key获取', this.$refs.tree.getCheckedKeys())
      },
      // 设置选中
      setChecked() {
        // 1.通过node设置
        this.$refs.tree.setCheckedNodes([
          { id: 2, label: '一级 2' },
          {
            id: 7,
            label: '二级 3-1',
          },
        ])
        // 2.通过key设置
        // this.$refs.tree.setCheckedKeys([5, 8]);
      },
      // 全选
      selectAllNodes: function () {
        //  获取根节点
        let rootNode = this.$refs.tree.getNode(this.treeData[0].id).parent
        travelNodes(rootNode)
        function travelNodes(tmpRoot) {
          // 选中该节点
          tmpRoot.checked = true
          // 叶子节点
          if (tmpRoot.childNodes.length === 0) {
            return
          }
          // 不是叶子节点,递归遍历
          else {
            let tmpChildNoes = tmpRoot.childNodes
            for (let i = 0; i < tmpChildNoes.length; i++) {
              travelNodes(tmpChildNoes[i])
            }
          }
        }
      },
      // 清空
      clearAllNodes: function () {
        this.$refs.tree.setCheckedKeys([])
      },
      // 全选/清空
      //当el-tree设置check-strictly时，通过setCheckedNodes全选方法失效，父子不关联，只能选中一级父节点
      handleSelectAll() {
        if (this.checkAll == false) {
          this.$refs.tree.setCheckedNodes(this.treeData)
          // this.$refs.tree.setCheckedKeys([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
          this.checkAll = true
        } else {
          // this.$refs.tree.setCheckedNodes([]);
          this.$refs.tree.setCheckedKeys([])
          this.checkAll = false
        }
      },
      // 展开收合
      selectChange(value) {
        if (value == 1) {
          // 展开所有
          for (let i = 0; i < this.treeData.length; i++) {
            this.$refs.tree.store.nodesMap[this.treeData[i].id].expanded = true
          }
        } else if (value == 2) {
          // 收合所有
          for (let i = 0; i < this.treeData.length; i++) {
            this.$refs.tree.store.nodesMap[this.treeData[i].id].expanded = false
          }
        }
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hello {
    width: 600px;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    border: 1px solid rgb(209, 203, 203);
    border-radius: 6px;
  }
  .btn {
    margin-top: 20px;
  }
  .el-select {
    width: 120px;
    margin-left: 10px;
  }
</style>
