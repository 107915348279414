import request from '@/utils/request'

export function getStoreMealLanguage(params) {
  return request({
    url: '/serveManagement/getStoreMealLanguage',
    method: 'get',
    params,
  })
}

export function addOrEditStoreMealLanguage(data) {
  return request({
    url: '/serveManagement/addOrEditStoreMealLanguage',
    method: 'post',
    data,
  })
}

export function deleteStoreMealLanguage(data) {
  return request({
    url: '/serveManagement/deleteStoreMealLanguage',
    method: 'post',
    data,
  })
}
