<template>
  <el-select
    v-model="selectedArr"
    clearable
    :disabled="disabled"
    filterable
    :loading="mulSelectLoading"
    multiple
    placeholder="请选择设备型号"
    @change="changeSelect"
  >
    <el-checkbox v-model="checked" @change="selectAll">全选</el-checkbox>
    <el-option
      v-for="item in selectOptions"
      :key="item"
      :label="item"
      :value="item"
    />
  </el-select>
</template>

<script>
  export default {
    props: {
      // 选项
      selectOptions: {
        type: Array,
        default() {
          return []
        },
      },
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false,
      },
      // 已选中选项
      mulSelecteds: {
        type: Array,
        default() {
          return []
        },
      },
      mulSelectLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        checked: false,
        selectedArr: [],
      }
    },
    watch: {
      mulSelecteds: {
        handler(newVal) {
          this.selectedArr = newVal
          if (
            this.selectedArr.length &&
            this.selectedArr.length === this.selectOptions.length
          ) {
            this.checked = true
          } else {
            this.checked = false
          }
        },
        immediate: true,
      },
    },
    methods: {
      selectAll() {
        this.selectedArr = []
        if (this.checked) {
          this.selectOptions.forEach((item) => {
            this.selectedArr.push(item)
          })
        } else {
          this.selectedArr = []
        }
        this.$emit('updateMultipleSelect', this.selectedArr)
      },
      changeSelect(val) {
        if (val.length === this.selectOptions.length) {
          this.checked = true
        } else {
          this.checked = false
        }
        this.$emit('updateMultipleSelect', this.selectedArr)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-checkbox {
    text-align: right;
    width: 90%;
  }
</style>
