import request from '@/utils/request'

export function getCardCombo(params) {
  return request({
    url: 'unicom/manage/query-order',
    method: 'get',
    params,
  })
}

export function getFlowPackages(data) {
  return request({
    url: 'unicom/manage/getFlowPackages',
    method: 'post',
    data,
  })
}

export function transferDevicePackage(data) {
  return request({
    url: 'unicom/manage/transferDevicePackage',
    method: 'post',
    data,
  })
}
