/* eslint-disable no-const-assign */
<template>
  <div
    class="user-management-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="UID"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.orderID"
              clearable
              placeholder="订单id"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userID__username"
              clearable
              placeholder="用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userID__userEmail"
              clearable
              placeholder="商品描述"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.payType"
              clearable
              placeholder="支付方式"
            >
              <el-option
                v-for="option in payTypeOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="支付状态"
            >
              <el-option
                v-for="option in statusOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.orderType"
              clearable
              placeholder="订单类型"
            >
              <el-option
                v-for="option in orderTypeOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.tradeNo"
              clearable
              placeholder="第三方交易号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.serialNumber"
              clearable
              placeholder="序列号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userID__phone"
              clearable
              placeholder="手机号"
            />
          </el-form-item>
          <el-form-item>
            <div class="block">
              <span>添加时间:</span>
              <el-date-picker
                v-model="queryForm.timeRange"
                align="center"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="timestamp"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-upload
              ref="upload"
              action=""
              :auto-upload="false"
              class="upload-file"
              :http-request="upLoadFile"
            >
              <el-button
                slot="trigger"
                size="small"
                type="success"
                @click="selectFile"
              >
                选取excel文件
              </el-button>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button
              :disabled="btnDisabled"
              icon="el-icon-menu"
              type="success"
              @click="submitForm"
            >
              自动对账
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-dialog title="退款" :visible.sync="dialogFormVisible">
      <el-form :model="sendForm">
        <el-form-item label="用户名:" :label-width="formLabelWidth">
          <span>{{ sendForm.username }}</span>
        </el-form-item>
        <el-form-item label="订单金额:" :label-width="formLabelWidth">
          <span>{{ sendForm.price }}</span>
        </el-form-item>
        <el-form-item label="已退款金额:" :label-width="formLabelWidth">
          <span>{{ sendForm.refunded_amount }}</span>
        </el-form-item>
        <el-form-item label="退款金额:">
          <el-input
            v-model="sendForm.refund_amount"
            clearable
            placeholder="请输入退款金额"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doRefund">确 定</el-button>
      </div>
    </el-dialog>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '支付方式'">
            {{ row[item.prop] | typeFilter(payTypeOptions) }}
          </span>
          <span v-else-if="item.label === '支付状态'">
            {{ row[item.prop] | typeFilter(statusOptions) }}
          </span>
          <span
            v-else-if="
              (item.label === '订单创建时间') |
                (item.label === '订单状态更新时间') |
                (item.label === '订单付款时间')
            "
          >
            {{ row[item.prop] | dateFilter }}
          </span>
          <span v-else-if="item.label === '订单类型'">
            {{ row[item.prop] | typeFilter(orderTypeOptions) }}
          </span>
          <span v-else-if="item.label === '订单号'">
            {{ row[item.prop] | tradeNoFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="300"
      >
        <template #default="{ row }">
          <div>
            <el-row style="display: flex">
              <el-col :span="6">
                <el-button
                  v-if="$perms('serve_dviceorder_refund')"
                  v-show="row.refund_status === 1 && row.payType != 10"
                  type="primary"
                  @click="handleRefund(row)"
                >
                  退款
                </el-button>
              </el-col>
              <el-col :span="6">
                <el-button
                  v-if="$perms('serve_dviceorder_closed')"
                  v-show="
                    (row.status === 1 && row.user_status === 1) ||
                    (row.status === 5 && row.user_status === 1) ||
                    (row.status === 6 && row.user_status === 1)
                  "
                  type="success"
                  @click="handleClosed(row)"
                >
                  停用
                </el-button>
              </el-col>
              <el-col :span="6">
                <el-button
                  v-if="$perms('serve_dviceorder_delete')"
                  type="danger"
                  @click="handleDelete(row)"
                >
                  删除
                </el-button>
              </el-col>
              <el-col :span="7">
                <el-dropdown trigger="click">
                  <el-button type="primary">
                    更多
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item style="padding: 0">
                      <el-button
                        v-if="$perms('serve_dviceorder_refund')"
                        v-show="row.payType === 11"
                        style="width: 100%; background: none; border: 0"
                        @click="handleEdit(row)"
                      >
                        修改订单号
                      </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item style="padding: 0">
                      <el-button
                        v-if="$perms('serve_dviceorder_transfer')"
                        style="width: 100%; background: none; border: 0"
                        @click="handleTransfer(row)"
                      >
                        云存套餐转移
                      </el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <transfer ref="transfer" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    getDeviceOrderList,
    doDelete,
    doOutOfService,
  } from '@/api/deviceOrder'
  import VabDraggable from 'vuedraggable'
  const axios = require('axios')
  import network from '@/config' // 引用 baseURL.
  import Edit from './components/deviceOrder'
  import Transfer from './components/packageTransfer'
  import { formatDate } from '@/utils/formatDate'

  export default {
    name: 'DeviceOrder',
    components: {
      VabDraggable,
      Edit,
      Transfer,
    },
    filters: {
      typeFilter(value, list) {
        const option = list.find((item) => item.value === value)
        if (option) {
          return option.label
        }
        return value
      },
      dateFilter(value) {
        if (value === 'N/A' || !value) {
          return 'N/A'
        }
        return formatDate(new Date(value * 1000), 'yyyy-MM-dd hh:mm:ss')
      },
      tradeNoFilter(value) {
        if (value === '') {
          return 'N/A'
        } else {
          return value
        }
      },
    },
    data() {
      return {
        formLabelWidth: '80px',
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        btnDisabled: true,
        listLoading: true,
        dialogFormVisible: false,
        sendForm: {
          username: '',
          orderID: '',
          price: '',
          refund_amount: '',
          refunded_amount: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        checkList: [
          '订单ID',
          '设备UID',
          '序列号',
          'PayPal交易号',
          '用户名',
          '手机号',
          '账号昵称',
          '国家',
          '通道',
          '商品描述',
          '支付方式',
          '价格',
          '支付状态',
          '订单号',
          '已退金额',
          '订单付款时间',
          '订单创建时间',
          '订单状态更新时间',
          '订单类型',
          '备注',
        ],
        columns: [
          {
            label: '订单ID',
            width: '200',
            prop: 'orderID',
            sortable: true,
          },
          {
            label: '设备UID',
            width: '220',
            prop: 'UID',
            sortable: true,
            disableCheck: true,
          },
          {
            label: '序列号',
            width: '150',
            prop: 'serialNumber',
            sortable: true,
            disableCheck: true,
          },
          {
            label: 'PayPal交易号',
            width: '150',
            prop: 'trade_no',
            sortable: true,
          },
          {
            label: '用户名',
            width: '200',
            prop: 'userID__username',
            sortable: true,
          },
          {
            label: '手机号',
            width: '130',
            prop: 'userID__phone',
            sortable: true,
          },
          {
            label: '账号昵称',
            width: '80',
            prop: 'userID__NickName',
            sortable: true,
          },
          {
            label: '国家',
            width: '80',
            prop: 'country',
            sortable: true,
          },
          {
            label: '通道',
            width: '50',
            prop: 'channel',
            sortable: false,
          },
          {
            label: '商品描述',
            width: 'auto',
            prop: 'desc',
            sortable: true,
          },
          {
            label: '支付方式',
            width: 'auto',
            prop: 'payType',
            sortable: true,
          },
          {
            label: '价格',
            width: 'auto',
            prop: 'price',
            sortable: true,
          },
          {
            label: '支付状态',
            width: 'auto',
            prop: 'status',
            sortable: true,
          },
          {
            label: '订单号',
            width: 'auto',
            prop: 'trade_no',
            sortable: true,
          },
          {
            label: '已退金额',
            width: 'auto',
            prop: 'refunded_amount',
            sortable: true,
          },
          {
            label: '订单付款时间',
            width: '180',
            prop: 'payTime',
            sortable: true,
          },
          {
            label: '订单创建时间',
            width: '180',
            prop: 'addTime',
            sortable: true,
          },
          {
            label: '订单状态更新时间',
            width: '180',
            prop: 'updTime',
            sortable: true,
          },
          {
            label: '订单类型',
            width: '100',
            prop: 'order_type',
            sortable: true,
          },
          {
            label: '备注',
            width: '100',
            prop: 'express_id',
            sortable: true,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          username: '',
        },
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        payTypeOptions: [
          { value: 1, label: 'PayPal' },
          { value: 2, label: '支付宝' },
          { value: 3, label: '微信支付' },
          { value: 10, label: '免费体验' },
          { value: 11, label: '激活码' },
        ],
        statusOptions: [
          { value: 0, label: '待支付' },
          { value: 1, label: '付款成功' },
          { value: 2, label: '取消付款' },
          { value: 4, label: '退款失败' },
          { value: 5, label: '全额退款' },
          { value: 6, label: '部分退款' },
          { value: 7, label: 'PayPal已退款' },
          { value: 9, label: '处理中' },
          { value: 10, label: '付款失败' },
        ],
        orderTypeOptions: [
          { value: 0, label: '云存' },
          { value: 1, label: '云存+AI' },
          { value: 2, label: '联通4G' },
          { value: 3, label: '电信4G' },
          { value: 5, label: '鼎芯4G' },
        ],
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      selectFile() {
        this.btnDisabled = false
      },
      upLoadFile(file) {
        this.formData.append('file', file.file)
      },
      submitForm() {
        // 构建传给后端的数据
        this.formData = new FormData()
        this.$refs.upload.submit() // 会触发 http-request,即 upLoadFile 方法
        let url = network.baseURL + '/serveManagement/vodOrderReconcile'
        console.log('url: ' + url)
        axios({
          method: 'post',
          url: url,
          data: this.formData,
          responseType: 'blob',
        })
          .then((res) => {
            console.log('res:', res)
            var blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel;charset=utf-8',
            })
            console.log(blob)
            var downloadUrl = window.URL.createObjectURL(blob)
            var anchor = document.createElement('a')
            //anchor.style.display = "none";
            anchor.href = downloadUrl
            // 这里的filename 带有后缀，能决定文件的类型
            anchor.setAttribute('download', '对账结果.xlsx')
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
          })
          .catch((res) => {
            console.log(res)
          })
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleTransfer(row) {
        this.$refs['transfer'].showComponent(row)
      },
      handleRefund(row) {
        if (row.orderID) {
          this.sendForm.username = row.userID__username
          this.sendForm.price = row.price
          this.sendForm.refunded_amount = row.refunded_amount
          this.sendForm.orderID = row.orderID
          this.dialogFormVisible = true
        }
      },
      doRefund() {
        this.$confirm('确定退款给该用户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 输入不能为空
            if (this.sendForm.refund_amount === '') {
              this.$message({
                type: 'error',
                message: '请输入退款金额!',
              })
              return
            }

            let url = network.baseURL + '/cloudstorage/doRefund'
            // 构建传给后端的数据
            this.formData = new FormData()
            this.formData.append('orderID', this.sendForm.orderID)
            this.formData.append('refund_amount', this.sendForm.refund_amount)
            axios.post(url, this.formData).then(
              function (res) {
                console.log('响应数据:', res.data)
                console.log(res.data.result_code)
                console.log(res.data.reason)
                if (res.data.result_code === 0) {
                  this.$message({
                    type: 'success',
                    message: '退款成功!',
                  })
                } else {
                  this.$message({
                    type: 'error',
                    message: '退款失败! ' + res.data.reason,
                  })
                }
              }.bind(this)
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消操作',
            })
          })
      },
      handleClosed(row) {
        if (row.orderID) {
          this.$baseConfirm('你确定要停用当前项吗', null, async () => {
            const { msg } = await doOutOfService({
              status: row.status,
              uid: row.UID,
              orderID: row.orderID,
              orderType: row.order_type,
              payType: row.payType,
              userName: row.userID__username,
            })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleDelete(row) {
        if (row.orderID) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await doDelete({ orderID: row.orderID })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getDeviceOrderList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
    },
  }
</script>
