var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "100px", model: _vm.form, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "序列号", prop: "file" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload",
                  attrs: {
                    accept: ".txt",
                    action: "string",
                    "auto-upload": false,
                    "http-request": _vm.upLoadFile,
                    limit: 1,
                    "on-change": _vm.onChange,
                    "on-exceed": _vm.handleExceed,
                    "on-remove": _vm.handleRemove
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "upload_button",
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary"
                      },
                      slot: "trigger"
                    },
                    [_vm._v(" 选择txt文件 ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "套餐", prop: "packageId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择套餐" },
                  model: {
                    value: _vm.form.packageId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "packageId", $$v)
                    },
                    expression: "form.packageId"
                  }
                },
                _vm._l(_vm.comboList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.combo_name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }