var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "tabs-cards", attrs: { shadow: "hover" } },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { border: "", data: _vm.queryData }
        },
        [
          _c("el-table-column", { attrs: { label: "设备uid", prop: "uid" } }),
          _c("el-table-column", {
            attrs: { label: "上传时长", prop: "uploadDuration" }
          }),
          _c("el-table-column", {
            attrs: { label: "上传次数", prop: "uploadFrequency" }
          }),
          _c("el-table-column", {
            attrs: { label: "播放次数", prop: "playFrequency" }
          }),
          _c("el-table-column", {
            attrs: { label: "播放时长", prop: "playDuration" }
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }