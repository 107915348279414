import request from '@/utils/request'

export function getSysMessageList(params) {
  return request({
    url: '/userManagement/getSysMessageList',
    method: 'get',
    params,
  })
}

export function getAppDataList(params) {
  return request({
    url: '/userManagement/getAppDataList',
    method: 'get',
    params,
  })
}

export function sendSysMessage(data) {
  return request({
    url: '/userManagement/sendSysMessage',
    method: 'post',
    data,
  })
}

export function doEdit(data) {
  return request({
    url: '/userManagement/editAccount',
    method: 'post',
    data,
  })
}

export function deleteFeedBack(data) {
  return request({
    url: '/userManagement/deleteFeedBack',
    method: 'post',
    data,
  })
}
