/* eslint-disable no-const-assign */
<template>
  <div>
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="uid"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.orderId"
              clearable
              placeholder="订单号"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="用户名"
        prop="user_name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备UID"
        prop="uid"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="订单号"
        prop="order_id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备通道"
        prop="channel"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="detectStatusFormat"
        label="开关状态"
        prop="detect_status"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="detectGroupFormat"
        label="侦测类型"
        prop="detect_group"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="useStatusFormat"
        label="使用状态"
        prop="use_status"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="addFormat"
        label="开通时间"
        prop="addTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="endFormat"
        label="到期时间"
        prop="endTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="updFormat"
        label="更新时间"
        prop="updTime"
        show-overflow-tooltip
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getDeviceAiMealList } from '@/api/deviceAiPackage'
  import { formatDate } from '@/utils/formatDate'

  export default {
    name: 'DevicePackage',
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          username: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getDeviceAiMealList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      detectStatusFormat(row) {
        if (row.detect_status === 0) {
          return '关闭'
        } else {
          return '开启'
        }
      },
      detectGroupFormat(row) {
        let detectGroup = ''
        if (row.detect_group.indexOf('1') != -1) {
          detectGroup += '人,'
        }
        if (row.detect_group.indexOf('2') != -1) {
          detectGroup += '动物,'
        }
        if (row.detect_group.indexOf('3') != -1) {
          detectGroup += '车,'
        }
        if (row.detect_group.indexOf('4') != -1) {
          detectGroup += '包裹,'
        }
        return detectGroup.substr(0, detectGroup.length - 1)
      },
      useStatusFormat(row) {
        if (row.detect_status === 0) {
          return '未使用'
        } else if (row.detect_status === 1) {
          return '使用中'
        } else if (row.detect_status === 2) {
          return '已过期'
        }
      },
      dateFormat(val) {
        let date = val * 1000
        return formatDate(new Date(date), 'yyyy年MM月dd日 hh:mm:ss')
      },
      addFormat(row) {
        return this.dateFormat(row.addTime)
      },
      updFormat(row) {
        return this.dateFormat(row.updTime)
      },
      endFormat(row) {
        return this.dateFormat(row.endTime)
      },
    },
  }
</script>
