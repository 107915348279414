<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-upload
      action="http://127.0.0.1:8001/testServe/AItest"
      class="upload-demo"
      data="fileData"
      :file-list="fileList"
      list-type="picture"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">
        图片必须是.jpeg 或 .png 格式，不得大于5MB
      </div>
    </el-upload>
  </el-dialog>
</template>

<script>
  export default {
    name: 'UserInformation',
    data() {
      return {
        fileData: { file: 'test.jpg' },
        roleList: [],
        form: {
          role: '',
          isEdit: '',
        },
        rules: {
          fileData: [
            { required: true, trigger: 'blur', message: '请选择图片' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList)
      },
      handlePreview(file) {
        console.log(file)
      },
    },
  }
</script>
