var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    [
      _c(
        "el-card",
        { staticClass: "order-card1", attrs: { shadow: "hover" } },
        [
          _c("vab-chart", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            attrs: {
              "init-options": _vm.initOptions,
              lazy: "",
              option: _vm.option,
              theme: "vab-echarts-theme"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }