<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="400px"
    @close="close"
  >
    <el-input
      v-model="textarea"
      :autosize="{ minRows: 20, maxRows: 40 }"
      placeholder=""
      type="textarea"
    />
  </el-dialog>
</template>

<script>
  export default {
    name: 'CheckDialog',
    data() {
      return {
        title: '',
        dialogFormVisible: false,
        textarea: '',
      }
    },
    methods: {
      showComponent(val) {
        this.dialogFormVisible = true
        this.textarea = val
      },
      close() {
        this.textarea = ''
        this.dialogFormVisible = false
      },
    },
  }
</script>
