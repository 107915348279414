import request from '@/utils/request'
import { baseURL } from '@/config'

export function getCloudUserList(params) {
  return request({
    url: '/serveManagement/getCloudUserList',
    method: 'get',
    params,
  })
}

export function doDelete(data) {
  return request({
    url: '/serveManagement/deleteDeviceOrder',
    method: 'post',
    data,
  })
}

export function down() {
  window.location.href = baseURL + '/serveManagement/exportCloudUserList'
}

export function payPalCycleCancel(data) {
  return request({
    url: '/serveManagement/paypal-cycle-cancel',
    method: 'post',
    data,
  })
}
