<template>
  <div class="custom-push-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="4">
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          新增
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="20">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.title"
              clearable
              placeholder="请输入标题"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.country"
              clearable
              placeholder="请选择国家"
            >
              <el-option
                v-for="item in countryList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.device_type"
              clearable
              placeholder="请选择设备型号"
            >
              <el-option
                v-for="item in deviceTypeList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.push_satus"
              clearable
              placeholder="请选择推送状态"
            >
              <el-option
                v-for="item in pushStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="fetchData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        align="center"
        label="标题"
        prop="title"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="详情"
        prop="msg"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="链接"
        prop="link"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="预览图"
        prop="icon_link"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-image :src="scope.row.icon_link" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="国家"
        prop="country"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="设备型号"
        prop="device_type"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="registerPeriodFormat"
        label="注册年限"
        prop="register_period"
        show-overflow-tooltip
        width="100px"
      />
      <el-table-column
        align="center"
        label="推送时间"
        prop="time_zone,push_time"
        show-overflow-tooltip
        width="250px"
      >
        <template slot-scope="scope">
          {{ scope.row.time_zone }},{{ scope.row.push_time }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="推送状态"
        prop="push_satus"
        show-overflow-tooltip
        width="100px"
      />
      <el-table-column align="center" label="操作" width="150">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">修改</el-button>
          <el-button type="danger" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <Edit
      ref="edit"
      :country-list="countryList"
      :device-type-list="deviceTypeList"
      :device-type-list-loading="deviceTypeListLoading"
      @fetch-data="fetchData"
    />
  </div>
</template>

<script>
  import Edit from './components/customPush'
  import {
    getList,
    getCountryList,
    getDeviceTypeList,
    delCustomizedPush,
  } from '@/api/customPush'

  export default {
    name: 'CustomPush',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
        countryList: [],
        deviceTypeList: [],
        deviceTypeListLoading: true,
        pushStatusList: [
          {
            value: 'False',
            label: '待推送',
          },
          {
            value: 'True',
            label: '已推送',
          },
        ],
      }
    },
    created() {
      this.fetchData()
      this.getCountry()
      this.getDeviceType()
    },
    methods: {
      handleEdit(row) {
        this.$refs['edit'].showComponent(row)
      },
      handleAdd() {
        this.$refs['edit'].showComponent()
      },
      async handleDelete(row) {
        this.$baseConfirm('您确定删除吗？', null, async () => {
          const { msg } = await delCustomizedPush({
            customizedPushId: row.customized_push_id,
          })
          this.$baseMessage(msg, 'success', 'vab-hey-message-success')
          await this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      statusFormat(row) {
        const pushStatus = row.push_satus
        if (pushStatus === false) {
          return '待推送'
        } else if (pushStatus === true) {
          return '已推送'
        } else {
          return pushStatus
        }
      },
      registerPeriodFormat(row) {
        let registerPeriod = row.register_period
        const list = registerPeriod.match(/\d+/g).map(Number)
        if (list[1] === undefined) {
          registerPeriod = list[0] + '年以上'
        } else {
          registerPeriod = row.register_period
        }
        return registerPeriod
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      async getCountry() {
        const {
          data: { list },
        } = await getCountryList(this.queryForm)
        this.countryList = list
      },
      async getDeviceType() {
        const {
          data: { list },
        } = await getDeviceTypeList(this.queryForm)
        this.deviceTypeList = list
        this.deviceTypeListLoading = false
      },
    },
  }
</script>
