var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "echarts-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("data-picker"),
          _c("chart-line", { attrs: { title: "新增趋势" } }),
          _c("chart-collection"),
          _c("chart-tablea"),
          _c("chart-Bar", { attrs: { title: "用户地区分布" } }),
          _c("chart-table")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }