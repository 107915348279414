var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dataPicker" },
    [
      _c("el-date-picker", {
        attrs: {
          align: "center",
          "end-placeholder": "结束日期",
          "range-separator": "~",
          "start-placeholder": "开始日期",
          type: "daterange",
          "value-format": "timestamp"
        },
        on: { change: _vm.postData },
        model: {
          value: _vm.timeRange,
          callback: function($$v) {
            _vm.timeRange = $$v
          },
          expression: "timeRange"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }