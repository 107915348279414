<template>
  <div class="tabs">
    <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
      <el-card class="tabs-card" shadow="hover">
        <el-row class="row-bg" justify="space-between" type="flex">
          <el-button type="primary" @click="handleFold">
            <vab-icon
              class="vab-dropdown"
              :class="{ 'vab-dropdown-active': !fold }"
              icon="arrow-up-s-line"
            />
            用户地区明细表
          </el-button>
        </el-row>
        <el-tabs>
          <el-table v-loading="loading" :data="tableData" :height="height">
            <el-table-column label="地区名" prop="countryName" />
            <el-table-column label="用户数（百分比）" prop="count,rate">
              <template slot-scope="scope">
                {{ scope.row.count }} ({{ scope.row.rate }}%)
              </template>
            </el-table-column>
          </el-table>
        </el-tabs>
      </el-card>
    </el-col>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        loading: true,
        fold: false,
        height: 260,
        activeName: 'first',
        tableData: [],
        queryData: [],
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.$root.eventVue.$on('data-countries', (data) => {
          this.tableData = data
          this.loading = false
        })
      },
      handleFold() {
        this.fold = !this.fold
        if (this.fold) {
          this.height = 520
        } else {
          this.height = 260
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-table {
      overflow: auto;
      &::before {
        height: 0px !important;
      }
    }
  }
</style>
