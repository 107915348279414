import request from '@/utils/request'

export function getIncrease(params) {
  return request({
    url: 'dataManagement/deviceData/global/addDevice',
    method: 'get',
    params,
  })
}

export function getActive(params) {
  return request({
    url: 'dataManagement/deviceData/global/active',
    method: 'get',
    params,
  })
}

export function getTypeRegion(params) {
  return request({
    url: 'dataManagement/deviceData/global/type',
    method: 'get',
    params,
  })
}

export function getRegion(params) {
  return request({
    url: 'dataManagement/deviceData/global/regional',
    method: 'get',
    params,
  })
}
