<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="AI套餐ID" prop="aiMealID">
        <el-select
          v-model="form.aiMealID"
          clearable
          :disabled="form.selectStoreMealID"
          placeholder="请选择套餐ID"
        >
          <el-option
            v-for="item in aiStoreMealList"
            :key="item.id"
            :label="item.id"
            :value="item.id"
          >
            <span style="float: left">{{ item.id }}</span>
            <span style="float: right; font-size: 12px; color: #8492a6">
              {{ item.lang__title }}
            </span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="套餐语言" prop="lang">
        <el-input v-model.trim="form.lang" placeholder="请输入套餐语言" />
      </el-form-item>
      <el-form-item label="套餐标题" prop="title">
        <el-input v-model.trim="form.title" placeholder="请输入套餐标题" />
      </el-form-item>
      <el-form-item label="套餐描述" prop="content">
        <el-input v-model.trim="form.content" placeholder="请输入套餐描述" />
      </el-form-item>
      <el-form-item label="优惠描述" prop="discountContent">
        <el-input
          v-model.trim="form.discountContent"
          placeholder="请输入优惠描述"
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save()">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { addOrEditAiMealLanguage } from '@/api/aiPackageLanguage'
  import { getAiStoreMealList } from '@/api/aiPackageInformation'

  export default {
    name: 'AiPackageLanguage',
    data() {
      return {
        queryForm: { isSelect: 'true' },
        aiStoreMealList: [],
        form: {
          isEdit: '',
          selectStoreMealID: false,
        },
        rules: {
          aiMealID: [
            { required: true, trigger: 'blur', message: '请选择套餐ID' },
          ],
          lang: [
            { required: true, trigger: 'blur', message: '请输入套餐语言' },
          ],
          title: [
            { required: true, trigger: 'blur', message: '请输入套餐标题' },
          ],
          content: [
            { required: true, trigger: 'blur', message: '请输入套餐描述' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {
      this.fetchStoreMealData()
    },
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加AI套餐语言'
        } else {
          this.title = '编辑AI套餐语言'
          this.form = Object.assign({}, row)
          this.form.isEdit = true
          this.form.selectStoreMealID = true
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await addOrEditAiMealLanguage(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
      async fetchStoreMealData() {
        const {
          data: { list },
        } = await getAiStoreMealList(this.queryForm)
        this.aiStoreMealList = list
      },
    },
  }
</script>
