<template>
  <div class="echarts-container">
    <el-row :gutter="20">
      <data-picker />
      <chart-line title="新增趋势" />
      <chart-collection />
      <chart-tablea />
      <chart-Bar title="用户地区分布" />
      <chart-table />
    </el-row>
  </div>
</template>

<script>
  import chartLine from './components/chartLine'
  import chartBar from './components/chartBar'
  import chartTable from './components/chartTable'
  import dataPicker from './components/dataPicker'
  import chartTablea from './components/chartTablea'
  import chartCollection from './components/chartCollection'
  import { getRegion } from '@/api/userData'

  export default {
    name: 'Echarts',
    components: {
      chartLine,
      chartTable,
      chartBar,
      dataPicker,
      chartTablea,
      chartCollection,
    },
    data() {
      return {}
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        const {
          result: { countries },
        } = await getRegion()
        this.$root.eventVue.$emit('data-countries', countries)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .echarts-container {
    padding: 0 !important;
    background: $base-color-background !important;
    ::v-deep {
      .echarts {
        width: 100%;
        height: 280px;
      }
    }
  }
</style>
