import request from '@/utils/request'

export function getFlowComboList(params) {
  return request({
    url: '/unicom/manage/getFlowComboList',
    method: 'get',
    params,
  })
}

export function uploadSerialNumberFile(data) {
  return request({
    url: '/unicom/manage/uploadSerialNumberFile',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function getPackagePage(params) {
  return request({
    url: '/unicom/manage/getSerialNumberPackagePage',
    method: 'get',
    params,
  })
}

export function packageUpdate(data) {
  return request({
    url: '/unicom/manage/uploadSerialNumberFile',
    method: 'post',
    data,
  })
}

export function checkFiles(data) {
  return request({
    url: '/unicom/manage/verifyPackageExport',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}

export function exportFiles(data) {
  return request({
    url: '/unicom/manage/verifyPackageExport',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    responseType: 'blob',
  })
}
