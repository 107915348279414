<template>
  <div class="tabs">
    <el-card class="tabs-card" shadow="hover">
      <el-tabs v-model="activeName">
        <el-table v-loading="loading" :data="tableData" :height="height">
          <el-table-column label="地区名" prop="countryName" />
          <el-table-column
            v-for="item in tableOption"
            :key="item.label"
            :label="item.label"
          >
            <template slot-scope="scope">
              {{ scope.row.count }} ({{ scope.row.rate }}%)
            </template>
          </el-table-column>
        </el-table>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        loading: true,
        fold: false,
        height: 334,
        activeName: 'first',
        tableData: [],
        tableOption: [
          {
            label: '新增用户（百分比）',
          },
        ],
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.$root.eventVue.$on('data-C', (message) => {
          this.tableData = message
          this.loading = false
          this.getStatus()
        })
      },
      getStatus() {
        this.$root.eventVue.$on('data-status', (message) => {
          if (message == 'newUser') {
            this.tableOption[0].label = '新增用户（百分比）'
          } else {
            this.tableOption[0].label = '活跃用户（百分比）'
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .tabs {
    &-card {
      height: 334px;
    }
  }
</style>
