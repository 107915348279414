import request from '@/utils/request'

export function getDeviceOrderList(params) {
  return request({
    url: '/serveManagement/getDevicePackageList',
    method: 'get',
    params,
  })
}

export function experiencereset(data) {
  return request({
    url: '/serveManagement/experiencereset',
    method: 'post',
    data,
  })
}
