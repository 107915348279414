<template>
  <el-dialog
    :close-on-click-modal="false"
    title="云存套餐转移"
    :visible.sync="dialogVisible"
    width="350px"
  >
    <el-input v-model.trim="form.new_uid" clearable placeholder="请输入UID" />
    <template #footer>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="transfer">转 移</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { mealTransfer } from '@/api/deviceOrder'

  export default {
    name: 'PackageTransfer',
    data() {
      return {
        dialogVisible: false,
        form: {
          orderID: '',
          new_uid: '',
          old_uid: '',
        },
      }
    },
    methods: {
      showComponent(val) {
        this.dialogVisible = true
        this.form.orderID = val.orderID
        this.form.old_uid = val.UID
      },
      async transfer() {
        const { msg } = await mealTransfer(this.form)
        this.$baseMessage(msg, 'success', 'vab-hey-message-success')
        this.dialogFormVisible = false
      },
    },
  }
</script>
