var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "400px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      }
    },
    [
      _c("el-input", {
        attrs: {
          autosize: { minRows: 20, maxRows: 40 },
          placeholder: "",
          type: "textarea"
        },
        model: {
          value: _vm.textarea,
          callback: function($$v) {
            _vm.textarea = $$v
          },
          expression: "textarea"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }