<template>
  <div class="card-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.NickName"
              clearable
              placeholder="用户昵称"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.phone"
              clearable
              placeholder="手机号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.iccid"
              clearable
              placeholder="iccid"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.serialNo"
              clearable
              placeholder="序列号"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.status"
              clearable
              placeholder="设备状态"
            >
              <el-option key="可测试" label="可测试" value="0" />
              <el-option key="可使用" label="可使用" value="1" />
              <el-option key="已使用" label="已使用" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="iccidInfo">
              ICCID状态查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>

      <vab-query-form-right-panel :span="50">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-button
              v-if="$perms('iot_cardUserInformation_batchPackage')"
              icon="el-icon-files"
              type="primary"
              @click="handleBatchPackage"
            >
              批量充值套餐
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="$perms('iot_cardUserInformation_batchReset')"
              icon="el-icon-files"
              type="primary"
              @click="handleBatchReset"
            >
              批量重置
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="$perms('iot_cardUserInformation_flowCharge')"
              type="success"
              @click="handleFlowCharge"
            >
              <vab-icon icon="stack-overflow-fill" />
              流量充值
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        label="用户名称"
        min-width="200"
        prop="userName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="用户昵称"
        min-width="100"
        prop="NickName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="手机号"
        min-width="120"
        prop="phone"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="iccid"
        min-width="200"
        prop="iccid"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="序列号"
        min-width="100"
        prop="serialNo"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="cardTypeFormat"
        label="卡类型"
        min-width="70"
        prop="cardType"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="卡状态"
        min-width="90"
        prop="cardStatus"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="设备状态"
        min-width="90"
        prop="status"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="mainCardFormat"
        label="SIM卡"
        min-width="90"
        prop="mainCard"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="更新时间"
        min-width="160"
        prop="updatedTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="添加时间"
        min-width="160"
        prop="createdTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        fixed="right"
        label="操作"
        min-width="270"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-row style="display: flex">
            <el-col v-if="$perms('iot_cardUserInfo_activate')">
              <span v-show="row.cardType === 0 || row.cardType === 3">
                <el-button
                  v-if="row.cardStatus === '已激活'"
                  type="danger"
                  @click="deactivateStatus(row)"
                >
                  停用
                </el-button>
                <el-button
                  v-if="row.cardStatus === '已停用'"
                  type="success"
                  @click="activateStatus(row)"
                >
                  激活
                </el-button>
              </span>
            </el-col>
            <el-col>
              <el-button type="primary" @click="handleDetails(row.serialNo)">
                套餐详情
              </el-button>
            </el-col>
            <el-col v-if="$perms('iot_cardUserInfo_resetFlow')">
              <el-button type="warning" @click="resetFlow(row)">
                重置流量
              </el-button>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <flow ref="flow" @fetch-data="fetchData" />
    <detail ref="detail" />
    <reset ref="reset" />
    <packageRecharge ref="packageRecharge" />
  </div>
</template>

<script>
  import {
    getFilter,
    getStoreMealList,
    getIccidStatus,
    resetCardPackage,
    asyncUpdateStatus,
  } from '@/api/cardUserInformation'
  import flow from './components/CardUserInformation'
  import detail from './components/packageDetails'
  import reset from './components/batchReset'
  import packageRecharge from './components/batchOrderFlowPackage'
  export default {
    name: 'CardUserInformation',
    components: { flow, detail, reset, packageRecharge },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        selectValue: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      queryData() {
        this.setchData()
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        console.log(val)
        this.fetchData()
      },
      handleFlowCharge() {
        this.$refs['flow'].showFlow()
      },
      handleDetails(val) {
        this.$refs['detail'].showDetails(val)
      },
      handleBatchReset() {
        this.$refs['reset'].showComponent()
      },
      handleBatchPackage() {
        this.$refs['packageRecharge'].showComponent()
      },
      async setchData() {
        this.listLoading = true
        const {
          result: { list, total },
        } = await getFilter(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      async fetchData() {
        this.listLoading = true
        const {
          result: { list, total },
        } = await getStoreMealList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      async resetFlow(row) {
        if (row.serialNo) {
          this.$baseConfirm('你确定要重置流量吗', null, async () => {
            const { reason } = await resetCardPackage({
              serialNumber: row.serialNo,
            })
            this.$baseMessage(reason, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      async activateStatus(row) {
        const { reason } = await asyncUpdateStatus({
          serialNumber: row.serialNo,
          status: 1,
        })
        this.$baseMessage(reason, 'success', 'vab-hey-message-success')
        this.listLoading = true
        setTimeout(() => {
          this.fetchData()
        }, 4000)
      },
      async deactivateStatus(row) {
        const { reason } = await asyncUpdateStatus({
          serialNumber: row.serialNo,
          status: 3,
        })
        this.$baseMessage(reason, 'success', 'vab-hey-message-success')
        this.listLoading = true
        setTimeout(() => {
          this.fetchData()
        }, 4000)
      },
      statusFormat(row) {
        if (row.status === 0) {
          return '可测试'
        } else if (row.status === 1) {
          return '可使用'
        } else if (row.status === 2) {
          return '已使用'
        } else {
          return row.status
        }
      },
      cardTypeFormat(row) {
        if (row.cardType === 0) {
          return '联通'
        } else if (row.cardType === 1) {
          return '五兴电信'
        } else if (row.cardType === 2) {
          return '移动'
        } else if (row.cardType === 3) {
          return '鼎芯电信'
        } else {
          return row.cardType
        }
      },
      mainCardFormat(row) {
        if (row.mainCard === 0) {
          return '插拔卡'
        } else if (row.mainCard === 1) {
          return '内置卡'
        } else {
          return row.mainCard
        }
      },
      timeFormat(row, column) {
        const data = row[column.property]
        if (data === 0) {
          return 'N/A'
        } else {
          const date = new Date(data * 1000)
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds()
          )
        }
      },
      async iccidInfo() {
        const { result } = await getIccidStatus(this.queryForm)
        let status = 'N/A'
        if (result === 0) {
          status = 'N/A'
        } else if (result === 1) {
          status = '已激活'
        } else if (result === 2) {
          status = '可激活'
        } else if (result === 3) {
          status = '已停用'
        } else if (result === 4) {
          status = '已失效'
        } else if (result === 5) {
          status = '可测试'
        } else if (result === 6) {
          status = '库存'
        } else if (result === 7) {
          status = '已更换'
        } else if (result === 8) {
          status = '已清除'
        }
        this.$baseAlert(status, '当前最新状态')
      },
    },
  }
</script>
