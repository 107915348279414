<template>
  <div class="dataPicker">
    <el-card class="" shadow="hover">
      <el-date-picker
        v-model="queryForm.timeRange"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        range-separator="至"
        start-placeholder="开始日期"
        type="datetimerange"
        value-format="timestamp"
        @change="postData"
      />
    </el-card>
  </div>
</template>

<script>
  import Vue from 'vue'
  window.Event = new Vue()
  export default {
    name: '',
    components: {},
    data() {
      return {
        msg: 'msg',
        pickerOptions: {
          shortcuts: [
            {
              text: '本月',
              onClick(picker) {
                picker.$emit('pick', [new Date(), new Date()])
              },
            },
            {
              text: '今年至今',
              onClick(picker) {
                const end = new Date()
                const start = new Date(new Date().getFullYear(), 0)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近六个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setMonth(start.getMonth() - 6)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        value: '',
        queryForm: {},
      }
    },
    methods: {
      postData() {
        this.$root.eventVue.$emit('data-D', this.queryForm.timeRange)
      },
    },
  }
</script>
