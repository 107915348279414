<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userName"
              clearable
              placeholder="用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="设备uid"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.serialNo"
              clearable
              placeholder="序列号"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.comboUseType"
              clearable
              placeholder="状态"
            >
              <el-option key="未使用" label="未使用" value="0" />
              <el-option key="已使用" label="已使用" value="1" />
              <el-option key="已过期" label="已过期" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="用户名"
        prop="username"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="uid"
        prop="uid"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="statusFormat"
        label="付款状态"
        prop="status"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="序列号"
        prop="serialNo"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="订单id"
        prop="orderID"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="套餐名称"
        prop="desc"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="价格"
        prop="price"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="nextActivateFormat"
        label="是否下月激活"
        prop="nextActivate"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="iccid"
        prop="iccid"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="useStatusFormat"
        label="状态"
        prop="useStatus"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="更新时间"
        prop="upTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="激活时间"
        prop="acTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="timeFormat"
        label="过期时间"
        prop="epTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="已消耗流量"
        prop="using_total"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="200"
      >
        <template slot-scope="scope">
          <el-button type="primary" @click="openDetail(scope.row)">
            详情
          </el-button>
          <el-button
            v-if="$perms('iot_deviceCardPackage_transfer')"
            type="success"
            @click="handlePackageTransger(scope.row)"
          >
            套餐转移
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <package-transfer ref="packageTransfer" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getCardCombo } from '@/api/deviceCardPackage'
  import PackageTransfer from './components/packageTransfer.vue'
  export default {
    name: 'CompanyInfo',
    components: { PackageTransfer },
    data() {
      return {
        radio: '1',
        dialogFormVisible: false,
        formLabelWidth: '120px',
        sendForm: { username: '', userID: '', msg: '' },
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      handlePackageTransger(row) {
        this.$refs['packageTransfer'].showComponent(row)
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          result: { orderList, total },
        } = await getCardCombo(this.queryForm)
        this.list = orderList
        this.total = total
        this.listLoading = false
      },
      statusFormat(row) {
        if (row.status === 0) {
          return '待支付'
        } else if (row.status === 1) {
          return '支付成功'
        } else if (row.status === 2) {
          return '取消支付'
        } else if (row.status === 4) {
          return '退款失败'
        } else if (row.status === 5) {
          return '全额退款'
        } else if (row.status === 6) {
          return '部分退款'
        } else if (row.status === 7) {
          return 'PayPal已退款'
        } else if (row.status === 9) {
          return '处理中'
        } else if (row.status === 10) {
          return '支付失败'
        }
      },
      nextActivateFormat(row) {
        if (row.status === 0) {
          return '否'
        } else if (row.status === 1) {
          return '是'
        }
      },
      useStatusFormat(row) {
        if (row.useStatus === 0) {
          return '未使用'
        } else if (row.useStatus === 1) {
          return '已使用'
        } else if (row.useStatus === 2) {
          return '已过期'
        }
      },
      countTimeFormat(time) {
        if (time === 0) {
          return 0
        } else {
          var date = new Date(time * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds()
          )
        }
      },
      timeFormat(row, column) {
        let data = row[column.property]
        if (data === 0) {
          return 0
        } else {
          let date = new Date(data * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds()
          )
        }
      },
      usingTotalFormat(row) {
        let num = row.using_total
        num = num.toFixed(2)
        return num
      },
      // handleDetail(row, column) {
      //   this.$baseAlert(
      //     row.nickName,
      //     column.label +
      //       '：' +
      //       '<br>' +
      //       cell.getElementsByClassName('cell el-tooltip')[0].innerHTML,
      //     '详情'
      //   )
      //   console.log(row)
      //   console.log(column)
      // },
      openDetail(val) {
        // this.$baseAlert(Object.keys(val), '详情')
        // for (let props in val) {
        //   this.$baseAlert(val)
        //   console.log(props + ' = ' + val[props])
        // }
        // var leet = JSON.stringify(val)
        // this.$baseAlert(leet, '详情')
        // console.log(leet)
        // console.log(typeof leet)
        this.$baseAlert(
          '用户昵称：' +
            val.username +
            '<br>' +
            'iccid：' +
            val.iccid +
            '<br>' +
            '序列号：' +
            val.serialNo +
            '<br>' +
            '激活时间：' +
            this.countTimeFormat(val.acTime),
          '详情'
        )
      },
    },
  }
</script>
