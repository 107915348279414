var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-device-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 50 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.fetchData.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入序列号" },
                        model: {
                          value: _vm.queryForm.serialNumber,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "serialNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.serialNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入UID" },
                        model: {
                          value: _vm.queryForm.uid,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "uid",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.uid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入生产编号" },
                        model: {
                          value: _vm.queryForm.orderNumber,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "orderNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.orderNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入用户名" },
                        model: {
                          value: _vm.queryForm.name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入邮箱" },
                        model: {
                          value: _vm.queryForm.email,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "email",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.fetchData }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-download",
                            loading: _vm.downloadLoading,
                            type: "primary"
                          },
                          on: { click: _vm.download }
                        },
                        [_vm._v(" 导出 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "header-num" }, [
        _c(
          "span",
          { staticStyle: { "padding-left": "30px", "padding-right": "39px" } },
          [
            _c("span", { staticClass: "number" }, [_vm._v("设备已添加数量:")]),
            _vm._v(" " + _vm._s(_vm.total) + " ")
          ]
        ),
        _c("span", [
          _c("span", { staticClass: "number" }, [_vm._v("生产编号设备总量:")]),
          _vm._v(" " + _vm._s(_vm.orderDeviceQuantity) + " ")
        ])
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: { border: "", data: _vm.list },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        _vm._l(_vm.tableColumns, function(item) {
          return _c("el-table-column", {
            key: item.prop,
            attrs: {
              align: "center",
              label: item.label,
              prop: item.prop,
              width: item.width
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      item.prop === "addTime"
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("timeFilter")(row[item.prop])) +
                                " "
                            )
                          ])
                        : _c("span", [_vm._v(_vm._s(row[item.prop]))])
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.page,
          layout: _vm.layout,
          "page-size": _vm.queryForm.line,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }