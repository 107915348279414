import request from '@/utils/request'

export function getVodBucketList(params) {
  return request({
    url: '/serveManagement/getVodBucketList',
    method: 'get',
    params,
  })
}

export function addOrEditVodBucket(data) {
  return request({
    url: '/serveManagement/addOrEditVodBucket',
    method: 'post',
    data,
  })
}

export function deleteVodBucket(data) {
  return request({
    url: '/serveManagement/deleteVodBucket',
    method: 'post',
    data,
  })
}
