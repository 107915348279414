var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", type: "primary" },
                  on: { click: _vm.handleEdit }
                },
                [_vm._v(" 添加 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function() {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          false
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "id",
                  prop: "id",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { align: "center", label: "序号", width: "55" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var $index = ref.$index
                  return [_vm._v(" " + _vm._s($index + 1) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "套餐名称",
              prop: "comboName",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.statuTypeFormat,
              label: "状态",
              prop: "status",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.comboTypeFormat,
              label: "套餐类型",
              prop: "comboType",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "流量总量值",
              prop: "flowTotal",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "有效期天数",
              prop: "expirationDays",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.expirationTypeFormat,
              label: "有效期类型",
              prop: "expirationType",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.payTypeFormat,
              label: "付款类型",
              prop: "payTypes",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "价格",
              prop: "price",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "原价格",
              prop: "virtualPrice",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "描述信息",
              prop: "remark",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.TimeFormat,
              label: "更新时间",
              prop: "updatedTime",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.crTimeFormat,
              label: "创建时间",
              prop: "createdTime",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              formatter: _vm.showFormat,
              label: "是否显示",
              prop: "isShow",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "show-overflow-tooltip": "",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }