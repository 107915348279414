<template>
  <div class="device-reset-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="20">
        <el-form :inline="true" :model="uploadForm" @submit.native.prevent>
          <el-form-item>
            <el-upload
              ref="upload"
              action=""
              :auto-upload="false"
              class="upload-file"
              :http-request="upLoadFile"
              :limit="1"
              :on-change="changeUpload"
              :on-exceed="handleExceed"
              :on-remove="onRemove"
            >
              <el-button
                v-if="$perms('device_devicereset_select')"
                slot="trigger"
                size="small"
                type="primary"
                @click="changeUid"
              >
                选取UID文件
              </el-button>
              <el-button
                slot="trigger"
                size="small"
                type="primary"
                @click="changeSerial"
              >
                选取序列号文件
              </el-button>
              <span style="margin-left: 10px" />
              <el-button
                v-if="$perms('device_devicereset_del')"
                size="small"
                style="margin-left: 10px"
                type="danger"
                @click="submitUpload"
              >
                上传文件删除数据
              </el-button>
              <el-button
                :disabled="file.length === 0 ? true : false"
                size="small"
                style="margin-left: 10px"
                type="success"
                @click="submitCheck"
              >
                核对设备添加用户
              </el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-checkbox
      v-model="checkSelectAll"
      :indeterminate="isIndeterminate"
      @change="handleSelectAll"
    >
      全选 (请选择要删除的设备数据)
    </el-checkbox>
    <div style="margin: 15px 0"></div>
    <el-checkbox-group v-model="checkedDeleteData" @change="handleSelectChange">
      <el-checkbox
        v-for="delData in delDatas"
        :key="delData"
        border
        :disabled="delData === '序列号解绑uid' ? true : false"
        :label="delData"
      >
        {{ delData }}
      </el-checkbox>
      <span style="font-size: large; color: red; padding: -1px 15px 5px 10px">
        请联系管理员
      </span>
    </el-checkbox-group>
    <div style="margin: 15px 0"></div>
    <el-input v-model="finalList" :rows="30" type="textarea" />
    <check-dialog ref="checkDialog" />
  </div>
</template>

<script>
  import { checkDeviceInfo } from '@/api/device'
  import CheckDialog from './components/checkDialog'
  const axios = require('axios')
  import network from '@/config/index.js' // 引用 baseURL
  const delDataOptions = [
    '设备信息数据',
    '设备配置数据',
    '设备推送数据',
    '设备云存数据',
    '设备AI数据',
    '设备AP信息数据',
    '序列号解绑uid',
  ]

  export default {
    name: 'DeviceReset',
    components: { CheckDialog },
    data() {
      return {
        file: [],
        uploadForm: {},
        List: '',
        uidList: '',
        serialList: '',
        finalList: '',
        checkedDeleteData: [],
        checkSelectAll: false,
        isIndeterminate: false,
        delDatas: delDataOptions,
      }
    },
    methods: {
      handleSelectAll(val) {
        let options = delDataOptions.slice(0, delDataOptions.length - 1)
        this.checkedDeleteData = val ? options : []
        this.isIndeterminate = false
      },
      changeUid() {
        this.List = 'uid'
      },
      changeSerial() {
        this.List = 'serial'
      },
      handleSelectChange(value) {
        let checkedCount = value.length
        this.checkSelectAll = checkedCount === this.delDatas.length
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.delDatas.length
      },
      changeUpload(file) {
        this.file = URL.createObjectURL(file.raw)
        let reader = new FileReader()
        if (typeof FileReader === 'undefined') {
          this.$message({
            type: 'info',
            message: '您的浏览器不支持文件读取',
          })
          return
        }
        reader.readAsText(file.raw, 'utf-8')
        var _this = this
        reader.onload = function (e) {
          if (_this.List === 'uid') {
            _this.uidList = e.target.result
            _this.finalList = e.target.result
            console.log('uidList:\n', _this.uidList)
          } else if (_this.List === 'serial') {
            _this.serialList = e.target.result
            _this.finalList = e.target.result
            console.log('serialList:\n', _this.serialList)
          }
        }
      },
      upLoadFile(file) {
        this.formData.append('deviceFile', file.file)
      },
      handleExceed(files) {
        this.$refs.upload.clearFiles()
        this.$nextTick(() => {
          this.$refs.upload.handleStart(files[0])
        })
      },
      onRemove() {
        this.file = []
      },
      submitUpload() {
        this.$confirm('是否删除选中的数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 构建传给后端的数据
            this.formData = new FormData()
            if (this.List === 'uid') {
              this.formData.append('uidList', this.uidList)
            } else if (this.List === 'serial') {
              this.formData.append('serialNumberList', this.serialList)
            }
            this.formData.append('delDataOptions', this.checkedDeleteData)

            let url = network.baseURL + '/deviceManagement/delDeviceData'
            console.log('url: ' + url)
            axios
              .post(url, this.formData)
              .then(
                function (res) {
                  console.log(res)
                  if (res.data.code === 0) {
                    this.$message({
                      type: 'success',
                      message: '数据删除成功!',
                    })
                  } else {
                    this.$message({
                      type: 'error',
                      message: '数据删除失败! ' + res.data.msg,
                    })
                  }
                }.bind(this)
              )
              .catch((res) => {
                console.log(res)
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消删除',
            })
          })
      },
      async submitCheck() {
        this.formData = new FormData()
        this.$refs.upload.submit()
        if (this.List === 'uid') {
          this.formData.append('type', 1)
        } else {
          this.formData.append('type', 2)
        }
        const { data } = await checkDeviceInfo(this.formData)
        console.log(data)
        this.$refs['checkDialog'].showComponent(data.userInfo)
      },
    },
  }
</script>
