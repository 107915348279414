<template>
  <div class="echarts-container">
    <el-row :gutter="20">
      <data-picker />
      <chart-table />
    </el-row>
  </div>
</template>

<script>
  import dataPicker from './components/dataPicker'
  import chartTable from './components/chartTable'

  export default {
    name: 'Echarts',
    components: {
      dataPicker,
      chartTable,
    },
    data() {
      return {}
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .echarts-container {
    padding: 0 !important;
    background: $base-color-background !important;
    ::v-deep {
      .echarts {
        width: 100%;
        height: 200px;
      }
    }
  }
</style>
