<template>
  <div class="dataPicker">
    <el-card class="" shadow="hover">
      <el-date-picker
        v-model="queryForm.timeRange"
        end-placeholder="结束月份"
        :picker-options="pickerOptions"
        range-separator="~"
        start-placeholder="开始月份"
        type="monthrange"
        value-format="timestamp"
        @change="postData"
      />
    </el-card>
  </div>
</template>

<script>
  import Vue from 'vue'
  window.Event = new Vue()
  export default {
    name: '',
    components: {},
    data() {
      return {
        msg: 'msg',
        defaultTime: '',
        pickerOptions: {
          disabledDate(time) {
            let myDate = new Date()
            let year = myDate.getFullYear() //获取当前年
            let mon = myDate.getMonth() + 2 //获取当前月
            if (mon < 10) {
              mon = '0' + mon
            }
            let now =
              year +
              '-' +
              mon +
              '-' +
              '01' +
              ' ' +
              '00' +
              ':' +
              '00' +
              ':' +
              '00'
            let nowAny = ''
            nowAny = Date.parse(now)
            return time.getTime() > nowAny - 24 * 3600 * 1000
          },
        },
        value: '',
        queryForm: {
          timeRange: [],
        },
      }
    },
    created() {
      this.getNowMon()
      this.postData()
    },
    methods: {
      postData() {
        this.$root.eventVueWork.$emit('data-A', this.queryForm.timeRange)
      },
      getNowMon() {
        let myDate = new Date()
        let year = myDate.getFullYear() //获取当前年
        let mon = myDate.getMonth() + 1 //获取这个月
        if (mon < 10) {
          mon = '0' + mon
        }
        let now =
          year + '-' + mon + '-' + '01' + ' ' + '00' + ':' + '00' + ':' + '00'
        let lastAny = ''
        lastAny = Date.parse(now)
        this.queryForm.timeRange[0] = lastAny
        this.queryForm.timeRange[1] = lastAny
      },
    },
  }
</script>
