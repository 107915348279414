var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        title: "提示",
        visible: _vm.dialogFormVisible,
        width: "600px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      }
    },
    [
      _c(
        "el-row",
        { staticStyle: { display: "flex", "text-align": "center" } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSync("test")
                    }
                  }
                },
                [_vm._v(" 同步测试服数据 ")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSync("cn")
                    }
                  }
                },
                [_vm._v(" 同步国内数据 ")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSync("us")
                    }
                  }
                },
                [_vm._v(" 同步美洲数据 ")]
              )
            ],
            1
          ),
          _c(
            "el-col",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSync("eu")
                    }
                  }
                },
                [_vm._v(" 同步欧洲数据 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }