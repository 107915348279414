<template>
  <el-col>
    <el-card shadow="hover">
      <template #header>
        <span style="font-weight: bold; font-size: 18px">{{ title }}</span>
        <el-tooltip placement="top">
          <div slot="content">
            根据设备联网上传信息统计设备类型
            <br />
            注意：未知设备类型是由于当时并未统计特定设备类型
          </div>
          <vab-icon icon="information-line" />
        </el-tooltip>
      </template>
      <vab-chart
        v-loading="loading"
        :init-options="initOptions"
        :option="option"
        style="height: 470px"
        theme="vab-echarts-theme"
      />
    </el-card>
  </el-col>
</template>

<script>
  import VabChart from '@/extra/VabChart'
  export default {
    name: 'VabChartBar',
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: true,
        queryForm: {},
        initOptions: {
          renderer: 'svg',
        },
        option: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          dataZoom: [
            {
              startValue: '',
            },
            {
              type: 'inside',
            },
            {
              endValue: '',
            },
          ],
          grid: {
            top: '5%',
            left: '2%',
            right: '4%',
            bottom: '15%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: {
            type: 'bar',
            itemStyle: {
              color: new VabChart.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: new VabChart.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' },
                ]),
              },
            },
            data: [],
          },
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.$root.eventVueDevice.$on('data-type', (type) => {
          let typeName = []
          for (let i in type) {
            typeName.push(type[i].type)
          }
          let typeCount = []
          for (let r in type) {
            typeCount.push(type[r].count)
          }
          this.option.xAxis.data = typeName
          this.option.series.data = typeCount
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-card {
      height: 500px !important;
    }
  }
</style>
