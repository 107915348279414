/* eslint-disable no-const-assign */
<template>
  <div
    class="user-management-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="UID"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.orderID"
              clearable
              placeholder="订单id"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userID__username"
              clearable
              placeholder="用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userID__userEmail"
              clearable
              placeholder="商品描述"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.payType"
              clearable
              placeholder="支付方式"
            >
              <el-option key="PayPal" label="PayPal" value="1" />
              <el-option key="支付宝" label="支付宝" value="2" />
              <el-option key="微信支付" label="微信支付" value="3" />
              <el-option key="免费体验" label="免费体验" value="10" />
              <el-option key="激活码" label="激活码" value="11" />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="支付状态"
            >
              <el-option key="待支付" label="待支付" value="0" />
              <el-option key="付款成功" label="付款成功" value="1" />
              <el-option key="取消付款" label="取消付款" value="2" />
              <el-option key="退款失败" label="退款失败" value="4" />
              <el-option key="全额退款" label="全额退款" value="5" />
              <el-option key="部分退款" label="部分退款" value="6" />
              <el-option key="PayPal已退款" label="PayPal已退款" value="7" />
              <el-option key="处理中" label="处理中" value="9" />
              <el-option key="付款失败" label="付款失败" value="10" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <div class="block">
              <span>添加时间:</span>
              <el-date-picker
                v-model="queryForm.timeRange"
                align="right"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="timestamp"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-dialog title="退款" :visible.sync="dialogFormVisible">
      <el-form :model="sendForm">
        <el-form-item label="用户名:" :label-width="formLabelWidth">
          <span>{{ sendForm.username }}</span>
        </el-form-item>
        <el-form-item label="订单金额:" :label-width="formLabelWidth">
          <span>{{ sendForm.price }}</span>
        </el-form-item>
        <el-form-item label="已退款金额:" :label-width="formLabelWidth">
          <span>{{ sendForm.refunded_amount }}</span>
        </el-form-item>
        <el-form-item label="退款金额:">
          <el-input
            v-model="sendForm.refund_amount"
            clearable
            placeholder="请输入退款金额"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doRefund">确 定</el-button>
      </div>
    </el-dialog>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '支付方式'">
            {{ row[item.prop] | payTypeFilter }}
          </span>
          <span v-else-if="item.label === '支付状态'">
            {{ row[item.prop] | statusFilter }}
          </span>
          <span
            v-else-if="
              (item.label === '添加时间') | (item.label === '更新时间')
            "
          >
            {{ row[item.prop] | dateFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="160"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('serve_dviceorder_refund')"
            type="primary"
            @click="handleRefund(row)"
          >
            退款
          </el-button>
          <el-button
            v-if="$perms('serve_dviceorder_delete')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getDeviceOrderList, doDelete } from '@/api/deviceOrder'
  import VabDraggable from 'vuedraggable'
  const axios = require('axios')
  import network from '@/config/index.js' // 引用 baseURL

  export default {
    name: 'DeviceOrder',
    components: {
      VabDraggable,
    },
    filters: {
      payTypeFilter(value) {
        if (value === 1) {
          return 'PayPal'
        } else if (value === 2) {
          return '支付宝'
        } else if (value === 3) {
          return '微信支付'
        } else if (value === 10) {
          return '免费体验'
        } else if (value === 11) {
          return '激活码'
        }
      },
      statusFilter(value) {
        if (value === 0) {
          return '待支付'
        } else if (value === 1) {
          return '付款成功'
        } else if (value === 2) {
          return '取消付款'
        } else if (value === 4) {
          return '退款失败'
        } else if (value === 5) {
          return '全额退款'
        } else if (value === 6) {
          return '部分退款'
        } else if (value === 7) {
          return 'PayPal已退款'
        } else if (value === 9) {
          return '处理中'
        } else if (value === 10) {
          return '付款失败'
        }
      },
      dateFilter(value) {
        var date = new Date(value * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        return (
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':' +
          date.getSeconds()
        )
      },
    },
    data() {
      return {
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: true,
        dialogFormVisible: false,
        sendForm: {
          username: '',
          orderID: '',
          price: '',
          refund_amount: '',
          refunded_amount: '',
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        checkList: [
          '订单ID',
          '设备UID',
          '用户名',
          '账号昵称',
          '通道',
          '商品描述',
          '支付方式',
          '价格',
          '支付状态',
          '已退金额',
          '添加时间',
          '更新时间',
        ],
        columns: [
          {
            label: '订单ID',
            width: '200',
            prop: 'orderID',
            sortable: true,
          },
          {
            label: '设备UID',
            width: '220',
            prop: 'UID',
            sortable: true,
            disableCheck: true,
          },
          {
            label: '用户名',
            width: '200',
            prop: 'userID__username',
            sortable: true,
          },
          {
            label: '账号昵称',
            width: '80',
            prop: 'userID__NickName',
            sortable: true,
          },
          {
            label: '通道',
            width: '50',
            prop: 'channel',
            sortable: false,
          },
          {
            label: '商品描述',
            width: 'auto',
            prop: 'desc',
            sortable: true,
          },
          {
            label: '支付方式',
            width: 'auto',
            prop: 'payType',
            sortable: true,
          },
          {
            label: '价格',
            width: 'auto',
            prop: 'price',
            sortable: true,
          },
          {
            label: '支付状态',
            width: 'auto',
            prop: 'status',
            sortable: true,
          },
          {
            label: '已退金额',
            width: 'auto',
            prop: 'refunded_amount',
            sortable: true,
          },
          {
            label: '添加时间',
            width: '180',
            prop: 'addTime',
            sortable: true,
          },
          {
            label: '更新时间',
            width: '180',
            prop: 'updTime',
            sortable: true,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          username: '',
        },
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleRefund(row) {
        if (row.orderID) {
          this.sendForm.username = row.userID__username
          this.sendForm.price = row.price
          this.sendForm.refunded_amount = row.refunded_amount
          this.sendForm.orderID = row.orderID
          this.dialogFormVisible = true
        }
      },
      doRefund() {
        this.$confirm('确定退款给该用户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 输入不能为空
            if (this.sendForm.refund_amount === '') {
              this.$message({
                type: 'error',
                message: '请输入退款金额!',
              })
              return
            }

            let url = network.baseURL + '/cloudstorage/doRefund'
            // 构建传给后端的数据
            this.formData = new FormData()
            this.formData.append('orderID', this.sendForm.orderID)
            this.formData.append('refund_amount', this.sendForm.refund_amount)
            axios.post(url, this.formData).then(
              function (res) {
                console.log('响应数据:', res.data)
                console.log(res.data.result_code)
                console.log(res.data.reason)
                if (res.data.result_code === 0) {
                  this.$message({
                    type: 'success',
                    message: '退款成功!',
                  })
                } else {
                  this.$message({
                    type: 'error',
                    message: '退款失败! ' + res.data.reason,
                  })
                }
              }.bind(this)
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消操作',
            })
          })
      },
      handleDelete(row) {
        if (row.orderID) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await doDelete({ orderID: row.orderID })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getDeviceOrderList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
    },
  }
</script>
