var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "target" },
    [
      _c(
        "el-card",
        {
          staticClass: "target-card1",
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "18px"
                      }
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _c(
                    "el-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 统计一周（按7天为单位）、一个月（按周为单位）、 "
                          ),
                          _c("br"),
                          _vm._v(
                            " 三个月（按月为单位）和一年（按月为单位）的销售额趋势 "
                          )
                        ]
                      ),
                      _c("vab-icon", { attrs: { icon: "information-line" } })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { justify: "end", type: "flex" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.buttonChange },
                  model: {
                    value: _vm.radio1,
                    callback: function($$v) {
                      _vm.radio1 = $$v
                    },
                    expression: "radio1"
                  }
                },
                [
                  _c(
                    "el-radio-button",
                    { attrs: { label: 1, plain: "", type: "primary" } },
                    [_vm._v(" 一周 ")]
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: 2, plain: "", type: "primary" } },
                    [_vm._v(" 一个月 ")]
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: 3, plain: "", type: "primary" } },
                    [_vm._v(" 三个月 ")]
                  ),
                  _c(
                    "el-radio-button",
                    { attrs: { label: 4, plain: "", type: "primary" } },
                    [_vm._v(" 一年 ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("vab-chart", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "target-echart1",
            attrs: {
              "init-options": _vm.initOptions,
              option: _vm.option,
              theme: "vab-echarts-theme"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }