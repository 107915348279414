<template>
  <div class="alarm-log-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="UID"
              style="width: 250px"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" label="序号" width="100">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '第一次报警时间'">
            {{ row[item.prop] | timeFilter }}
          </span>
          <span v-else-if="item.label === '最后一次报警时间'">
            {{ row[item.prop] | timeFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getAlarmLog } from '@/api/alarmLog'
  import { formatDate } from '@/utils/formatDate'

  export default {
    name: 'AlarmLog',
    filters: {
      timeFilter(val) {
        if (val === 0) {
          return 'N/A'
        }
        let date = val * 1000
        return formatDate(new Date(date), 'yyyy-MM-dd hh:mm:ss')
      },
    },
    data() {
      return {
        list: [],
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        columns: [
          {
            label: 'UID',
            width: 'auto',
            prop: 'uid',
            sortable: false,
            disableCheck: true,
          },
          {
            label: '频率',
            width: 'auto',
            prop: 'frequency',
            sortable: false,
          },
          {
            label: '第一次报警时间',
            width: 'auto',
            prop: 'addTime',
            sortable: false,
          },
          {
            label: '最后一次报警时间',
            width: 'auto',
            prop: 'updateTime',
            sortable: false,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getAlarmLog(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
    },
  }
</script>
