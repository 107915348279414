<template>
  <div class="echarts-container">
    <el-row :gutter="20">
      <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
        <new-user />
      </el-col>
      <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
        <active-user />
      </el-col>
      <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
        <total-user />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
        <devi-new-user />
      </el-col>
      <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
        <devi-active-user />
      </el-col>
      <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
        <devi-total-user />
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :lg="4" :md="4" :sm="4" :xl="4" :xs="4">
        <day-sales-vol />
      </el-col>
      <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
        <day-bar title="各服务占比" />
      </el-col>
      <el-col :lg="4" :md="4" :sm="4" :xl="4" :xs="4">
        <total-sales-vol />
      </el-col>
      <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
        <total-bar title="各服务占比" />
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
        <sales-line title="销售额趋势" />
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="12">
        <new-region-pie title="新增用户地区" />
      </el-col>
      <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="12">
        <total-region-pie title="总用户地区" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import newUser from './components/newUser'
  import activeUser from './components/activeUser'
  import totalUser from './components/totalUser'
  import deviNewUser from './components/deviNewUser'
  import deviActiveUser from './components/deviActiveUser'
  import deviTotalUser from './components/deviTotalUser'
  import daySalesVol from './components/daySalesVol'
  import totalSalesVol from './components/totalSalesVol'
  import salesLine from './components/salesLine'
  import newRegionPie from './components/newRegionPie'
  import totalRegionPie from './components/totalRegionPie'
  import dayBar from './components/dayBar'
  import totalBar from './components/totalBar'
  import { getAllData } from '@/api/overView'
  export default {
    name: 'Echarts',
    components: {
      newUser,
      activeUser,
      totalUser,
      deviNewUser,
      deviActiveUser,
      deviTotalUser,
      daySalesVol,
      totalSalesVol,
      salesLine,
      newRegionPie,
      totalRegionPie,
      dayBar,
      totalBar,
    },
    data() {
      return {}
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        const {
          result: {
            userIncreaseCount,
            userActiveCount,
            userAllCount,
            deviceIncreaseCount,
            deviceActiveCount,
            deviceAllCount,
            orderTotal,
            orderAllTotal,
            userIncreaseRegion,
            userAllRegion,
            vodOrderTotal,
            aiOrderTotal,
            unicomOrderTotal,
            vodOrderAllTotal,
            aiOrderAllTotal,
            unicomOrderAllTotal,
          },
        } = await getAllData(this.queryForm)
        this.userIncreaseCount = userIncreaseCount
        this.userActiveCount = userActiveCount
        this.userAllCount = userAllCount
        this.deviceIncreaseCount = deviceIncreaseCount
        this.deviceActiveCount = deviceActiveCount
        this.deviceAllCount = deviceAllCount
        this.orderTotal = orderTotal
        this.orderAllTotal = orderAllTotal
        this.userIncreaseRegion = userIncreaseRegion
        this.userAllRegion = userAllRegion
        this.vodOrderTotal = vodOrderTotal
        this.aiOrderTotal = aiOrderTotal
        this.unicomOrderTotal = unicomOrderTotal
        this.vodOrderAllTotal = vodOrderAllTotal
        this.aiOrderAllTotal = aiOrderAllTotal
        this.unicomOrderAllTotal = unicomOrderAllTotal
        this.postData()
      },
      postData() {
        this.$root.eventVueOv.$emit('data-A', this.userIncreaseCount)
        this.$root.eventVueOv.$emit('data-B', this.userActiveCount)
        this.$root.eventVueOv.$emit('data-C', this.userAllCount)
        this.$root.eventVueOv.$emit('data-D', this.deviceIncreaseCount)
        this.$root.eventVueOv.$emit('data-E', this.deviceActiveCount)
        this.$root.eventVueOv.$emit('data-F', this.deviceAllCount)
        this.$root.eventVueOv.$emit('data-G', this.orderTotal)
        this.$root.eventVueOv.$emit('data-H', this.orderAllTotal)
        this.$root.eventVueOv.$emit('data-I', this.userIncreaseRegion)
        this.$root.eventVueOv.$emit('data-J', this.userAllRegion)
        this.$root.eventVueOv.$emit('data-vodOrderTotal', this.vodOrderTotal)
        this.$root.eventVueOv.$emit('data-aiOrderTotal', this.aiOrderTotal)
        this.$root.eventVueOv.$emit(
          'data-unicomOrderTotal',
          this.unicomOrderTotal
        )
        this.$root.eventVueOv.$emit(
          'data-vodOrderAllTotal',
          this.vodOrderAllTotal
        )
        this.$root.eventVueOv.$emit(
          'data-aiOrderAllTotal',
          this.aiOrderAllTotal
        )
        this.$root.eventVueOv.$emit(
          'data-unicomOrderAllTotal',
          this.unicomOrderAllTotal
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .echarts-container {
    padding: 0 !important;
    background: $base-color-background !important;
    ::v-deep {
      .echarts {
        width: 100%;
        height: 200px;
      }
    }
  }
</style>
