<template>
  <div class="version-apprecord-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-tabs
          v-model="queryForm.app_type"
          type="card"
          @tab-click="handleTypeClick"
        >
          <el-tab-pane
            v-for="(item, index) in appTypeList"
            :key="index"
            :label="item"
          />
        </el-tabs>
        <Edit ref="edit" />
        <span style="margin-left: 10px" />
        <el-button
          v-if="$perms('version_apprecord_insert')"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="app_record_list">
      <el-table-column
        align="center"
        label="appID"
        prop="app_id__appBundleId"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="app名称"
        prop="app_id__appName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="appTypeFormat"
        label="app类型"
        prop="app_id__app_type"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="系统语言"
        prop="newApp_version_list"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="margin-left: 10px">
            {{ scope.row.newApp_version_list[0][0] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="系统版本"
        prop="newApp_version_list"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <!-- <span style="margin-left: 10px">
            {{ scope.row.newApp_version_list }}
          </span> -->
          <el-select
            v-model="scope.row.version"
            placeholder="系统版本"
            @change="
              fetchData(scope.row.app_id__appBundleId, scope.row.version)
            "
          >
            <el-option
              v-for="item in scope.row.newApp_version_list"
              :key="item[0] + item[1]"
              :value="item[0] + item[1]"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="更新内容"
        prop="content"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.content"
            :autosize="{ minRows: 2, maxRows: 4 }"
            type="textarea"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="更新时间"
        prop="version_time"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="180"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('version_apprecord_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$perms('version_apprecord_del')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getAppRecordList, deleteAppRecord } from '@/api/appRecord'
  import Edit from './components/APPrecord'

  export default {
    name: 'AppRecord',
    components: { Edit },
    data() {
      return {
        appTypeList: ['IOS', '安卓'],
        app_record_list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          appType: 'IOS',
          queryVersion: '',
          queryAppBundleId: '',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleEdit(row) {
        if (row.app_id__appBundleId) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteAppRecord({ app_colophon_id: row.id })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData(appBundleId, version) {
        this.listLoading = true
        console.log('选中值: ' + appBundleId + version)
        if (typeof version != 'undefined') {
          // 选中系统版本查询
          this.queryForm.queryVersion = version
          this.queryForm.queryAppBundleId = appBundleId
        }
        const {
          data: { app_record_list, total },
        } = await getAppRecordList(this.queryForm)
        this.app_record_list = app_record_list
        this.total = total
        this.listLoading = false
      },
      appTypeFormat(row) {
        if (row.app_id__app_type === 1) {
          return 'IOS'
        } else {
          return 'Android'
        }
      },
      handleTypeClick(tab, event) {
        console.log(tab, event)
        this.queryForm.appType = tab.label
        this.fetchData()
        this.$refs.edit.fetchData(this.queryForm) // 父组件调用子组件的方法,点击标签页时获取appID数据
      },
    },
  }
</script>
