/* eslint-disable no-const-assign */
<template>
  <div
    class="user-management-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <el-checkbox
      v-model="checkAllLang"
      :indeterminate="isIndeterminate"
      @change="handleCheckAllLanguage"
    >
      全选 (请选择语言)
    </el-checkbox>
    <div style="margin: 15px 0"></div>
    <el-checkbox-group
      v-model="sendForm.checkedLanguages"
      @change="handleCheckedLangChange"
    >
      <el-checkbox
        v-for="language in languages"
        :key="language"
        border
        :label="language"
      >
        {{ language }}
      </el-checkbox>
    </el-checkbox-group>
    <div style="margin: 15px 0"></div>
    <el-checkbox
      v-model="checkAllPla"
      :indeterminate="isIndeterminatePla"
      @change="handleCheckAllPlatform"
    >
      全选 (请选择平台)
    </el-checkbox>
    <div style="margin: 15px 0"></div>
    <el-checkbox-group
      v-model="sendForm.checkedPlatforms"
      @change="handleCheckedPlaChange"
    >
      <el-checkbox
        v-for="platform in platforms"
        :key="platform"
        border
        :label="platform"
      >
        {{ platform }}
      </el-checkbox>
    </el-checkbox-group>
    <div style="margin: 15px 0"></div>
    <el-checkbox
      v-model="checkAllApp"
      :indeterminate="isIndeterminateApp"
      @change="handleCheckAllApp"
    >
      全选 (请选择APP)
    </el-checkbox>
    <div style="margin: 15px 0"></div>
    <el-checkbox-group
      v-model="sendForm.checkedApps"
      @change="handleCheckedAppChange"
    >
      <el-checkbox v-for="app in appDataList" :key="app" :label="app">
        {{ app }}
      </el-checkbox>
    </el-checkbox-group>
    <div style="margin: 15px 0"></div>
    <el-input
      id="sendMSG"
      v-model="sendForm.message"
      :autosize="{ minRows: 4, maxRows: 20 }"
      clearable
      placeholder="请输入群发内容"
      type="textarea"
    />
    <div style="margin: 15px 0"></div>
    <el-button
      v-if="$perms('send_sys_msg')"
      icon="el-icon-s-promotion"
      type="primary"
      @click="sendMSG"
    >
      发送消息
    </el-button>
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '发送时间'">
            {{ row[item.prop] | dateFilter }}
          </span>
          <div v-else-if="item.label === '发送内容'">
            <el-input
              v-model="row[item.prop]"
              :disabled="true"
              :rows="4"
              type="textarea"
            />
          </div>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  const axios = require('axios')
  import network from '@/config/index.js' // 引用 baseURL
  import { getSysMessageList } from '@/api/featureGroupSending'
  import VabDraggable from 'vuedraggable'
  const languageOptions = [
    '简体中文(cn)',
    '繁体中文(tc)',
    '英文(en)',
    '法文(fr)',
    '俄文(ru)',
    '西班牙文(es)',
    '波兰文(pl)',
    '日文(ja)',
    '德文(de)',
    '意大利(it)',
  ]
  const platformOptions = ['android', 'ios']

  export default {
    name: 'FeatureGroupSending',
    components: {
      VabDraggable,
    },
    filters: {
      statusFilter(value) {
        if (value === 0) {
          return '未回复'
        } else if (value === 1) {
          return '已回复'
        }
      },
      dateFilter(value) {
        var date = new Date(value * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        return (
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':' +
          date.getSeconds()
        )
      },
      typeFilter(value) {
        if (value === 0) {
          return '产品建议'
        } else if (value === 1) {
          return '功能异常'
        } else if (value === 2) {
          return '安全问题'
        }
      },
    },
    data() {
      return {
        sendForm: {
          checkedLanguages: [],
          checkedPlatforms: [],
          checkedApps: [],
          languages: [],
          message: '',
        },
        checkAllLang: false,
        checkAllPla: false,
        checkAllApp: false,
        languages: languageOptions,
        platforms: platformOptions,
        isIndeterminate: true,
        isIndeterminatePla: true,
        isIndeterminateApp: true,
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        appDataList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        checkList: ['语言', '平台', 'APP', '发送内容', '发送时间', '发送人'],
        columns: [
          {
            label: '语言',
            width: '200',
            prop: 'lang',
            sortable: true,
          },
          {
            label: '平台',
            width: '100',
            prop: 'platform',
            sortable: true,
          },
          {
            label: 'APP',
            width: '150',
            prop: 'recever',
            sortable: true,
          },
          {
            label: '发送内容',
            width: '800',
            prop: 'msg',
            sortable: true,
          },
          {
            label: '发送时间',
            width: '160',
            prop: 'addTime',
            sortable: true,
          },
          {
            label: '发送人',
            width: '160',
            prop: 'sender__username',
            sortable: true,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          username: '',
        },
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleCheckAllLanguage(val) {
        this.sendForm.checkedLanguages = val ? languageOptions : []
        this.isIndeterminate = false
      },
      handleCheckedLangChange(value) {
        let checkedCount = value.length
        this.checkAllLang = checkedCount === this.languages.length
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.languages.length
      },
      handleCheckAllPlatform(val) {
        let _this = this
        this.sendForm.checkedPlatforms = val ? platformOptions : []
        this.isIndeterminatePla = false
        this.$options.methods.getAppBundleId(
          _this,
          _this.sendForm.checkedPlatforms
        )
      },
      handleCheckedPlaChange(value) {
        let _this = this
        let checkedCount = value.length
        this.checkAllPla = checkedCount === this.platforms.length
        this.isIndeterminatePla =
          checkedCount > 0 && checkedCount < this.platforms.length
        this.$options.methods.getAppBundleId(
          _this,
          _this.sendForm.checkedPlatforms
        )
      },
      getAppBundleId(_this, platforms) {
        let url = network.baseURL + '/userManagement/getAppDataList'
        let formData = new FormData()
        formData.append('platform', platforms)
        axios.post(url, formData).then(
          function (res) {
            let resData = res.data
            console.log(resData)
            console.log(resData.data.appBundleId_list)
            _this.appDataList = resData.data.appBundleId_list
          }.bind(_this)
        )
      },
      handleCheckAllApp(val) {
        this.sendForm.checkedApps = val ? this.appDataList : []
        this.isIndeterminateApp = false
      },
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        let _this = this
        this.listLoading = true
        const {
          data: { list, total },
        } = await getSysMessageList(this.queryForm)
        this.list = list
        this.total = total
        this.$options.methods.getAppBundleId(
          _this,
          _this.sendForm.checkedPlatforms
        )
        this.listLoading = false
      },
      sendMSG() {
        this.$confirm('是否群发消息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let url = network.baseURL + '/userManagement/sendSysMessage'
            // 构建传给后端的数据
            this.formData = new FormData()
            let langArr = []
            this.sendForm.checkedLanguages.forEach(function (element) {
              console.log(element)
              langArr.push(element.slice(-3, -1)) // 截取括号里的值
            })
            let platformArr = []
            this.sendForm.checkedPlatforms.forEach(function (element) {
              console.log(element)
              platformArr.push(element)
            })
            let msgArr = []
            this.sendForm.checkedApps.forEach(function (element) {
              console.log(element)
              msgArr.push(element)
            })
            console.log(langArr)
            console.log(platformArr)
            console.log(msgArr)
            this.formData.append('lang', langArr)
            this.formData.append('platform', platformArr)
            this.formData.append('app_list', msgArr)
            this.formData.append('msg', this.sendForm.message)
            axios.post(url, this.formData).then(
              function (res) {
                console.log('sendSysMessage 响应数据:', res.data)
                console.log(res.data.code)
                console.log(res.data.msg)
                if (res.data.code === 0) {
                  this.$message({
                    type: 'success',
                    message: '发送成功!',
                  })
                } else {
                  this.$message({
                    type: 'error',
                    message: '发送失败! ' + res.data.msg,
                  })
                }
              }.bind(this)
            )
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消发送',
            })
          })
      },
    },
  }
</script>
