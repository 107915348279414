import request from '@/utils/request'

export function getAiMealLanguage(params) {
  return request({
    url: '/aiServe/getAiMealLanguage',
    method: 'get',
    params,
  })
}

export function addOrEditAiMealLanguage(data) {
  return request({
    url: '/aiServe/addOrEditAiMealLanguage',
    method: 'post',
    data,
  })
}

export function deleteAiMealLanguage(data) {
  return request({
    url: '/aiServe/deleteAiMealLanguage',
    method: 'post',
    data,
  })
}
