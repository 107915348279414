<template>
  <el-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24">
    <el-card shadow="hover">
      <span class="demonstration"></span>
      <el-row class="row-bg" justify="space-between" type="flex">
        <el-radio-group v-model="radio1" @change="buttonChange">
          <el-radio-button :label="1">新增用户</el-radio-button>
          <el-radio-button :label="2">活跃用户</el-radio-button>
        </el-radio-group>
        <el-radio-group v-model="radio2" @change="buttonChange2">
          <el-radio-button :label="1">天</el-radio-button>
          <el-radio-button :disabled="disabledWeek" :label="2">
            周
          </el-radio-button>
          <el-radio-button :disabled="disabledMonth" :label="3">
            月
          </el-radio-button>
          <el-radio-button :disabled="disabledQuarter" :label="4">
            季度
          </el-radio-button>
          <el-radio-button :disabled="disabledYear" :label="5">
            年
          </el-radio-button>
        </el-radio-group>
      </el-row>
      <template #header>
        <span style="font-weight: bold; font-size: 18px">{{ title }}</span>
        <el-tooltip placement="top">
          <div slot="content">提供新增用户和活跃用户的趋势变化及明细数据</div>
          <vab-icon icon="information-line" />
        </el-tooltip>
      </template>
      <vab-chart
        v-loading="loading"
        class="trend-echart"
        :init-options="initOptions"
        lazy
        :option="option"
        style="height: 360px"
        theme="vab-echarts-theme"
      />
    </el-card>
  </el-col>
</template>

<script>
  import VabChart from '@/extra/VabChart'
  import { getActive, getIncrease } from '@/api/userData'

  export default {
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loading: true,
        disabledWeek: false,
        disabledMonth: false,
        disabledQuarter: false,
        disabledYear: false,
        radio1: 1,
        radio2: 1,
        queryForm: {
          startTime: '',
          endTime: '',
          timeUnit: 'day',
        },
        initOptions: {
          renderer: 'svg',
        },
        dataPost: {},
        dataPostEx: [],
        status: 'newUser',
        option: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                type: 'dashed',
                color: 'rgba(198, 196, 196, 0.75)',
              },
            },
          },
          legend: {
            bottom: -5,
          },
          grid: {
            top: '5%',
            left: '2%',
            right: '4%',
            bottom: '5%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [],
          },
          yAxis: {
            type: 'value',
          },
          series: {
            name: '新增用户',
            data: [],
            type: 'line',
            emphasis: {
              itemStyle: {
                color: '#409EFF',
                borderColor: 'white',
              },
            },
          },
        },
      }
    },
    created() {
      this.getNowDate()
      this.changeData()
      this.getData()
      this.postData()
    },
    methods: {
      changeData() {
        if (this.status == 'newUser') {
          this.fetchData()
        } else {
          this.activeData()
        }
      },
      async fetchData() {
        const {
          result: { user, region },
        } = await getIncrease(this.queryForm)
        let startTime = []
        for (let i in user) {
          startTime.push(this.timeFormat(user[i].startTime))
        }
        let count = []
        for (let r in user) {
          count.push(user[r].count)
        }
        this.option.xAxis.data = startTime
        this.option.series.data = count
        this.dataPostEx = region
        this.postData()
        this.loading = false
      },
      async activeData() {
        const {
          result: { user, region },
        } = await getActive(this.queryForm)
        let startTime = []
        for (let i in user) {
          startTime.push(this.timeFormat(user[i].startTime))
        }
        let count = []
        for (let r in user) {
          count.push(user[r].count)
        }
        this.option.xAxis.data = startTime
        this.option.series.data = count
        this.dataPostEx = region
        this.postData()
        this.loading = false
      },
      unitFormat() {
        if (this.queryForm.endTime - this.queryForm.startTime < 2678400 * 12) {
          this.disabledYear = true
        } else {
          this.disabledYear = false
        }
        if (this.queryForm.endTime - this.queryForm.startTime < 2678400 * 3) {
          this.disabledQuarter = true
        } else {
          this.disabledQuarter = false
        }
        if (this.queryForm.endTime - this.queryForm.startTime < 2678400) {
          this.disabledMonth = true
        } else {
          this.disabledMonth = false
        }
        if (this.queryForm.endTime - this.queryForm.startTime <= 604800) {
          this.disabledWeek = true
        } else {
          this.disabledWeek = false
        }
      },
      suitChange() {
        if (this.disabledYear == false) {
          this.queryForm.timeUnit = 'year'
          this.radio2 = 5
        } else if (this.disabledQuarter == false) {
          this.queryForm.timeUnit = 'quarter'
          this.radio2 = 4
        } else if (this.disabledMonth == false) {
          this.queryForm.timeUnit = 'month'
          this.radio2 = 3
        } else if (this.disabledWeek == false) {
          this.queryForm.timeUnit = 'week'
          this.radio2 = 2
        } else {
          this.queryForm.timeUnit = 'day'
          this.radio2 = 1
        }
      },
      buttonChange(val) {
        if (val == 1) {
          this.newUser()
        } else {
          this.activeUser()
        }
      },
      buttonChange2(val) {
        if (val == 1) {
          this.postDay()
        } else if (val == 2) {
          this.postWeek()
        } else if (val == 3) {
          this.postMonth()
        } else if (val == 4) {
          this.postQuarter()
        } else {
          this.postYear()
        }
      },
      newUser() {
        this.status = 'newUser'
        this.option.series.name = '新增用户'
        this.changeData()
      },
      activeUser() {
        this.status = 'activeUser'
        this.option.series.name = '活跃用户'
        this.changeData()
      },
      getData() {
        this.$root.eventVue.$on('data-A', (message) => {
          this.queryForm.startTime = message[0] / 1000
          this.queryForm.endTime = message[1] / 1000
          this.unitFormat()
          this.suitChange()
          this.changeData()
        })
      },
      postData() {
        this.$root.eventVue.$emit('data-B', this.queryForm.timeUnit)
        this.$root.eventVue.$emit('data-C', this.dataPostEx)
        this.$root.eventVue.$emit('data-status', this.status)
      },
      postDay() {
        this.queryForm.timeUnit = 'day'
        this.changeData()
      },
      postWeek() {
        this.queryForm.timeUnit = 'week'
        this.changeData()
      },
      postMonth() {
        this.queryForm.timeUnit = 'month'
        this.changeData()
      },
      postQuarter() {
        this.queryForm.timeUnit = 'quarter'
        this.changeData()
      },
      postYear() {
        this.queryForm.timeUnit = 'year'
        this.changeData()
      },
      timeFormat(time) {
        if (time === 0) {
          return 0
        } else {
          let date = new Date(time * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
      getNowDate() {
        let myDate = new Date()
        let year = myDate.getFullYear() //获取当前年
        let mon = myDate.getMonth() + 1 //获取当前月
        if (mon < 10) {
          mon = '0' + mon
        }
        let date = myDate.getDate() //获取当前日
        if (date < 10) {
          date = '0' + date
        }
        let now =
          year + '-' + mon + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00'
        let nowAny = ''
        nowAny = Date.parse(now)
        this.queryForm.endTime = nowAny / 1000
        this.queryForm.startTime = this.queryForm.endTime - 1 * 60 * 60 * 24 * 7
        this.unitFormat()
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-card {
      height: 500px !important;
    }
  }
</style>
