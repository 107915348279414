<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="520px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="编号" prop="no">
        <el-input
          v-model.trim="form.no"
          clearable
          :disabled="inpNo"
          width="15px"
        />
      </el-form-item>
      <el-form-item label="题目json" prop="content">
        <el-input
          v-model="form.content"
          placeholder="json"
          :rows="10"
          type="textarea"
        />
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">保 存</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { getTitleList, titleSave } from '@/api/surveys'
  export default {
    name: 'CloudVodTitle',
    data() {
      return {
        queryForm: { isSelect: 'true', info: 'one' },
        roleList: [],
        inpNo: true,
        form: {
          content: '',
          no: '',
          surveyId: '',
        },
        rules: {
          no: [{ required: true, trigger: 'blur', message: '请输入编号' }],
        },
        title: '',
        dialogFormVisible: false,
        value2: '',
      }
    },
    created() {},
    methods: {
      async showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.queryForm.surveyId = row.id
          const data = await getTitleList(this.queryForm)
          console.log(data)
          if (!data.data.id) {
            this.title = '添加'
            this.form.no = row.no
            this.form.surveyId = row.id
          } else if (data.data.id) {
            this.form.id = data.data.id
            this.form.content = data.data.content
            this.title = '编辑'
            //this.form = Object.assign({}, row)
            this.form.surveyId = row.id
            this.form.no = row.no
            this.form.isEdit = true
          }
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      async save() {
        console.log(this.form)
        const data = await titleSave(this.form)
        this.$baseMessage(data.msg, 'success', 'vab-hey-message-success')
        this.$emit('fetch-data')
        this.close()
      },
      async fetchData() {},
    },
  }
</script>
