<template>
  <div class="echarts-container">
    <el-row :gutter="20">
      <data-picker />
      <chart-line title="新增趋势" />
      <chart-collection />
      <chart-collectiona />
      <chart-collectionb />
      <chart-tablec />
      <chart-bar title="设备类型统计" />
      <chart-tablee />
      <chart-bara title="设备地区分布" />
      <chart-tablef />
    </el-row>
  </div>
</template>

<script>
  import dataPicker from './components/dataPicker'
  import chartLine from './components/chartLine'
  import chartCollection from './components/chartCollection'
  import chartCollectiona from './components/chartCollectiona'
  import chartCollectionb from './components/chartCollectionb'
  import chartTablec from './components/chartTablec'
  import chartBar from './components/chartBar'
  import chartBara from './components/chartBara'
  import chartTablee from './components/chartTablee'
  import chartTablef from './components/chartTablef'
  import { getTypeRegion, getRegion } from '@/api/deviceData'

  export default {
    name: 'Echarts',
    components: {
      dataPicker,
      chartLine,
      chartCollection,
      chartCollectiona,
      chartCollectionb,
      chartTablec,
      chartBar,
      chartBara,
      chartTablee,
      chartTablef,
    },
    data() {
      return {
        type: [],
        countries: [],
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        const {
          result: { type },
        } = await getTypeRegion(this.queryForm)
        const {
          result: { countries },
        } = await getRegion(this.queryForm)
        this.type = type
        this.countries = countries
        this.postData()
      },
      postData() {
        this.$root.eventVueDevice.$emit('data-type', this.type)
        this.$root.eventVueDevice.$emit('data-countries', this.countries)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .echarts-container {
    padding: 0 !important;
    background: $base-color-background !important;
    ::v-deep {
      .echarts {
        width: 100%;
        height: 280px;
      }
    }
  }
</style>
