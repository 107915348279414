<template>
  <el-col>
    <el-card shadow="hover">
      <span class="demonstration"></span>
      <el-row class="row-bg" justify="space-between" type="flex">
        <el-tag class="card-header-tag" type="success">新增用户</el-tag>
        <el-button-group>
          <el-button type="primary" @click="postDay">天</el-button>
          <el-button type="primary" @click="postWeek">周</el-button>
          <el-button type="primary" @click="postMonth">月</el-button>
          <el-button type="primary" @click="postQuarter">季度</el-button>
          <el-button type="primary" @click="postYear">年</el-button>
        </el-button-group>
      </el-row>
      <template #header>
        <span>{{ title }}</span>
      </template>
      <vab-chart
        class="trend-echart"
        :init-options="initOptions"
        lazy
        :option="option"
        theme="vab-echarts-theme"
      />
    </el-card>
  </el-col>
</template>

<script>
  import VabChart from '@/extra/VabChart'
  import { getActive } from '@/api/userData'

  export default {
    components: {
      VabChart,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        queryForm: {
          startTime: 1660492800,
          endTime: 1661097600,
          timeUnit: 'day',
        },
        initOptions: {
          renderer: 'svg',
        },
        dataPost: {},
        option: {
          grid: {
            top: '5%',
            left: '2%',
            right: '4%',
            bottom: '0%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: [],
          },
          yAxis: {
            type: 'value',
          },
          series: {
            data: [],
            type: 'line',
          },
        },
      }
    },
    created() {
      this.fetchData()
      this.getData()
      this.postData()
    },
    methods: {
      async fetchData() {
        console.log(this.queryForm)
        console.log(typeof this.queryForm.startTime)
        console.log(typeof this.queryForm.endTime)
        const {
          result: { user },
        } = await getActive(this.queryForm)
        console.log(user)
        this.dataPost = user
        console.log(this.dataPost)
        let startTime = []
        for (var i in user) {
          startTime.push(this.timeFormat(user[i].startTime))
        }
        let count = []
        for (var r in user) {
          count.push(user[r].count)
        }
        this.option.xAxis.data = startTime
        this.option.series.data = count
        this.postData()
      },
      getData() {
        this.$root.eventVue.$on('data-D', (message) => {
          this.queryForm.startTime = message[0] / 1000
          this.queryForm.endTime = message[1] / 1000
          this.fetchData()
        })
      },
      postData() {
        this.$root.eventVue.$emit('data-E', this.dataPost)
      },
      postDay() {
        this.queryForm.timeUnit = 'day'
        this.fetchData()
      },
      postWeek() {
        this.queryForm.timeUnit = 'week'
        this.fetchData()
      },
      postMonth() {
        this.queryForm.timeUnit = 'month'
        this.fetchData()
      },
      postQuarter() {
        this.queryForm.timeUnit = 'quarter'
        this.fetchData()
      },
      postYear() {
        this.queryForm.timeUnit = 'year'
        this.fetchData()
      },
      timeFormat(time) {
        if (time === 0) {
          return 0
        } else {
          var date = new Date(time * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
          return (
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            '-' +
            date.getDate() +
            ' '
          )
        }
      },
    },
  }
</script>
