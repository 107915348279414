<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" width="1450px">
    <el-table ref="table" border :data="list">
      <el-table-column
        align="center"
        label="套餐名称"
        prop="packageName"
        show-overflow-tooltip
        width="200"
      />
      <el-table-column
        align="center"
        label="状态"
        prop="status"
        show-overflow-tooltip
        width="150"
      />
      <el-table-column
        align="center"
        label="总流量"
        prop="flowTotal"
        show-overflow-tooltip
        width="150"
      />
      <el-table-column
        align="center"
        label="已用流量"
        prop="used"
        show-overflow-tooltip
        width="150"
      />
      <el-table-column
        align="center"
        label="激活时间"
        prop="activationTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="失效时间"
        prop="expireTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="更新时间"
        prop="updatedTime"
        show-overflow-tooltip
      />
      <el-table-column
        v-if="$perms('iot_cardUserInfo_editExpireTime')"
        align="center"
        fixed="right"
        label="操作"
        width="200"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('iot_cardUserInfo_editExpireTime')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑套餐有效期
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Edit ref="edit" @fetch-data="fetchData" />
  </el-dialog>
</template>

<script>
  import { getPackageDetails } from '@/api/cardUserInformation'
  import Edit from './editExpireTime.vue'
  export default {
    name: 'PackageDetails',
    components: { Edit },
    data() {
      return {
        list: [],
        title: '套餐详情',
        dialogFormVisible: false,
        queryForm: {
          serialNumber: '',
        },
        edit: false,
      }
    },
    created() {},
    methods: {
      showDetails(val) {
        this.dialogFormVisible = true
        this.queryForm.serialNumber = val
        this.fetchData()
      },
      async fetchData() {
        const { result } = await getPackageDetails(this.queryForm)
        this.list = result.package_list
      },
      handleEdit(val) {
        this.$refs['edit'].showComponent(val)
      },
    },
  }
</script>
