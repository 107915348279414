var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tabs" },
    [
      _c(
        "el-card",
        { staticClass: "tabs-card", attrs: { shadow: "hover" } },
        [
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              staticStyle: { margin: "0 0 15px" },
              attrs: { justify: "center", type: "flex" }
            },
            [
              _c(
                "el-col",
                [
                  _vm.title
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleFold }
                        },
                        [
                          _c("vab-icon", {
                            staticClass: "vab-dropdown",
                            class: { "vab-dropdown-active": !_vm.fold },
                            attrs: { icon: "arrow-up-s-line" }
                          }),
                          _vm._v(" " + _vm._s(_vm.title) + " ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.desc
                    ? _c(
                        "el-tooltip",
                        { attrs: { placement: "top" } },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v(_vm._s(_vm.desc))]
                          ),
                          _c("vab-icon", {
                            staticStyle: { color: "#409eff" },
                            attrs: { icon: "information-line" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { display: "flex", "justify-content": "right" }
                },
                [
                  _vm.excelData
                    ? _c(
                        "download-excel",
                        {
                          staticClass: "export-excel-wrapper",
                          attrs: {
                            "before-finish": _vm.finishDownload,
                            "before-generate": _vm.startDownload,
                            data: _vm.excelData,
                            fields: _vm.json_fields,
                            header: _vm.title,
                            name: "用户数据明细表.xls"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-menu",
                                loading: _vm.buttonLoading,
                                type: "success"
                              }
                            },
                            [_vm._v(" 导出 ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: { data: _vm.tableData, height: _vm.height }
            },
            _vm._l(_vm.tableColumns, function(column) {
              return _c("el-table-column", {
                key: column.prop,
                attrs: { label: column.label, prop: column.prop },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._t("default", null, {
                            column: column,
                            scope: scope
                          }),
                          column.label === "日期"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("timeFormat")(
                                        scope.row[column.prop]
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : column.label === "用户数（百分比）"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.count) +
                                    " (" +
                                    _vm._s(scope.row.rate) +
                                    "%) "
                                )
                              ])
                            : column.label === "新增用户（百分比）"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.count) +
                                    " (" +
                                    _vm._s(scope.row.rate) +
                                    "%) "
                                )
                              ])
                            : column.label === "活跃用户（百分比）"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.count) +
                                    " (" +
                                    _vm._s(scope.row.rate) +
                                    "%) "
                                )
                              ])
                            : column.label === "新增用户（占比）"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.count) +
                                    " (" +
                                    _vm._s(scope.row.rate) +
                                    "%) "
                                )
                              ])
                            : column.label === "活跃用户（占比）"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(scope.row.aCount) +
                                    " (" +
                                    _vm._s(scope.row.acRate) +
                                    "%) "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " + _vm._s(scope.row[column.prop]) + " "
                                )
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }