<template>
  <div
    class="user-management-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-left-panel :span="20">
        <el-form :inline="true" :model="uploadForm" @submit.native.prevent>
          <el-form-item>
            <el-upload
              ref="upload"
              action=""
              :auto-upload="false"
              class="upload-file"
              :http-request="upLoadFile"
            >
              <el-button slot="trigger" size="small" type="primary">
                选取图标
              </el-button>
              <span style="margin-left: 10px" />
              <el-form-item>
                <el-input
                  v-model.trim="uploadForm.name"
                  clearable
                  placeholder="设备型号"
                />
              </el-form-item>
              <span style="margin-left: 10px" />
              <el-form-item>
                <el-select
                  v-model="uploadForm.model"
                  clearable
                  placeholder="设备类型"
                  style="width: 100px"
                >
                  <el-option
                    v-for="item in modelList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <span style="margin-left: 10px" />
              <el-form-item>
                <el-select
                  v-model="uploadForm.ptz_type"
                  clearable
                  placeholder="ptz类型"
                  style="width: 100px"
                >
                  <el-option
                    v-for="item in ptzTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <span style="margin-left: 10px" />
              <el-form-item>
                <el-input
                  v-model.trim="uploadForm.type"
                  clearable
                  placeholder="型号值"
                  style="width: 100px"
                />
              </el-form-item>
              <el-button
                v-if="$perms('version_versioninfo_upload')"
                size="small"
                style="margin-left: 10px"
                type="success"
                @click="submitUpload"
              >
                上传
              </el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="30">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="设备型号"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <div v-if="item.label === '图标'">
            <el-image
              :preview-src-list="row[item.prop]"
              :src="row[item.prop]"
              style="width: 80px; height: 80px"
            />
          </div>
          <span v-else-if="item.label === '设备类型'">
            {{ row[item.prop] | daviceTypeFilter }}
          </span>
          <span v-else-if="item.label === 'ptz类型'">
            {{ row[item.prop] | ptzTypeFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="150"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('serve_dviceorder_delete')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getDeviceTypeList, deleteDeviceType } from '@/api/device'
  import VabDraggable from 'vuedraggable'
  const axios = require('axios')
  import network from '@/config/index.js' // 引用 baseURL

  export default {
    name: 'DeviceType',
    components: {
      VabDraggable,
    },
    filters: {
      daviceTypeFilter(value) {
        if (value === 1) {
          return 'DVR'
        } else if (value === 2) {
          return 'IPC'
        }
      },
      ptzTypeFilter(value) {
        if (value === 1) {
          return 'DVR球机'
        } else if (value === 2) {
          return 'IPCPTZ'
        } else if (value === 3) {
          return '同轴菜单'
        }
      },
    },
    data() {
      return {
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        modelList: [
          {
            label: 'DVR',
            value: '1',
          },
          {
            label: 'IPC',
            value: '2',
          },
        ],
        ptzTypeList: [
          {
            label: 'DVR球机',
            value: '1',
          },
          {
            label: 'IPCPTZ',
            value: '2',
          },
          {
            label: '同轴菜单',
            value: '3',
          },
        ],
        checkList: ['设备型号', '设备类型', 'ptz类型', '型号值', '图标'],
        columns: [
          {
            label: '设备型号',
            width: '210',
            prop: 'name',
            sortable: false,
          },
          {
            label: '设备类型',
            width: 'auto',
            prop: 'model',
            sortable: false,
          },
          {
            label: 'ptz类型',
            width: 'auto',
            prop: 'ptz_type',
            sortable: false,
          },
          {
            label: '型号值',
            width: 'auto',
            prop: 'type',
            sortable: false,
            disableCheck: false,
          },
          {
            label: '图标',
            width: 'auto',
            prop: 'icon',
            sortable: false,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
        uploadForm: {
          name: '',
          model: '',
          ptz_type: '',
          type: '',
        },
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getDeviceTypeList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('确定要删除当前项吗', null, async () => {
            const { msg } = await deleteDeviceType({ deviceTypeID: row.id })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        }
      },
      upLoadFile(file) {
        this.formData.append('iconFile', file.file)
      },
      submitUpload() {
        this.$confirm('是否上传文件?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 构建传给后端的数据
            this.formData = new FormData()
            this.formData.append('name', this.uploadForm.name)
            this.formData.append('model', this.uploadForm.model)
            this.formData.append('type', this.uploadForm.type)
            this.formData.append('ptz_type', this.uploadForm.ptz_type)

            this.$refs.upload.submit() // 会触发 http-request,即 upLoadFile 方法
            let url = network.baseURL + '/deviceManagement/addDeviceType'
            console.log('url: ' + url)
            let headers = {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
            axios
              .post(url, this.formData, headers)
              .then(
                function (res) {
                  console.log(res)
                  if (res.data.code === 0) {
                    this.$message({
                      type: 'success',
                      message: '文件上传成功!',
                    })
                    this.fetchData()
                  } else {
                    this.$message({
                      type: 'error',
                      message: '文件上传失败! ' + res.data.msg,
                    })
                  }
                }.bind(this)
              )
              .catch((res) => {
                console.log(res)
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消上传',
            })
          })
      },
    },
  }
</script>
