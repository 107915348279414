<template>
  <el-dialog
    title="添加"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form
      ref="form"
      label-position="left"
      label-width="110px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="生产编码" prop="orderNumber">
        <el-input v-model="form.orderNumber" />
      </el-form-item>
      <el-form-item label="用户名" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="生产数量" prop="quantity">
        <el-input v-model="form.quantity" />
      </el-form-item>
      <el-form-item label="用户邮箱" prop="email">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-form-item label="国家代码" prop="countryCode">
        <el-input v-model="form.countryCode" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">保 存</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { addData } from '@/api/customCustomer'

  export default {
    name: 'AddEditComponet',
    data() {
      return {
        title: '',
        dialogFormVisible: false,
        form: {},
        rules: {
          orderNumber: [
            { required: true, trigger: 'blur', message: '请输入生产编码' },
          ],
          name: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
          quantity: [
            { required: true, trigger: 'blur', message: '请输入生产数量' },
          ],
          email: [
            { required: true, trigger: 'blur', message: '请输入用户邮箱' },
          ],
          countryCode: [
            { required: true, trigger: 'blur', message: '请输入国家iso2代码' },
          ],
        },
      }
    },
    methods: {
      showComponent() {
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await addData(this.form)
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>
