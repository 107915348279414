<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="序列号" prop="file">
        <el-upload
          ref="upload"
          accept=".txt"
          action="string"
          :auto-upload="false"
          class="upload"
          :http-request="upLoadFile"
          :limit="1"
          :on-change="onChange"
          :on-exceed="handleExceed"
          :on-remove="handleRemove"
        >
          <el-button
            class="upload_button"
            slot="trigger"
            size="small"
            type="primary"
          >
            选择txt文件
          </el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="套餐" prop="packageId">
        <el-select v-model="form.packageId" placeholder="请选择套餐">
          <el-option
            v-for="item in comboList"
            :key="item.id"
            :label="item.combo_name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save()">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    batchOrderFlowPackage,
    getComboType,
  } from '@/api/cardUserInformation'
  export default {
    name: 'CardUserInformation',
    data() {
      const validateFile = (rule, value, callback) => {
        console.log(this.form.file.length)
        console.log(this.form.file)
        if (this.form.file.length === 0) {
          callback(new Error('请选择序列号文件'))
        } else {
          callback()
        }
      }
      return {
        comboList: [],
        form: {
          file: [],
        },
        rules: {
          file: [{ required: true, trigger: 'blur', validator: validateFile }],
          packageId: [
            { required: true, trigger: 'blur', message: '请选择套餐' },
          ],
        },
        title: '流量充值',
        dialogFormVisible: false,
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      showComponent() {
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      upLoadFile(file) {
        this.formData.append('serialNumberFile', file.file)
      },
      handleExceed(files) {
        this.$refs.upload.clearFiles()
        this.$nextTick(() => {
          this.$refs.upload.handleStart(files[0])
        })
      },
      handleRemove() {
        this.form.file = []
      },
      onChange(file) {
        this.form.file = URL.createObjectURL(file.raw)
        console.log(this.form)
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.formData = new FormData()
            this.$refs.upload.submit()
            this.formData.append('packageId', this.form.packageId)
            const { reason } = await batchOrderFlowPackage(this.formData)
            this.$baseMessage(reason, 'success', 'vab-hey-message-success')
            this.close()
          }
        })
      },
      async fetchData() {
        const { result } = await getComboType()
        this.comboList = result
      },
    },
  }
</script>
