<template>
  <div
    class="upload-info-container"
    :class="{ 'vab-fullscreen': isFullscreen }"
  >
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.userName"
              clearable
              placeholder="用户名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.uid"
              clearable
              placeholder="uid"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <vab-draggable v-bind="dragOptions" :list="columns">
                  <div v-for="(item, index) in columns" :key="item + index">
                    <vab-icon icon="drag-drop-line" />
                    <el-checkbox
                      :disabled="item.disableCheck === true"
                      :label="item.label"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </vab-draggable>
              </el-checkbox-group>
              <template #reference>
                <el-button
                  icon="el-icon-setting"
                  style="margin: 0 0 10px 0 !important"
                  type="primary"
                >
                  显示列设置
                </el-button>
              </template>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button
              style="margin: 0 10px 10px 0 !important"
              type="primary"
              @click="clickFullScreen"
            >
              <vab-icon
                :icon="
                  isFullscreen ? 'fullscreen-exit-fill' : 'fullscreen-fill'
                "
              />
              表格全屏
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
      >
        <template #default="{ row }">
          <span v-if="item.label === '状态'">
            {{ row[item.prop] | statusFilter }}
          </span>
          <span v-else-if="item.label === '添加时间'">
            {{ row[item.prop] | dateFilter }}
          </span>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="120"
      >
        <template #default="{ row }">
          <a
            v-if="$perms('log_devicelog_download')"
            class="download"
            :href="row.appLog_url"
          >
            下载日志文件
          </a>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getRequestList } from '@/api/appLog'

  export default {
    name: 'AppLog',
    filters: {
      statusFilter(value) {
        if (value === 0) {
          return '成功'
        } else {
          return '失败'
        }
      },
      dateFilter(value) {
        if (value === 'N/A' || !value) {
          return 'N/A'
        }
        let date = new Date(value * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        return (
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':' +
          date.getSeconds()
        )
      },
    },
    data() {
      return {
        isFullscreen: false,
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        checkList: [
          '用户名',
          '用户id',
          'uid',
          '文件名',
          '状态',
          '添加时间',
          '最高平均延时',
        ],
        columns: [
          {
            label: '用户名',
            prop: 'user__username',
            sortable: false,
          },
          {
            label: '用户id',
            prop: 'user_id',
            sortable: false,
          },
          {
            label: 'uid',
            width: 'auto',
            prop: 'uid',
            sortable: false,
            disableCheck: true,
          },
          {
            label: '文件名',
            width: 'auto',
            prop: 'filename',
            sortable: false,
          },
          {
            label: '状态',
            width: '100',
            prop: 'status',
            sortable: false,
          },
          {
            label: '添加时间',
            width: '200',
            prop: 'add_time',
            sortable: true,
          },
          {
            label: '最高平均延时',
            width: '150',
            prop: 'average_delay',
            sortable: true,
          },
        ],
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      finallyColumns() {
        return this.columns.filter((item) =>
          this.checkList.includes(item.label)
        )
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      clickFullScreen() {
        this.isFullscreen = !this.isFullscreen
        this.handleHeight()
      },
      handleHeight() {
        if (this.isFullscreen) this.height = this.$baseTableHeight(1) + 210
        else this.height = this.$baseTableHeight(1)
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getRequestList(this.queryForm)
        this.list = list
        this.total = total
        this.listLoading = false
      },
    },
  }
</script>
