<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="6">
        <el-button
          v-if="$perms('user_userinfo_insert')"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="18">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <!-- 选择查询内容 -->
          <el-form-item>
            <el-select
              v-model="selectValue"
              clearable
              placeholder="存储桶名称"
              @change="selectChange"
            >
              <el-option
                v-for="item in selectList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-show="showBucketInput"
              v-model.trim="queryForm.bucket"
              clearable
              placeholder="请输入存储桶名称"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.subscription_group"
              clearable
              placeholder="请输入内购订阅组"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.subscription_group_id"
              clearable
              placeholder="请输入内购订阅组id"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.package_type"
              clearable
              placeholder="订阅类型"
            >
              <el-option
                v-for="item in packageTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column align="center" label="序号" width="55">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="套餐ID"
        prop="storeMealID"
        show-overflow-tooltip
        width="70"
      />
      <el-table-column
        align="center"
        label="存储桶名称"
        prop="bucket"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="天数"
        prop="day"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="有效期(月)"
        prop="expire"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="commodityTypeFormat"
        label="类型"
        prop="commodity_type"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="payTypeFormat"
        label="支付类型"
        prop="pay_type"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="规格码"
        prop="commodity_code"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="discountsFormat"
        label="是否优惠"
        prop="is_discounts"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="优惠价格"
        prop="discount_price"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="虚拟金额"
        prop="virtual_price"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="价格"
        prop="price"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="货币"
        prop="currency"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="符号"
        prop="symbol"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="showFormat"
        label="是否隐藏"
        prop="is_show"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="aiFormat"
        label="是否支持AI"
        prop="is_ai"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        :formatter="pixelLevelFormat"
        label="像素等级"
        prop="pixel_level"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="苹果内购商品id"
        prop="product_id"
        show-overflow-tooltip
        width="120"
      />
      <el-table-column
        align="center"
        label="内购订阅组"
        prop="subscription_group"
        show-overflow-tooltip
        width="120"
      />
      <el-table-column
        align="center"
        label="内购订阅组id"
        prop="subscription_group_id"
        show-overflow-tooltip
        width="120"
      />
      <el-table-column
        align="center"
        :formatter="packageTypeFormat"
        label="订阅类型"
        prop="package_type"
        show-overflow-tooltip
        width="120"
      />
      <el-table-column
        align="center"
        label="添加时间"
        prop="addTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="更新时间"
        prop="updTime"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="150"
      >
        <template #default="{ row }">
          <el-button
            v-if="$perms('user_userinfo_edit')"
            type="primary"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$perms('user_userinfo_del')"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { deleteStoreMeal, getStoreMealList } from '@/api/packageInformation'
  import Edit from './components/PackageInformation'

  export default {
    name: 'PackageInformation',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        selectValue: '',
        selectList: [
          {
            value: '存储桶名称',
            label: '存储桶名称',
          },
        ],
        moldValue: '国内',
        moldList: [
          {
            value: '国内',
            label: '国内',
          },
          {
            value: '国外',
            label: '国外',
          },
        ],
        freeValue: '否',
        freeList: [
          {
            value: '否',
            label: '否',
          },
          {
            value: '是',
            label: '是',
          },
        ],
        packageTypeList: [
          {
            value: 0,
            label: '非订阅',
          },
          {
            value: 1,
            label: '订阅',
          },
        ],
        showBucketInput: true,
        showMoldSelect: false,
        showFreeSelect: false,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.storeMealID) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        if (row.storeMealID) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteStoreMeal({
              storeMealID: row.storeMealID,
            })
            this.$baseMessage(msg, 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await deleteStoreMeal({ ids })
              this.$baseMessage(msg, 'success', 'vab-hey-message-success')
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { list, total },
        } = await getStoreMealList(this.queryForm)
        this.list = list
        this.total = total
        console.log(list)
        this.listLoading = false
      },
      commodityTypeFormat(row) {
        if (row.commodity_type === 0) {
          return '事件型'
        } else {
          return '连续型'
        }
      },
      payTypeFormat(row) {
        let payType = ''
        for (let payTypeID of row.pay_type) {
          if (payTypeID === 1) {
            payType += 'PayPal,'
          } else if (payTypeID === 2) {
            payType += '支付宝,'
          } else if (payTypeID === 3) {
            payType += '微信支付,'
          } else if (payTypeID === 4) {
            payType += '苹果支付,'
          } else if (payTypeID === 5) {
            payType += '苹果内购,'
          } else if (payTypeID === 10) {
            payType += '免费体验,'
          } else if (payTypeID === 11) {
            payType += '激活码,'
          }
        }
        return payType.slice(0, -1) // 切掉最后的','
      },
      discountsFormat(row) {
        if (row.is_discounts === 0) {
          return '否'
        } else {
          return '是'
        }
      },
      showFormat(row) {
        if (row.is_show === 0) {
          return '否'
        } else {
          return '是'
        }
      },
      aiFormat(row) {
        const isAi = row.is_ai
        if (isAi === 0) {
          return '不支持'
        } else if (isAi === 1) {
          return '支持'
        } else {
          return isAi
        }
      },
      packageTypeFormat(row) {
        const package_type = row.package_type
        if (package_type === 0) {
          return '非订阅'
        } else if (package_type === 1) {
          return '订阅'
        } else {
          return package_type
        }
      },
      pixelLevelFormat(row) {
        const pixelLevel = row.pixel_level
        if (pixelLevel === 0) {
          return '低于4k像素'
        } else if (pixelLevel === 1) {
          return '大于等于4k像素'
        } else {
          return pixelLevel
        }
      },
      selectChange(selectValue) {
        if (selectValue == '存储桶名称') {
          // 切换显示
          this.showBucketInput = true
          this.showMoldSelect = false
          this.showFreeSelect = false
          // 清空无关的查询数据
          this.queryForm.mold = ''
          this.queryForm.is_free = ''
        } else if (selectValue == '国内外') {
          this.showBucketInput = false
          this.showMoldSelect = true
          this.showFreeSelect = false
          this.queryForm.bucket = ''
          this.queryForm.mold = 0
          this.queryForm.is_free = ''
        } else if (selectValue == '是否免费') {
          this.showBucketInput = false
          this.showMoldSelect = false
          this.showFreeSelect = true
          this.queryForm.bucket = ''
          this.queryForm.mold = ''
          this.queryForm.is_free = 0
        }
      },
      moldChange(moldValue) {
        if (moldValue == '国内') {
          this.queryForm.mold = 0
        } else {
          this.queryForm.mold = 1
        }
      },
      freeChange(freeValue) {
        if (freeValue == '否') {
          this.queryForm.is_free = 0
        } else {
          this.queryForm.is_free = 1
        }
      },
    },
  }
</script>
